.DepositInfoModal__icon {
  position: absolute;
  top: 32px;
  left: -16px;
}
.DepositInfoModal__cont {
  width: 616px;
  padding: 40px;
}
.DepositInfoModal__cont .WalletCard {
  margin-top: -16px;
  margin-bottom: 24px;
}
.DepositInfoModal__withdrawAction {
  text-align: center;
  margin-top: 40px;
}
.DepositInfoModal__withdrawAction .Button {
  min-width: 200px;
}
.DepositInfoModal__withdrawalHistory {
  border-radius: 8px;
  width: calc(100% + 80px);
  margin: 0 -40px -40px;
}
.DepositInfoModal__columns {
  display: flex;
  width: 100%;
}
.DepositInfoModal__column {
  flex: 1;
  max-width: 50%;
}
.DepositInfoModal__withdrawal_form {
  display: flex;
  align-items: center;
  margin-top: 40px;
}
.DepositInfoModal__withdrawal_form .Button {
  margin-left: 16px;
}
.DepositInfoModal__withdrawal_form__currency {
  color: var(--text-black);
}
.adaptive .DepositInfoModal__wrapper {
  padding-top: 80px;
}
.adaptive .DepositInfoModal__wrapper .DepositInfoModal__cont {
  padding-top: 48px;
}
.adaptive .DepositInfoModal__wrapper .DepositInfoModal__cont .WalletCard {
  margin: 16px 0 32px;
}
.adaptive .DepositInfoModal__column {
  display: block;
}
.adaptive .DepositInfoModal__column tbody {
  display: block;
}
.adaptive .DepositInfoModal__column:first-child {
  margin-right: 16px;
}
.adaptive .DepositInfoModal__icon {
  left: 0;
  right: 0;
  top: -16px;
  margin: 0 auto;
}
.adaptive .DepositInfoModal__cont {
  box-sizing: border-box;
  width: 100%;
  padding: 24px 16px;
}
.adaptive .DepositInfoModal__cont .InfoRow {
  display: block;
}
.adaptive .DepositInfoModal__cont .InfoRow td {
  padding: 0;
}
.adaptive .DepositInfoModal__cont .InfoRow__label {
  line-height: 16px;
  text-align: left;
  display: block;
}
.adaptive .DepositInfoModal__cont .InfoRow__value {
  display: block;
}
