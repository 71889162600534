.TransactionResponse {
  text-align: center;
}
.TransactionResponse .Modal__box {
  max-width: 330px;
  width: 100%;
}
.TransactionResponse__container {
  padding: 43.4px 20px 30px;
  width: 100%;
  background: #fff;
  border-radius: var(--radius-panel);
  box-sizing: border-box;
  position: relative;
}
.TransactionResponse h3 {
  margin: 18.4px 0 10px;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: var(--dark-gunmetal);
}
.TransactionResponse p {
  margin-bottom: 20px;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: var(--cool-gray);
}
.TransactionResponse__statusIcon.COMPLETED {
  margin: 3.4px 0;
}
.TransactionResponse .isvg {
  display: inline-flex;
  align-items: center;
}
.TransactionResponse .Button {
  width: 100%;
  padding: 0 15px;
}
.TransactionResponse .Button.secondary-alice .Button__label {
  font-weight: 600;
  font-size: 14px;
  line-height: 100%;
  color: var(--dark-black);
}
.TransactionResponse .Button .isvg {
  margin-left: 1px;
}
.TransactionResponse .Button:not(:last-child) {
  margin-bottom: 15px;
}
