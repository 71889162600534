.ManageBalanceModal {
  width: 330px;
}
.ManageBalanceModal__icon {
  margin-right: 16px;
}
.ManageBalanceModal__input_button {
  display: flex;
}
.ManageBalanceModal__input_button .Button {
  margin-left: 16px;
}
.ManageBalanceModal__row {
  display: flex;
  align-items: center;
  margin: 40px 0;
}
.ManageBalanceModal__row .SwitchTabs,
.ManageBalanceModal__row .Dropdown {
  flex: 1;
}
.ManageBalanceModal__submit_wrap {
  margin-bottom: 8px;
}
.ManageBalanceModal__submit_wrap .Button {
  width: 100%;
}
.adaptive .ManageBalanceModal {
  width: 100%;
}
.adaptive .ManageBalanceModal__row:first-child {
  margin-top: 0;
}
.adaptive .ManageBalanceModal__row .Button {
  width: auto;
}
