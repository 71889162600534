.PasswordInfo {
  text-align: left;
  color: var(--text-black);
}
.PasswordInfo ul {
  padding: 0;
  margin: 4px 18px 0;
}
.PasswordInfo ul li {
  color: var(--text-red);
}
.PasswordInfo ul li.success {
  color: var(--green);
}
