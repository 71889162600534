.WalletAddress {
  display: inline-flex;
  align-items: baseline;
}
.WalletAddress svg {
  display: block;
  align-self: center;
  width: 16px;
  height: 16px;
  margin-right: 4px;
}
