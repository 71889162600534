.Footer__social {
  display: flex;
}
.Footer__social .Footer__social__link {
  display: block;
  width: 32px;
  height: 32px;
  margin-right: 8px;
  color: inherit;
  cursor: pointer;
  transition: all 0.3s ease;
}
.Footer__social .Footer__social__link.telegram {
  transform-origin: top left;
  transform: scale(0.57) translate(-6px, -6px);
  filter: grayscale(1) contrast(0.4) brightness(1.55);
}
.Footer__social .Footer__social__link.discord,
.Footer__social .Footer__social__link.discord .isvg {
  display: flex;
  align-items: center;
  justify-content: center;
}
.Footer__social .Footer__social__link.discord svg {
  width: 23px;
  height: 23px;
}
.Footer__social .Footer__social__link.discord svg path {
  fill: currentColor;
}
.Footer__social .Footer__social__link:hover.facebook {
  color: #0084FF;
}
.Footer__social .Footer__social__link:hover.twitter {
  color: #55ACEE;
}
.Footer__social .Footer__social__link:hover.instagram {
  color: #E4405F;
}
.Footer__social .Footer__social__link:hover.medium {
  color: #02B875;
}
.Footer__social .Footer__social__link:hover.linkedin {
  color: #0077B5;
}
.Footer__social .Footer__social__link:hover.telegram {
  filter: initial;
}
.Footer__social .Footer__social__link:hover.discord {
  color: #7289da;
}
