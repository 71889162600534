.Exchanger__WatchVideo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px 20px;
  margin-bottom: 10px;
  width: 100%;
  padding: 17px 43px;
  background: rgba(80, 120, 230, 0.65);
  box-shadow: 4px 10px 45px rgba(199, 201, 207, 0.3);
  backdrop-filter: blur(22.5px);
  border-radius: 12px;
  box-sizing: border-box;
}
.Exchanger__WatchVideo-text {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 20px;
  line-height: 18px;
  color: #fff;
}
.Exchanger__WatchVideo-text .isvg {
  margin-right: 7.8px;
}
.Exchanger__WatchVideo-button .Button.middle .isvg {
  margin-right: 6.5px;
}
.Exchanger__WatchVideo-button .Button.middle .Button__label {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
}
.adaptive .Exchanger__WatchVideo {
  flex-direction: column;
  padding: 25px 20px;
}
.adaptive .Exchanger__WatchVideo-text {
  font-size: 14px;
}
.adaptive .Exchanger__WatchVideo-text .isvg {
  width: 18px;
  height: 18px;
}
.adaptive .Exchanger__WatchVideo-button {
  width: 100%;
  max-width: 320px;
}
.adaptive .Exchanger__WatchVideo-button .Button {
  width: 100%;
}
