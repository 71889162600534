.GoogleCodeModal {
  text-align: center;
}
.GoogleCodeModal__description {
  font-size: 13px;
  line-height: 24px;
  margin: 16px 0;
}
.GoogleCodeModal__qr_code {
  display: block;
  width: 200px;
  height: 200px;
  margin: 0 auto;
}
.GoogleCodeModal__hash {
  user-select: text;
  margin: 32px 0;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
}
.GoogleCodeModal__submit_wrapper {
  display: flex;
  margin: 32px -8px 0;
}
.GoogleCodeModal__submit_wrapper .Button {
  flex: 1;
  margin: 0 8px;
}
