.GAConfirmModal__submit_wrapper {
  width: 100%;
  display: flex;
  padding-top: 40px;
}
.GAConfirmModal__submit_wrapper .Button {
  margin: auto;
}
.GAConfirmModal .Input__wrapper {
  margin-top: 32px;
}
.GAConfirmModal__autoCompleteHack {
  height: 0;
  padding: 0;
  border: 0;
  pointer-events: none;
}
