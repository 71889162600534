.adaptive .p2p-orders .orders-list-item,
.p2p-orders .adaptive .orders-list-item {
  grid-template-columns: auto;
  padding: 0 0 20px;
  background: transparent;
  box-sizing: border-box;
  max-width: 100%;
  border-bottom: 1px solid #f1f1f1;
  border-radius: 0;
}
.adaptive .p2p-orders .orders-list-item:not(:last-child),
.p2p-orders .adaptive .orders-list-item:not(:last-child) {
  margin-bottom: 20px;
}
.adaptive .p2p-orders .orders-list .CabinetTable td:not(:first-child),
.p2p-orders .adaptive .orders-list .CabinetTable td:not(:first-child) {
  margin-top: 0;
}
.adaptive .p2p-orders .orders-list .CabinetTable td:nth-child(2),
.p2p-orders .adaptive .orders-list .CabinetTable td:nth-child(2),
.adaptive .p2p-orders .orders-list .CabinetTable td:nth-child(5),
.p2p-orders .adaptive .orders-list .CabinetTable td:nth-child(5) {
  grid-column: 1/3;
}
.adaptive .p2p-orders .orders-list .CabinetTable td:nth-child(2),
.p2p-orders .adaptive .orders-list .CabinetTable td:nth-child(2) {
  margin: 15px 0 10px;
}
.adaptive .p2p-orders .orders-list .CabinetTable td:nth-child(5),
.p2p-orders .adaptive .orders-list .CabinetTable td:nth-child(5) {
  margin: 15px 0 0;
}
.adaptive .p2p-orders .orders-list-user__info,
.p2p-orders .adaptive .orders-list-user__info {
  margin-left: 0;
}
.adaptive .p2p-orders .orders-list-price,
.p2p-orders .adaptive .orders-list-price {
  font-size: 22px;
  line-height: 27px;
}
.adaptive .p2p-orders .orders-list-buy,
.p2p-orders .adaptive .orders-list-buy {
  padding: 3px 0 0 10px;
}
.adaptive .p2p-orders .orders-list-buy .Button.small,
.p2p-orders .adaptive .orders-list-buy .Button.small {
  min-width: 90px;
}
.adaptive .p2p-orders .orders-list-limits,
.p2p-orders .adaptive .orders-list-limits {
  max-width: 100%;
}
.adaptive .p2p-orders .orders-list-limits__title,
.p2p-orders .adaptive .orders-list-limits__title {
  min-width: auto;
  box-sizing: border-box;
}
.adaptive .p2p-orders .orders-list-limits__limit,
.p2p-orders .adaptive .orders-list-limits__limit {
  margin-top: 5px;
}
.adaptive .p2p-orders .orders-list-payment,
.p2p-orders .adaptive .orders-list-payment {
  max-width: 100%;
  color: #fff;
  gap: 5px;
}
.adaptive .p2p-orders .orders-list-payment__item,
.p2p-orders .adaptive .orders-list-payment__item {
  line-height: 13.41px;
  padding: 5px 8px;
  border-radius: 10px;
}
.adaptive .p2p-orders .orders-list-payment__color,
.p2p-orders .adaptive .orders-list-payment__color {
  display: none;
}
