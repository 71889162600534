.WalletBalance {
  display: flex;
  justify-content: space-between;
  width: 472px;
  min-height: 210px;
  position: relative;
}
.WalletBalance .ContentBox__content {
  width: 100%;
  padding-top: 40px;
  padding-bottom: 40px;
}
.WalletBalance .WalletBalance__selected_buttons {
  margin-top: auto;
  padding-top: 50px;
}
.WalletBalance .WalletBalance__selected_buttons .Button:first-child {
  margin-right: 24px;
}
.WalletBalance .WalletBalance__convert:hover {
  color: var(--almost-black);
}
.WalletBalance .WalletBalance__convert {
  position: absolute;
  right: 40px;
  top: 42px;
  color: var(--text-black);
  cursor: pointer;
  user-select: none;
  text-decoration: underline;
}
.WalletBalance .WalletBalance__list {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: auto;
}
.WalletBalance .WalletBalance__list h3 {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.444444px;
  color: var(--black);
  padding-inline-start: 40px;
  padding-bottom: 40px;
}
.WalletBalance .WalletBalance__list ul {
  list-style: none;
  margin: 0;
}
.WalletBalance .WalletBalance__list ul .WalletBalance__list__item {
  display: flex;
  align-items: center;
}
.WalletBalance .WalletBalance__list ul .WalletBalance__list__item_dot {
  content: "\2022";
  background: linear-gradient(4.37deg, #ffc75d 21.44%, #ffa363 76.19%);
  color: transparent;
  font-weight: bold;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 8px;
  height: 8px;
  margin-right: 8px;
  border-radius: 50%;
}
.WalletBalance .WalletBalance__list ul li {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin: 8px 4px;
  color: var(--text-black);
}
.WalletBalance .WalletBalance__list ul li span {
  color: var(--black);
  width: 60px;
  display: inline-block;
}
.WalletBalance .WalletBalance__pie {
  display: inline-block;
  margin-inline-end: 40px;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  border: 1px solid var(--primary-background);
  background-origin: border-box;
  position: relative;
}
.WalletBalance .WalletBalance__pie .WalletBalance__pie__balance {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 50%;
  position: absolute;
  top: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.WalletBalance .WalletBalance__pie .WalletBalance__pie__balance h3 {
  font-weight: 600;
  font-size: 22px;
  line-height: 32px;
  text-align: center;
  letter-spacing: 1.02px;
  color: var(--black);
  margin-top: 20px;
}
.WalletBalance .WalletBalance__pie .WalletBalance__pie__balance p:hover {
  color: var(--orange-gradient) !important;
}
.WalletBalance .WalletBalance__pie .WalletBalance__pie__balance p {
  display: inline-block;
  padding: 2px 0;
  margin: 2px 4px;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: var(--gray);
  cursor: pointer;
  border-bottom: 1px dotted rgba(0, 0, 0, 0.1);
}
.WalletBalance .WalletBalance__pie .WalletBalance__pie__balance p.active {
  color: var(--black);
}
.WalletBalance__adaptiveHelper {
  width: 100%;
  display: flex;
}
.adaptive .WalletBalance__pie {
  width: 100px;
  height: 100px;
}
.adaptive .WalletBalance__selected_wallet {
  padding-top: 8px;
}
.adaptive .WalletBalance {
  width: 100%;
  display: inline-block;
}
.adaptive .WalletBalance .WalletBalance__selected_buttons {
  padding-top: 50px;
}
.adaptive .WalletBalance .WalletBalance__selected_buttons .Button {
  width: calc(50% - 24px);
}
.adaptive .WalletBalance .WalletBalance__selected_buttons .Button:first-child {
  margin-right: 24px;
}
.adaptive .WalletBalance .WalletBalance__header {
  text-align: center;
}
.adaptive .WalletBalance__pie__balance {
  text-align: center;
}
.adaptive .WalletBalance__pie__balance h3 {
  font-size: 24px;
  line-height: 32px;
}
.adaptive .WalletBalance__pie__balance h3 span {
  border-bottom: 2px solid;
}
.adaptive .WalletBalance__adaptiveHelper {
  width: 240px;
  display: flex;
  flex-flow: row-reverse;
  padding-top: 24px;
  margin: auto;
  box-sizing: border-box;
}
.adaptive .WalletBalance__adaptiveHelper .WalletBalance__pie {
  margin-inline-end: 0;
}
.adaptive .WalletBalance__adaptiveHelper .WalletBalance__list ul {
  padding-inline-start: 24px;
}
.adaptive .WalletBalance__adaptiveHelper .WalletBalance__list ul li {
  font-size: 13px;
  margin: 2px 0px;
}
