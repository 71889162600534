.DepositModal__container {
  box-sizing: border-box;
}
.DepositModal .isvg {
  display: flex;
  align-items: center;
}
.DepositModal p.blue,
.DepositModal span.blue,
.DepositModal h3.blue,
.DepositModal h4.blue {
  color: var(--secondary-blue);
}
.DepositModal p.dark,
.DepositModal span.dark,
.DepositModal h3.dark,
.DepositModal h4.dark {
  color: var(--dark-black);
}
.DepositModal p.secondary,
.DepositModal span.secondary,
.DepositModal h3.secondary,
.DepositModal h4.secondary {
  color: var(--cool-gray);
}
.DepositModal p.extra-small,
.DepositModal span.extra-small,
.DepositModal h3.extra-small,
.DepositModal h4.extra-small {
  font-size: 12px;
}
.DepositModal p.small,
.DepositModal span.small,
.DepositModal h3.small,
.DepositModal h4.small {
  font-size: 14px;
}
.DepositModal p.medium,
.DepositModal span.medium,
.DepositModal h3.medium,
.DepositModal h4.medium {
  font-size: 16px;
}
.DepositModal p.default,
.DepositModal span.default,
.DepositModal h3.default,
.DepositModal h4.default {
  font-weight: 600;
  line-height: 110%;
  letter-spacing: -0.01em;
}
.DepositModal p.hight-height,
.DepositModal span.hight-height,
.DepositModal h3.hight-height,
.DepositModal h4.hight-height {
  line-height: 150%;
}
.DepositModal p.small-height,
.DepositModal span.small-height,
.DepositModal h3.small-height,
.DepositModal h4.small-height {
  line-height: 16px;
}
.DepositModal p.large-height,
.DepositModal span.large-height,
.DepositModal h3.large-height,
.DepositModal h4.large-height {
  line-height: 18px;
}
.DepositModal p.extra-large-height,
.DepositModal span.extra-large-height,
.DepositModal h3.extra-large-height,
.DepositModal h4.extra-large-height {
  line-height: 26px;
}
.DepositModal p.medium-weight,
.DepositModal span.medium-weight,
.DepositModal h3.medium-weight,
.DepositModal h4.medium-weight {
  font-weight: 500;
}
.DepositModal p.left,
.DepositModal span.left,
.DepositModal h3.left,
.DepositModal h4.left {
  text-align: left;
}
.DepositModal p.blue:not(.extra-small),
.DepositModal span.blue:not(.extra-small),
.DepositModal h3.blue:not(.extra-small),
.DepositModal h4.blue:not(.extra-small) {
  margin-top: 10px;
}
.DepositModal .Button {
  border-radius: 15px;
}
.adaptive .DepositModal {
  height: 100%;
  background: none;
}
.adaptive .DepositModal .Modal__box {
  display: flex;
  align-items: flex-start;
  width: 100%;
  padding: 24px 11.5px !important;
  box-shadow: 0px 34px 70px rgba(199, 201, 207, 0.25) !important;
  border-radius: var(--radius-panel-large) !important;
  top: 50%;
  transform: translateY(-50%);
}
.adaptive .DepositModal .Modal__back {
  background: rgba(1, 1, 1, 0.25);
}
.adaptive .DepositModal .medium {
  font-size: 14px;
}
.adaptive .DepositModal .small {
  font-size: 12px;
}
