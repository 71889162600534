.DepositModal__ChoosedBank {
  padding: 29px 44px 19px;
  flex: 1 1;
  box-sizing: border-box;
}
.DepositModal__ChoosedBank h3 {
  margin-bottom: 30px;
}
.DepositModal__ChoosedBank__items {
  display: flex;
  width: 100%;
  margin-bottom: 10px;
}
.DepositModal__ChoosedBank__items:first-of-type {
  margin-top: 24px;
}
.DepositModal__ChoosedBank__items:last-of-type {
  margin-top: 14px;
  margin-bottom: 52px;
}
.DepositModal__ChoosedBank__items:nth-child(2) .DepositModal__InfoWrapper {
  padding: 34px 10px;
}
.DepositModal__ChoosedBank__items:nth-child(2) .DepositModal__InfoWrapper .extra-small {
  font-size: 11px;
}
.DepositModal__ChoosedBank__items:nth-child(3) .DepositModal__InfoWrapper {
  padding: 16px 36.5px;
}
.DepositModal__ChoosedBank__items:nth-child(1) .DepositModal__InfoWrapper {
  padding: 22px 37.8px;
}
.DepositModal__ChoosedBank .DepositModal__InfoWrapper:not(:last-child) {
  margin-right: 10px;
}
.DepositModal__ChoosedBank .buttons {
  margin-top: auto;
}
.DepositModal__ChoosedBank .buttons .Button {
  flex: 1 1 50%;
}
.DepositModal__ChoosedBank .buttons .Button__label {
  font-size: 12px;
}
.DepositModal__ChoosedBank .buttons .Button:not(:last-child) {
  margin-right: 17px;
}
.DepositModal__ChoosedBank .BankLogo .isvg {
  height: 30px;
  max-width: 50vw;
}
.DepositModal__ChoosedBank .BankLogo .isvg g {
  transform: none;
}
.DepositModal__ChoosedBank .BankLogo .isvg svg {
  transform: none;
  max-width: 100%;
  max-height: 100%;
  width: 108px;
  height: 30px;
}
.DepositModal__ChoosedBank .BankLogo .isvg image {
  width: 100%;
  height: 100%;
}
.adaptive .DepositModal__ChoosedBank {
  padding: 17px 11px 22px;
}
.adaptive .DepositModal__ChoosedBank h3 {
  margin-bottom: 38px;
}
.adaptive .DepositModal__ChoosedBank__items {
  width: 100%;
  flex-wrap: wrap;
}
.adaptive .DepositModal__ChoosedBank__items .hight-height {
  line-height: 18px;
}
.adaptive .DepositModal__ChoosedBank__items:first-child {
  margin-top: 28px;
}
.adaptive .DepositModal__ChoosedBank__items:first-child .small {
  font-size: 11px;
}
.adaptive .DepositModal__ChoosedBank__items:nth-child(1) .DepositModal__InfoWrapper {
  min-height: 110px;
  box-sizing: border-box;
  padding: 9.5px 14px;
}
.adaptive .DepositModal__ChoosedBank__items:nth-child(2) .DepositModal__InfoWrapper {
  padding-top: 30px;
  padding-bottom: 30px;
}
.adaptive .DepositModal__ChoosedBank__items:nth-child(2) .DepositModal__InfoWrapper .extra-small {
  font-size: 12px;
}
.adaptive .DepositModal__ChoosedBank__items:nth-child(3) {
  margin-top: 22px;
  margin-bottom: 0;
}
.adaptive .DepositModal__ChoosedBank__items:nth-child(3) .DepositModal__InfoWrapper {
  padding: 9px 24px;
}
.adaptive .DepositModal__ChoosedBank__items .DepositModal__InfoWrapper {
  flex: 1 1 100%;
  padding: 9.5px 14.8px 9.5px 14.8px;
}
.adaptive .DepositModal__ChoosedBank__items .DepositModal__InfoWrapper:not(:last-child) {
  margin: 0 0 10px;
}
.adaptive .DepositModal__ChoosedBank__items .DepositModal__InfoWrapper .left {
  text-align: center;
}
.adaptive .DepositModal__ChoosedBank .buttons {
  width: auto;
  flex-direction: row !important;
  flex-wrap: wrap;
  margin: 48px -7.5px 0;
}
.adaptive .DepositModal__ChoosedBank .buttons .Button {
  flex: 1 1 40%;
  min-width: 210px;
  margin: 0 7.5px;
}
.adaptive .DepositModal__ChoosedBank .buttons .Button:not(:last-child) {
  margin-bottom: 10px;
}
.adaptive .DepositModal__ChoosedBank .buttons .Button__label {
  font-size: 14px !important;
}
.adaptive .DepositModal__ChoosedBank .buttons .Button__label .isvg {
  height: 15px;
  width: 13.65px;
}
