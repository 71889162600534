.DepositModal__ChooseBank__timeIsOver {
  position: relative;
  padding: 24px 32px;
  min-height: 400px;
}
.DepositModal__ChooseBank__timeIsOver .LoadingStatus__failed__message {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: var(--dark-black);
}
.DepositModal__ChooseBank__timeIsOver .LoadingStatus__failed__description {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: var(--cool-gray);
}
