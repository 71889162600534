.SwapTutorial {
  margin-bottom: var(--gap-size);
}
.SwapTutorial .ContentBox__content {
  display: flex;
  padding: 40px;
}
.SwapTutorial__image {
  flex: 0 0 auto;
  width: 160px;
  height: 160px;
  margin: -16px;
  margin-right: 24px;
}
.SwapTutorial__image .isvg {
  display: block;
}
.SwapTutorial__image .isvg svg {
  display: block;
}
.SwapTutorial__list {
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
}
.SwapTutorial__list li {
  flex: 1;
  display: block;
  margin-right: 16px;
}
.SwapTutorial__list h4 {
  font-weight: 600;
  font-size: 13px;
  line-height: 24px;
  margin-bottom: 8px;
}
.SwapTutorial__list p,
.SwapTutorial__list .link {
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
}
.SwapTutorial__list p {
  margin-bottom: 8px;
}
