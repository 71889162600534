.Chart__profit {
  padding: 40px;
}
.Chart__profit__header {
  display: flex;
  align-items: center;
}
.Chart__profit__header__cont {
  flex: 1;
}
.Chart__profit__header__period {
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  color: var(--text-black);
}
.Chart__profit__header__fiat {
  color: var(--green);
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
}
.Chart__profit__chart {
  margin-top: 8px;
  margin-bottom: -12px;
}
