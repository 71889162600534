.DepositModal__Bank__DepositTransfer {
  padding: 43px 33px 26px;
  flex: 1 1;
  text-align: center;
}
.DepositModal__Bank__DepositTransfer .speed-icon {
  margin-top: 57px;
  margin-bottom: 24px;
}
.DepositModal__Bank__DepositTransfer p {
  max-width: 305px;
  font-weight: 600;
}
.DepositModal__Bank__DepositTransfer h3 {
  margin-top: 0 !important;
  margin-bottom: 24px;
  font-weight: 600;
  font-size: 20px;
}
.DepositModal__Bank__DepositTransfer-subtitle {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.01em;
}
.DepositModal__Bank__DepositTransfer .buttons {
  margin-top: 97px;
}
.DepositModal__Bank__DepositTransfer .buttons .Button {
  flex: 1 1 50%;
  padding: 0 10px;
}
.DepositModal__Bank__DepositTransfer .buttons .Button:not(:last-child) {
  margin-right: 18px;
}
.DepositModal__Bank__DepositTransfer .buttons .Button.secondary-alice .Button__label {
  font-size: 12px;
}
.DepositModal__Bank__DepositTransfer .buttons .Button .isvg {
  margin-left: 5px;
}
.adaptive .DepositModal__Bank__DepositTransfer {
  height: auto;
  padding: 20px 11px;
}
.adaptive .DepositModal__Bank__DepositTransfer h3 {
  margin: 0 0 10px;
  font-weight: 600;
  font-size: 16px;
  line-height: 32px;
  letter-spacing: -0.01em;
}
.adaptive .DepositModal__Bank__DepositTransfer-subtitle {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}
.adaptive .DepositModal__Bank__DepositTransfer .speed-icon {
  margin: 48px 0 24px;
}
.adaptive .DepositModal__Bank__DepositTransfer .buttons {
  margin: 48px -7.5px 0;
  width: auto;
}
.adaptive .DepositModal__Bank__DepositTransfer .buttons .Button {
  flex: 1 1 50%;
  min-width: 210px;
  margin: 0 7.5px;
}
.adaptive .DepositModal__Bank__DepositTransfer .buttons .Button:not(:last-child) {
  margin-bottom: 10px;
}
.adaptive .DepositModal__Bank__DepositTransfer .buttons .Button__label {
  font-size: 14px !important;
}
.adaptive .DepositModal__Bank__DepositTransfer .buttons .Button__label .isvg {
  height: 15px;
  width: 13.65px;
}
.adaptive .DepositModal__Bank__DepositTransfer p.small {
  font-size: 14px;
  margin: 0;
}
