.DepositModal__ChooseBank-items {
  min-height: 413px;
  width: 100%;
  box-sizing: border-box;
}
.DepositModal__ChooseBank-items .ScrollbarBox .ScrollbarTrackY {
  right: -13px !important;
}
.DepositModal__ChooseBank-items .ScrollbarsCustom-Scroller {
  padding: 2px !important;
}
.DepositModal__ChooseBank-items .ScrollbarTrackY {
  background: var(--alice-blue) !important;
  height: 99% !important;
  top: 0 !important;
}
.adaptive .DepositModal__ChooseBank-items {
  margin: 0 0 30px;
  padding-bottom: 24px;
}
