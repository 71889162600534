.FarmingAdaptiveItem:nth-child(odd) .FarmingAdaptiveItem_default {
  background: var(--alice-blue);
}
.FarmingAdaptiveItem_default {
  padding: 12px;
  width: 100%;
  border-radius: var(--radius-panel);
  box-sizing: border-box;
}
.FarmingAdaptiveItem_default > .row {
  justify-content: space-between;
  align-items: center;
}
.FarmingAdaptiveItem_default > .row:first-child {
  margin-bottom: 16px;
}
.FarmingAdaptiveItem_default > .row .col {
  position: relative;
}
.FarmingAdaptiveItem_default > .row .col .LoadingStatus {
  margin-left: 12px;
}
.FarmingAdaptiveItem_default > .row .col:nth-child(2) {
  width: 56px;
  margin-left: auto;
  margin-right: 45px;
}
.FarmingAdaptiveItem_default > .row .col:nth-child(3) {
  width: 14px;
  align-items: center;
}
