.SwapSettings {
  width: 100%;
  box-sizing: border-box;
}
.SwapSettings__close {
  position: absolute;
  display: flex;
  right: 32px;
  top: 16px;
  background-size: 14px;
  width: 38px;
  height: 38px;
  color: var(--text-black);
  cursor: pointer;
  z-index: 2;
}
.SwapSettings__close .isvg {
  display: block;
  margin: auto;
}
.SwapSettings__close .isvg svg {
  display: block;
}
.SwapSettings__close .isvg svg path {
  fill: var(--text-black);
}
.SwapSettings__close:hover .isvg svg path {
  fill: var(--primary-orange);
}
.SwapSettings h2 {
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 16px;
}
.SwapSettings .SectionBlock {
  margin-bottom: 27px;
}
.SwapSettings .SectionBlock__title {
  display: flex;
  align-items: center;
}
.SwapSettings .SectionBlock__title .isvg {
  margin-left: 6px;
}
.SwapSettings .transaction-speed__content {
  display: flex;
}
.SwapSettings .transaction-speed__content .Button {
  width: auto;
  flex: 1 1 auto !important;
}
.SwapSettings .transaction-speed__content .Button:not(:last-child) {
  margin-right: 10px;
}
.SwapSettings .slippage__content {
  display: flex;
}
.SwapSettings .slippage .Button {
  margin-right: 5px;
}
.SwapSettings .slippage .Button:last-of-type {
  margin-right: 20px;
}
.SwapSettings .slippage .Input {
  padding-right: 22px !important;
  padding-left: 12px;
  box-sizing: border-box;
}
.SwapSettings .slippage .Input__container {
  margin-left: auto;
  max-width: 170px;
  width: 100%;
}
.SwapSettings .deadline__title {
  display: flex;
  margin-bottom: 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 180%;
  color: var(--cool-gray);
}
.SwapSettings .deadline__title .isvg {
  margin-left: 7px;
}
.SwapSettings .deadline .Input {
  padding-right: 70px !important;
}
.SwapSettings .Input {
  height: 44px;
  background: none;
  border-radius: 10px;
  border: 1px solid #dfe3f0;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: -0.01em;
}
.SwapSettings .Input__container {
  display: flex;
  align-items: center;
  position: relative;
}
.SwapSettings .Input__icon {
  position: absolute;
  right: 10px;
  font-weight: 600;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: -0.01em;
  color: #dfe3f0;
  user-select: none;
}
.SwapSettings .Button {
  transition: background 0.2s;
}
.SwapSettings .Button__label {
  font-weight: 600;
  font-size: 14px;
  line-height: 100%;
  transition: color 0.2s;
}
.SwapSettings .Button.active {
  background: #3071ee;
  pointer-events: none;
}
.SwapSettings .Button.active .Button__label {
  color: #fff;
}
.adaptive .SwapSettings {
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
}
.adaptive .SwapSettings__close {
  right: 18px;
}
.adaptive .SwapSettings .SectionBlock {
  margin-bottom: 10px;
}
.adaptive .SwapSettings .transaction-speed {
  padding-bottom: 10px;
}
.adaptive .SwapSettings .transaction-speed__content {
  display: flex;
}
.adaptive .SwapSettings .transaction-speed .Button {
  padding: 0 10px;
  margin-bottom: 5px;
}
.adaptive .SwapSettings .transaction-speed .Button:not(:last-child) {
  margin-right: 3px;
}
.adaptive .SwapSettings .slippage__content {
  flex-wrap: wrap;
}
.adaptive .SwapSettings .slippage .Input__container {
  width: 100%;
  max-width: 100%;
  margin: 10px 0 0;
}
