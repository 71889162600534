.Status {
  display: inline-flex;
  align-items: center;
}
.Status svg {
  align-self: center;
  display: block;
  margin-right: 8px;
  height: 24px;
  width: 24px;
  margin-top: -2px;
}
.Status.confirmation {
  color: var(--dark-blue);
}
.Status.warning {
  color: var(--text-yellow);
}
.Status.online,
.Status.done,
.Status.completed,
.Status.confirmed {
  color: var(--text-green);
}
.Status.failed,
.Status.offline,
.Status.cancelled,
.Status.rejected,
.Status.canceled {
  color: var(--text-red);
}
.Status .Status__indicator {
  font-size: 0;
  background: currentColor;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 5px;
}
