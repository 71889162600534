.MethodForm .ContentBox__content {
  padding: 40px;
  overflow: hidden;
}
.MethodForm .ContentBox__content form .MethodForm__grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;
  margin-bottom: 32px;
}
.MethodForm .ContentBox__content form .MethodForm__grid.headers {
  grid-template-columns: 1fr;
}
.MethodForm .ContentBox__content form .File {
  width: 100%;
}
.MethodForm .ContentBox__content form .Button {
  margin-top: auto;
}
.MethodForm .MethodForm__field {
  display: block;
}
.MethodForm .MethodForm__field .MethodForm__field__label {
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  margin-bottom: 8px;
  margin-left: 16px;
}
.MethodForm .MethodForm__response {
  margin: 40px -40px -40px;
  padding: 24px 40px;
}
.MethodForm .MethodForm__response .MethodForm__response__editor {
  font-family: monospace;
  color: #333;
  font-size: 11px;
  line-height: 16px;
  text-align: left;
  display: block;
  width: 100%;
  margin: 0;
  height: 100px;
  overflow: visible;
  resize: vertical;
  background: transparent;
  border: none;
  outline: none;
}
