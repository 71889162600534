.WalletCard {
  position: relative;
  border-radius: 8px;
  height: 92px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  font-weight: 600;
  padding: 0 32px;
  box-sizing: border-box;
  background: currentColor;
}
.WalletCard.success,
.WalletCard.completed,
.WalletCard.confirmed,
.WalletCard.done {
  background: var(--green) !important;
  color: var(--text-green) !important;
}
.WalletCard.primary {
  color: var(--primary-blue);
  background: var(--blue-gradient);
}
.WalletCard.secondary,
.WalletCard.pending {
  background: var(--gray) !important;
  color: var(--gray) !important;
}
.WalletCard.minus {
  background: var(--text-black) !important;
  color: var(--text-black) !important;
}
.WalletCard.fail,
.WalletCard.failed,
.WalletCard.error,
.WalletCard.reject,
.WalletCard.rejected,
.WalletCard.canceled {
  background: var(--text-red) !important;
  color: var(--text-red) !important;
}
.WalletCard > * {
  position: relative;
  z-index: 1;
}
.WalletCard:before {
  position: absolute;
  border-radius: 7px;
  left: 0;
  top: 0;
  height: calc(100% - 4px);
  width: calc(100% - 4px);
  margin: 2px;
  content: "";
  display: block;
  background: var(--primary-background);
}
.WalletCard .WalletCard__title {
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  margin-bottom: 4px;
}
.WalletCard .WalletCard__balance {
  font-size: 24px;
  line-height: 32px;
}
.WalletCard .WalletCard__balanceUsd {
  font-size: 13px;
  line-height: 16px;
}
