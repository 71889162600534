.TransactionHistory__tableAdaptive-operation,
.TransactionHistory__tableAdaptive-date,
.TransactionHistory__tableAdaptive-link,
.TransactionHistory__tableAdaptive-status,
.TransactionHistory__tableAdaptive-currency {
  color: var(--dark-black);
  line-height: 100%;
  font-weight: 600;
}
.TransactionHistory__tableAdaptive-operation,
.TransactionHistory__tableAdaptive-date,
.TransactionHistory__tableAdaptive-link {
  font-size: 14px;
  text-transform: capitalize;
}
.TransactionHistory__tableAdaptive-currency {
  position: relative;
  font-size: 14px;
}
.TransactionHistory__tableAdaptive-status {
  font-weight: 500;
  font-size: 14px;
  color: var(--cool-gray);
}
.TransactionHistory__tableAdaptive-link {
  color: var(--royal-blue);
}
.TransactionHistory__tableAdaptive-date {
  margin-bottom: 4px;
  color: var(--cool-gray);
}
.TransactionHistory__tableAdaptive__item {
  background: var(--alice-blue);
  border-radius: var(--radius-panel);
}
.TransactionHistory__tableAdaptive__item > .Row {
  box-sizing: border-box;
}
.TransactionHistory__tableAdaptive__item > .Row:first-child {
  padding: 15px 21px 26px 21px;
  border-bottom: 1px solid #eee;
}
.TransactionHistory__tableAdaptive__item > .Row:last-child {
  padding: 15px 21px;
}
.TransactionHistory__tableAdaptive__item:not(:last-child) {
  margin-bottom: 8px;
}
.TransactionHistory__tableAdaptive .TransactionHistory__icon-export {
  margin-left: 4px;
}
.TransactionHistory__tableAdaptive .TransactionHistory__icon-export .isvg {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
}
.TransactionHistory__tableAdaptive .TransactionHistory__icon-arrow {
  margin: 0 6px;
  display: flex;
  justify-content: center;
}
.TransactionHistory__tableAdaptive .TransactionHistory__icon-arrow .isvg {
  margin: 0 !important;
}
.TransactionHistory__tableAdaptive .TransactionHistory__icon-sortArrow {
  margin-left: 4px;
}
.TransactionHistory__tableAdaptive .TransactionHistory__icon-sortArrow.up svg {
  transform: rotate(-180deg);
}
.TransactionHistory__tableAdaptive .TransactionHistory__icon-sortArrow .isvg {
  margin: 0;
}
.TransactionHistory__tableAdaptive .TransactionHistory__icon-sortArrow .isvg svg {
  width: 8px;
  height: 8px;
}
.TransactionHistory__tableAdaptive .WalletIcon,
.TransactionHistory__tableAdaptive .DoubleWallets {
  margin-right: 8px;
}
.adaptive .TransactionHistory__tableAdaptive-operation,
.adaptive .TransactionHistory__tableAdaptive-date,
.adaptive .TransactionHistory__tableAdaptive-link {
  font-size: 12px;
}
.adaptive .TransactionHistory__tableAdaptive-status {
  font-size: 12px;
}
.adaptive .TransactionHistory__tableAdaptive__item > .Row {
  box-sizing: border-box;
}
.adaptive .TransactionHistory__tableAdaptive__item > .Row:first-child {
  padding: 12px 12px 16px 12px;
}
.adaptive .TransactionHistory__tableAdaptive__item > .Row:last-child {
  padding: 15px 12px 12px 12px;
}
.adaptive .TransactionHistory__tableAdaptive__item:not(:last-child) {
  margin-bottom: 8px;
}
