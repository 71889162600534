.FloatingButton {
  position: fixed;
  bottom: 64px;
  right: 16px;
  width: 56px;
  z-index: 200;
}
.FloatingButton.static {
  position: relative;
  bottom: 0;
  right: 0;
}
.FloatingButton__back {
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 200;
  transition: background-color 0.3s ease;
}
.FloatingButton__back.static {
  position: relative;
}
.FloatingButton__back.opened {
  pointer-events: all;
  background-color: rgba(0, 0, 0, 0.3);
}
.FloatingButton__back .FloatingButton {
  pointer-events: all;
  position: absolute;
  bottom: 64px;
  right: 16px;
}
.FloatingButton__button {
  cursor: pointer;
  display: flex;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background: var(--blue-gradient);
  color: var(--primary-background);
  box-shadow: 0px 16px 24px rgba(80, 120, 230, 0.3), 0px 0px 8px rgba(80, 120, 230, 0.3);
}
.FloatingButton__button .isvg {
  transition: opacity 0.3s ease;
  position: absolute;
  display: block;
  width: 24px;
  height: 24px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  line-height: 0;
  margin: auto;
}
.FloatingButton__button__close {
  opacity: 0;
}
.FloatingButton__menu {
  width: 200px;
  position: absolute;
  right: 0;
  bottom: 56px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.FloatingButton__menu__item {
  cursor: pointer;
  display: flex;
  align-items: center;
  width: fit-content;
  border-radius: 20px;
  padding: 12px 16px;
  background: var(--primary-background);
  color: var(--text-black);
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  margin-bottom: 16px;
  box-shadow: 0px 6px 24px rgba(0, 0, 0, 0.08), 0 0 2px rgba(0, 0, 0, 0.1);
  animation-direction: reverse;
  opacity: 0;
}
.FloatingButton__menu__item .isvg {
  width: 24px;
  height: 24px;
  line-height: 0;
  margin: -2px;
  margin-left: 8px;
}
.FloatingButton.opened .FloatingButton__menu__item {
  animation: FloatingButtonItem 0.3s forwards;
}
.FloatingButton.opened .FloatingButton__menu__item:nth-last-child(1) {
  animation-delay: 0.05s;
}
.FloatingButton.opened .FloatingButton__menu__item:nth-last-child(2) {
  animation-delay: 0.1s;
}
.FloatingButton.opened .FloatingButton__menu__item:nth-last-child(3) {
  animation-delay: 0.15s;
}
.FloatingButton.opened .FloatingButton__menu__item:nth-last-child(4) {
  animation-delay: 0.2s;
}
.FloatingButton.opened .FloatingButton__menu__item:nth-last-child(5) {
  animation-delay: 0.25s;
}
.FloatingButton.opened .FloatingButton__button__close {
  opacity: 1;
}
.FloatingButton.opened .FloatingButton__button__icon {
  opacity: 0;
}
@keyframes FloatingButtonItem {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
