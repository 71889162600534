.RefillBlock .ContentBox__content {
  padding: 40px;
}
.RefillBlock h3 {
  display: flex;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 24px;
}
.RefillBlock h3 strong {
  font-weight: inherit;
  margin-left: auto;
}
.RefillBlock .RefillBlock__row {
  display: flex;
  align-items: center;
}
.RefillBlock .RefillBlock__row .Button {
  margin-left: auto;
}
