.SwitchTabs {
  --indicator-width: 0;
  --padding: 4px;
  position: relative;
  color: var(--primary-blue);
  background: var(--primary-blue-opacity);
  box-sizing: border-box;
  border-radius: var(--radius-panel);
  height: 48px;
  display: flex;
  user-select: none;
  transition: border-color 400ms ease-in-out;
}
.SwitchTabs.ultra_small {
  height: 24px;
}
.SwitchTabs.small {
  height: 32px;
}
.SwitchTabs.medium {
  height: 46px !important;
}
.SwitchTabs.large {
  height: 65px;
}
.SwitchTabs.secondary {
  color: var(--cloudy);
}
.SwitchTabs.secondary .SwitchTabs__item {
  color: var(--text-black);
  border-color: var(--cloudy);
}
.SwitchTabs.secondary .SwitchTabs__item.active > span {
  color: var(--almost-black);
}
.SwitchTabs.secondary .SwitchTabs__indicator span {
  background: var(--cloudy);
}
.SwitchTabs.disabled {
  opacity: 0.8;
  pointer-events: none;
}
.SwitchTabs.light-blue .SwitchTabs__indicator span {
  background: var(--blue-light-gradient);
}
.SwitchTabs.orange .SwitchTabs__indicator span {
  background: var(--light-orange-gradient);
}
.SwitchTabs.orange .SwitchTabs__item,
.SwitchTabs.light-blue .SwitchTabs__item {
  font-weight: inherit;
  font-size: inherit;
  line-height: inherit;
}
.SwitchTabs.orange .SwitchTabs__item::before,
.SwitchTabs.light-blue .SwitchTabs__item::before {
  opacity: 0;
}
.SwitchTabs.orange .SwitchTabs__item.active,
.SwitchTabs.light-blue .SwitchTabs__item.active {
  font-weight: 600;
  color: #fff;
}
.SwitchTabs__item {
  position: relative;
  flex: 1;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: inset 0 0 0 -1px #f00;
}
.SwitchTabs__item::before {
  position: absolute;
  content: '';
  left: 0;
  width: 1px;
  height: calc(100% - 16px);
  top: 8px;
  background: var(--primary-blue);
  opacity: 0.5;
  transition: opacity 400ms ease-in-out;
}
.SwitchTabs__item > span {
  position: relative;
  z-index: 3;
}
.SwitchTabs__item:first-child::before {
  display: none;
}
.SwitchTabs__item.active {
  color: var(--dark-blue);
}
.SwitchTabs__item.active::before,
.SwitchTabs__item.active + *::before {
  opacity: 0;
}
.SwitchTabs__item.disabled {
  pointer-events: none;
  opacity: 0.5;
}
.SwitchTabs__indicator {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  transition: transform 400ms ease-in-out;
  width: calc(1% * var(--indicator-width));
  transform: translateX(calc(100% * var(--indicator-offset)));
}
.SwitchTabs__indicator span {
  --scale-indicator: 1;
  display: block;
  background: var(--white);
  border-radius: 8px;
  box-shadow: 0 0 2px rgba(64, 112, 255, 0.15), 0 2px 4px rgba(64, 112, 255, 0.1);
  position: absolute;
  top: 4px;
  left: 4px;
  bottom: 4px;
  right: 4px;
  will-change: transform;
}
.SwitchTabs__indicator.animation span {
  animation: SwitchTabsAnimation 400ms ease-in-out;
}
@keyframes SwitchTabsAnimation {
  0%,
  100% {
    transform: scaleX(1);
  }
  50% {
    transform: scaleX(1.3);
  }
}
