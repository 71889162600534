.MainLandingWrapperFooter-read-doc {
  padding: 25px 143px 38px 36px;
  background: linear-gradient(198.49deg, #e5e9f4 12.53%, #f2f6ff 95.91%), #e5e9f4;
  border-radius: 20px;
  font-size: 16px;
  line-height: 160%;
}
.MainLandingWrapperFooter-read-doc__title {
  font-weight: 600;
}
.MainLandingWrapperFooter-read-doc__description {
  margin-bottom: 24px;
  font-weight: 500;
  opacity: 0.4;
}
.MainLandingWrapperFooter-read-doc .Button {
  height: 60px;
  padding: 0 69px;
  border-radius: 13px;
  opacity: 1;
}
.MainLandingWrapperFooter-read-doc .Button__label {
  font-size: 16px;
}
.MainLandingWrapperFooter-read-doc .Button .isvg {
  margin-left: 9px;
}
.adaptive .MainLandingWrapperFooter-read-doc {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px 27px 38px;
  width: 100%;
}
.adaptive .MainLandingWrapperFooter-read-doc__title,
.adaptive .MainLandingWrapperFooter-read-doc__description {
  text-align: center;
}
