.LanguageChanger {
  border: none;
  background: none;
  margin-left: 30px;
  position: relative;
  z-index: 100;
  text-transform: capitalize;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: -0.01em;
  color: #2f323d;
  cursor: pointer;
}
.LanguageChanger__container {
  display: flex;
  align-items: center;
}
.LanguageChanger__arrow {
  margin: 0 15px 0 5px;
}
.LanguageChanger__circle {
  height: 36px;
  line-height: auto;
  border-radius: 50%;
  margin-top: 4px;
}
.LanguageChanger__circle svg {
  height: 36px;
}
.LanguageChanger__languages {
  position: absolute;
  top: 100%;
  left: -50%;
  padding: 15px;
  background: #fff;
  border-radius: 18px;
  box-sizing: border-box;
  font-weight: 400;
}
.LanguageChanger__language {
  opacity: 0.9;
}
.LanguageChanger__language:hover {
  opacity: 1;
}
.LanguageChanger__language:not(:last-child) {
  margin-bottom: 10px;
}
