.FiatMarketForm .FiatMarketForm__loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  padding: 40px;
  box-sizing: border-box;
  background-color: var(--primary-background);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 14;
}
.FiatMarketForm .FiatMarketForm__loader .isvg {
  display: block;
  width: 120px;
  height: 120px;
  animation-fill-mode: forwards;
  animation: FiatMarketFormLoader 1500ms ease-in-out infinite;
  margin-bottom: 24px;
}
@keyframes FiatMarketFormLoader {
  0%,
  13% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.FiatMarketForm .FiatMarketForm__loader .isvg svg {
  display: block;
}
.FiatMarketForm .FiatMarketForm__loader h2 {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 24px;
}
.FiatMarketForm .FiatMarketForm__loader p {
  font-size: 13px;
  line-height: 24px;
  max-width: 320px;
  color: var(--dark-gray);
}
.FiatMarketForm .FiatMarketForm__indicatorIcon {
  animation: indicatorIcon 1s linear infinite;
  color: var(--dark-gray);
}
.FiatMarketForm .FiatMarketForm__indicatorIcon svg {
  display: block;
}
@keyframes indicatorIcon {
  form {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
.FiatMarketForm .ContentBox__content {
  padding: 40px;
}
.FiatMarketForm .FiatMarketForm__title {
  margin-bottom: 40px;
}
.FiatMarketForm .FiatMarketForm__row {
  position: relative;
  display: flex;
  margin: 40px -8px;
  align-items: baseline;
}
.FiatMarketForm .FiatMarketForm__column {
  margin: 0 8px;
}
.FiatMarketForm .FiatMarketForm__column:first-child {
  flex: 1;
}
.FiatMarketForm .FiatMarketForm__column:last-child {
  width: 232px;
}
.FiatMarketForm .FiatMarketForm__inputLabel {
  font-size: 13px;
  line-height: 16px;
  top: -24px;
  left: 16px;
  position: absolute;
}
.FiatMarketForm .FiatMarketForm__rate,
.FiatMarketForm .FiatMarketForm__balance {
  min-height: 16px;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  color: var(--text-black);
  margin: 8px 16px;
  margin-right: 0;
  transition: opacity 0.5s ease;
}
.FiatMarketForm .FiatMarketForm__rate.hidden,
.FiatMarketForm .FiatMarketForm__balance.hidden {
  opacity: 0;
  transition: none;
}
.FiatMarketForm .FiatMarketForm__balance {
  cursor: pointer;
}
.FiatMarketForm .FiatMarketForm__button_wrapper {
  margin-top: 28px;
  text-align: center;
}
.FiatMarketForm .FiatMarketForm__button_wrapper .Button {
  width: 160px;
}
.FiatMarketForm .FiatMarketForm__fee,
.FiatMarketForm .FiatMarketForm__total {
  font-size: 11px;
  line-height: 16px;
  font-weight: 500;
  color: var(--text-black);
  margin-bottom: 16px;
}
.adaptive .FiatMarketForm .ContentBox__content {
  padding: 0;
}
.adaptive .FiatMarketForm .FiatMarketForm__row {
  flex-direction: column-reverse;
  margin: 42px 0;
}
.adaptive .FiatMarketForm .FiatMarketForm__column {
  margin: 0;
  width: 100%;
}
.adaptive .FiatMarketForm .FiatMarketForm__column:last-child {
  width: 100%;
}
.adaptive .FiatMarketForm .Button {
  width: 100%;
}
