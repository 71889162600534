.MainLandingWrapper {
  display: flex;
  flex-direction: column;
  background: var(--alice-blue);
}
.MainLandingWrapper__content {
  width: 100%;
  z-index: 1;
}
.MainLandingWrapper .isvg.flex {
  display: flex;
  align-items: center;
}
