.Tooltip__wrapper {
  position: relative;
  width: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: help;
}
.Tooltip__wrapper:not(.active).disableHover .Tooltip {
  opacity: 0 !important;
  pointer-events: none !important;
}
.Tooltip__wrapper .Tooltip {
  opacity: 0;
  position: absolute;
  z-index: 3;
  background: white;
  border-radius: 8px;
  width: max-content;
  max-width: 200px;
  box-shadow: 0 6px 24px rgba(0, 0, 0, 0.08), 0 0 4px rgba(0, 0, 0, 0.05);
  pointer-events: none;
  color: #4d4d4d;
}
.Tooltip__wrapper .Tooltip:before {
  position: absolute;
  content: "";
  display: block;
  height: 12px;
  width: 12px;
  background: inherit;
  z-index: -2;
  transform: rotate(45deg);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.05);
}
.Tooltip__wrapper .Tooltip:after {
  position: absolute;
  content: "";
  display: block;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  border-radius: inherit;
  background: inherit;
  z-index: -1;
}
.Tooltip__wrapper .Tooltip .Tooltip__area {
  bottom: -12px;
  left: 0;
  width: 100%;
  position: absolute;
  height: 12px;
}
.Tooltip__wrapper .Tooltip.small {
  font-weight: 500;
  font-size: 9px;
  line-height: 12px;
  padding: 8px;
}
.Tooltip__wrapper .Tooltip.medium {
  padding: 12px;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
}
.Tooltip__wrapper .Tooltip.large {
  padding: 16px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}
.Tooltip__wrapper.bottom .Tooltip__area {
  top: -12px;
}
.Tooltip__wrapper.bottom .Tooltip {
  bottom: 0;
  transform: translate(0, 100%);
}
.Tooltip__wrapper.bottom .Tooltip:before {
  top: -6px;
  bottom: auto;
}
.Tooltip__wrapper.bottom:hover .Tooltip,
.Tooltip__wrapper.bottom.active .Tooltip {
  transform: translate(0, calc(100% + 12px));
}
.Tooltip__wrapper.top .Tooltip {
  top: 0;
  transform: translate(0, -100%);
}
.Tooltip__wrapper.top .Tooltip:before {
  bottom: -6px;
  top: auto;
}
.Tooltip__wrapper.top:hover .Tooltip,
.Tooltip__wrapper.top.active .Tooltip {
  transform: translate(0, calc(-100% - 12px));
}
.Tooltip__wrapper.left .Tooltip {
  left: 0;
  transform: translate(-100%, 0);
}
.Tooltip__wrapper.left .Tooltip:before {
  right: -6px;
  left: auto;
}
.Tooltip__wrapper.left:hover .Tooltip,
.Tooltip__wrapper.left.active .Tooltip {
  transform: translate(calc(-100% - 12px), 0);
}
.Tooltip__wrapper.right .Tooltip {
  right: 0;
  transform: translate(100%, 0);
}
.Tooltip__wrapper.right .Tooltip:before {
  left: -6px;
  right: auto;
}
.Tooltip__wrapper.right:hover .Tooltip,
.Tooltip__wrapper.right.active .Tooltip {
  transform: translate(calc(100% + 12px), 0);
}
.Tooltip__wrapper:hover .Tooltip,
.Tooltip__wrapper.active .Tooltip {
  opacity: 1;
  transition: opacity 0.2s ease, transform 0.2s ease;
  pointer-events: all;
}
