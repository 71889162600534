.Bot__form .ContentBox__content {
  padding: 40px;
}
.Bot__form .Bot__form__row {
  margin-bottom: 24px;
}
.Bot__form .Bot__form__row .Bot__form__row__label {
  color: var(--text-black);
  margin-bottom: 8px;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
}
