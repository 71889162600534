body,
.SiteWrapper {
  --font-size-landing: 16px;
  --font-size-title: 32px;
  --padding-section: 80px;
  --padding-panel: 40px;
  --primary-orange: #ff9326;
  --primary-orange-opacity: rgba(255, 147, 38, 0.1);
  --dark-orange: #f28d00;
  --main-orange: #fa9751;
  --royal-blue: #5078e6;
  --secondary-blue: #3071ee;
  --primary-blue: #1949d7;
  --primary-blue-opacity: rgba(58, 101, 230, 0.1);
  --dark-blue: #1949d7;
  --primary-dark-blue: #365fd9;
  --orange-gradient-color-start: #ffa526;
  --orange-gradient-color-end: #ff860d;
  --orange-gradient: linear-gradient(0deg, var(--orange-gradient-color-end) 0%, var(--orange-gradient-color-start) 100%);
  --light-orange-gradient-color-start: #fa9751;
  --light-orange-gradient-color-end: #fabe4c;
  --light-orange-gradient: linear-gradient(180deg, var(--light-orange-gradient-color-start) 0%, var(--light-orange-gradient-color-end) 100%);
  --blue-gradient-color-start: #4d72df;
  --blue-gradient-color-end: #274cbd;
  --blue-gradient: linear-gradient(0deg, var(--blue-gradient-color-end) 0%, var(--blue-gradient-color-start) 100%);
  --blue-light-gradient-color-start: #78a0ff;
  --blue-light-gradient-color-end: #5078e5;
  --blue-light-gradient: linear-gradient(135deg, var(--blue-light-gradient-color-start) 0%, var(--blue-light-gradient-color-end) 100%),
    #224dcf;
  --primary-blue-color-start: #4070ff;
  --primary-blue-color-end: var(--primary-dark-blue);
  --primary-blue-gradient: linear-gradient(180deg, var(--primary-blue-color-start) 0%, var(--primary-blue-color-end) 100%);
  --gunmetal: #2f323d;
  --black-gunmetal2: #2f323d;
  --black-gunmetal: #2d313f;
  --dark-gunmetal: #222330;
  --dark-black: #0a1539;
  --black: #1c212e;
  --almost-black: #2e323e;
  --text-black: #3a3f4d;
  --cool-gray: #9095a5;
  --cool-dark-gray: #757575;
  --slate-gray: #595e6b;
  --dark-gray: #7b808c;
  --gray: #9ea2ad;
  --light-gray: #c9ccd4;
  --extra-light-gray: #e4e6ed;
  --sidebar-icon: #c9ccd4;
  --cloudy: #edf0f5;
  --alice-blue: #f7f9fc;
  --azureish-white: #dfe3f0;
  --white: #fff;
  --text-secondary: #4e5c85;
  --text-green: #008060;
  --light-green: #5ec47a;
  --green: #00b277;
  --green-opacity: rgba(0, 178, 119, 0.1);
  --light-green: #ebf5f5;
  --text-yellow: #e6aa00;
  --yellow: #ffc832;
  --yellow-opacity: rgba(255, 200, 50, 0.1);
  --light-yellow: #fff5e6;
  --orange-error: #ff5d17;
  --text-red: #e51300;
  --red: #ff4433;
  --red-opacity: rgba(240, 55, 35, 0.1);
  --light-red: #fff5f5;
  --small-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15),
    0px 1px 4px rgba(0, 0, 0, 0.1);
  --main-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08),
    0px 0px 2px rgba(0, 0, 0, 0.15);
  --hover-shadow: 0px 6px 24px rgba(0, 0, 0, 0.08),
    0px 0px 2px rgba(0, 0, 0, 0.1);
  --primary-background: var(--white);
  --secondary-background: var(--alice-blue);
  --radius-panel: 12px;
  --radius-panel-large: 25px;
  --radius-button: 12px;
}
body.theme-dark {
  --primary-orange: #ff9326;
  --primary-orange-opacity: rgba(255, 147, 38, 0.1);
  --dark-orange: #f28d00;
  --primary-blue: #3b6dff;
  --primary-blue-opacity: rgba(58, 101, 230, 0.1);
  --dark-blue: #1949d7;
  --orange-gradient-color-start: #ffa526;
  --orange-gradient-color-end: #ff860d;
  --orange-gradient: linear-gradient(0deg, var(--orange-gradient-color-end) 0%, var(--orange-gradient-color-start) 100%);
  --blue-gradient-color-start: #4d72df;
  --blue-gradient-color-end: #274cbd;
  --blue-gradient: linear-gradient(0deg, var(--blue-gradient-color-end) 0%, var(--blue-gradient-color-start) 100%);
  --black: #d9d9d9;
  --almost-black: #d9d9d9;
  --text-black: #dbdbdb;
  --slate-gray: #595e6b;
  --dark-gray: #7b808c;
  --gray: #9ea2ad;
  --light-gray: #192230;
  --extra-light-gray: #1a2332;
  --sidebar-icon: #c9ccd4;
  --cloudy: #25354a;
  --alice-blue: #f7f9fc;
  --white: #fff;
  --text-secondary: #4e5c85;
  --text-green: #008060;
  --green: #00b277;
  --green-opacity: rgba(0, 178, 119, 0.1);
  --light-green: #ebf5f5;
  --text-yellow: #e6aa00;
  --yellow: #ffc832;
  --yellow-opacity: rgba(255, 200, 50, 0.1);
  --light-yellow: #fff5e6;
  --text-red: #e51300;
  --red: #ff4433;
  --red-opacity: rgba(240, 55, 35, 0.1);
  --light-red: #fff5f5;
  --small-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15),
    0px 1px 4px rgba(0, 0, 0, 0.1);
  --main-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08),
    0px 0px 2px rgba(0, 0, 0, 0.15);
  --hover-shadow: 0px 6px 24px rgba(0, 0, 0, 0.08),
    0px 0px 2px rgba(0, 0, 0, 0.1);
  --primary-background: #233044;
  --secondary-background: #1b2635;
}
body {
  --gap-size: 20px;
}
@media (max-width: 768px) {
  body {
    --gap-size: 16px;
  }
}
@keyframes showElement {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.Referral {
  padding: 30px 49px 27px 60px;
  height: 100%;
  box-sizing: border-box;
}
.Referral__container {
  position: relative;
  max-width: 1033px;
  margin: 0 auto;
}
.Referral__container > * {
  animation: showElement 0.5s;
}
.Referral__Dashboard {
  margin-bottom: 32px;
}
.Referral__ReferralList {
  margin-bottom: 37px;
}
.Referral h1 {
  margin-bottom: 12px;
  font-weight: 600;
  font-size: 32px;
  line-height: 110%;
  letter-spacing: 0.01em;
  color: var(--black-gunmetal);
}
.Referral h2 {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
}
.Referral .isvg {
  display: flex;
  align-items: center;
}
.Referral .create-new-link {
  cursor: pointer;
}
.Referral .create-new-link:hover {
  opacity: 0.8;
}
.Referral .create-new-link .isvg {
  margin-left: 10px;
}
.Referral .strong {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: var(--dark-gunmetal);
}
.Referral .secondary-text {
  font-weight: 600;
  font-size: 12px;
  line-height: 110%;
  color: var(--cool-gray);
}
.Referral .small-text {
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
}
.Referral .blue {
  color: var(--secondary-blue);
}
.Referral .link {
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  transition: opacity 0.1s;
}
.Referral .link:hover {
  opacity: 0.8;
}
.Referral .blue-gradient-text {
  color: var(--secondary-blue);
}
.Referral .SwitchTabs {
  margin-bottom: 73px;
  font-weight: 600;
}
.Referral .SwitchTabs__item:not(.active) {
  color: #365fd9;
}
.Referral__share {
  height: 53px;
  width: 53px;
  background: #e5ebfc;
  border-radius: 12px;
  box-sizing: border-box;
  transition: opacity 0.2s, background 0.2s;
  cursor: pointer;
}
.Referral__share .isvg {
  width: 20px;
  height: 21px;
}
.Referral__share .isvg svg {
  width: 100%;
  height: 100%;
}
.Referral__share:hover {
  background: #cfdaf9;
}
.Referral__information_procent {
  font-weight: 500;
  font-size: 32px;
  line-height: 39px;
}
@media screen and (max-width: 768px) {
  .Referral {
    padding: 23px 17px 30px;
  }
  .Referral h1 {
    margin-top: 0;
    margin-bottom: 10px;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: -0.01em;
  }
  .Referral h2 {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
  }
  .Referral .strong {
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
  }
  .Referral .SwitchTabs {
    height: 55px;
    margin-bottom: 32px;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
  }
  .Referral .create-new-link span {
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
  }
  .Referral__share {
    width: 36px;
    height: 36px;
    min-width: 36px;
    min-height: 36px;
  }
  .Referral__share .isvg {
    width: 13px;
    height: 14px;
  }
}
