.MainLanding-ShowIn {
  pointer-events: none;
}
.MainLanding-ShowIn.visible {
  pointer-events: all;
}
.MainLanding-ShowIn.opacity {
  transition: opacity 1s;
}
.MainLanding-ShowIn.opacity:not(.visible) {
  opacity: 0;
}
.MainLanding-ShowIn.opacity.visible {
  opacity: 1;
}
.MainLanding-ShowIn.swipeHorizontal {
  transition: transform 0.05s;
}
.MainLanding-ShowIn.slideRight,
.MainLanding-ShowIn.slideLeft,
.MainLanding-ShowIn.slideTop,
.MainLanding-ShowIn.slideBottom {
  opacity: 0;
  transition: transform 0.7s, opacity 0.7s;
}
.MainLanding-ShowIn.slideRight.visible,
.MainLanding-ShowIn.slideLeft.visible,
.MainLanding-ShowIn.slideTop.visible,
.MainLanding-ShowIn.slideBottom.visible {
  opacity: 1;
  transform: none;
}
.MainLanding-ShowIn.slideRight {
  transform: translateX(-100%);
}
.MainLanding-ShowIn.slideLeft {
  transform: translateX(100%);
}
.MainLanding-ShowIn.slideTop {
  transform: translateY(100%);
}
.MainLanding-ShowIn.slideBottom {
  transform: translateY(-100%);
}
