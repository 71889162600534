.SocialLinks {
  margin-top: auto;
  padding-top: 57px;
  max-width: 100%;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: -0.023em;
  flex-wrap: wrap;
  gap: 11px 78px;
}
.SocialLinks,
.SocialLinks-social,
.SocialLinks-social__items {
  display: flex;
  align-items: center;
}
.SocialLinks-social__title {
  color: var(--cool-gray);
  margin-right: 17px;
}
.SocialLinks-social__items a {
  cursor: pointer;
  opacity: 0.4;
}
.SocialLinks-social__items a:not(:last-child) {
  margin-right: 14px;
}
.SocialLinks-social__items a .isvg {
  display: flex;
}
.SocialLinks-social__items a .isvg path {
  fill: var(--cool-gray);
}
.SocialLinks-social__items a:hover {
  opacity: 0.6;
}
