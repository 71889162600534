.ValidatorTradeForm h2 {
  margin: 0 0 20px;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: var(--dark-black);
}
.ValidatorTradeForm h3 {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: var(--black-gunmetal);
}
.ValidatorTradeForm h4 {
  margin-bottom: 5px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #9095a5;
}
.ValidatorTradeForm-row {
  gap: 10px 50px;
  box-sizing: border-box;
  width: 100%;
}
.ValidatorTradeForm .trade-type {
  margin-bottom: 40px;
}
.ValidatorTradeForm .more-information {
  margin-bottom: 50px;
}
.ValidatorTradeForm .more-information__item {
  width: 100%;
}
.ValidatorTradeForm .more-information__item:not(:last-child) {
  margin-bottom: 30px;
}
.ValidatorTradeForm .security-options {
  gap: 20px 90px;
}
.ValidatorTradeForm .security-options .ValidatorTradeForm-col {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: none;
}
.ValidatorTradeForm-col {
  flex: 1 1 0;
}
.ValidatorTradeForm-col__title {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.ValidatorTradeForm .AnswerPopup {
  margin-left: 10px;
}
.ValidatorTradeForm .Radio:first-child {
  margin-top: 0;
}
.ValidatorTradeForm .Input {
  --input-height: 46px;
  background: transparent;
  font-weight: 500;
  font-size: 13px;
  line-height: 140%;
  border-color: #dfe3f0;
  border-radius: 10px;
}
.ValidatorTradeForm .Input__wrapper {
  margin: 0;
}
.ValidatorTradeForm .Input__indicator {
  font-weight: 700;
  font-size: 13px;
  line-height: 140%;
  color: var(--azureish-white);
}
.ValidatorTradeForm textarea.multiLine {
  width: 100%;
  height: 200px;
  max-width: 765px;
  padding: 14px 9px;
}
.ValidatorTradeForm .CabinetSelect {
  width: 100%;
}
.ValidatorTradeForm .CabinetSelect__control {
  height: 46px;
  min-width: 0 !important;
}
.ValidatorTradeForm-opening-hours .ValidatorTradeForm-row:not(:last-child) {
  margin-bottom: 20px;
}
.ValidatorTradeForm-opening-hours .CabinetSelect {
  min-width: 70px;
}
.ValidatorTradeForm__hours .Row {
  gap: 10px;
}
.ValidatorTradeForm .trade-price {
  width: 260px;
  margin-top: 10px;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  font-style: italic;
  color: #9095a5;
}
.ValidatorTradeForm .trade-price strong {
  font-weight: 600;
}
