.MainLanding-BenefitCard {
  display: flex;
  flex: 1 1 0;
  height: 307px;
  padding: 40px 43px;
  overflow: hidden;
  position: relative;
  border-radius: 30px;
  box-sizing: border-box;
  transition: transform 0.3s ease-out, opacity 0.3s ease-out;
}
.MainLanding-BenefitCard:not(.visible) {
  opacity: 0;
  transform: translateY(100%);
}
.MainLanding-BenefitCard.small {
  flex: 1 1 306px;
  text-align: center;
}
.MainLanding-BenefitCard.medium {
  flex: 1 1 504px;
}
.MainLanding-BenefitCard.large {
  flex: 1 1 702px;
  text-align: center;
}
.MainLanding-BenefitCard.small .MainLanding-BenefitCard__title,
.MainLanding-BenefitCard.large .MainLanding-BenefitCard__title {
  margin: auto auto 0;
}
.MainLanding-BenefitCard.medium .MainLanding-BenefitCard__title {
  max-width: 273px;
}
.MainLanding-BenefitCard.blue {
  background: var(--royal-blue);
}
.MainLanding-BenefitCard.orange {
  background: linear-gradient(202.23deg, #fa9751 1.99%, #fabe4c 100%);
}
.MainLanding-BenefitCard.alice-blue {
  background: linear-gradient(198.49deg, #e5e9f4 12.53%, #f2f6ff 95.91%);
}
.MainLanding-BenefitCard__title {
  margin-top: auto;
  font-weight: 600;
  font-size: 25px;
  line-height: 114%;
  color: #fff;
  position: relative;
  z-index: 2;
}
.MainLanding-BenefitCard__background {
  z-index: 1;
  pointer-events: none;
}
.MainLanding-BenefitCard.alice-blue .MainLanding-BenefitCard__title {
  color: var(--gunmetal);
}
.MainLanding-BenefitCard .users-image {
  font-weight: 600;
  font-size: 88px;
  line-height: 114%;
  text-align: center;
  letter-spacing: -0.07em;
  opacity: 0.23;
}
@keyframes showBenefitCard {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.adaptive .MainLanding-BenefitCard {
  padding: 29px;
  height: 210px;
}
.adaptive .MainLanding-BenefitCard:not(.visible) {
  opacity: 1;
  transform: none;
}
.adaptive .MainLanding-BenefitCard__title {
  font-size: 18px;
}
