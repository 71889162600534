.ValidatorCreateTrade .CabinetBlock {
  border-radius: var(--radius-panel-large);
  padding: 50px;
}
.ValidatorCreateTrade .CabinetBlock:not(:last-child) {
  margin-bottom: 30px;
}
.ValidatorCreateTrade h1,
.ValidatorCreateTrade h2,
.ValidatorCreateTrade h3 {
  color: var(--dark-black);
}
.ValidatorCreateTrade h1 {
  margin-bottom: 20px;
  max-width: 358px;
  font-weight: 600;
  font-size: 32px;
  line-height: 110%;
  letter-spacing: 0.01em;
}
.ValidatorCreateTrade h2 {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
}
.ValidatorCreateTrade .DappMessage {
  margin-bottom: 40px;
}
.ValidatorCreateTrade ul {
  padding-left: 20px;
  margin: 15px 0 0;
}
.ValidatorCreateTrade ul li {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: var(--cool-gray);
}
.ValidatorCreateTrade ul li::marker {
  font-size: 13px;
}
.ValidatorCreateTrade .dark {
  color: var(--black-gunmetal);
}
