.AttentionBuyToken {
  text-align: center;
}
.AttentionBuyToken__container {
  padding: 32px 29px;
  background: #fff;
  border-radius: var(--radius-panel);
  box-sizing: border-box;
}
.AttentionBuyToken h3 {
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  color: var(--secondary-blue);
  margin-bottom: 10px;
}
.AttentionBuyToken p {
  margin: 0 auto;
  max-width: 305px;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  color: var(--dark-black);
}
.AttentionBuyToken p:not(:last-child) {
  margin-bottom: 24px;
}
.AttentionBuyToken p.AttentionBuyToken__text {
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
}
.AttentionBuyToken p.AttentionBuyToken__text.secondary-alice {
  background: rgba(48, 113, 238, 0.12);
  padding: 11.6px 36.3px;
  border-radius: var(--radius-panel);
}
.AttentionBuyToken .Button {
  width: 100%;
  margin: 0 auto;
  border-radius: 15px;
}
.AttentionBuyToken .Button.large {
  height: 50px;
  max-width: 360px;
}
.AttentionBuyToken .Button.large .Button__label {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}
.AttentionBuyToken .Button:not(:last-child) {
  margin-bottom: 14px;
}
.adaptive .AttentionBuyToken .Modal__box {
  padding: 20px;
}
.adaptive .AttentionBuyToken .CabinetModal__container {
  max-width: 435.594px;
}
.adaptive .AttentionBuyToken__container {
  padding: 23px 20px 30px;
}
