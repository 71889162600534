.SendCoinsModal {
  margin-top: 20px;
  box-sizing: border-box;
  max-width: 472px;
}
.SendCoinsModal__wrapper .LoadingStatus__wrap {
  width: 300px;
}
.SendCoinsModal__wallet {
  display: flex;
  align-items: center;
}
.SendCoinsModal__wallet .Dropdown {
  flex: 1;
}
.SendCoinsModal__wallet .CircleIcon {
  margin-right: 16px;
}
.SendCoinsModal__wallet__icon {
  flex: 0 0 auto;
  width: 72px;
  height: 72px;
  margin-left: -16px;
  background: no-repeat center / contain;
}
.SendCoinsModal__row {
  margin-top: 28px;
}
.SendCoinsModal__row:first-child {
  margin-top: 0;
}
.SendCoinsModal__amount {
  display: flex;
  align-items: flex-start;
}
.SendCoinsModal__amount > .Input__wrapper,
.SendCoinsModal__amount .Tooltip__wrapper {
  flex: 1;
}
.SendCoinsModal__amount .Button {
  flex: 0 0 auto;
}
.SendCoinsModal__amount > .Input__wrapper,
.SendCoinsModal__amount .Tooltip__wrapper,
.SendCoinsModal__amount .Button {
  margin-left: 16px;
}
.SendCoinsModal__amount > .Input__wrapper:first-child,
.SendCoinsModal__amount .Tooltip__wrapper:first-child,
.SendCoinsModal__amount .Button:first-child {
  margin-left: 0;
}
.SendCoinsModal__submit_wrap {
  margin-top: 40px;
  text-align: center;
}
.cabinet_sendCoinsModal__noFee {
  color: var(--green);
}
.adaptive .SendCoinsModal__block {
  max-width: 100%;
}
.adaptive .SendCoinsModal__wrapper .LoadingStatus__wrap {
  width: 100%;
}
.adaptive .Modal .SendCoinsModal__amount {
  width: calc(100% - 80px);
  position: relative;
  flex-direction: column;
}
.adaptive .Modal .SendCoinsModal__amount .Input__wrapper {
  margin: 0;
  margin-bottom: 24px;
}
.adaptive .Modal .SendCoinsModal__amount .Tooltip__wrapper {
  width: 100%;
  margin-left: 0;
}
.adaptive .Modal .SendCoinsModal__amount .Tooltip__wrapper .Input__wrapper {
  margin-bottom: 0;
}
.adaptive .Modal .SendCoinsModal__amount .Button {
  position: absolute;
  top: 0;
  right: -80px;
  width: 64px;
}
