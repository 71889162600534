.TraderNewBotModal {
  min-width: 300px;
}
.TraderNewBotModal form {
  display: flex;
  padding: 24px 0;
}
.TraderNewBotModal form .Button {
  margin-left: 16px;
}
