.DepositPoolSuccessModal {
  text-align: center;
}
.DepositPoolSuccessModal p {
  margin: 24px 0;
  width: 300px;
  font-size: 13px;
  line-height: 24px;
}
.DepositPoolSuccessModal .DepositPoolSuccessModal__icon {
  width: 120px;
  height: 120px;
  background-position: center;
  background-repeat: no-repeat;
  margin: 24px auto;
}
