.TokenSelect {
  padding: 23px 32px 31px;
  box-sizing: border-box;
  min-height: 400px;
}
.TokenSelect__wrap {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100vw;
  max-width: 445px;
  z-index: 10;
}
.TokenSelect__wrap .CabinetBlock {
  box-shadow: 0px 34px 70px rgba(199, 201, 207, 0.7);
}
.Modal .TokenSelect__wrap .CabinetBlock {
  box-shadow: none;
}
.TokenSelect h2 {
  font-weight: 600 !important;
  font-size: 24px !important;
  text-align: left !important;
}
.TokenSelect__close {
  position: absolute;
  display: flex;
  cursor: pointer;
  right: 25px;
  top: 16px;
  background-size: 14px;
  width: 38px;
  height: 38px;
  color: var(--text-black);
  z-index: 2;
}
.TokenSelect__close .isvg {
  display: block;
  margin: auto;
}
.TokenSelect__close .isvg svg {
  display: block;
}
.TokenSelect__close .isvg svg path {
  fill: var(--text-black);
}
.TokenSelect__close:hover .isvg svg path {
  fill: var(--primary-orange);
}
.TokenSelect__search {
  border: 1px solid var(--light-gray);
  border-radius: var(--radius-panel);
  height: 46px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 16px 0 12px;
  padding: 0 14px;
}
.TokenSelect__search input {
  font-size: 14px;
  font-weight: 500;
  border: none;
  outline: none;
  flex-grow: 1;
  background: transparent;
}
.TokenSelect__search span.isvg {
  margin-left: 6px;
}
.TokenSelect__search span.isvg svg {
  transform: scale(0.8);
}
.TokenSelect__search span.isvg svg path {
  fill: #dfe3f0;
}
.TokenSelect .SwitchTabs__item span,
.TokenSelect .SwitchTabs__item.active span {
  font-size: 11px;
  line-height: 16px;
  font-weight: 500;
}
.TokenSelect .SwitchTabs__item.active span {
  font-weight: 600;
  color: #fff;
}
.TokenSelect__fiat {
  margin: 11px 0 19px;
}
.TokenSelect__fiat__content {
  display: flex;
  flex-wrap: wrap;
}
.TokenSelect__fiat__item {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 110%;
  letter-spacing: -0.01em;
  height: 45px;
  box-sizing: border-box;
  cursor: pointer;
}
.TokenSelect__fiat__item:not(:last-child) {
  margin-right: 20px;
}
.TokenSelect__fiat__item .WalletIcon {
  margin-right: 7px;
}
.TokenSelect__list {
  margin: 0 -12px;
}
.TokenSelect__list h3 {
  margin: 12px 12px;
  font-size: 14px;
  font-weight: 600;
}
.TokenSelect__list .react-scrollable-list {
  height: 400px;
  overflow-y: scroll;
  --scroll-size: 6px;
}
.TokenSelect__list .react-scrollable-list::-webkit-scrollbar {
  width: var(--scroll-size);
  height: var(--scroll-size);
}
.TokenSelect__list .react-scrollable-list::-webkit-scrollbar-button {
  width: 0;
  height: 0;
}
.TokenSelect__list .react-scrollable-list::-webkit-scrollbar-thumb {
  background: var(--primary-blue-opacity);
  border: 0 none #ffffff;
  border-radius: 2px;
}
.TokenSelect__list .react-scrollable-list::-webkit-scrollbar-thumb:hover {
  background: var(--secondary-background);
}
.TokenSelect__list .react-scrollable-list::-webkit-scrollbar-track {
  background: var(--secondary-background);
  border: 0 none #ffffff;
  border-radius: 50px;
}
.TokenSelect__list .react-scrollable-list::-webkit-scrollbar-corner {
  background: transparent;
}
.TokenSelect__token {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 11px 12px 11px 12px;
  cursor: pointer;
}
.TokenSelect__token:hover {
  background: var(--secondary-background);
}
.TokenSelect__token-left {
  display: flex;
  align-items: center;
  flex-grow: 1;
  position: relative;
}
.TokenSelect__token-icon {
  border-radius: 50%;
  background: var(--secondary-background);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.TokenSelect__token-name {
  margin-left: 8px;
  display: flex;
  flex-direction: column;
}
.TokenSelect__token-name span:last-child {
  font-size: 12px;
}
.TokenSelect__token-name span:first-child {
  font-size: 14px;
  font-weight: 600;
}
.TokenSelect__token-right {
  display: flex;
  flex-direction: column;
  text-align: right;
}
.TokenSelect__token-price {
  font-size: 10px;
  font-weight: 500;
  color: #cdcfd7;
}
.TokenSelect__token-balance {
  font-size: 14px;
  font-weight: 600;
}
.TokenSelect-small {
  max-width: 342px;
  width: 100vw;
  box-sizing: border-box;
}
.TokenSelect-small .TokenSelect {
  padding: 26px 26px 30px;
  box-sizing: border-box;
}
.TokenSelect-small .TokenSelect__close {
  right: 20px;
}
.TokenSelect-small .TokenSelect__token {
  padding: 9px 12px 9px 12px;
}
.TokenSelect-small .TokenSelect__search {
  border: 1px solid var(--azureish-white);
  border-radius: 10px;
  margin: 16px 0 24px;
}
.TokenSelect-small .TokenSelect__search input::placeholder {
  color: var(--cool-gray);
}
.TokenSelect-small .TokenSelect__list {
  margin: 0 -12px;
}
.TokenSelect-small .TokenSelect__list h3 {
  margin: 0px 12px 9px;
}
.TokenSelect-small .TokenSelect__list .react-scrollable-list {
  height: 353px;
}
.adaptive .TokenSelect {
  padding: 24px 15px;
}
.adaptive .TokenSelect__wrap {
  left: 0;
  right: 0;
  transform: none;
  max-width: 100%;
}
.adaptive .TokenSelect__wrap .CabinetBlock {
  border-radius: var(--radius-panel-large) !important;
}
.adaptive .TokenSelect h2 {
  margin-top: 0;
}
.adaptive .TokenSelect h2 span:first-child {
  font-size: 18px !important;
}
.adaptive .TokenSelect__close {
  right: 15px;
  top: 18px;
}
