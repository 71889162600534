.FooterNotice {
  width: 100%;
  box-sizing: border-box;
  font-size: 11px;
  line-height: 16px;
  color: var(--gray);
  padding: 8px 16px;
}
.FooterNotice.active {
  cursor: pointer;
}
.FooterNotice.active p {
  overflow: initial;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.FooterNotice.active:hover {
  opacity: 0.9;
}
