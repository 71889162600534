.MainLanding-promo h1 {
  max-width: 792px;
  font-weight: 600;
  font-size: 71px;
  line-height: 110%;
  color: var(--gunmetal) !important;
}
.MainLanding-promo h1 span.blue {
  color: var(--royal-blue);
}
.MainLanding-promo__content {
  display: flex;
  align-items: start;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 0 20px;
  margin-top: -113px;
  position: relative;
}
.MainLanding-promo__content p {
  margin-top: 27px;
  max-width: 398px;
  font-weight: 500;
  font-size: 18px;
  line-height: 160%;
  color: var(--landing-color-gray);
}
.MainLanding-promo .MainLanding-SuggestiveBox__showIn {
  margin-top: 12px;
}
.MainLanding-promo__bg {
  margin: -7% auto 0;
  max-width: 1352px;
  width: 78%;
  box-sizing: border-box;
  position: relative;
  height: 807px;
}
.MainLanding-promo__bg svg {
  max-width: 100%;
  width: 100%;
  height: 807px;
}
.MainLanding-promo__bg .loading {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 2s;
}
.MainLanding-promo__bg .loading .isvg {
  justify-content: center;
  align-items: center;
}
.MainLanding-promo__bg .loading .isvg svg {
  height: auto;
  width: auto;
}
.MainLanding-promo__bg .loading.disabled {
  opacity: 0;
}
.adaptive .MainLanding-promo__bg {
  height: 527px;
  width: 100%;
  overflow: hidden;
  position: relative;
  margin: 0;
}
.adaptive .MainLanding-promo__bg .isvg {
  display: flex;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  max-width: none;
  width: 834px;
  height: 100%;
}
.adaptive .MainLanding-promo__bg svg {
  height: 527px;
}
.adaptive .MainLanding-promo__bg .loading {
  z-index: 2;
}
.adaptive .MainLanding-promo h1 {
  font-size: 34px;
  line-height: 120%;
  text-align: center;
}
.adaptive .MainLanding-promo__content {
  width: 100%;
  margin-top: -61px;
  justify-content: center;
  position: relative;
}
.adaptive .MainLanding-promo__content p {
  font-size: 16px;
  margin: 13px auto 0;
  text-align: center;
}
.adaptive .MainLanding-promo .MainLanding-SuggestiveBox__showIn {
  margin-top: 23px;
}
