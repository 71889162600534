.EmptyContentBlock {
  min-height: 424px;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  display: flex;
}
.EmptyContentBlock .ContentBox__content {
  flex: 1;
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.EmptyContentBlock__content__icon {
  width: 120px;
  height: 120px;
  background: no-repeat center / contain;
  margin: 0 auto;
}
.EmptyContentBlock__content__message {
  text-align: center;
  margin-top: 0px;
  margin-bottom: 40px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  max-width: 148px;
  color: var(--text-black);
}
.EmptyContentBlock__call_to_action {
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
  padding-bottom: 40px;
}
