.LiquidityList {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: var(--alice-blue);
  border-radius: var(--radius-panel);
  list-style: none;
  margin: 0;
  padding: 8px 16px;
  box-sizing: border-box;
}
.LiquidityList__item__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
  padding: 8px 0;
}
.LiquidityList.empty li {
  margin: 24px 0;
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: var(--cool-gray);
}
.LiquidityList .ItemContent span {
  display: inline-flex;
  align-items: center;
}
.LiquidityList .ItemContent__body {
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: -0.01em;
  color: var(--cool-gray);
}
.LiquidityList .ItemContent__body div {
  display: flex;
  align-items: center;
}
.LiquidityList .ItemContent__body > div {
  justify-content: space-between;
  padding: 8px 0;
}
.LiquidityList .ItemContent__body .WalletIcon {
  margin-left: 4px;
}
.LiquidityList .ItemContent__footer {
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
}
.LiquidityList .ItemContent__footer .Button {
  width: calc(50% - 6px);
}
