.CabinetWalletScreen__wallets {
  display: grid;
  flex-wrap: wrap;
  grid-template-columns: 33.3% 33.3% 33.3%;
  width: calc(100% + 28px);
  margin-bottom: var(--gap-size);
}
.CabinetWalletScreen__fiat {
  padding: 0 !important;
  margin: 0 !important;
}
.Wallets__history_table {
  margin-top: 16px;
}
.Wallets__history_indicator {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}
.Wallets__history_indicator.done {
  background: var(--green);
}
.Wallets__history_indicator.pending {
  background: var(--yellow);
}
.Wallets__history_indicator.failed {
  background: var(--red);
}
.Wallets__history__status {
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
}
.Wallets__history__status.receive {
  color: var(--green);
}
.Wallets__history__status.send {
  color: var(--dark-blue);
}
.Wallets__history__address {
  word-break: break-word;
  display: flex;
  align-items: center;
}
.Wallets__history__date {
  white-space: nowrap;
  line-height: 18px;
}
.WalletBalance__selected_wallet {
  width: 100%;
  padding-inline-start: 40px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
.WalletBalance__selected_wallet .WalletBalance__currency_name {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.444444px;
  color: var(--black);
}
.WalletBalance__selected_wallet .WalletBalance__selected_amount {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 1.02px;
  color: var(--black);
  margin-top: 20px;
}
.BuyCurrency {
  padding: 40px;
  margin: 24px 0;
  width: 472px;
  box-sizing: border-box;
}
.BuyCurrency h3 {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.444444px;
  color: var(--black);
  text-align: left;
}
.BuyCurrency .Button {
  display: block;
  margin: 0 auto;
}
.BuyCurrency .BuyCurrency__content {
  padding: 40px 0;
}
.BuyCurrency .BuyCurrency__content .BuyCurrency__content__row {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.BuyCurrency .BuyCurrency__content .BuyCurrency__content__row .BuyCurrency__input__wrapper {
  flex: 4;
}
.BuyCurrency .BuyCurrency__content .BuyCurrency__content__row .BuyCurrency__input__wrapper .Input {
  box-shadow: var(--main-shadow);
}
.BuyCurrency .BuyCurrency__content .BuyCurrency__content__row .BuyCurrency__dropdown__wrapper {
  flex: 6;
  margin-left: 16px;
}
.BuyCurrency .BuyCurrency__content .BuyCurrency__content__row .BuyCurrency__dropdown__wrapper .Input {
  box-shadow: var(--main-shadow);
}
.BuyCurrency .BuyCurrency__content .BuyCurrency__content__row .BuyCurrency__dropdown__wrapper .Drpdown__helper__text {
  color: var(--gray);
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: 0.444444px;
  margin: 8px 20px;
}
.CabinetWalletScreen__height_padding {
  height: var(--gap-size);
  width: 100%;
}
.Empty_box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 12px;
}
.Empty_box h3 {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.444444px;
  color: var(--text-black);
  max-width: 150px;
}
.adaptive .CabinetWalletScreen__wallets {
  grid: none;
  width: 100% !important;
}
.adaptive .WalletBalance {
  padding-block-end: 0;
}
.adaptive .CabinetWalletsScreen__walletsContentBox {
  background-color: var(--primary-background);
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
}
