.Bot__history .ContentBox__content {
  padding: 40px;
}
.Bot__history h2 {
  margin-bottom: 16px;
}
.Bot__history .Table {
  border-spacing: 20px;
  margin: 0 -16px;
  width: calc(100% + 32px);
}
.Bot__history .Table .Table__td__cont {
  margin-bottom: 8px;
}
.Bot__history .Table thead .Table__td__cont {
  margin-bottom: 16px;
}
