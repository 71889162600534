.SiteTokenScreen {
  overflow: hidden;
  padding-top: 1px;
  --block-margin: 260px;
}
.SiteTokenScreen .anchor {
  position: absolute;
  margin-top: -120px;
}
@media (max-width: 800px) {
  .SiteTokenScreen {
    padding-top: 1px;
    --block-margin: 150px;
  }
  .SiteTokenScreen .Button {
    --button-height: 40px;
  }
}
