.Currency {
  display: flex;
  justify-content: center;
  height: 100%;
  padding: 45px 10px 117px;
}
.Currency__container {
  max-width: 667px;
  width: 100%;
}
.Currency__header span {
  line-height: 110%;
  letter-spacing: -0.01em;
}
.Currency__preview {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  padding: 0;
}
.Currency__preview__container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.Currency__preview .WalletIcon {
  margin-right: 24px;
  border-radius: 50%;
  overflow: hidden;
}
.Currency__preview .WalletIcon__empty {
  background: var(--alice-blue);
}
.Currency__currency {
  margin: 8px 0 15px;
}
.Currency__currency span {
  font-weight: 600;
  font-size: 14px;
  line-height: 180%;
  color: var(--cool-gray);
}
.Currency .LoadingStatus.inline {
  min-height: 50px;
}
.Currency__rate {
  color: var(--dark-black);
  font-weight: 600;
  font-size: 32px;
  line-height: 110%;
  margin-bottom: 8px;
}
.Currency__currency_amount_rate {
  justify-content: flex-start;
  font-weight: 500;
  font-size: 20px;
  line-height: 110%;
  color: var(--secondary-blue);
}
.Currency__buttons {
  display: flex;
  justify-content: space-between;
}
.Currency__buttons .col {
  display: flex;
  width: 50%;
}
.Currency__buttons .col:not(:last-child) {
  margin-right: 15px;
}
.Currency__buttons .col .Button:not(:last-child) {
  margin-right: 15px;
}
.Currency__buttons .Button {
  flex: 1 1 155px;
  max-height: 68.9px;
  height: 68.9px;
}
.Currency__buttons .Button.secondary-alice .Button__label {
  color: var(--dark-black) !important;
}
.Currency__buttons .Button .coming-soon {
  width: 100%;
  font-size: 9px;
}
.Currency__buttons .isvg {
  margin-right: 7.2px;
}
.Currency__buttons .isvg .card-send__body,
.Currency__buttons .isvg .card-send__head {
  fill: var(--royal-blue);
}
.Currency__body {
  margin-top: 30px;
}
.Currency__transactions {
  padding: 0;
  font-weight: 500;
}
.Currency__transactions h3 {
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 20px;
  line-height: 110%;
  letter-spacing: -0.01em;
  color: var(--dark-black);
}
.Currency__transactions h4 {
  margin-bottom: 27px;
  font-weight: 600;
  font-size: 18px;
  line-height: 110%;
  color: #000;
}
.Currency__transactions .isvg {
  display: flex;
  align-items: center;
  margin-right: 10px;
}
.Currency__transactions__body {
  position: relative;
}
.Currency ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.Currency__date:not(:last-child) {
  margin-bottom: 42px;
}
.Currency .white-icon path {
  fill: #fff;
}
.Currency .TransactionHistoryOverlay {
  height: 100%;
  border-radius: 15px;
}
.Currency .TransactionHistoryOverlay__bg {
  background: linear-gradient(179.85deg, #ffffff 0.13%, rgba(255, 255, 255, 0.71) 107.49%);
}
.Currency .icon__wallet {
  fill: var(--royal-blue);
}
@media screen and (max-width: 1024px) {
  .Currency__buttons {
    flex-direction: column;
  }
  .Currency__buttons .col {
    width: 100%;
    margin-bottom: 8px;
  }
}
.adaptive .Currency {
  padding: 20px 21px 23px;
}
.adaptive .Currency__body {
  margin-top: 22px;
}
.adaptive .Currency__transactions__header h3 {
  display: none;
}
