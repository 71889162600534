.SwiftGenerated {
  text-align: center;
}
.SwiftGenerated .Modal__box {
  width: auto;
}
.SwiftGenerated__container {
  padding: 32px;
  background: #fff;
  border-radius: var(--radius-panel);
  box-sizing: border-box;
}
.SwiftGenerated__row:not(:last-child) {
  margin-bottom: 24px;
}
.SwiftGenerated h3 {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: var(--secondary-blue);
}
.SwiftGenerated p {
  max-width: 360px;
  margin-left: auto;
  margin-right: auto;
  font-weight: 600;
  font-size: 16px;
  line-height: 25px;
  color: var(--cool-gray);
}
.SwiftGenerated strong {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: var(--dark-black);
}
.SwiftGenerated .Button {
  width: 100%;
  border-radius: 15px;
}
.SwiftGenerated .Button.large {
  height: 50px;
}
.SwiftGenerated .Button.large .Button__label {
  font-size: 16px !important;
  line-height: 20px !important;
}
.SwiftGenerated .Button.large:not(.disabled) .Button__label {
  font-weight: 700 !important;
}
.SwiftGenerated .Button.secondary-alice.disabled .Button__label {
  color: var(--cool-gray);
}
.SwiftGenerated .HaveAQuestion__title {
  color: var(--secondary-blue);
}
.SwiftGenerated__next {
  display: inline-flex;
  align-items: center;
  margin: 0 auto;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-transform: capitalize;
  color: var(--secondary-blue);
  cursor: pointer;
}
.SwiftGenerated__next .isvg {
  transform: rotate(-90deg);
  display: flex;
  align-items: center;
  margin-left: 6px;
}
.SwiftGenerated__next .isvg svg {
  width: 10px !important;
  height: 6px !important;
}
.SwiftGenerated__next .isvg svg path {
  fill: var(--secondary-blue);
}
.SwiftGenerated__next:hover {
  opacity: 0.8;
}
.adaptive .SwiftGenerated .CabinetModal__container {
  max-width: 329px;
}
.adaptive .SwiftGenerated .Modal__box {
  padding: 20px 11px;
}
.adaptive .SwiftGenerated__container {
  padding: 32px 20px;
}
.adaptive .SwiftGenerated__row {
  width: 100%;
}
.adaptive .SwiftGenerated__row:not(:last-child) {
  margin-bottom: 20px;
}
.adaptive .SwiftGenerated h3 {
  font-size: 18px;
  line-height: 22px;
}
.adaptive .SwiftGenerated p {
  font-size: 15px;
  line-height: 24px;
}
.adaptive .SwiftGenerated strong {
  font-size: 18px;
  line-height: 26px;
}
.adaptive .SwiftGenerated .HaveAQuestion__title {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
}
.adaptive .SwiftGenerated .Button.large .Button__label {
  font-size: 15px !important;
  line-height: 18px !important;
}
