.TokenSale {
  height: 100%;
  width: 100%;
  padding: 32px;
  color: var(--black-gunmetal);
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  text-align: center;
}
.TokenSale__wrap {
  display: flex;
  height: calc(100vh - 58px);
  padding: 17px;
  box-sizing: border-box;
}
.TokenSale h3 {
  font-weight: 600;
  font-size: 20px;
  line-height: 110%;
  letter-spacing: -0.01em;
  margin-bottom: 25px;
}
.TokenSale .Form {
  max-width: 300px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 30px 20px 45px;
  margin: auto;
  border: 1px solid var(--sidebar-icon);
  border-radius: 24px;
}
.TokenSale .Form .Input {
  height: 50px;
}
.TokenSale .Form .Input__wrapper {
  margin: 0 0 15px;
}
.TokenSale .Form .Button {
  height: 50px;
  margin-top: 10px;
}
.TokenSale .Form .Button .Button__label svg {
  margin-right: 4px;
  transform: translateY(-2px);
}
.TokenSale .Form .Button .Button__label svg path {
  fill: white;
}
.TokenSale .Form label {
  text-align: left;
  margin-bottom: -10px;
}
.TokenSale .Form label p {
  padding-left: 5px;
  margin-bottom: 5px;
  font-size: 15px;
  font-weight: 500;
  color: var(--cool-gray);
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.TokenSale__error {
  font-size: 12px;
  margin-top: 16px;
  color: var(--text-red);
}
