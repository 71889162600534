.Method__main .Method__main__title {
  display: flex;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 32px;
  margin-top: 0;
}
.Method__main .Method__main__title .Button {
  margin-left: auto;
}
.Method__main .Method__main__path {
  display: flex;
  margin-bottom: 16px;
}
.Method__main .Method__main__path .Label {
  margin-left: auto;
}
.Method__main .Method__main__path .link {
  color: inherit;
}
.Method__main .Method__main__content {
  display: block;
  font-size: 13px;
  line-height: 24px;
  margin-bottom: 32px;
}
.Method__main .Method__main__requirements {
  display: flex;
  align-items: center;
}
.Method__main .Method__main__requirements .Method__main__requirements__title {
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
}
