.Badge {
  position: relative;
  width: fit-content;
  display: inline-block;
}
.Badge .Badge__count {
  font-weight: 600;
  font-size: 8px;
  line-height: 8px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white);
  background: var(--text-red);
  position: absolute;
  height: 12px;
  min-width: 12px;
  padding: 0 4px;
  border-radius: 6px;
  box-sizing: border-box;
  top: 1px;
  left: 100%;
  transform: translate(-12px, 0%);
}
.Badge .Badge__count:empty {
  display: none;
}
.Badge-blue-icon {
  position: relative;
  cursor: pointer;
}
.Badge-blue-counter {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 11px;
  left: -3px;
  width: 12px;
  height: 12px;
  background: #577eea;
  border: 1px solid #f7f9fc;
  border-radius: 50%;
}
.Badge-blue-counter > div {
  position: relative;
  width: 100%;
  height: 100%;
}
.Badge-blue-counter > div span {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-weight: 600;
  font-size: 8px;
  line-height: 9px;
  color: #fff;
}
