.DexSwapInput {
  width: 100%;
}
.DexSwapInput__container {
  display: flex;
  align-items: center;
  justify-content: start;
  width: 100%;
  padding: 14.5px 0 16.5px 15px;
  border: 1px solid var(--light-gray);
  border-radius: 20px;
  box-sizing: border-box;
}
.DexSwapInput__select {
  display: flex;
  flex-direction: column;
  margin-left: 12px;
}
.DexSwapInput__label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 9px;
  padding: 0 15px 0 7px;
  color: var(--black-gunmetal);
}
.DexSwapInput__label > div {
  display: flex;
}
.DexSwapInput__title {
  margin-right: 4px;
  font-weight: 600;
  font-size: 16px;
  line-height: 110%;
  letter-spacing: -0.01em;
}
.DexSwapInput__balance {
  opacity: 0.5;
  font-weight: 500;
  font-size: 14px;
  line-height: 110%;
  text-align: right;
  letter-spacing: -0.01em;
  color: var(--dark-blue);
  cursor: pointer;
}
.DexSwapInput__balance:hover {
  opacity: 1;
}
.DexSwapInput__balance.active {
  color: inherit;
  opacity: 0.5;
  cursor: initial;
}
.DexSwapInput__icon {
  cursor: pointer;
  border-radius: 50%;
  background: var(--secondary-background);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
  width: 41px;
  height: 41px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
}
.DexSwapInput__icon .isvg,
.DexSwapInput__icon svg,
.DexSwapInput__icon img {
  width: 100%;
}
.DexSwapInput__select {
  margin-left: 53px;
  font-weight: 500;
  font-size: 14px;
  line-height: 110%;
  cursor: pointer;
}
.DexSwapInput__select > span {
  color: #cdcfd7;
  margin-bottom: 4px;
}
.DexSwapInput__currency {
  display: flex;
  font-weight: 600;
  font-size: 18px;
  line-height: 110%;
  letter-spacing: -0.01em;
}
.DexSwapInput__currency .isvg {
  margin-left: 8px;
}
.DexSwapInput__input {
  padding-top: 2px;
  box-sizing: border-box;
  width: 100%;
}
.DexSwapInput__input .Input {
  --input-height: 37px;
  border: none;
  background: none;
  font-weight: 500;
  font-size: 26px;
  line-height: 110%;
  letter-spacing: -0.01em;
}
.DexSwapInput__input input {
  text-align: right;
  padding: 0 0 0 10px;
  margin-right: -1px;
}
.adaptive .DexSwapInput .Input__wrapper {
  --input-height: 24px;
}
.adaptive .DexSwapInput__title {
  font-size: 14px;
}
.adaptive .DexSwapInput__container {
  padding: 8px 8px;
  border-radius: 12px;
}
.adaptive .DexSwapInput__select {
  font-size: 12px;
  margin-left: 42px;
}
.adaptive .DexSwapInput__currency {
  font-size: 16px;
}
.adaptive .DexSwapInput__icon {
  width: 32px;
  height: 32px;
}
body.theme-dark .DexSwapInput__balance {
  color: var(--primary-blue);
}
