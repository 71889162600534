.PrivatePools__CreatePool {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.PrivatePools__CreatePool h3 {
  text-align: left;
  font-weight: 500;
  font-size: 14px;
  color: var(--cool-gray);
}
