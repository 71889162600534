.DepositModal__ConfirmCancel .DepositModal__container {
  padding: 39px 38.5px 32px;
  max-width: 436px;
  width: 100%;
  box-sizing: border-box;
  text-align: center;
}
.DepositModal__ConfirmCancel h3 {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 26px;
}
.DepositModal__ConfirmCancel .buttons {
  width: 100%;
  margin-top: 24px;
}
.DepositModal__ConfirmCancel .buttons .Button {
  height: 50px;
  width: 100%;
}
.DepositModal__ConfirmCancel .buttons .Button__label {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
}
.DepositModal__ConfirmCancel .buttons .Button:not(:last-child) {
  margin-bottom: 20px;
}
.adaptive .DepositModal__ConfirmCancel .DepositModal__container {
  padding: 39px 20px 32px;
  max-width: 100%;
  background: #fff;
  box-shadow: 0px 15px 75px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
}
.adaptive .DepositModal__ConfirmCancel h3 {
  font-size: 16px;
  line-height: 19.5px;
}
.adaptive .DepositModal__ConfirmCancel p {
  line-height: 17px;
}
.adaptive .DepositModal__ConfirmCancel .buttons {
  width: auto;
  flex-direction: row !important;
  flex-wrap: wrap;
  margin: 24px -7.5px 0;
}
.adaptive .DepositModal__ConfirmCancel .buttons .Button {
  flex: 1 1 40%;
  min-width: 210px;
  margin: 0 7.5px;
}
.adaptive .DepositModal__ConfirmCancel .buttons .Button:not(:last-child) {
  margin-bottom: 10px;
}
.adaptive .DepositModal__ConfirmCancel .buttons .Button__label {
  font-size: 14px !important;
}
.adaptive .DepositModal__ConfirmCancel .buttons .Button__label .isvg {
  height: 15px;
  width: 13.65px;
}
