.ExchangerTopup {
  margin-bottom: 10px;
  border: none;
  padding: 0 40px;
  background: #fff;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
  border-radius: var(--radius-panel-large);
  position: relative;
}
.ExchangerTopup .ContentBox__content {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px 40px 18px 0;
  overflow: hidden;
}
.ExchangerTopup__bg-icon {
  position: absolute;
  top: -41px;
  left: 50%;
  margin-left: -35px;
  transform: translateX(-50%);
  pointer-events: none;
}
.ExchangerTopup__info {
  font-size: 18px;
  font-weight: 600;
}
.ExchangerTopup__info-balance {
  margin-top: 9px;
  display: flex;
  align-items: center;
}
.ExchangerTopup__info-balance.active {
  color: var(--royal-blue);
  cursor: pointer;
}
.ExchangerTopup__info-balance span {
  display: inline-block;
  font-size: 11px;
  color: var(--royal-blue);
  cursor: pointer;
  margin-left: 8px;
  opacity: 0.65;
  padding: 4px 8px;
  transition: all 0.15s ease;
}
.ExchangerTopup__info-balance span:hover {
  opacity: 1;
}
.ExchangerTopup__actions {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ExchangerTopup__actions time.Timer {
  margin-bottom: 10px;
  font-size: 12px;
  color: var(--royal-blue);
  font-weight: 600;
}
.ExchangerTopup__actions .Button:not(:first-child) {
  margin-top: 13px;
}
.ExchangerTopup__actions-buttons {
  display: flex;
}
.ExchangerTopup__actions-buttons .Button:not(:first-child) {
  margin-top: 0;
  margin-left: 8px;
}
.ExchangerTopup .Button {
  height: 40px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
  color: var(--royal-blue);
  background: var(--alice-blue);
  width: 189px;
}
.ExchangerTopup .Button__label {
  font-size: 12px;
}
.ExchangerTopup .Button:after {
  opacity: 0;
}
.ExchangerTopup .Button:hover:after {
  background: var(--royal-blue);
  opacity: 0.1;
}
.ExchangerTopup__waiting {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
}
.ExchangerTopup.adaptive {
  margin-bottom: 20px;
}
.ExchangerTopup.adaptive .ContentBox__content {
  flex-direction: column;
  height: initial;
  padding: 20px 18px 26px;
  text-align: center;
}
.ExchangerTopup.adaptive .ExchangerTopup__actions {
  margin-top: 20px;
}
.ExchangerTopup.adaptive .ExchangerTopup__actions-buttons {
  flex-direction: column;
}
.ExchangerTopup.adaptive .ExchangerTopup__actions-buttons .Button:not(:first-child) {
  margin-left: 0;
  margin-top: 8px;
}
.ExchangerTopup.adaptive .ExchangerTopup__info-balance {
  justify-content: center;
}
