.Logo {
  display: block;
  width: 104px;
  height: 32px;
}
.Logo.large {
  width: 130px;
  height: 39px;
}
.Logo.extra-large {
  width: 160px;
  height: 48px;
}
.Logo .isvg {
  display: block;
  height: inherit;
  width: inherit;
  font-size: 0;
}
.Logo .isvg svg {
  height: inherit;
  width: inherit;
}
