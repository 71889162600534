.List {
  display: table;
  border-spacing: 16px;
}
.List .List__item {
  display: table-row;
}
.List .List__item.margin > * {
  padding-bottom: 16px;
}
.List .List__item .List__item__label,
.List .List__item .List__item__value {
  display: table-cell;
}
.List .List__item .List__item__value {
  word-break: break-word;
  display: flex;
  color: var(--text-black);
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}
.List .List__item .List__item__label {
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  text-align: right;
  color: var(--slate-gray);
}
.Admin_wrapper .List__item .Group {
  align-items: center;
}
.Admin_wrapper .List__item .Button {
  margin-left: 8px;
}
