.RoadmapV3 {
  position: relative;
  left: 0;
}
.RoadmapV3__items {
  display: flex;
  overflow-x: scroll;
  padding-top: 20px;
  scrollbar-width: none;
}
.RoadmapV3__items::-webkit-scrollbar {
  display: none;
}
.RoadmapV3__line {
  position: absolute;
  top: 20px;
  left: 14px;
  width: 100vw;
  border-top: 3px dashed #d5e4fe;
}
