.Translation__wrapper {
  width: 400px;
  padding: 30px 40px 40px;
}
.Translation__title {
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: var(--black);
  margin-bottom: 8px;
  padding: 0 15px;
}
.Translation__button {
  text-align: center;
  margin-top: 40px;
}
.Translation__button .Button {
  width: 160px;
}
.Translation__key {
  font-size: 13px;
  line-height: 24px;
  margin-bottom: 28px;
  padding: 0 15px;
}
.Translation__lang {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.Translation__lang span {
  width: 16px;
  cursor: pointer;
}
.adaptive .Translation__wrapper {
  box-sizing: border-box;
  padding: 16px;
  width: 100%;
}
