.Referral__Card {
  min-height: 132px;
  background: #fff;
  border-radius: var(--radius-panel);
  padding: 30px 16px 27px;
  box-sizing: border-box;
  margin: 5px 0;
}
.Referral__Card:not(:last-child) {
  margin-right: 10px;
}
.Referral__Card.secondary {
  background: #e5ebfc;
}
.Referral__Card > .Row > .Col:not(:last-child) {
  margin-right: 16px;
}
.Referral__Card > div:first-child {
  margin-bottom: 27px;
}
.Referral__Card__title {
  margin-bottom: 5px;
  color: var(--cool-gray);
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  white-space: nowrap;
}
.Referral__Card__count {
  white-space: nowrap;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}
.secondary .Referral__Card__count,
.Referral__Card .Row:first-child .Referral__Card__count {
  font-size: 20px;
  line-height: 24px;
  color: var(--secondary-blue);
}
.Referral__Card .HoverPopup {
  margin-left: auto;
  margin-top: 4px;
  font-weight: 600;
  font-size: 12px;
  line-height: 110%;
}
.Referral__Card .HoverPopup__content {
  max-width: 300px;
}
.Referral__Card .icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
@media screen and (min-width: 1600px) {
  .Referral__Card {
    max-width: 420px;
  }
}
.adaptive .Referral__Card {
  min-height: 0;
  background: #fff;
  border-radius: var(--radius-panel);
  padding: 17px 16px;
  box-sizing: border-box;
  margin: 0;
}
.adaptive .Referral__Card__title {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
}
.adaptive .Referral__Card > .Row > .Col:not(:last-child) {
  margin-right: 7px;
}
.adaptive .Referral__Card.secondary {
  flex: 0 0 100%;
  background: #e5ebfc;
}
