.DepositModal__Bank__sidebar {
  background: #fff;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  padding: 23.9px 24px 23.9px;
  box-sizing: border-box;
}
.DepositModal__Bank__sidebar h4 {
  margin-bottom: 60px;
}
.DepositModal__Bank__sidebar p:not(:last-child) {
  margin-bottom: 8px;
}
.DepositModal__Bank__sidebar-item {
  width: 100%;
  margin-bottom: 24px;
}
.DepositModal__Bank__sidebar-item.line {
  height: 1px;
  background: #c4c4c4;
}
.adaptive .DepositModal__Bank__sidebar {
  padding: 20px;
  text-align: center;
}
.adaptive .DepositModal__Bank__sidebar h4 {
  margin-bottom: 24px;
}
.adaptive .DepositModal__Bank__sidebar-item:last-child {
  margin-bottom: 0;
}
.adaptive .DepositModal__Bank__sidebar-item .small:nth-child(2) {
  font-size: 14px;
}
