.Team__Card {
  padding: 23px 27px;
  background: #f7f8fb;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
  border-radius: var(--radius-panel);
  box-sizing: border-box;
}
.Team__Card__icon {
  margin: 0 auto 18px;
  max-width: 250px;
  max-height: 250px;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  overflow: hidden;
}
.Team__Card__icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.Team__Card__title {
  font-weight: 600;
  font-size: 24px;
  line-height: 25px;
  letter-spacing: -0.01em;
  color: #000;
}
.Team__Card__tag {
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: -0.01em;
  color: #000;
}
.Team__Card__description {
  margin-bottom: 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 110%;
  letter-spacing: -0.01em;
  color: var(--dark-black);
  transition: color 0.2s;
}
.Team__Card__description:not(.active) {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  -webkit-box-orient: vertical;
  color: var(--cool-gray);
}
.Team__Card__show-all {
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  color: var(--royal-blue);
  cursor: pointer;
}
.Team__Card__show-all:hover {
  opacity: 0.8;
}
.Team__Card__arrow {
  display: flex;
  align-items: center;
  margin-left: 3px;
  transition: transform 0.2s;
}
.Team__Card__arrow.active {
  transform: rotate(-90deg) translateX(-1px);
}
