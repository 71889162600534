@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
.SendTokens > .Modal__box > .CabinetModal__container {
  padding: 40px 38px;
  background: #fff;
  border-radius: var(--radius-panel);
}
.SendTokens h3 {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: var(--dark-black);
}
.SendTokens__address {
  position: relative;
}
.SendTokens__address input {
  padding-right: 36px !important;
}
.SendTokens__address-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  fill: var(--light-gray);
  cursor: pointer;
}
.SendTokens__address-icon path {
  fill: var(--light-gray);
  transition: fill 0.2s;
}
.SendTokens__address-icon:hover path {
  fill: var(--royal-blue);
}
.SendTokens-loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 40px 40px;
}
.SendTokens-loading .isvg svg {
  margin-bottom: 16px;
  animation: spin 1s linear infinite;
}
.SendTokens-loading .isvg svg path {
  fill: #fabe4c;
}
.SendTokens-form {
  margin-top: 16.85px;
}
.SendTokens-form h3 {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  font-weight: 600;
  font-size: 14px;
  line-height: 180%;
  color: var(--cool-gray);
}
.SendTokens-form h3 span:not(:first-child) {
  margin-left: 16px;
}
.SendTokens-form h3 span.active {
  font-weight: 600;
  font-size: 11px;
  line-height: 180%;
  color: var(--royal-blue);
  outline: none;
  cursor: pointer;
}
.SendTokens-form h3 span.active:hover {
  text-decoration: underline;
}
.SendTokens-form .Input__wrapper {
  margin: 7.5px 0 0;
}
.SendTokens-form .Input__wrapper .Input {
  min-width: 381px;
  border: 1px solid #dfe3f0;
  background: none;
  border-radius: 10px;
  height: 46px;
}
.adaptive .SendTokens-form .Input__wrapper .Input {
  min-width: initial;
  width: 100%;
}
.SendTokens-form .Input__wrapper .Input:focus {
  border-color: var(--royal-blue) !important;
}
.SendTokens-form .Input__wrapper .Input__description {
  margin: 16px 0 0;
}
.SendTokens-form .Input__wrapper:not(:last-child) {
  margin-bottom: 27px;
}
.SendTokens-form .Button {
  margin: 30px auto 0;
  width: 100%;
}
.SendTokens-form .Button .isvg {
  margin-right: 10.6px;
}
.SendTokens-form .Button .isvg svg {
  width: 24.12px;
  height: 27.13px;
}
.adaptive .SendTokens > .Modal__box {
  padding: 20px !important;
}
.adaptive .SendTokens > .Modal__box > .CabinetModal__container {
  padding: 48px 20px 40px;
}
.adaptive .SendTokens h3 {
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 20.85px;
}
.adaptive .SendTokens-form h3 {
  font-size: 14px;
  margin: 0;
  align-items: center;
}
.adaptive .SendTokens-form .Input__wrapper {
  margin: 8.15px 0 0;
}
.adaptive .SendTokens-form .Input__wrapper .Input {
  height: 42px;
}
.adaptive .SendTokens-form .Input__wrapper:not(:last-child) {
  margin-bottom: 16px;
}
.adaptive .SendTokens-form .Button {
  margin: 22px auto 0;
  width: 100%;
}
.adaptive .SendTokens-form .Button__label {
  font-size: 14px;
  line-height: 17px;
}
.adaptive .SendTokens-form .Button svg {
  width: 21.33px;
  height: 24px;
}
