.MainLanding-join-us {
  padding: 60px 30px 59px 52px;
  box-sizing: border-box;
  gap: 38px 10px;
  border-radius: 30px;
  background: linear-gradient(198.49deg, #e5e9f4 12.53%, #f2f6ff 95.91%), #e5e9f4;
}
.MainLanding-join-us__wrapper:not(:last-child) {
  margin-bottom: 150px;
}
.MainLanding-join-us__content {
  height: auto;
}
.MainLanding-join-us h2 {
  margin-bottom: 6px;
}
.MainLanding-join-us__text {
  margin-bottom: 20px;
  font-weight: 500;
  font-size: 18px;
  line-height: 160%;
  color: rgba(47, 50, 61, 0.6);
}
.MainLanding-join-us__text span {
  color: var(--royal-blue);
  font-weight: 600;
  word-break: break-word;
}
.MainLanding-join-us__buttons {
  margin-top: auto;
  gap: 10px;
}
.MainLanding-join-us .MainLanding-SocialLinks {
  margin: 20px 0;
}
.MainLanding-join-us .MainLanding-SuggestiveBox__title {
  width: 137px;
}
@media screen and (max-width: 1200px) {
  .MainLanding-JoinUsCards__showIn {
    width: 100%;
  }
  .MainLanding-JoinUsCards {
    flex-wrap: wrap;
  }
  .MainLanding-JoinUsCard {
    flex: 1 1 300px;
  }
}
.adaptive .MainLanding-join-us {
  padding: 50px 34px 60px;
}
.adaptive .MainLanding-join-us__wrapper:not(:last-child) {
  margin-bottom: 80px;
}
.adaptive .MainLanding-join-us h2 {
  margin-bottom: 4px;
  text-align: center;
}
.adaptive .MainLanding-join-us p {
  font-size: 16px;
  text-align: center;
}
.adaptive .MainLanding-join-us .MainLanding-SocialLinks {
  margin: 25px 0 30px;
}
.adaptive .MainLanding-join-us__buttons {
  margin-top: 54px;
}
