.DexDescription {
  background: var(--secondary-background);
  border-radius: 15px;
  width: 100%;
  margin: 20px 0 0;
  padding: 19px 21px 25px 19px;
  font-size: 12px;
  font-weight: 600;
  box-sizing: inherit;
}
.DexDescription h3 {
  font-size: 14px;
  font-weight: 800;
  margin-bottom: 20px;
}
.DexDescription-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
}
.DexDescription-item:not(:first-child) {
  margin-top: 18px;
}
.DexDescription-item > * {
  display: flex;
  align-items: center;
  position: relative;
}
.DexDescription-item > *:first-child {
  color: var(--gray);
  white-space: nowrap;
}
.DexDescription-item > *:nth-child(2) {
  font-weight: 600;
  text-align: right;
}
.DexDescription-item > *.green {
  color: var(--green);
}
.DexDescription-item > *.yellow {
  color: var(--yellow);
}
.DexDescription-item > *.red {
  color: var(--red);
}
.DexDescription-item > * a {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 160px;
  display: inline-block;
}
.adaptive .DexDescription {
  width: 100%;
  padding: 15px 15px;
  border-radius: 0;
  box-sizing: border-box;
}
.adaptive .DexDescription > * {
  font-weight: 500;
  font-size: 12px;
  line-height: 110%;
}
.adaptive .DexDescription > * a {
  width: 100px;
}
