.NrfxPresaleModal .Modal__box {
  background-color: var(--text-black);
  width: 400px;
  color: var(--white);
  padding: 40px;
  background-repeat: no-repeat;
  background-image: url("./assets/background-left.svg"), url("./assets/background-right.svg");
  background-position: left bottom, right bottom;
}
.NrfxPresaleModal .Modal__box__close {
  color: currentColor !important;
}
.NrfxPresaleModal .Modal__header {
  background: var(--text-black);
  color: var(--white);
}
.NrfxPresaleModal .NrfxPresaleModal__label {
  text-align: center;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  margin-bottom: 16px;
}
.NrfxPresaleModal .NrfxPresaleModal__promo > .isvg {
  display: block;
  width: 253px;
  height: 120px;
  margin: 0 auto;
}
.NrfxPresaleModal .NrfxPresaleModal__promo .Tooltip__wrapper {
  margin: 16px auto;
}
.NrfxPresaleModal .NrfxPresaleModal__promo .Tooltip__wrapper a {
  display: flex;
  align-items: center;
}
.NrfxPresaleModal .NrfxPresaleModal__promo .Tooltip__wrapper a .isvg {
  margin-left: 4px;
}
.NrfxPresaleModal .NrfxPresaleModal__promo .Tooltip__wrapper a .isvg svg {
  display: block;
}
.NrfxPresaleModal .NrfxPresaleModal__promo small {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
}
.NrfxPresaleModal .NrfxPresaleModal__promo small .isvg {
  display: block;
  margin-left: 6px;
}
.NrfxPresaleModal .NrfxPresaleModal__promo small .isvg svg {
  width: 16px;
  height: 16px;
  display: block;
}
.NrfxPresaleModal .NrfxPresaleModal__amountInput {
  margin: 40px 0;
}
.NrfxPresaleModal .NrfxPresaleModal__amountInput .Input__wrapper {
  border-radius: 0;
}
.NrfxPresaleModal .NrfxPresaleModal__amountInput .Input__wrapper .Input {
  color: var(--white);
  border-radius: 16px;
  padding: 16px;
  border: 1px solid;
  background: transparent;
  box-shadow: none;
}
.NrfxPresaleModal .NrfxPresaleModal__amountInput .Input__wrapper .Input__indicator {
  color: currentColor;
}
.NrfxPresaleModal .NrfxPresaleModal__currencies {
  margin: 40px 0;
}
.NrfxPresaleModal .NrfxPresaleModal__price {
  margin: 16px 32px;
  display: flex;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
}
.NrfxPresaleModal .NrfxPresaleModal__price strong {
  margin-left: auto;
  transition: opacity 0.2s ease;
}
.NrfxPresaleModal .NrfxPresaleModal__price strong.pending {
  transition: none;
  opacity: 0;
}
.NrfxPresaleModal .NrfxPresaleModal__total {
  position: relative;
  text-align: center;
  margin: 40px 0;
}
.NrfxPresaleModal .NrfxPresaleModal__total .isvg {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  width: 24px;
  height: 24px;
  animation: nrfxLoader 1s linear infinite;
}
.NrfxPresaleModal .NrfxPresaleModal__total .isvg svg {
  display: block;
}
.NrfxPresaleModal .NrfxPresaleModal__total.pending .NrfxPresaleModal__label,
.NrfxPresaleModal .NrfxPresaleModal__total.pending .NrfxPresaleModal__total__amount {
  opacity: 0;
}
.NrfxPresaleModal .NrfxPresaleModal__total.pending .isvg {
  display: block;
}
.NrfxPresaleModal .NrfxPresaleModal__total .NrfxPresaleModal__label {
  margin-bottom: 8px;
  transition: opacity 0.2s ease;
}
.NrfxPresaleModal .NrfxPresaleModal__total .NrfxPresaleModal__total__amount {
  transition: opacity 0.2s ease;
}
.NrfxPresaleModal .NrfxPresaleModal__total .NrfxPresaleModal__total__amount strong {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
}
.NrfxPresaleModal .NrfxPresaleModal__total .NrfxPresaleModal__total__amount small {
  text-transform: uppercase;
  display: inline-block;
  margin-left: 4px;
  font-weight: 500;
  font-size: 13px;
  line-height: 24px;
}
.NrfxPresaleModal .NrfxPresaleModal__submit .NrfxButton {
  display: block;
  width: 240px;
  margin: 0 auto;
}
.adaptive .NrfxPresaleModal .Modal__back {
  background: var(--black);
}
.adaptive .NrfxPresaleModal .Modal__box {
  padding: 40px 16px;
}
@keyframes nrfxLoader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
