.FarmingTableItem.options {
  box-shadow: inset 0 0 0 1px #e3e9f0;
  border-radius: var(--radius-panel);
  margin-bottom: 20px;
  opacity: 0;
  transition: opacity 0.5s;
}
.FarmingTableItem.options td {
  padding-top: 12px !important;
}
.FarmingTableItem.options td:last-child {
  padding-left: 16px;
}
.FarmingTableItem.isVisible {
  opacity: 1;
}
.FarmingTableItem__separator {
  padding: 8px 0 !important;
}
