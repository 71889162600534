.OpenPopupLink {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: -0.023em;
  color: var(--royal-blue);
  cursor: pointer;
}
.OpenPopupLink .isvg {
  display: flex;
  align-items: center;
  width: 13px;
  height: 13px;
  margin-left: 7px;
}
.OpenPopupLink:hover {
  opacity: 0.9;
}
