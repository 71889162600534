.Modal {
  position: fixed;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  background-color: rgba(0, 0, 0, 0.25);
  z-index: 1100;
  right: 0;
  top: 0;
  bottom: 0;
  overflow-y: scroll;
  padding: 60px 0;
  box-sizing: border-box;
  flex-wrap: wrap;
  -webkit-overflow-scrolling: touch;
}
.Modal .Modal__box {
  background: var(--primary-background);
  border-radius: 8px;
  padding: 32px 40px;
  margin: auto;
  box-sizing: border-box;
  min-width: 120px;
  position: relative;
  flex: 0 0 auto;
}
.Modal .Modal__box .Modal__box__close {
  position: absolute;
  display: flex;
  right: 0;
  cursor: pointer;
  top: 0;
  background-size: 14px;
  width: 38px;
  height: 38px;
  color: var(--gray);
  z-index: 2;
}
.Modal .Modal__box .Modal__box__close .isvg {
  display: block;
  margin: auto;
}
.Modal .Modal__box .Modal__box__close .isvg svg {
  display: block;
}
.Modal.Modal__grayBackground .Modal__box {
  background: transparent;
  box-shadow: none;
}
.Modal.Modal__noSpacing .Modal__box {
  padding: 0 !important;
}
.Modal__header {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  height: 40px;
  display: flex;
  align-items: center;
  text-align: center;
  position: relative;
  width: 100%;
  justify-content: center;
}
.Modal__noSpacing .Modal__header {
  margin-top: 32px;
}
.Modal__back {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
}
.Modal.adaptive {
  align-items: start;
  background: var(--alice-blue);
  min-height: 100%;
  padding: 64px 0 16px;
}
.Modal.adaptive .Modal__box {
  width: 100% !important;
  max-height: none;
  overflow: initial;
  border-radius: 0;
  padding: 24px 16px;
  box-shadow: 0 0 0 1px var(--extra-light-gray);
  margin-top: 0;
}
.Modal.adaptive .Modal__box__close {
  position: fixed;
  top: 5px;
  left: 12px;
  right: auto;
  z-index: 101;
}
.Modal.adaptive .Modal__back {
  height: 100%;
}
.Modal.adaptive .Modal__header {
  background: var(--primary-background);
  box-shadow: 0 0 0 1px var(--extra-light-gray);
  border-radius: 0 0 8px 8px;
  overflow: initial;
  position: fixed;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  top: 0;
  left: 0;
  height: 48px;
  margin: 0;
  z-index: 100;
}
.Modal.adaptive .Button {
  width: 100%;
}
@keyframes showModal {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
