.MainLanding-other-products:not(:last-child) {
  margin-bottom: 150px;
}
.MainLanding-other-products__title {
  margin-bottom: 35px;
}
.MainLanding-other-products .slider-control {
  display: flex;
  gap: 8px;
}
.MainLanding-other-products .slider-control__arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 67px;
  width: 67px;
  border: 2px solid #e5e9f4;
  border-radius: 21px;
}
.MainLanding-other-products .slider-control__arrow:active {
  transform: scale(0.95);
}
.MainLanding-other-products .slider-control__prev .isvg {
  transform: rotate(180deg);
}
.adaptive .MainLanding-other-products:not(:last-child) {
  margin-bottom: 86px;
}
.adaptive .MainLanding-other-products__title {
  margin-bottom: 18px;
  gap: 16px;
}
.adaptive .MainLanding-other-products .slider-control {
  gap: 6px;
}
.adaptive .MainLanding-other-products .slider-control__arrow {
  height: 54px;
  width: 54px;
}
.adaptive .MainLanding-other-products .slider-control__arrow svg {
  width: 19.44px;
  height: 19.44px;
}
