.Code {
  white-space: pre-wrap;
  padding: 8px 16px;
  margin: 16px 0;
  color: #333;
  font-size: 11px;
  line-height: 16px;
  background: #f8f8f8;
  max-width: 50em;
  text-align: left;
  display: block;
  font-family: monospace;
}
.Code .hljs {
  overflow-x: auto;
}
.Code .hljs-comment,
.Code .hljs-quote {
  color: #998;
  font-style: italic;
}
.Code .hljs-keyword,
.Code .hljs-selector-tag,
.Code .hljs-subst {
  color: #333;
  font-weight: bold;
}
.Code .hljs-number,
.Code .hljs-literal,
.Code .hljs-variable,
.Code .hljs-template-variable,
.Code .hljs-tag .hljs-attr {
  color: #008080;
}
.Code .hljs-string,
.Code .hljs-doctag {
  color: #d14;
}
.Code .hljs-title,
.Code .hljs-section,
.Code .hljs-selector-id {
  color: #900;
  font-weight: bold;
}
.Code .hljs-subst {
  font-weight: normal;
}
.Code .hljs-type,
.Code .hljs-class .hljs-title {
  color: #458;
  font-weight: bold;
}
.Code .hljs-tag,
.Code .hljs-name,
.Code .hljs-attribute {
  color: #000080;
  font-weight: normal;
}
.Code .hljs-regexp,
.Code .hljs-link {
  color: #009926;
}
.Code .hljs-symbol,
.Code .hljs-bullet {
  color: #990073;
}
.Code .hljs-built_in,
.Code .hljs-builtin-name {
  color: #0086b3;
}
.Code .hljs-meta {
  color: #999;
  font-weight: bold;
}
.Code .hljs-deletion {
  background: #fdd;
}
.Code .hljs-addition {
  background: #dfd;
}
.Code .hljs-emphasis {
  font-style: italic;
}
.Code .hljs-strong {
  font-weight: bold;
}
