.PartnerInfoModal__user_info {
  padding: 40px 40px 32px 40px;
  display: flex;
  align-items: center;
}
.PartnerInfoModal__user_info .ProfileUser__avatar__wrap {
  margin: 0;
}
.adaptive .PartnerInfoModal__user_info {
  padding: 8px 24px 24px 24px;
}
.PartnerInfoModal__user_info__photo {
  width: 112px;
  height: 112px;
  background: no-repeat center / cover;
  background: red;
}
.PartnerInfoModal__user_info__cont {
  margin-left: 40px;
}
.adaptive .PartnerInfoModal__user_info__cont {
  margin-left: 16px;
}
.PartnerInfoModal__user_info__name {
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
}
.adaptive .PartnerInfoModal__user_info__name {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}
.PartnerInfoModal__user_info__login {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-top: 8px;
  color: var(--text-black);
  text-transform: uppercase;
}
.adaptive .PartnerInfoModal__user_info__login {
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
}
.PartnerInfoModal__block {
  margin-bottom: 32px;
  margin-top: 0 !important;
}
.PartnerInfoModal__block.skip_margin {
  margin-bottom: 0;
}
.PartnerInfoModal__block.padding {
  padding: 0 40px;
}
.PartnerInfoModal__block__title {
  font-weight: 500;
  font-size: 16px;
  line-height: 40px;
  text-align: center;
}
.adaptive .PartnerInfoModal__block__title {
  line-height: 24px;
  padding: 16px 0 0 0;
}
.PartnerInfoModal__block__content {
  margin-top: 16px;
}
.adaptive .PartnerInfoModal__block__content.wallets {
  margin-top: 0;
}
.PartnerInfoModal__block.wallets .PartnerInfoModal__block__content {
  padding: 0 40px;
}
.adaptive .PartnerInfoModal__block.wallets .PartnerInfoModal__block__content {
  padding: 0;
}
.PartnerInfoModal__block__content .TableMain {
  box-shadow: var(--main-shadow);
}
.adaptive .PartnerInfoModal__block__content .TableMain {
  box-shadow: none !important;
}
.PartnerInfoModal__block__content .CabinetProfileScreen__walletsContentBox {
  box-shadow: none !important;
}
