.CabinetFooter {
  list-style: none;
  padding: 0;
  padding-left: 16px;
  margin: 28px 0;
}
.CabinetFooter__translator {
  font-size: 12px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.CabinetFooter__translator .Switch {
  margin-top: 0;
  margin-left: 7px;
}
.CabinetFooter__item {
  display: inline-block;
  letter-spacing: 0.444444px;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
}
.CabinetFooter__item a,
.CabinetFooter__item .link {
  color: var(--text-black);
  margin-right: 8px;
}
