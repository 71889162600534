.MainLanding-SuggestiveBox {
  display: flex;
  align-items: center;
  padding: 25px;
  border-radius: 29px;
  color: #000;
  box-sizing: border-box;
  text-align: left;
  transition: transform 0.1s;
}
.MainLanding-SuggestiveBox:hover {
  transform: scale(1.02);
  text-decoration: none;
}
.MainLanding-SuggestiveBox-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 67px;
  height: 67px;
  min-width: 67px;
  min-height: 67px;
  position: relative;
  margin-right: 20px;
}
.MainLanding-SuggestiveBox-icon__bg {
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(136.77deg, #c4d9f7 9.79%, rgba(196, 217, 247, 0) 94.58%);
  border-radius: 21px;
  opacity: 0.45;
}
.MainLanding-SuggestiveBox-icon .isvg {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
}
.MainLanding-SuggestiveBox-icon .isvg svg {
  min-width: 22px;
  min-height: 22px;
  max-width: 70%;
  max-height: 70%;
  fill: var(--royal-blue);
}
.MainLanding-SuggestiveBox-icon .isvg svg path {
  fill: inherit;
}
.MainLanding-SuggestiveBox__title {
  font-weight: 600;
  font-size: 14px;
  line-height: 132%;
  margin-bottom: 3px;
}
.MainLanding-SuggestiveBox__subtitle {
  font-weight: 500;
  font-size: 12px;
  line-height: 132%;
  opacity: 0.3;
}
.MainLanding-SuggestiveBox__title,
.MainLanding-SuggestiveBox__subtitle {
  max-width: 137px;
}
.MainLanding-SuggestiveBox.small {
  padding: 11px 10px 9px;
}
.MainLanding-SuggestiveBox.medium {
  padding: 19px 21px 19px 16px;
}
.MainLanding-SuggestiveBox.large {
  padding: 25px;
}
.MainLanding-SuggestiveBox.border {
  border: 1px solid var(--azureish-white);
}
.MainLanding-SuggestiveBox.background {
  background: #fff;
  box-shadow: 0px 34px 74px rgba(13, 40, 80, 0.26);
}
.adaptive .MainLanding-SuggestiveBox.large {
  padding: 11px 10px 9px;
}
