.SwapForm {
  margin-bottom: var(--gap-size);
}
.SwapForm .ContentBox__content {
  padding: 40px;
  padding-bottom: 32px;
}
.SwapForm__formWrapper {
  display: flex;
  margin-bottom: 8px;
}
.SwapForm__form {
  flex: 1;
}
.SwapForm__form__label {
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  margin-bottom: 16px;
}
.SwapForm__form__control {
  margin-bottom: 16px;
}
.SwapForm__form__control input {
  text-align: right;
}
.SwapForm__form__control__meta {
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  color: var(--gray);
  margin: 8px 16px 0;
}
.SwapForm__form__control__meta.right {
  text-align: right;
}
.SwapForm__form__control__meta.active {
  cursor: pointer;
}
.SwapForm__form__control__meta.active:hover {
  color: var(--primary-blue);
}
.SwapForm__separator {
  position: relative;
  width: 104px;
  margin: 32px 0 40px;
  display: flex;
}
.SwapForm__separator:before {
  position: absolute;
  content: '';
  display: block;
  width: 1px;
  height: 100%;
  left: 0;
  right: 0;
  margin: auto;
  background: var(--black);
  transform-origin: left center;
  opacity: 0.2;
  z-index: -1;
}
@media (min-resolution: 2dppx) {
  .SwapForm__separator:before {
    transform: scaleX(0.5);
  }
}
@media (min-resolution: 3dppx) {
  .SwapForm__separator:before {
    transform: scaleX(0.32);
  }
}
.SwapForm__switchButton {
  position: relative;
  margin: auto;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: var(--blue-gradient);
  color: var(--white);
  display: flex;
  cursor: pointer;
  border: 8px solid var(--primary-background);
  background: var(--light-gray);
  cursor: default;
  pointer-events: none;
}
.SwapForm__switchButton .isvg {
  transition: 0.3s ease;
  margin: auto;
  display: block;
}
.SwapForm__switchButton .isvg svg {
  display: block;
}
.SwapForm__switchButton:hover {
  background: var(--dark-blue);
}
.SwapForm__switchButton::after {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  content: "";
  height: 24px;
  width: 24px;
  display: block;
  filter: invert(1);
  background: url("../../../../../../asset/24px/loading.svg");
  animation: Button__loader_anim 1s infinite linear;
  z-index: 1;
  opacity: 0;
  transform: scale(0.3);
  transition: 0.3s ease;
}
.SwapForm__switchButton.loading {
  pointer-events: none;
}
.SwapForm__switchButton.loading .isvg {
  opacity: 0;
  transform: scale(0.3);
}
.SwapForm__switchButton.loading::after {
  opacity: 1;
  transform: scale(1);
}
.SwapForm__submitWrapper {
  display: flex;
}
.SwapForm__submitWrapper .Button {
  margin: 0 auto;
}
