.CabinetSelect__control {
  height: 39px;
  min-width: 150px !important;
  border: 1px solid #dfe3f0 !important;
  background: var(--alice-blue) !important;
  border-radius: 10px !important;
  padding: 0 11px !important;
}
.CabinetSelect__value-container {
  padding: 0 !important;
}
.CabinetSelect__option,
.CabinetSelect__single-value {
  display: flex !important;
  align-items: center !important;
  font-weight: 600;
  font-size: 14px;
  line-height: 115%;
  color: var(--dark-black);
}
.CabinetSelect__option img,
.CabinetSelect__single-value img {
  width: 20px;
  height: 20px;
}
.CabinetSelect__option-icon {
  margin-right: 7px;
  height: 100%;
  display: flex;
  align-items: center;
}
.CabinetSelect__option-icon img {
  width: 100%;
  height: 100%;
}
.CabinetSelect__option-title {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.CabinetSelect__option-value {
  margin-left: auto;
}
.CabinetSelect__menu-list {
  padding: 0 !important;
}
.CabinetSelect__option {
  font-weight: 500;
}
.CabinetSelect__option:hover {
  background: #e4eafa !important;
}
.CabinetSelect__option--is-selected {
  background: transparent !important;
  color: var(--dark-black) !important;
}
.CabinetSelect__option span:last-child {
  transform: translateY(1px);
}
.CabinetSelect__indicators .isvg {
  display: flex;
  align-items: center;
}
.simple .CabinetSelect__control {
  background: none !important;
  border-color: #dfe3f0 !important;
}
.simple .CabinetSelect__single-value,
.simple .CabinetSelect__option,
.simple .CabinetSelect__placeholder {
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.01em;
}
.simple .CabinetSelect__single-value {
  color: var(--cool-gray);
}
