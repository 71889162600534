body,
.SiteWrapper {
  --font-size-landing: 16px;
  --font-size-title: 32px;
  --padding-section: 80px;
  --padding-panel: 40px;
  --primary-orange: #ff9326;
  --primary-orange-opacity: rgba(255, 147, 38, 0.1);
  --dark-orange: #f28d00;
  --main-orange: #fa9751;
  --royal-blue: #5078e6;
  --secondary-blue: #3071ee;
  --primary-blue: #1949d7;
  --primary-blue-opacity: rgba(58, 101, 230, 0.1);
  --dark-blue: #1949d7;
  --primary-dark-blue: #365fd9;
  --orange-gradient-color-start: #ffa526;
  --orange-gradient-color-end: #ff860d;
  --orange-gradient: linear-gradient(0deg, var(--orange-gradient-color-end) 0%, var(--orange-gradient-color-start) 100%);
  --light-orange-gradient-color-start: #fa9751;
  --light-orange-gradient-color-end: #fabe4c;
  --light-orange-gradient: linear-gradient(180deg, var(--light-orange-gradient-color-start) 0%, var(--light-orange-gradient-color-end) 100%);
  --blue-gradient-color-start: #4d72df;
  --blue-gradient-color-end: #274cbd;
  --blue-gradient: linear-gradient(0deg, var(--blue-gradient-color-end) 0%, var(--blue-gradient-color-start) 100%);
  --blue-light-gradient-color-start: #78a0ff;
  --blue-light-gradient-color-end: #5078e5;
  --blue-light-gradient: linear-gradient(135deg, var(--blue-light-gradient-color-start) 0%, var(--blue-light-gradient-color-end) 100%),
    #224dcf;
  --primary-blue-color-start: #4070ff;
  --primary-blue-color-end: var(--primary-dark-blue);
  --primary-blue-gradient: linear-gradient(180deg, var(--primary-blue-color-start) 0%, var(--primary-blue-color-end) 100%);
  --gunmetal: #2f323d;
  --black-gunmetal2: #2f323d;
  --black-gunmetal: #2d313f;
  --dark-gunmetal: #222330;
  --dark-black: #0a1539;
  --black: #1c212e;
  --almost-black: #2e323e;
  --text-black: #3a3f4d;
  --cool-gray: #9095a5;
  --cool-dark-gray: #757575;
  --slate-gray: #595e6b;
  --dark-gray: #7b808c;
  --gray: #9ea2ad;
  --light-gray: #c9ccd4;
  --extra-light-gray: #e4e6ed;
  --sidebar-icon: #c9ccd4;
  --cloudy: #edf0f5;
  --alice-blue: #f7f9fc;
  --azureish-white: #dfe3f0;
  --white: #fff;
  --text-secondary: #4e5c85;
  --text-green: #008060;
  --light-green: #5ec47a;
  --green: #00b277;
  --green-opacity: rgba(0, 178, 119, 0.1);
  --light-green: #ebf5f5;
  --text-yellow: #e6aa00;
  --yellow: #ffc832;
  --yellow-opacity: rgba(255, 200, 50, 0.1);
  --light-yellow: #fff5e6;
  --orange-error: #ff5d17;
  --text-red: #e51300;
  --red: #ff4433;
  --red-opacity: rgba(240, 55, 35, 0.1);
  --light-red: #fff5f5;
  --small-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15),
    0px 1px 4px rgba(0, 0, 0, 0.1);
  --main-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08),
    0px 0px 2px rgba(0, 0, 0, 0.15);
  --hover-shadow: 0px 6px 24px rgba(0, 0, 0, 0.08),
    0px 0px 2px rgba(0, 0, 0, 0.1);
  --primary-background: var(--white);
  --secondary-background: var(--alice-blue);
  --radius-panel: 12px;
  --radius-panel-large: 25px;
  --radius-button: 12px;
}
body.theme-dark {
  --primary-orange: #ff9326;
  --primary-orange-opacity: rgba(255, 147, 38, 0.1);
  --dark-orange: #f28d00;
  --primary-blue: #3b6dff;
  --primary-blue-opacity: rgba(58, 101, 230, 0.1);
  --dark-blue: #1949d7;
  --orange-gradient-color-start: #ffa526;
  --orange-gradient-color-end: #ff860d;
  --orange-gradient: linear-gradient(0deg, var(--orange-gradient-color-end) 0%, var(--orange-gradient-color-start) 100%);
  --blue-gradient-color-start: #4d72df;
  --blue-gradient-color-end: #274cbd;
  --blue-gradient: linear-gradient(0deg, var(--blue-gradient-color-end) 0%, var(--blue-gradient-color-start) 100%);
  --black: #d9d9d9;
  --almost-black: #d9d9d9;
  --text-black: #dbdbdb;
  --slate-gray: #595e6b;
  --dark-gray: #7b808c;
  --gray: #9ea2ad;
  --light-gray: #192230;
  --extra-light-gray: #1a2332;
  --sidebar-icon: #c9ccd4;
  --cloudy: #25354a;
  --alice-blue: #f7f9fc;
  --white: #fff;
  --text-secondary: #4e5c85;
  --text-green: #008060;
  --green: #00b277;
  --green-opacity: rgba(0, 178, 119, 0.1);
  --light-green: #ebf5f5;
  --text-yellow: #e6aa00;
  --yellow: #ffc832;
  --yellow-opacity: rgba(255, 200, 50, 0.1);
  --light-yellow: #fff5e6;
  --text-red: #e51300;
  --red: #ff4433;
  --red-opacity: rgba(240, 55, 35, 0.1);
  --light-red: #fff5f5;
  --small-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15),
    0px 1px 4px rgba(0, 0, 0, 0.1);
  --main-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08),
    0px 0px 2px rgba(0, 0, 0, 0.15);
  --hover-shadow: 0px 6px 24px rgba(0, 0, 0, 0.08),
    0px 0px 2px rgba(0, 0, 0, 0.1);
  --primary-background: #233044;
  --secondary-background: #1b2635;
}
body {
  --gap-size: 20px;
}
@media (max-width: 768px) {
  body {
    --gap-size: 16px;
  }
}
@keyframes showElement {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.FiatRefillModal {
  display: flex;
  min-height: 700px;
}
.FiatRefillModal .LoadingStatus__failed__message {
  max-width: 320px;
}
.FiatRefillModal .FiatRefillModal__sideBar {
  width: 304px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  background: linear-gradient(169.99deg, var(--blue-gradient-color-start) 0%, var(--blue-gradient-color-end) 700px);
  color: var(--white);
  padding: 40px;
  box-sizing: border-box;
}
.FiatRefillModal .FiatRefillModal__sideBar .Input__wrapper {
  margin-top: 4px;
}
.FiatRefillModal .FiatRefillModal__sideBar .Input__wrapper input.Input {
  background: none;
  color: white;
}
.FiatRefillModal .FiatRefillModal__sideBar .Input__wrapper input.Input::placeholder {
  color: white;
  opacity: 0.5;
}
.FiatRefillModal .FiatRefillModal__sideBar .Input__wrapper .Input__indicator {
  color: white;
  opacity: 0.3;
  pointer-events: none;
}
.FiatRefillModal .FiatRefillModal__sideBar .FiatRefillModal__sideBar__content {
  box-sizing: border-box;
  width: 100%;
  margin-top: 96px;
}
.FiatRefillModal .FiatRefillModal__sideBar .FiatRefillModal__sideBar__content .FiatRefillModal__sideBar__amount {
  margin-bottom: 24px;
}
.FiatRefillModal .FiatRefillModal__sideBar .FiatRefillModal__sideBar__content .FiatRefillModal__sideBar__amount small {
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  display: block;
}
.FiatRefillModal .FiatRefillModal__sideBar .FiatRefillModal__sideBar__content .FiatRefillModal__sideBar__amount strong {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}
.FiatRefillModal .FiatRefillModal__sideBar .FiatRefillModal__sideBar__content .FiatRefillModal__sideBar__fee small {
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  display: block;
}
.FiatRefillModal .FiatRefillModal__sideBar .FiatRefillModal__sideBar__content .FiatRefillModal__sideBar__fee strong {
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
}
.FiatRefillModal .FiatRefillModal__sideBar .FiatRefillModal__sideBar__content hr {
  border: 0;
  border-bottom: 1px solid var(--white);
  margin: 24px 0;
}
.FiatRefillModal .FiatRefillModal__body {
  flex: 1;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  padding: 40px;
  width: 496px;
  display: flex;
  flex-direction: column;
}
.FiatRefillModal .FiatRefillModal__body .FiatRefillModal__body__content {
  flex: 1;
}
.FiatRefillModal .FiatRefillModal__body .FiatRefillModal__body__content > p {
  margin: 32px 0;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
}
.FiatRefillModal .FiatRefillModal__body .FiatRefillModal__body__content .FiatRefillModal__accountName {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}
.FiatRefillModal .FiatRefillModal__body .FiatRefillModal__body__content .FiatRefillModal__accountName span {
  cursor: pointer;
  color: var(--dark-blue);
}
.FiatRefillModal .FiatRefillModal__body .FiatRefillModal__body__footer {
  margin-top: auto;
}
.FiatRefillModal .FiatRefillModal__body .Form {
  width: 320px;
}
.FiatRefillModal .FiatRefillModal__header {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}
.FiatRefillModal .FiatRefillModal__dataList {
  display: block;
  list-style: none;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  padding: 0;
}
.FiatRefillModal .FiatRefillModal__dataList li {
  margin: 32px 0;
}
.FiatRefillModal .FiatRefillModal__dataList li .value {
  color: var(--dark-blue);
}
.FiatRefillModal .FiatRefillModal__accountInfo {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  list-style: none;
  padding-left: 0;
  margin-bottom: -16px;
}
.FiatRefillModal .FiatRefillModal__accountInfo span {
  color: var(--dark-blue);
  font-weight: 600;
}
.FiatRefillModal .BankList {
  margin: 24px -40px;
}
.FiatRefillModal .MethodsList {
  margin: 24px -40px;
}
.FiatRefillModal__infoBlock {
  display: flex;
  margin: 16px -8px 16px;
}
@media (max-width: 768px) {
  .FiatRefillModal__infoBlock {
    flex-direction: column;
  }
  .FiatRefillModal__infoBlock .FiatRefillModal__infoBlock__item {
    margin-bottom: 16px;
  }
  .FiatRefillModal__infoBlock .FiatRefillModal__infoBlock__item:last-child {
    margin-bottom: 0;
  }
}
.FiatRefillModal__infoBlock__item {
  border-radius: 8px;
  flex: 1;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: var(--alice-blue);
  padding: 16px;
  border: 1px solid var(--cloudy);
  margin: 0 8px;
}
.FiatRefillModal__infoBlock__item > span {
  display: block;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  margin-bottom: 8px;
}
.FiatRefillModal__infoBlock__item strong {
  display: block;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: var(--primary-blue);
}
.FiatRefillModal__infoBlock__item.primary strong {
  margin-bottom: 16px;
}
.FiatRefillModal__infoBlock__item.primary strong:last-child {
  margin-bottom: 0;
}
.FiatRefillModal__infoBlock__item .Clipboard .isvg {
  align-self: center;
}
@media (min-width: 768px) {
  .FiatRefillModal__infoBlock__item.primary {
    padding: 32px 40px;
  }
  .FiatRefillModal__infoBlock__item.primary > span {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin-top: 16px;
  }
  .FiatRefillModal__infoBlock__item.primary strong {
    margin: 0;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
  }
}
.adaptive .FiatRefillModal {
  flex-direction: column;
}
.adaptive .FiatRefillModal .FiatRefillModal__header {
  text-align: center;
  margin-bottom: 24px;
}
.adaptive .FiatRefillModal .FiatRefillModal__sideBar {
  text-align: center;
  flex-direction: column;
  padding: 40px 16px !important;
  background-image: var(--blue-gradient);
  display: flex;
  align-items: center;
  border-radius: 0;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.adaptive .FiatRefillModal .FiatRefillModal__sideBar .FiatRefillModal__sideBar__content {
  margin-top: 0;
}
.adaptive .FiatRefillModal .FiatRefillModal__sideBar,
.adaptive .FiatRefillModal .FiatRefillModal__body {
  width: 100%;
  padding: 16px;
}
.adaptive .FiatRefillModal .FiatRefillModal__body form {
  width: 100%;
}
.adaptive .FiatRefillModal .BankList {
  margin: 24px -16px;
}
.adaptive .ButtonWrapper {
  flex-direction: column;
}
.adaptive .ButtonWrapper .Button {
  margin: 8px 0;
  flex: auto;
}
