.CopyTextComponent__container {
  position: relative;
  max-width: 100%;
  cursor: pointer;
}
.CopyTextComponent__container:hover span {
  opacity: 0.95;
}
.CopyTextComponent__container span {
  max-width: 100%;
}
.CopyTextComponent__copied {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 110%);
  padding: 3px 8px;
  background: var(--orange-gradient);
  border-radius: 15px;
  color: #fff;
  z-index: 100;
}
