.Team {
  padding: 48.8px 82.5px 84px;
  display: flex;
  justify-content: center;
}
.Team__container {
  max-width: 976px;
}
.Team h1 {
  margin-bottom: 18px;
  font-weight: 600;
  font-size: 32px;
  line-height: 110%;
  letter-spacing: -0.01em;
  color: var(--black-gunmetal);
}
.Team__description {
  max-width: 938px;
  font-weight: 600;
  font-size: 20px;
  line-height: 110%;
  letter-spacing: -0.01em;
  color: var(--cool-gray);
}
.Team__header {
  margin-bottom: 83px;
}
.Team__cards {
  margin: 0 -32px;
  width: calc(100% + 32px);
}
.Team__Card {
  flex: 0 1 calc(33% - 32px);
  margin: 0 0 32px 32px;
}
@media screen and (max-width: 1300px) {
  .Team__Card {
    flex: 0 1 calc(50% - 32px);
  }
}
