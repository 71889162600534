.AdaptiveSidebar {
  visibility: hidden;
  position: absolute;
  height: calc(100vh - var(--header-height) - 17px);
  width: 100vw;
  top: calc(var(--header-height) + 17px);
  left: 0;
  margin: 0;
  box-sizing: border-box;
  transition: visibility 0.5s;
  z-index: 2;
}
.AdaptiveSidebar__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: background 0.5s;
}
.AdaptiveSidebar.active {
  visibility: visible;
}
.AdaptiveSidebar.active .AdaptiveSidebar__bg {
  background: rgba(40, 40, 40, 0.24);
}
