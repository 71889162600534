.Bot__indicators h2 {
  margin-bottom: 24px;
}
.Bot__indicators .ContentBox__content {
  padding: 40px;
}
.Bot__indicators .Bot__indicators__row {
  margin-bottom: 24px;
}
.Bot__indicators .Bot__indicators__row .Bot__indicators__row__label {
  color: var(--text-black);
  margin-bottom: 8px;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
}
