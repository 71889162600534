@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
.PrivateKeyModal-loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 40px 40px;
}
.PrivateKeyModal-loading .isvg svg {
  margin-bottom: 16px;
  animation: spin 1s linear infinite;
}
.PrivateKeyModal-loading .isvg svg path {
  fill: #fabe4c;
}
.PrivateKeyModal-form {
  margin-top: 40px;
}
.PrivateKeyModal-form h3 {
  font-size: 13px;
}
.PrivateKeyModal-form .Input__wrapper {
  margin-bottom: 20px;
}
.PrivateKeyModal-form .Input__wrapper .Input {
  min-width: 640px;
}
.adaptive .PrivateKeyModal-form .Input__wrapper .Input {
  min-width: initial;
  width: 100%;
}
.PrivateKeyModal-form .Input__wrapper .Input__description {
  margin: 16px 0 0;
}
.PrivateKeyModal-form .Input__wrapper .Input__display_password_button {
  margin: 13px auto;
}
.PrivateKeyModal-form .Button {
  margin: 8px auto 20px;
}
.PrivateKeyModal-done {
  margin-top: 40px;
}
.PrivateKeyModal-done h3 {
  font-size: 13px;
}
.PrivateKeyModal-done p {
  margin: 20px 0;
  font-weight: 600;
  max-width: 600px;
}
.PrivateKeyModal-done .Input__wrapper {
  margin-bottom: 20px;
}
.PrivateKeyModal-done .Input__wrapper .Input {
  min-width: 640px;
}
.PrivateKeyModal-done .Input__wrapper .Input__description {
  margin: 16px 0 0;
}
.PrivateKeyModal-done .Button {
  margin: 8px auto 20px;
}
