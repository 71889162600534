.MainLandingWrapperFooter {
  margin-top: 120px;
  padding-left: 31px;
  box-sizing: border-box;
  width: 100%;
}
.MainLandingWrapperFooter__main {
  padding: 33px 0 36px 5px;
  border-top: 1px solid #e5e9f4;
  border-bottom: 1px solid #e5e9f4;
  gap: 47px;
}
.MainLandingWrapperFooter-nav {
  display: flex;
  line-height: 160%;
  font-size: 16px;
  color: var(--gunmetal);
  width: 61%;
  flex-wrap: wrap;
  row-gap: 47px;
}
.MainLandingWrapperFooter-nav__col:nth-child(1) {
  margin-right: 9%;
}
.MainLandingWrapperFooter-nav__items {
  gap: 9px 60px;
}
.MainLandingWrapperFooter-nav__items:not(.Col) {
  display: grid;
  grid-template-columns: auto auto;
}
.MainLandingWrapperFooter h6 {
  margin-bottom: 24px;
  font-weight: 600;
  font-size: inherit;
}
.MainLandingWrapperFooter a {
  font-weight: 500;
  color: inherit;
  opacity: 0.4;
  word-break: keep-all !important;
}
.MainLandingWrapperFooter__logo {
  width: 105px;
  height: 33px;
}
.MainLandingWrapperFooter__footer {
  padding: 36px 145px 34px 5px;
  box-sizing: border-box;
}
.MainLandingWrapperFooter .copyright {
  margin-left: 43px;
  font-weight: 500;
  font-size: 16px;
  line-height: 160%;
  color: var(--gunmetal);
  opacity: 0.4;
}
.MainLandingWrapperFooter-social-links {
  margin-left: auto;
}
.MainLandingWrapperFooter-social-links a {
  opacity: 1;
}
.adaptive .MainLandingWrapperFooter {
  margin-top: 112px;
  padding-left: 0;
}
.adaptive .MainLandingWrapperFooter__main {
  padding: 0 0 28px;
  border-top: none;
}
.adaptive .MainLandingWrapperFooter-nav {
  width: 100%;
  column-gap: 47px;
}
.adaptive .MainLandingWrapperFooter-nav__col {
  flex: 1 1 auto;
  margin: 0 !important;
}
.adaptive .MainLandingWrapperFooter-footer {
  padding: 28px 0 60px;
  gap: 24px 43px;
}
.adaptive .MainLandingWrapperFooter .copyright {
  margin: 0;
}
.adaptive .MainLandingWrapperFooter-social-links {
  margin: 0 20px;
}
