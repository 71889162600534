.RateIndicator {
  display: inline-flex;
  height: 13px;
  margin: 0 7px;
  padding: 3px;
  background: #5ec47a;
  font-weight: 600;
  font-size: 9px;
  line-height: 110%;
  letter-spacing: -0.01em;
  color: #fff;
  border-radius: 3px;
  box-sizing: border-box;
}
.RateIndicator-container {
  display: inline-flex;
  align-items: center;
  opacity: 0.57;
}
.down .RateIndicator-container {
  opacity: 1;
}
.RateIndicator .isvg {
  display: inline-flex;
  align-items: center;
  height: 8px;
  width: 8px;
  margin-right: 2px;
}
.RateIndicator.down {
  background: #ff5d17;
}
.RateIndicator.down .isvg {
  transform: rotate(180deg);
}
.RateIndicator.other {
  background: #fa9751;
}
.RateIndicator.noActive {
  background: var(--cool-gray);
}
.RateIndicator.other .isvg,
.RateIndicator.noActive .isvg {
  width: 5px;
  height: 5px;
  margin-right: 5px;
  border-radius: 50%;
  background: #dfe3f0;
}
.RateIndicator svg,
.RateIndicator svg path {
  fill: #fff !important;
}
