.Notifications {
  box-shadow: var(--hover-shadow);
  z-index: 1;
}
.Notifications > .ContentBox__content {
  overflow: hidden;
  padding: 0;
}
.Notifications .HistoryTable {
  border: 0;
}
.Notifications .HistoryTable .ContentBox__content {
  padding: 0;
}
.Notifications .HistoryTable {
  --horisontal-padding: 40px;
  --vertical-padding: 16px;
}
.Notifications .HistoryTable .HistoryItem {
  border-radius: 0;
}
.Notifications__scroll {
  overflow-y: scroll;
  max-height: 400px;
  min-height: 250px;
}
.Notifications__buttonWrapper {
  display: flex;
  padding: 16px;
}
.Notifications__buttonWrapper .Button {
  margin: auto;
  margin-bottom: 8px;
}
