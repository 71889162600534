.p2p .orders-blog-card {
  max-width: 100%;
  min-width: 300px;
  border-radius: 18px;
  overflow: hidden;
  background: var(--alice-blue);
}
.p2p .orders-blog-card img {
  width: 100%;
}
.p2p .orders-blog-card h5 {
  margin-bottom: 10px;
}
.p2p .orders-blog-card p {
  line-height: 20px;
}
.p2p .orders-blog-card__content {
  display: flex;
  flex-direction: column;
  padding: 17px 20px 27px;
}
.p2p .orders-blog-card__date {
  margin-top: 15px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: var(--black-gunmetal);
}
