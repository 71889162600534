.p2p-orders .orders-list-filters--adaptive {
  margin-bottom: 30px;
}
.p2p-orders .orders-list-filters--adaptive-content {
  margin-top: 9px;
  padding: 15px 8px;
  background: var(--alice-blue);
  border-radius: 10px;
  gap: 20px;
  box-sizing: border-box;
}
.p2p-orders .orders-list-filters--adaptive-content .CustomButton {
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: var(--dark-black);
}
.p2p-orders .orders-list-filters--adaptive-content .CustomButton:nth-child(2) {
  margin-left: auto;
}
.p2p-orders .orders-list-filters--adaptive-content .CustomButton:active {
  color: var(--royal-blue);
}
.p2p-orders .orders-list-filters--adaptive-content .CustomButton:active g {
  opacity: 1;
}
.p2p-orders .orders-list-filters--adaptive-content .CustomButton:active path {
  fill: var(--royal-blue);
}
.p2p-orders .orders-list-filters--adaptive-content .WalletIcon {
  margin-right: 6px;
}
.p2p-orders .orders-list-filters--adaptive-content .dark-dropdown-icon {
  width: 7px;
  height: 4px;
}
.p2p-orders .orders-list-filters--adaptive-content .dark-dropdown-icon path {
  fill: var(--dark-black);
}
.p2p-orders .orders-list-filters--adaptive .Modal-AdaptiveTop .DappInput {
  height: 44px;
  border: 1px solid var(--azureish-white);
  background: none;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
}
.p2p-orders .orders-list-filters--adaptive .Modal-AdaptiveTop .DappInput__indicator {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: var(--cool-gray);
}
