.FarmingTableAdaptive {
  width: 100%;
}
.FarmingTableAdaptive .row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}
.FarmingTableAdaptive .col {
  display: flex;
  flex-direction: column;
}
.FarmingTableAdaptive .ScrollbarTrackY {
  visibility: hidden;
}
.FarmingTableAdaptive__cell {
  display: flex;
  flex-direction: column;
  color: var(--royal-blue);
  letter-spacing: -0.01em;
}
.FarmingTableAdaptive__cell > span:first-child {
  margin-bottom: 4px;
}
.FarmingTableAdaptive__cell > span:last-child {
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 100%;
}
.FarmingTableAdaptive__cell > span:last-child .HoverPopup {
  margin-left: 4px;
  font-weight: 500;
  color: var(--cool-gray);
}
.FarmingTableAdaptive__cell > span:last-child .HoverPopup__children {
  opacity: 0.5;
}
.FarmingTableAdaptive__icon-question path {
  fill: #5078e6;
}
.FarmingTableAdaptive__icon-question circle {
  stroke: #5078e6;
}
.FarmingTableAdaptive .isvg {
  display: inline-flex;
  align-items: center;
}
.FarmingTableAdaptive .little-title {
  font-size: 10px;
  font-weight: 500;
  line-height: 100%;
  color: var(--royal-blue);
  opacity: 0.5;
}
