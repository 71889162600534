.Method__result h2 {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 16px;
}
.Method__result .Method__result__content {
  display: block;
  font-size: 13px;
  line-height: 24px;
}
