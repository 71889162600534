.SidebarItem {
  list-style-type: none;
}
.SidebarItem:hover .SidebarItem__content {
  color: var(--royal-blue);
}
.SidebarItem__content {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  color: var(--dark-black);
  cursor: pointer;
  user-select: none;
  transition: color 0.1s;
}
.SidebarItem.active .SidebarItem__content {
  color: var(--royal-blue);
}
.SidebarItem__children {
  margin-left: 11px;
  margin-top: 7.42px;
  padding-top: 7.08px;
  padding-left: 25px;
  border-left: 1px solid #e9eaed;
  position: relative;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: -0.023em;
  color: #9095a5;
}
.SidebarItem__children::after,
.SidebarItem__children::before {
  content: '';
  display: inline;
  position: absolute;
  left: -1px;
  width: 3px;
  height: 3px;
  background: #e9eaed;
  transform: translateX(-50%);
  border-radius: 50%;
}
.SidebarItem__children::after {
  bottom: 0;
}
.SidebarItem__children::before {
  top: 0;
}
.SidebarItem__children ul {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
}
.SidebarItem__children li {
  cursor: pointer;
  transition: color 0.1s;
}
.SidebarItem__children li.active {
  color: var(--dark-black);
}
.SidebarItem__children li:hover {
  color: var(--dark-black);
}
.SidebarItem__children li:not(:last-child) {
  margin-bottom: 4px;
}
.SidebarItem:not(:last-child) {
  margin-bottom: 32px;
}
.SidebarItem__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 13px;
  height: 23px;
  width: 23px;
}
.SidebarItem__arrow {
  display: flex;
  align-items: center;
  margin-left: auto;
}
.SidebarItem__arrow .isvg {
  transition: transform 0.3s;
}
.haveChild .SidebarItem__arrow.active .isvg {
  transform: rotate(90deg);
}
.SidebarItem .isvg {
  display: flex;
  align-items: center;
  justify-content: center;
}
.SidebarItem a {
  color: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
}
.SidebarItem a:hover {
  text-decoration: none;
}
