.DocumentationHeader {
  margin: 0;
  border-radius: 0;
  background: var(--white);
}
.DocumentationHeader > .ContentBox__content {
  display: flex;
  align-items: center;
  background: none;
  max-width: 1340px;
  margin: 0 auto;
  padding: 12px 0;
  border-radius: 0;
}
.DocumentationHeader .DocumentationHeader__title {
  line-height: 24px;
  font-weight: 500;
  font-size: 16px;
  border-left: 1px solid;
  color: var(--dark-blue);
  margin-left: 24px;
  padding-left: 23px;
}
.DocumentationHeader .DocumentationHeader__controls {
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: 20px;
}
.DocumentationHeader .DocumentationHeader__dropDownMenuIcon {
  cursor: pointer;
  color: var(--text-black);
}
.DocumentationHeader .ActionsSheet__list {
  overflow: visible;
  margin-top: 32px;
  margin-right: -8px;
}
.DocumentationHeader .ActionsSheet__list .isvg {
  font-size: 0;
  display: block;
  width: 18px;
  height: 13.5px;
  border: 1px solid;
}
