.ExchangerSwap {
  padding: 36px 40px 26px;
  background: #fff;
  box-shadow: 0 34px 70px rgba(199, 201, 207, 0.25);
  border: none;
  border-radius: var(--radius-panel-large);
  color: var(--text-black);
  margin-bottom: 20px;
  position: static;
}
.ExchangerSwap__row {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.ExchangerSwap__dropdown {
  display: flex;
  align-items: center;
  border: 1px solid var(--azureish-white);
  border-radius: 20px;
  padding: 16px 8px;
  position: relative;
  background: var(--secondary-background);
  cursor: pointer;
}
.ExchangerSwap__dropdown-rate {
  position: absolute;
  top: 100%;
  margin-top: 6px;
  left: 12px;
  font-size: 11px;
  color: var(--gray);
  font-weight: 500;
}
.ExchangerSwap__track {
  position: absolute;
  top: 100%;
  right: 0;
  margin-top: -4px;
  text-decoration: none;
  font-size: 12px;
  color: var(--primary-blue);
}
.ExchangerSwap__track:hover {
  text-decoration: underline;
  cursor: pointer;
}
.ExchangerSwap__icon {
  cursor: pointer;
  border-radius: 50%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
  width: 41px;
  height: 41px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ExchangerSwap__icon .isvg,
.ExchangerSwap__icon svg,
.ExchangerSwap__icon img {
  width: 100%;
}
.ExchangerSwap__select {
  margin-left: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 110%;
  cursor: pointer;
}
.ExchangerSwap__select > span {
  color: #cdcfd7;
  margin-bottom: 4px;
}
.ExchangerSwap__currency {
  display: flex;
  font-weight: 600;
  font-size: 18px;
  line-height: 110%;
  letter-spacing: -0.01em;
}
.ExchangerSwap__currency .isvg {
  position: absolute;
  right: 12px;
  top: 50%;
  margin-top: -5px;
}
.ExchangerSwap__balance {
  text-align: right;
  margin-top: -6px;
  position: relative;
}
.ExchangerSwap__balance-number {
  font-size: 40px;
  font-weight: 600;
  line-height: 48px;
}
.ExchangerSwap__balance-price {
  opacity: 0.5;
  position: absolute;
  bottom: 100%;
  right: 6px;
  font-size: 12px;
  margin-bottom: -6px;
}
.ExchangerSwap__balance-price:before {
  content: "$";
}
.ExchangerSwap__actions {
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ExchangerSwap__actions-buy {
  margin-top: 24px;
  display: flex;
  justify-content: center;
}
.ExchangerSwap__actions-buy time {
  margin-top: 10px;
  font-size: 12px;
  opacity: 0.65;
  text-align: right;
}
.ExchangerSwap__reservation {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ExchangerSwap__reservation-status {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ExchangerSwap__reservation-status time {
  opacity: 0.65;
  font-size: 12px;
}
.ExchangerSwap__swap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
  padding-top: 40px;
  border-top: 1px solid var(--light-gray);
}
.ExchangerSwap__fiat-amount {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: flex-start;
}
.ExchangerSwap__fiat-amount .Input__wrapper .Input__indicator,
.ExchangerSwap__fiat-amount .DappInput__wrapper .Input__indicator,
.ExchangerSwap__fiat-amount .Input__wrapper .DappInput__indicator,
.ExchangerSwap__fiat-amount .DappInput__wrapper .DappInput__indicator {
  opacity: 0.5;
  pointer-events: none;
}
.ExchangerSwap__fiat-amount > span {
  margin-left: 12px;
  margin-top: 6px;
  font-size: 11px;
  font-weight: 500;
  color: var(--gray);
  transition: color 0.3s;
}
.ExchangerSwap__fiat-amount > span.ExchangerSwap__link {
  color: var(--royal-blue);
  cursor: pointer;
  font-weight: 600;
}
.ExchangerSwap__fiat-amount > span.ExchangerSwap__link:hover {
  text-decoration: underline;
}
.ExchangerSwap__coin {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ExchangerSwap__coin-amount {
  font-size: 24px;
  font-weight: 600;
}
.ExchangerSwap__coin-amount span {
  opacity: 0.4;
}
.ExchangerSwap__coin-price {
  font-size: 12px;
}
.ExchangerSwap__coin-track {
  color: var(--primary-blue);
  font-size: 12px;
  cursor: pointer;
}
.ExchangerSwap__coin-track:hover {
  text-decoration: underline;
}
.ExchangerSwap__limits {
  margin-top: 40px;
  font-size: 12px;
  line-height: 1.6em;
}
.ExchangerSwap__limits-row {
  display: flex;
}
.ExchangerSwap__limits-row span:first-child {
  font-weight: 600;
  margin-right: 6px;
}
.ExchangerSwap.adaptive {
  margin-bottom: 10px;
  padding: 43px 18px 32px;
}
.ExchangerSwap.adaptive .ContentBox__content {
  padding: 0;
}
.ExchangerSwap.adaptive .ExchangerSwap__dropdown {
  position: absolute;
  z-index: 1;
  background: none;
  border: none;
  padding: 13px 8px;
}
.ExchangerSwap.adaptive .ExchangerSwap__dropdown-rate {
  display: none;
}
.ExchangerSwap.adaptive .ExchangerSwap__currency span.isvg {
  right: -12px;
}
.ExchangerSwap.adaptive .ExchangerSwap__fiat-amount .Input__wrapper input.Input,
.ExchangerSwap.adaptive .ExchangerSwap__fiat-amount .DappInput__wrapper input.Input,
.ExchangerSwap.adaptive .ExchangerSwap__fiat-amount .Input__wrapper input.DappInput,
.ExchangerSwap.adaptive .ExchangerSwap__fiat-amount .DappInput__wrapper input.DappInput {
  padding: 26px 19px 26px 9px;
  --input-height: 30px;
}
.ExchangerSwap.adaptive .ExchangerSwap__icon {
  width: 26px;
  height: 26px;
}
.ExchangerSwap.adaptive .ExchangerSwap__rate {
  font-size: 10px;
  color: var(--gray);
  position: absolute;
  right: 12px;
  top: 100%;
  margin-top: -13px;
}
.ExchangerSwap .error-orange {
  color: var(--orange-error) !important;
}
.SwapForm {
  margin-bottom: var(--gap-size);
  border-radius: var(--radius-panel-large);
  border: none;
}
.SwapForm .ContentBox__content {
  width: 100%;
  padding: 33px 43px 32px;
  box-sizing: border-box;
}
.SwapForm__formWrapper {
  display: flex;
  margin-bottom: 8px;
}
.SwapForm__form {
  flex: 1;
}
.SwapForm__form__control:not(:last-child) {
  margin-bottom: 65px;
}
.SwapForm__form__label {
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  margin-bottom: 16px;
}
.SwapForm__form__label:empty {
  display: none;
}
.SwapForm__form__control {
  margin-bottom: 26px;
}
.SwapForm__form__control:last-child {
  margin-bottom: 0;
}
.SwapForm__form__control .SwapForm__input {
  text-align: right;
}
.SwapForm__form__control .Dropdown {
  border-radius: 20px;
  border: 1px solid var(--azureish-white);
}
.SwapForm__form__control .Dropdown__header {
  padding: 16px 8px;
}
.SwapForm__form__control .Dropdown__options {
  border-radius: inherit;
  border: 1px solid var(--azureish-white);
}
.SwapForm__form__control .Dropdown .Dropdown__option__title {
  font-weight: 600;
  font-size: 18px;
  line-height: 16px;
  color: #1c212e;
}
.SwapForm__form__control .Input,
.SwapForm__form__control .DappInput {
  --input-height: 72px;
  border-radius: 20px;
  border: 1px solid var(--azureish-white);
}
.SwapForm__form__control__meta {
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  color: var(--gray);
  margin: 7px 14px 0;
}
.SwapForm__form__control__meta.right {
  text-align: right;
}
.SwapForm__form__control__meta.active {
  cursor: pointer;
}
.SwapForm__form__control__meta.active:hover {
  color: var(--primary-blue);
}
.SwapForm__separator {
  position: relative;
  width: 104px;
  margin: 32px 0 40px;
  display: flex;
}
.SwapForm__separator:before {
  position: absolute;
  content: '';
  display: block;
  width: 1px;
  height: 100%;
  left: 0;
  right: 0;
  margin: auto;
  background: var(--black);
  transform-origin: left center;
  opacity: 0.2;
  z-index: -1;
}
@media (min-resolution: 2dppx) {
  .SwapForm__separator:before {
    transform: scaleX(0.5);
  }
}
@media (min-resolution: 3dppx) {
  .SwapForm__separator:before {
    transform: scaleX(0.32);
  }
}
.ExchangerSwap__switchButton {
  position: relative;
  margin: auto;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: var(--primary-blue-gradient);
  color: var(--white);
  display: flex;
  border: 8px solid var(--primary-background);
  cursor: pointer;
}
.ExchangerSwap__switchButton .isvg {
  transition: 0.3s ease;
  margin: auto;
  display: block;
}
.ExchangerSwap__switchButton .isvg svg {
  display: block;
}
.ExchangerSwap__switchButton:hover {
  background: var(--dark-blue);
}
.ExchangerSwap__switchButton::after {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  content: '';
  height: 24px;
  width: 24px;
  display: block;
  filter: invert(1);
  background: url('../../../../../../asset/24px/loading.svg');
  animation: Button__loader_anim 1s infinite linear;
  z-index: 1;
  opacity: 0;
  transform: scale(0.3);
  transition: 0.3s ease;
}
.ExchangerSwap__switchButton.loading {
  pointer-events: none;
}
.ExchangerSwap__switchButton.loading .isvg {
  opacity: 0;
  transform: scale(0.3);
}
.ExchangerSwap__switchButton.loading::after {
  opacity: 1;
  transform: scale(1);
}
.SwapForm__submitWrapper {
  display: flex;
}
.SwapForm__submitWrapper .Button {
  margin: 0 auto;
  padding: 0 24px;
}
@media screen and (max-width: 768px) {
  .ExchangerSwap__switchButton {
    background: #e5ebfc;
    width: 40px;
    height: 40px;
  }
  .ExchangerSwap__switchButton svg {
    width: 24px;
    height: 24px;
    transform: rotate(90deg);
  }
  .ExchangerSwap__switchButton:hover {
    background: #e5ebfc;
  }
  .SwapForm {
    box-shadow: 0 34px 70px rgba(199, 201, 207, 0.25);
  }
  .SwapForm .ContentBox__content {
    width: 100%;
    padding: 43px 18px 32px;
    box-sizing: border-box;
  }
  .SwapForm__formWrapper {
    flex-direction: column;
    align-items: center;
    margin-bottom: 33px;
  }
  .SwapForm__form {
    width: 100%;
  }
  .SwapForm__form__control {
    margin-bottom: 0;
  }
  .SwapForm__form__control:not(:last-child) {
    margin-bottom: 0;
  }
  .SwapForm__form__control__meta {
    margin-top: 8px;
    font-size: 12px;
  }
  .SwapForm__separator {
    margin: 23px 0;
  }
  .SwapForm__switchButton {
    background: #e5ebfc;
    width: 40px;
    height: 40px;
  }
  .SwapForm__switchButton svg {
    width: 24px;
    height: 24px;
    transform: rotate(90deg);
  }
  .SwapForm__submitWrapper .Button {
    width: 100%;
    height: 65px;
  }
}
