.Skeleton {
  background: var(--cloudy);
  min-height: 1em;
  min-width: 5em;
  animation: skeleton 1s linear infinite;
}
@keyframes skeleton {
  0%,
  60%,
  100% {
    opacity: 1;
  }
  30% {
    opacity: 0.5;
  }
}
