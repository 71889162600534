.DepositModal__WithdrawalTransfer {
  text-align: center;
}
.DepositModal__WithdrawalTransfer .DepositModal__container {
  padding: 23px 28px 32px;
}
.DepositModal__WithdrawalTransfer > .Modal__box {
  max-width: 362px;
  width: 100%;
}
.DepositModal__WithdrawalTransfer-content {
  width: 100%;
}
.DepositModal__WithdrawalTransfer__title {
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  letter-spacing: -0.01em;
  color: var(--dark-black);
}
.DepositModal__WithdrawalTransfer__subtitle {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  letter-spacing: -0.01em;
  color: var(--cool-gray);
}
.DepositModal__WithdrawalTransfer__icon {
  margin: 32px 0;
}
.DepositModal__WithdrawalTransfer .Button {
  width: 100%;
  margin-top: 18px;
}
.DepositModal__WithdrawalTransfer .Button__label {
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 16px !important;
}
.DepositModal__WithdrawalTransfer .Button.middle {
  border-radius: 10px;
}
.DepositModal__WithdrawalTransfer .Button.extra_large {
  height: 50px;
}
.DepositModal__WithdrawalTransfer .Button.extra_large .Button__label {
  font-weight: 600 !important;
}
.DepositModal__WithdrawalTransfer .Button:nth-of-type(2) {
  margin-top: 10px;
}
.adaptive .DepositModal__WithdrawalTransfer > .Modal__box {
  max-width: 100%;
}
.adaptive .DepositModal__WithdrawalTransfer > .Modal__box > .CabinetModal__container {
  max-width: 329px;
}
.adaptive .DepositModal__WithdrawalTransfer .DepositModal__container {
  padding: 20px;
}
.adaptive .DepositModal__WithdrawalTransfer__title {
  font-size: 16px;
  line-height: 22px;
}
.adaptive .DepositModal__WithdrawalTransfer__icon {
  margin: 24px 0;
}
.adaptive .DepositModal__WithdrawalTransfer .Button {
  border-radius: 15px !important;
}
.adaptive .DepositModal__WithdrawalTransfer .Button.extra_large .Button__label {
  font-size: 14px !important;
}
.adaptive .DepositModal__WithdrawalTransfer .Button:first-of-type {
  margin-top: 24px;
}
.adaptive .DepositModal__WithdrawalTransfer .Button:nth-of-type(2) {
  margin-top: 10px;
}
.adaptive .DepositModal__WithdrawalTransfer .Button:last-of-type {
  margin-top: 18px;
}
