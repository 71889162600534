.Menu {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var(--almost-black);
}
.Menu .Menu__section {
  margin: 16px 0;
}
.Menu .Menu__section .ContentBox__content {
  padding: 8px 0;
}
.Menu .Menu__section__noSpacing {
  padding: 0;
}
.Menu .Menu__section__title {
  margin: 16px;
}
.Menu .Menu__section__item {
  display: flex;
  align-items: center;
  color: inherit;
  padding: 16px;
  text-decoration: none;
}
.Menu .Menu__section__item .isvg {
  line-height: 0;
  display: block;
  color: var(--text-black);
  margin-right: 16px;
}
.Menu .Menu__section__item__flag {
  width: 16px;
  height: 12px;
  border: 1px solid var(--text-black);
}
.Menu .Menu__section__item .Switch {
  margin-top: 0;
  margin-left: auto;
}
