@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
.ImportWalletModal-loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 40px 40px;
}
.ImportWalletModal-loading .isvg svg {
  margin-bottom: 16px;
  animation: spin 1s linear infinite;
}
.ImportWalletModal-loading .isvg svg path {
  fill: #fabe4c;
}
.ImportWalletModal-form {
  margin-top: 40px;
}
.ImportWalletModal-form h3 {
  font-size: 13px;
}
.ImportWalletModal-form h3 span:not(:first-child) {
  margin-left: 16px;
}
.ImportWalletModal-form h3 span.active {
  color: var(--primary-blue);
  text-decoration: underline;
  cursor: pointer;
  outline: none;
}
.ImportWalletModal-form h3 span.active:hover {
  text-decoration: none;
}
.ImportWalletModal-form .Input__wrapper {
  margin-bottom: 20px;
}
.ImportWalletModal-form .Input__wrapper .Input {
  min-width: 640px;
}
.adaptive .ImportWalletModal-form .Input__wrapper .Input {
  min-width: initial;
  width: 100%;
}
.ImportWalletModal-form .Input__wrapper .Input__description {
  margin: 16px 0 0;
}
.ImportWalletModal-form .Button {
  margin: 8px auto 20px;
}
