.Referral__ReferralList {
  box-sizing: border-box;
  background: var(--alice-blue);
  border-radius: var(--radius-panel-large);
  padding: 32px 28px 28px;
}
.Referral__ReferralList h2 {
  margin-left: 2px;
  margin-bottom: 8px;
}
.Referral__ReferralList .subtitle {
  margin-left: 2px;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: var(--cool-gray);
}
.Referral__ReferralList .Number {
  word-break: break-word;
}
.Referral__ReferralList .CabinetTable thead td {
  padding-bottom: 8px;
}
.Referral__ReferralList .CabinetTable tbody td {
  padding-top: 21px;
  padding-bottom: 20px;
}
.Referral__ReferralList__items-exists {
  margin-top: 16px;
  width: 100%;
  line-height: 110%;
}
.Referral__ReferralList__items-exists .Col {
  width: 33.3%;
  box-sizing: border-box;
}
.Referral__ReferralList__items-exists .title-row {
  margin-bottom: 22px;
  color: #292d32;
  font-size: 18px;
}
.Referral__ReferralList__items-empty {
  padding: 41px 0 63px;
  font-weight: 600;
  font-size: 24px;
  line-height: 110%;
  color: #bfcef8;
  letter-spacing: -0.01em;
  user-select: none;
}
.Referral__ReferralList__items-empty .logo {
  width: 80px;
  height: 80px;
  margin-bottom: 10px;
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  background: var(--blue-light-gradient);
  opacity: 0.3;
}
.Referral__ReferralList__items-empty .logo svg {
  height: 59px;
  width: 39px;
}
.Referral__ReferralList .show-more {
  margin-top: 22px;
  margin-left: 77px;
  display: flex;
  align-items: center;
  color: var(--royal-blue);
  font-weight: 600;
  font-size: 18px;
  line-height: 16px;
  transition: opacity 0.1s;
  cursor: pointer;
}
.Referral__ReferralList .show-more:hover {
  opacity: 0.8;
}
.Referral__ReferralList .show-more .isvg {
  display: flex;
  align-items: center;
  margin-left: 8px;
}
.Referral__ReferralList .show-more .isvg svg {
  margin-top: 3px;
  width: 9.58px;
  height: 5.75px;
}
.Referral__ReferralList .show-more .isvg svg path {
  fill: currentColor;
}
@keyframes smoothShow {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.adaptive .Referral__ReferralList {
  padding: 20px 9px 28px;
  border-radius: var(--radius-panel);
}
.adaptive .Referral__ReferralList h2 {
  margin-bottom: 10px;
}
.adaptive .Referral__ReferralList .subtitle {
  font-weight: 600;
  font-size: 11px;
  line-height: 13px;
}
.adaptive .Referral__ReferralList h2,
.adaptive .Referral__ReferralList .subtitle {
  padding: 0 7px;
}
.adaptive .Referral__ReferralList .show-more {
  margin-top: 13px;
  margin-left: 6.35px;
  font-size: 12px;
  line-height: 16px;
}
.adaptive .Referral__ReferralList .show-more .isvg {
  margin-left: 4px;
}
.adaptive .Referral__ReferralList .show-more svg {
  width: 6.25px;
  height: 3.75px;
}
.adaptive .Referral__ReferralList__items-exists {
  margin-top: 30px;
}
.adaptive .Referral__ReferralList__items-empty {
  padding: 34px 0 17px;
  font-size: 20px;
}
.adaptive .Referral__ReferralList__items-empty .logo {
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  background: var(--blue-light-gradient);
  opacity: 0.3;
}
.adaptive .Referral__ReferralList__items-empty .logo svg {
  height: 36.6px;
  width: 24.4px;
}
