.BankList .BankList__item {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 32px 40px;
  color: var(--almost-black);
}
.BankList .BankList__item::after {
  content: "";
  display: block;
  position: absolute;
  bottom: -1px;
  left: 0;
  right: 0;
  height: 1px;
  margin: 0 40px;
  background: var(--gray);
  opacity: 0.4;
}
.BankList .BankList__item:hover {
  box-shadow: var(--hover-shadow);
}
.BankList .BankList__item:hover::after {
  opacity: 0;
}
.BankList .BankList__item .BankList__item__title {
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
}
.BankList .BankList__item .BankList__item__arrow {
  color: var(--gray);
  margin-left: 32px;
  width: 24px;
}
.BankList .BankList__item .BankList__item__arrow .isvg {
  width: inherit;
}
.BankList .BankList__item .BankList__item__arrow .isvg svg {
  display: block;
}
.BankList .BankList__item .BankList__item__logo {
  margin-left: auto;
}
.adaptive .BankList .BankList__item {
  padding: 32px 16px;
}
