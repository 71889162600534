.CabinetHeaderContainer {
  position: sticky;
  z-index: 100;
  top: 0;
}
.CabinetHeader {
  background: var(--primary-background);
  border-bottom: 1px solid var(--extra-light-gray);
  width: 100%;
}
.CabinetHeader .CabinetHeader__content {
  height: 56px;
  max-width: 1400px;
  padding: 0 18px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
}
.CabinetHeader .CabinetHeader__content .logo {
  display: block;
}
.CabinetHeader .CabinetHeader__content .CabinetHeader__links {
  display: flex;
}
.CabinetHeader .CabinetHeader__content .CabinetHeader__links .isvg {
  width: 24px;
  height: 24px;
}
.CabinetHeader .CabinetHeader__content .CabinetHeader__controls {
  display: flex;
}
.CabinetHeader .CabinetHeader__content .CabinetHeader__controls .Button {
  margin-left: 8px;
}
.CabinetHeader .CabinetHeader__content .CabinetHeader__link {
  position: relative;
  color: var(--text-black);
  display: flex;
  align-items: center;
  padding: 16px 8px 16px 3px;
  justify-content: center;
  font-weight: 600;
  margin-right: 32px;
  font-size: 13px;
  line-height: 16px;
  text-decoration: none !important;
  transition: color 0.3s ease;
}
.CabinetHeader .CabinetHeader__content .CabinetHeader__link:before {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  border-radius: 0 0 2px 2px;
  background: var(--primary-blue);
  height: 0;
  width: 0;
  margin: 0 auto;
  transition: width 0.3s ease, height 0.3s ease;
}
.CabinetHeader .CabinetHeader__content .CabinetHeader__link:hover {
  color: var(--almost-black);
}
.CabinetHeader .CabinetHeader__content .CabinetHeader__link.active {
  color: var(--primary-blue);
}
.CabinetHeader .CabinetHeader__content .CabinetHeader__link.active:before {
  height: 2px;
  width: 90%;
}
.CabinetHeader .CabinetHeader__content .CabinetHeader__link .isvg {
  margin-right: 16px;
  height: 24px;
}
.CabinetHeader .CabinetHeader__content .CabinetHeader__icons {
  position: relative;
  display: flex;
  margin-right: 18px;
}
.CabinetHeader .CabinetHeader__content .CabinetHeader__icons .Notifications {
  width: 648px;
  position: absolute;
  top: 48px;
  right: 0;
}
.CabinetHeader .CabinetHeader__content .CabinetHeader__icons .CabinetHeader__icon__svg {
  display: block;
}
.CabinetHeader .CabinetHeader__content .CabinetHeader__icon {
  color: var(--text-black);
  position: relative;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 56px;
  cursor: pointer;
}
.CabinetHeader .CabinetHeader__content .CabinetHeader__icon:hover {
  color: var(--almost-black);
}
.CabinetHeader .CabinetHeader__content .CabinetHeader__icon .isvg {
  width: 24px;
  height: 24px;
}
.CabinetHeader .CabinetHeader__content .CabinetHeader__icon .ActionsSheet__list {
  overflow: visible;
  margin-right: -8px;
}
.CabinetHeader .CabinetHeader__content .CabinetHeader__icon .ActionsSheet__list .isvg {
  font-size: 0;
  display: block;
  width: 18px;
  height: 13.5px;
  border: 1px solid;
}
.CabinetHeader .CabinetHeader__content .CabinetHeader__icon .ActionsSheet__list::before {
  position: absolute;
  content: "";
  display: block;
  height: 12px;
  width: 100%;
  top: -12px;
}
.adaptive .CabinetHeaderContainer .CabinetHeader {
  -webkit-border-radius: 0 0 8px 8px;
  -moz-border-radius: 0 0 8px 8px;
  border-radius: 0 0 8px 8px;
  display: flex;
}
.adaptive .CabinetHeader__leftContent,
.adaptive .CabinetHeader__rightContent {
  display: flex;
  align-items: center;
  justify-content: center;
}
.adaptive .CabinetHeader__leftContent {
  width: 60px;
  height: 48px;
}
.adaptive .CabinetHeader__leftContent .CabinetHeader__leftContent_icon {
  width: 100%;
  height: 48px;
  display: flex;
}
.adaptive .CabinetHeader__leftContent .CabinetHeader__leftContent_icon span {
  display: flex;
  margin: auto;
}
.adaptive .CabinetHeader__leftContent .CabinetHeader__leftContent_icon svg {
  margin: auto;
}
.adaptive .CabinetHeader__mainContent {
  width: auto;
  height: 48px;
  margin: auto;
  display: flex;
  font-size: 16px;
}
.adaptive .CabinetHeader__mainContent div,
.adaptive .CabinetHeader__mainContent span {
  margin: auto;
  font-weight: 500;
}
.adaptive .CabinetHeader__mainContent .CabinetHeader__mainContent_logo svg {
  width: 150px;
}
.adaptive .CabinetHeader__rightContent {
  width: 60px;
  height: 48px;
  display: flex;
}
.adaptive .CabinetHeader__rightContent span {
  display: flex;
  margin: auto;
}
.adaptive .CabinetHeader__rightContent span svg {
  margin: auto;
  color: var(--text-black);
}
