.MainLandingWrapperHeader-sidebar {
  position: fixed;
  padding-left: 30px;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  box-sizing: border-box;
  transition: transform 0.4s;
}
.MainLandingWrapperHeader-sidebar:not(.active) {
  transform: translateX(100%);
}
.MainLandingWrapperHeader-sidebar__content {
  margin-left: auto;
  overflow-y: scroll;
  overflow-x: hidden;
  max-width: 344px;
  height: 100%;
  padding: 64px 29px 64px 42px;
  background: #fff;
  box-shadow: 0px 54px 64px rgba(0, 0, 0, 0.1);
  border-radius: 42px 0px 0px 42px;
  box-sizing: border-box;
}
.MainLandingWrapperHeader-sidebar .MainLandingWrapperHeader-nav {
  margin-bottom: 52px;
  flex-direction: column;
  align-items: flex-start;
  gap: 42px 10px;
}
.MainLandingWrapperHeader-sidebar .MainLandingWrapperHeader-nav__link {
  margin: 0;
}
.MainLandingWrapperHeader-sidebar .Button {
  margin-top: 9px;
  width: 100%;
  padding: 0 12px;
  justify-content: flex-start;
}
.MainLandingWrapperHeader-sidebar .Button__cont,
.MainLandingWrapperHeader-sidebar .Button__label {
  width: 100%;
  margin: 0;
}
.MainLandingWrapperHeader-sidebar .Button .isvg {
  margin-left: auto;
}
.MainLandingWrapperHeader-sidebar .close {
  position: absolute;
  top: 15px;
  right: 25px;
  font-size: 24px;
  font-weight: 500;
}
.MainLandingWrapperHeader-sidebar .close svg {
  width: 20px;
  height: 20px;
}
