body,
.SiteWrapper {
  --font-size-landing: 16px;
  --font-size-title: 32px;
  --padding-section: 80px;
  --padding-panel: 40px;
  --primary-orange: #ff9326;
  --primary-orange-opacity: rgba(255, 147, 38, 0.1);
  --dark-orange: #f28d00;
  --main-orange: #fa9751;
  --royal-blue: #5078e6;
  --secondary-blue: #3071ee;
  --primary-blue: #1949d7;
  --primary-blue-opacity: rgba(58, 101, 230, 0.1);
  --dark-blue: #1949d7;
  --primary-dark-blue: #365fd9;
  --orange-gradient-color-start: #ffa526;
  --orange-gradient-color-end: #ff860d;
  --orange-gradient: linear-gradient(0deg, var(--orange-gradient-color-end) 0%, var(--orange-gradient-color-start) 100%);
  --light-orange-gradient-color-start: #fa9751;
  --light-orange-gradient-color-end: #fabe4c;
  --light-orange-gradient: linear-gradient(180deg, var(--light-orange-gradient-color-start) 0%, var(--light-orange-gradient-color-end) 100%);
  --blue-gradient-color-start: #4d72df;
  --blue-gradient-color-end: #274cbd;
  --blue-gradient: linear-gradient(0deg, var(--blue-gradient-color-end) 0%, var(--blue-gradient-color-start) 100%);
  --blue-light-gradient-color-start: #78a0ff;
  --blue-light-gradient-color-end: #5078e5;
  --blue-light-gradient: linear-gradient(135deg, var(--blue-light-gradient-color-start) 0%, var(--blue-light-gradient-color-end) 100%),
    #224dcf;
  --primary-blue-color-start: #4070ff;
  --primary-blue-color-end: var(--primary-dark-blue);
  --primary-blue-gradient: linear-gradient(180deg, var(--primary-blue-color-start) 0%, var(--primary-blue-color-end) 100%);
  --gunmetal: #2f323d;
  --black-gunmetal2: #2f323d;
  --black-gunmetal: #2d313f;
  --dark-gunmetal: #222330;
  --dark-black: #0a1539;
  --black: #1c212e;
  --almost-black: #2e323e;
  --text-black: #3a3f4d;
  --cool-gray: #9095a5;
  --cool-dark-gray: #757575;
  --slate-gray: #595e6b;
  --dark-gray: #7b808c;
  --gray: #9ea2ad;
  --light-gray: #c9ccd4;
  --extra-light-gray: #e4e6ed;
  --sidebar-icon: #c9ccd4;
  --cloudy: #edf0f5;
  --alice-blue: #f7f9fc;
  --azureish-white: #dfe3f0;
  --white: #fff;
  --text-secondary: #4e5c85;
  --text-green: #008060;
  --light-green: #5ec47a;
  --green: #00b277;
  --green-opacity: rgba(0, 178, 119, 0.1);
  --light-green: #ebf5f5;
  --text-yellow: #e6aa00;
  --yellow: #ffc832;
  --yellow-opacity: rgba(255, 200, 50, 0.1);
  --light-yellow: #fff5e6;
  --orange-error: #ff5d17;
  --text-red: #e51300;
  --red: #ff4433;
  --red-opacity: rgba(240, 55, 35, 0.1);
  --light-red: #fff5f5;
  --small-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15),
    0px 1px 4px rgba(0, 0, 0, 0.1);
  --main-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08),
    0px 0px 2px rgba(0, 0, 0, 0.15);
  --hover-shadow: 0px 6px 24px rgba(0, 0, 0, 0.08),
    0px 0px 2px rgba(0, 0, 0, 0.1);
  --primary-background: var(--white);
  --secondary-background: var(--alice-blue);
  --radius-panel: 12px;
  --radius-panel-large: 25px;
  --radius-button: 12px;
}
body.theme-dark {
  --primary-orange: #ff9326;
  --primary-orange-opacity: rgba(255, 147, 38, 0.1);
  --dark-orange: #f28d00;
  --primary-blue: #3b6dff;
  --primary-blue-opacity: rgba(58, 101, 230, 0.1);
  --dark-blue: #1949d7;
  --orange-gradient-color-start: #ffa526;
  --orange-gradient-color-end: #ff860d;
  --orange-gradient: linear-gradient(0deg, var(--orange-gradient-color-end) 0%, var(--orange-gradient-color-start) 100%);
  --blue-gradient-color-start: #4d72df;
  --blue-gradient-color-end: #274cbd;
  --blue-gradient: linear-gradient(0deg, var(--blue-gradient-color-end) 0%, var(--blue-gradient-color-start) 100%);
  --black: #d9d9d9;
  --almost-black: #d9d9d9;
  --text-black: #dbdbdb;
  --slate-gray: #595e6b;
  --dark-gray: #7b808c;
  --gray: #9ea2ad;
  --light-gray: #192230;
  --extra-light-gray: #1a2332;
  --sidebar-icon: #c9ccd4;
  --cloudy: #25354a;
  --alice-blue: #f7f9fc;
  --white: #fff;
  --text-secondary: #4e5c85;
  --text-green: #008060;
  --green: #00b277;
  --green-opacity: rgba(0, 178, 119, 0.1);
  --light-green: #ebf5f5;
  --text-yellow: #e6aa00;
  --yellow: #ffc832;
  --yellow-opacity: rgba(255, 200, 50, 0.1);
  --light-yellow: #fff5e6;
  --text-red: #e51300;
  --red: #ff4433;
  --red-opacity: rgba(240, 55, 35, 0.1);
  --light-red: #fff5f5;
  --small-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15),
    0px 1px 4px rgba(0, 0, 0, 0.1);
  --main-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08),
    0px 0px 2px rgba(0, 0, 0, 0.15);
  --hover-shadow: 0px 6px 24px rgba(0, 0, 0, 0.08),
    0px 0px 2px rgba(0, 0, 0, 0.1);
  --primary-background: #233044;
  --secondary-background: #1b2635;
}
body {
  --gap-size: 20px;
}
@media (max-width: 768px) {
  body {
    --gap-size: 16px;
  }
}
@keyframes showElement {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.Promo {
  overflow: hidden;
}
@media (max-width: 768px) {
  .Promo {
    height: auto;
  }
}
.Promo .Promo__content {
  display: flex;
  padding: var(--padding-section) 0;
  align-items: center;
}
@media (max-width: 768px) {
  .Promo .Promo__content {
    flex-direction: column-reverse;
  }
}
.Promo .Promo__content__text {
  margin-left: 64px;
}
@media (max-width: 768px) {
  .Promo .Promo__content__text {
    margin-left: 0;
    text-align: center;
  }
}
.Promo .Promo__content__text h1 {
  width: 544px;
  font-weight: bold;
  font-size: var(--font-size-title);
  line-height: 1.4em;
  margin-bottom: 32px;
}
@media (max-width: 768px) {
  .Promo .Promo__content__text h1 {
    width: 100%;
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 8px;
  }
}
.Promo .Promo__content__text p {
  width: 446px;
  font-weight: normal;
  font-size: var(--font-size-landing);
  line-height: 1.5em;
  padding-bottom: 32px;
  color: var(--text-secondary);
}
@media (max-width: 768px) {
  .Promo .Promo__content__text p {
    width: 100%;
    font-size: 16px;
    line-height: 24px;
  }
}
.Promo .Promo__content__text .Promo__appButtons {
  display: none;
}
@media (max-width: 768px) {
  .Promo .Promo__content__text .Promo__appButtons {
    display: flex;
    width: min-content;
    margin: 0 auto;
  }
}
@media (max-width: 768px) {
  .Promo .Promo__content__text .Button {
    width: 100%;
  }
}
.Promo .Promo__buyNrfx {
  cursor: pointer;
  width: max-content;
  display: flex;
  align-items: center;
  background: var(--white);
  border: 1px solid var(--cloudy);
  box-sizing: border-box;
  border-radius: 16px;
  padding: 6px;
  margin-top: 32px;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
}
@media (max-width: 768px) {
  .Promo .Promo__buyNrfx {
    display: none;
  }
}
.Promo .Promo__buyNrfx .Promo__buyNrfx__button {
  padding: 4px 8px;
  background: #f0f;
  border-radius: 16px;
  background: var(--orange-gradient);
  color: var(--white);
}
.Promo .Promo__buyNrfx .Promo__buyNrfx__label {
  padding: 4px 8px;
}
.Promo .Promo__buyNrfx a {
  margin-left: 8px;
}
.Promo .Promo__image {
  width: 880px;
  height: 640px;
  position: absolute;
  top: calc(50% + var(--padding-section));
  margin-top: -320px;
  right: 0;
  flex: 0 0 auto;
  margin-right: -176px;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url('./assets/exchange.jpg');
}
@media (max-width: 768px) {
  .Promo .Promo__image {
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 420px;
    margin-right: 0;
    margin-left: auto;
    position: relative;
    top: initial;
    right: initial;
    display: none;
  }
}
