.p2p-orders .orders-list-filters {
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid #f1f1f1;
}
.p2p-orders .orders-list-filters-column {
  display: flex;
  flex-direction: column;
  gap: 8px 0;
}
.p2p-orders .orders-list-filters-column__title {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: var(--cool-gray);
}
.p2p-orders .orders-list-filters-column__content {
  margin-top: auto;
}
.p2p-orders .orders-list-filters__columns {
  gap: 11px;
}
.p2p-orders .orders-list-filters .CabinetSelect-BottomSheet {
  height: 100%;
  min-height: 46px;
}
.p2p-orders .orders-list-filters .CabinetSelect-BottomSheet-option__title {
  font-weight: 500;
  font-size: 12px;
  line-height: 100%;
  color: var(--dark-black);
}
.p2p-orders .orders-list-filters-amount {
  display: flex;
}
.p2p-orders .orders-list-filters-amount .DappInput {
  border-radius: 10px 0 0 10px;
  border: 1px solid var(--azureish-white);
  border-right: none;
  background: none;
  height: 46px;
  font-weight: 500;
  font-size: 12px;
  line-height: 100%;
  letter-spacing: -0.01em;
  color: var(--dark-black);
}
.p2p-orders .orders-list-filters-amount .DappInput__wrapper {
  width: 177px;
}
.p2p-orders .orders-list-filters-amount .DappInput__indicator {
  padding-right: 12px;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: -0.01em;
  color: var(--cool-gray);
}
.p2p-orders .orders-list-filters-amount .Button {
  height: 46px;
  width: 66px;
  border-radius: 0 10px 10px 0;
}
.p2p-orders .orders-list-filters-amount .Button__label {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
}
.p2p-orders .orders-list-filters-refresh {
  width: 46px;
  height: 46px;
  background: #f7f8fb;
  border-radius: 7px;
}
.p2p-orders .orders-list-filters-refresh:hover:not(:active) {
  transform: scale(1.04);
}
