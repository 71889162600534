.ExchangerModal .Modal__box {
  max-width: 515px;
  width: 100%;
}
.ExchangerModal__container {
  background: #fff;
  box-sizing: border-box;
  border-radius: var(--radius-panel);
  padding: 40px 38px;
}
.ExchangerModal h3 {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
}
.ExchangerModal__Currency__container {
  margin-top: 20px;
}
.ExchangerModal__Currency__container > span {
  margin-bottom: 15.5px;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: var(--cool-gray);
}
.ExchangerModal-List {
  color: var(--black-gunmetal);
}
.ExchangerModal-ListItem {
  font-size: 14px;
  line-height: 110%;
  letter-spacing: -0.01em;
}
.ExchangerModal-ListItem__title {
  font-weight: 500;
  opacity: 0.5;
  display: flex;
  align-items: center;
}
.ExchangerModal-ListItem__value {
  font-weight: 600;
  display: flex;
  align-items: center;
}
.ExchangerModal .CustomButton.swap {
  background: #e5ebfc;
  border-radius: 50%;
  width: 19px;
  height: 19px;
  margin-left: 8px;
}
.ExchangerModal .CustomButton.swap:active {
  background: #d1dbf6;
}
.ExchangerModal__rate {
  margin-top: 25px;
}
.ExchangerModal .Button.exchange {
  margin-top: 30px;
  width: 100%;
}
.ExchangerModal .Button.exchange .Button__label {
  font-size: 14px;
  line-height: 16px;
}
.ExchangerModal .DexDescription {
  padding: 17px 23px 20px;
  margin-top: 30px;
}
.ExchangerModal .AnswerPopup {
  margin-left: 7px;
}
.ExchangerModal .DexRoute {
  margin-top: 30px;
  margin-bottom: 0;
}
.ExchangerModal .Number {
  word-break: break-word;
}
.adaptive .ExchangerModal__container {
  padding: 50px 20px 40px;
  border-radius: 18px;
}
.adaptive .ExchangerModal h3 {
  font-size: 16px;
  line-height: 20px;
}
.adaptive .ExchangerModal__Currency__container {
  margin-top: 15px;
}
.adaptive .ExchangerModal__Currency__container > span {
  margin-bottom: 12px;
  font-size: 14px;
  line-height: 17px;
}
.adaptive .ExchangerModal-ListItem {
  font-size: 12px;
}
.adaptive .ExchangerModal .CustomButton.swap {
  width: 17px;
  height: 17px;
  margin-left: 5px;
}
.adaptive .ExchangerModal .CustomButton.swap svg {
  width: 12px;
  height: 12px;
}
.adaptive .ExchangerModal .Button.exchange {
  margin-top: 16px;
}
.adaptive .ExchangerModal .DexDescription {
  padding: 18px 8px 23px;
  margin-top: 20px;
}
.adaptive .ExchangerModal .DexRoute {
  margin-top: 22px;
  margin-bottom: 0;
}
