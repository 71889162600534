.SecretKeyDescModal {
  max-width: 544px;
}
.SecretKeyDescModal .Modal_box {
  padding: 64px;
  padding-bottom: 40px;
}
.SecretKeyDescModal__content {
  margin-top: 32px;
}
.SecretKeyDescModal__content p {
  margin-bottom: 0.5em;
  font-size: 13px;
  line-height: 24px;
}
.SecretKeyDescModal__content a {
  color: #456fe5;
}
.SecretKeyDescModal .Button {
  display: block;
  margin: 40px auto 0;
}
