.TestnetOverlay__wrap {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.TestnetOverlay__background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: var(--secondary-background);
  opacity: 0.85;
  z-index: 1000;
}
.TestnetOverlay__content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  z-index: 1001;
  margin-top: 128px;
}
.TestnetOverlay__content h2,
.TestnetOverlay__content p {
  text-align: center;
  padding: 0 10px 10px;
}
.TestnetOverlay__buttons {
  display: flex;
  margin-top: 20px;
}
.TestnetOverlay__buttons .Button:not(:first-child) {
  margin-left: 20px;
}
