.SwiftGenerated__download {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2.5px 0;
}
.SwiftGenerated__download__title {
  margin: 0 auto;
  padding: 0 4.65px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;
}
.SwiftGenerated__download__title .back {
  width: 18px;
  height: 9px;
  transform: rotate(90deg);
}
.SwiftGenerated__download__title .back .isvg {
  height: inherit;
  width: inherit;
  display: flex;
  justify-content: center;
}
.SwiftGenerated__download__title .back .isvg svg {
  width: inherit;
  height: inherit;
}
.SwiftGenerated__download__title .close svg rect {
  fill: var(--secondary-blue);
}
.SwiftGenerated__download__title .close,
.SwiftGenerated__download__title .back {
  cursor: pointer;
}
.SwiftGenerated__download__title .close:hover,
.SwiftGenerated__download__title .back:hover {
  opacity: 0.8;
}
.SwiftGenerated__download .SwiftGenerated__row {
  width: 80vw;
  max-width: 359px;
}
.SwiftGenerated__download .SwiftGenerated__row .Button:not(:last-child) {
  margin-bottom: 18px;
}
.adaptive .SwiftGenerated__download .SwiftGenerated__row .Button:not(:last-child) {
  margin-bottom: 16px;
}
.adaptive .SwiftGenerated__download .SwiftGenerated__row:not(:last-child) {
  margin-bottom: 24px;
}
