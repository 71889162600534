.AppButtons {
  padding: 16px 0;
  display: flex;
}
.AppButtons .AppButtons__button {
  position: relative;
  cursor: pointer;
  margin-right: 16px;
}
.AppButtons .AppButtons__button i {
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  z-index: 1;
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  line-height: 16px;
  color: var(--black);
}
.AppButtons .AppButtons__button.disabled {
  opacity: 0.5;
  cursor: default;
}
.AppButtons .AppButtons__button.disabled:hover {
  opacity: 1;
}
.AppButtons .AppButtons__button.disabled:hover i {
  opacity: 1;
}
.AppButtons .AppButtons__button.disabled:hover .isvg {
  opacity: 0.1;
}
.AppButtons .AppButtons__button:last-child {
  margin-right: 0;
}
.AppButtons .AppButtons__button .isvg {
  display: block;
}
.AppButtons .AppButtons__button .isvg:hover {
  opacity: 0.8;
}
.AppButtons .AppButtons__button .isvg svg {
  display: block;
}
