.MainLanding-roadmap__wrapper:not(:last-child) {
  margin-bottom: 150px;
}
.MainLanding-roadmap .Roadmap {
  padding: 0;
  box-sizing: border-box;
}
.MainLanding-roadmap .Roadmap__container {
  max-width: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
}
.adaptive .MainLanding-roadmap__wrapper:not(:last-child) {
  margin-bottom: 105px;
}
