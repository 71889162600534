.HaveAQuestion__title {
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  letter-spacing: -0.01em;
  color: inherit;
  margin-bottom: 18px;
}
.HaveAQuestion__button {
  display: block;
}
.HaveAQuestion__button a {
  text-decoration: none;
}
.HaveAQuestion__button:not(:last-child) {
  margin-bottom: 10px;
}
.HaveAQuestion .Button {
  width: 100%;
}
.HaveAQuestion .Button__label {
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 16px !important;
}
.adaptive .HaveAQuestion__title {
  margin-bottom: 16px;
}
.adaptive .HaveAQuestion .Button__label {
  font-weight: 600 !important;
  font-size: 14px !important;
}
