.p2p-orders .orders-list thead td {
  padding-top: 0;
}
.p2p-orders .orders-list thead td span {
  display: inline-flex;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  margin-left: 10px;
}
.p2p-orders .orders-list-user {
  display: flex;
  flex-direction: column;
}
.p2p-orders .orders-list-user__profile {
  display: flex;
  align-items: center;
}
.p2p-orders .orders-list-user__info {
  margin: 7px 0 0 35px;
  font-weight: 400;
  font-size: 11px;
  line-height: 105%;
}
.p2p-orders .orders-list-user__info span:first-child:not(:last-child) {
  position: relative;
  padding-right: 4px;
  margin-right: 7px;
}
.p2p-orders .orders-list-user__info span:first-child:not(:last-child)::after {
  content: '';
  position: absolute;
  width: 1px;
  height: 7px;
  background: var(--cool-gray);
  top: 50%;
  right: -1px;
  transform: translateY(-50%);
}
.p2p-orders .orders-list-user__avatar {
  width: 25px;
  height: 25px;
}
.p2p-orders .orders-list-user__name {
  margin: 0 5px 0 10px;
}
.p2p-orders .orders-list-user__name,
.p2p-orders .orders-list-price,
.p2p-orders .orders-list-limits .Number {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: var(--dark-black);
}
.p2p-orders .orders-list-price .Number {
  margin-left: 8px;
}
.p2p-orders .orders-list-limits {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: var(--cool-gray);
}
.p2p-orders .orders-list-limits > * {
  display: flex;
}
.p2p-orders .orders-list-limits__limit {
  margin-top: 7px;
}
.p2p-orders .orders-list-limits__limit > div {
  display: flex;
  flex-wrap: wrap;
}
.p2p-orders .orders-list-limits__title {
  min-width: 63px;
  margin-right: 7px;
}
.p2p-orders .orders-list-limits .Number {
  line-height: 22px;
}
.p2p-orders .orders-list-payment {
  display: flex;
  flex-wrap: wrap;
  max-width: 182px;
  gap: 7px 14px;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
}
.p2p-orders .orders-list-payment__item {
  display: flex;
}
.p2p-orders .orders-list-payment__color {
  width: 2px;
  height: 15px;
  border-radius: 20px;
  margin-right: 5px;
}
.p2p-orders .orders-list .Button.small {
  padding: 0 18px;
  height: 36px;
  border-radius: 7px;
}
.p2p-orders .orders-list .Button.small .Button__label {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
}
.p2p-orders .orders-list tr td:nth-child(3) {
  width: 27%;
}
.p2p-orders *.adaptive .orders-list tr td:nth-child(3),
.adaptive .p2p-orders .orders-list tr td:nth-child(3) {
  width: auto;
}
