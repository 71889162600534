.DappFAQ .DropdownElement {
  width: 100%;
  margin-bottom: 10px;
  background: #f7f9fc;
  border-radius: 12px;
}
.DappFAQ .DropdownElement p {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: var(--dark-black);
}
.DappFAQ .DropdownElement__content {
  padding: 14px 15px 14px 16.6px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  cursor: pointer;
  user-select: none;
  transition: color 0.2s;
}
.DappFAQ .DropdownElement__content .isvg {
  margin-left: 7px;
  transition: transform 0.2s;
}
.DappFAQ .DropdownElement.active .DropdownElement__content .isvg {
  transform: rotate(180deg);
}
.DappFAQ .DropdownElement__dropElement {
  padding: 0 30px 16px 16.6px;
  line-height: 22px;
  box-sizing: border-box;
}
.DappFAQ .DropdownElement__dropElement p {
  color: var(--cool-gray);
  font-weight: 500;
  font-size: 14px;
}
.DappFAQ__items {
  width: calc(50% - 13.75px);
}
.DappFAQ__items:first-child {
  margin-right: 27.5px;
}
.adaptive .DappFAQ__items {
  width: 100%;
}
.adaptive .DappFAQ__items:first-child {
  margin: 0;
}
.adaptive .DappFAQ .DropdownElement__dropElement {
  padding: 0 20px 14px 16.6px;
  box-sizing: border-box;
}
