.NewWalletModal__content {
  width: 480px;
}
.NewWalletModal__content .NewWalletModal__currencies .NewWalletModal__currency__icon {
  width: 72px;
  height: 72px;
  background: no-repeat center / contain;
}
.NewWalletModal__content .NewWalletModal__currencies .NewWalletModal__currency .NewWalletModal__currency__content {
  height: 88px;
  margin: 0 24px;
  border-bottom: 1px solid var(--alice-blue);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.NewWalletModal__content .NewWalletModal__currencies .NewWalletModal__currency .NewWalletModal__currency__content div {
  display: flex;
  align-items: center;
}
.NewWalletModal__content .NewWalletModal__currencies .NewWalletModal__currency .NewWalletModal__currency__content .NewWalletModal__currency__name {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var(--black);
  padding: 20px;
}
.NewWalletModal__content .NewWalletModal__currencies .NewWalletModal__currency .NewWalletModal__currency__content .NewWalletModal__currency__code {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var(--text-black);
  padding: 20px;
}
.NewWalletModal__content .NewWalletModal__currencies .NewWalletModal__currency .NewWalletModal__currency__type .isvg {
  height: 48px;
}
.NewWalletModal__content .NewWalletModal__currencies .NewWalletModal__currency .isvg {
  height: 24px;
}
