.StatusIndicator {
  font-size: 0;
  background: var(--text-black);
  width: 8px;
  height: 8px;
  border-radius: 50%;
}
.StatusIndicator.online,
.StatusIndicator.activated {
  background-color: var(--green);
}
.StatusIndicator.offline,
.StatusIndicator.deactivated {
  background-color: var(--red);
}
