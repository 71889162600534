.MainLandingWrapperHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 19px 30px;
  left: 0;
  top: 0;
  box-sizing: border-box;
  z-index: 2;
}
.MainLandingWrapperHeader.main {
  position: absolute;
}
.MainLandingWrapperHeader__logo svg {
  width: 105px;
  height: 33px;
}
.MainLandingWrapperHeader-nav {
  display: flex;
  align-items: center;
  text-transform: capitalize;
}
.MainLandingWrapperHeader-nav__link {
  font-weight: 600;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: -0.01em;
  color: #2f323d;
}
.MainLandingWrapperHeader-nav__link:not(:last-child) {
  margin-right: 42px;
}
.MainLandingWrapperHeader__action {
  display: flex;
}
.MainLandingWrapperHeader__action > *:not(:last-child) {
  margin-right: 9px;
}
.MainLandingWrapperHeader .CabinetSelect__control {
  height: 40px;
}
.MainLandingWrapperHeader .CabinetSelect__value-container {
  height: 100%;
}
.MainLandingWrapperHeader .CabinetSelect__single-value span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
}
.MainLandingWrapperHeader .CabinetSelect .isvg {
  display: inline-flex;
  margin-right: 5px;
}
.MainLandingWrapperHeader .CabinetSelect__option .isvg {
  display: none;
}
.MainLandingWrapperHeader .Button .isvg {
  margin-left: 13px;
}
.MainLandingWrapperHeader .Button svg {
  width: 16px;
  height: 12px;
}
.adaptive .MainLandingWrapperHeader {
  padding: 20px 15px;
}
.adaptive .MainLandingWrapperHeader__logo {
  width: 105px;
  height: 33px;
}
.adaptive .MainLandingWrapperHeader__action {
  display: flex;
}
.adaptive .MainLandingWrapperHeader__action > *:not(:last-child) {
  margin-right: 26px;
}
.adaptive .MainLandingWrapperHeader__action .CabinetSelect {
  width: 71px;
  margin-right: 4px;
}
.adaptive .MainLandingWrapperHeader__action .CabinetSelect__control {
  padding: 10px 14px 10px 9px !important;
  min-width: 71px !important;
}
.adaptive .MainLandingWrapperHeader__action .CabinetSelect .CabinetSelect__single-value {
  margin: 0;
  max-width: 100%;
}
.adaptive .MainLandingWrapperHeader__action .CabinetSelect .CabinetSelect__single-value svg {
  width: 20px;
  height: 20px;
}
.adaptive .MainLandingWrapperHeader__action .CabinetSelect__option {
  text-transform: capitalize;
}
.adaptive .MainLandingWrapperHeader .Button.middle {
  padding: 0 15px;
}
html.noScroll,
body.noScroll {
  position: relative;
  height: 100vh;
  overflow: hidden;
}
