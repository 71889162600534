.MainLanding-SocialLink {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.1s;
}
.MainLanding-SocialLink.buttons {
  height: 104px;
  width: 104px;
  background: #fff;
  border-radius: 30px;
}
.MainLanding-SocialLink.icons svg {
  height: 32.75px;
  width: 32.75px;
}
.MainLanding-SocialLink:hover {
  transform: scale(1.1);
}
.adaptive .MainLanding-SocialLink.buttons {
  height: 77.54px;
  width: 77.54px;
}
.adaptive .MainLanding-SocialLink.buttons svg {
  height: 33.55px;
  width: 33.55px;
}
.adaptive .MainLanding-SocialLink.icons svg {
  height: 27.37px;
  width: 27.37px;
}
