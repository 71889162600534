.SecretKeyInfoModal {
  width: 312px;
}
.SecretKeyInfoModal .Modal_box {
  padding: 64px;
  padding-bottom: 40px;
}
.SecretKeyInfoModal__content {
  margin-top: 32px;
}
.SecretKeyInfoModal__content p {
  margin-bottom: 0.5em;
  font-size: 13px;
  line-height: 24px;
}
.SecretKeyInfoModal__content a {
  color: #456fe5;
}
.SecretKeyInfoModal .Button {
  display: block;
  margin: 64px auto 0;
}
