.CabinetTable {
  width: 100%;
  border-spacing: 0;
}
.CabinetTable td {
  padding: 16px 0;
}
.CabinetTable thead td {
  background: transparent;
  padding: 12px 0;
}
.CabinetTable tr td {
  padding-right: 30px;
}
.CabinetTable tr td:first-child {
  padding-left: 16px;
  border-radius: var(--radius-panel) 0 0 var(--radius-panel);
}
.CabinetTable tr td:last-child {
  border-radius: 0 var(--radius-panel) var(--radius-panel) 0;
}
.CabinetTable tbody tr:not(.custom):nth-child(2n) {
  background: transparent;
}
.CabinetTable tbody tr:not(.custom) {
  background: var(--alice-blue);
}
.CabinetTable thead {
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  color: var(--cool-gray);
}
.CabinetTable tbody {
  font-size: 14px;
  font-weight: 600;
}
.CabinetTable td.gray {
  color: var(--cool-gray);
}
.CabinetTable td.dark {
  color: var(--black-gunmetal);
}
.CabinetTable td.blue {
  color: var(--royal-blue);
}
.CabinetTable td.small {
  font-weight: 500;
}
.adaptive .CabinetTable thead {
  display: none;
}
.adaptive .CabinetTable tr {
  display: grid;
  grid-template-columns: 48% 48%;
  padding: 15px 6px 15px 15px;
  border-radius: var(--radius-panel);
  justify-content: space-between;
}
.adaptive .CabinetTable td:first-child {
  grid-column: 1/3;
}
.adaptive .CabinetTable td:not(:first-child) {
  margin-top: 14px;
}
.adaptive .CabinetTable td {
  display: flex;
  flex-direction: column;
  padding: 0 !important;
}
.adaptive .CabinetTable td::before {
  content: attr(data-label);
  font-weight: 500;
  font-size: 10px;
  line-height: 20px;
  margin-bottom: 2px;
  color: var(--cool-gray);
}
