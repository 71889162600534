.SocialMedia {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 66px 0 136px;
  height: 100%;
}
.SocialMedia__container {
  max-width: 756px;
  width: 100%;
  padding: 32px;
  box-shadow: 0px 34px 70px rgba(199, 201, 207, 0.25);
  border-radius: var(--radius-panel-large);
}
.SocialMedia h1 {
  margin-bottom: 32px;
  font-weight: 600;
  font-size: 35px;
  line-height: 110%;
  letter-spacing: -0.01em;
  color: var(--black-gunmetal);
}
.SocialMedia .Row {
  margin-bottom: 24px;
}
.SocialMedia .Button {
  width: calc(50% - 24px);
  height: 116px;
  justify-content: start;
  padding-left: 62px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
  transition: all 0.2s;
}
.SocialMedia .Button a {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: inherit;
  text-decoration: none;
}
.SocialMedia .Button a,
.SocialMedia .Button a .isvg,
.SocialMedia .Button a svg,
.SocialMedia .Button a path {
  transition: all 0.3s, background 0.4s;
}
.SocialMedia .Button a .isvg {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  width: 50px;
  height: 50px;
  background: var(--royal-blue);
  border-radius: 50%;
  color: #fff;
}
.SocialMedia .Button a svg {
  width: 53%;
  height: 53%;
}
.SocialMedia .Button a svg .stop-color-1 {
  stop-color: #fff;
}
.SocialMedia .Button a svg .stop-color-2 {
  stop-color: #fff;
}
.SocialMedia .Button:hover {
  box-shadow: 1px 7px 10px rgba(199, 201, 207, 0.45);
}
.SocialMedia .Button:hover a {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: var(--royal-blue);
}
.SocialMedia .Button:hover .isvg {
  background: none;
}
.SocialMedia .Button:hover .isvg svg {
  transform: scale(1.34);
}
.SocialMedia .Button:hover .isvg svg .stop-color-1 {
  stop-color: #4574ff;
}
.SocialMedia .Button:hover .isvg svg .stop-color-2 {
  stop-color: #84a3ff;
}
.SocialMedia .Button:first-child {
  margin-right: 24px;
}
.SocialMedia.adaptive {
  padding: 33px 24px 56px;
}
.SocialMedia.adaptive .SocialMedia__container {
  padding: 0;
  background: none;
  box-shadow: none;
  border-radius: 0;
  box-sizing: border-box;
}
.SocialMedia.adaptive h1 {
  margin-bottom: 32px;
  font-size: 24px;
}
.SocialMedia.adaptive .Button {
  padding-left: 26px;
  margin: 0 0 18px;
  height: 70px;
  width: 100%;
  box-sizing: border-box;
}
.SocialMedia.adaptive .Button a {
  font-size: 18px;
  line-height: 22px;
}
.SocialMedia.adaptive .Button .isvg {
  height: 36px;
  width: 36px;
}
.SocialMedia.adaptive .Button:hover a {
  font-size: 22px;
  line-height: 26px;
}
.SocialMedia.adaptive .Row {
  margin-bottom: 0;
}
