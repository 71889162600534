.InternalNotification {
  cursor: pointer;
  display: flex;
  background: var(--primary-blue);
  color: var(--white);
  padding: 8px 16px;
  align-items: center;
  font-size: 13px;
  line-height: 16px;
}
.InternalNotification__message {
  margin: 0 auto;
}
.InternalNotification__close {
  cursor: pointer;
  margin: -6px;
  display: block;
  opacity: 0.5;
  line-height: 0;
}
.InternalNotification__close:hover {
  opacity: 0.7;
}
.InternalNotification__button {
  text-transform: uppercase;
  border: 0;
  background: none;
  padding: 8px;
  margin: -8px 8px;
  margin-right: 16px;
  font: inherit;
  line-height: inherit;
  color: inherit;
  outline: none;
}
.adaptive .InternalNotification__close {
  align-self: flex-start;
  margin-right: -12px;
  margin-top: -5px;
}
