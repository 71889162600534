.DepositWithdrawModal .DepositWithdrawModal__content {
  display: flex;
  margin: 40px -28px 0;
}
.DepositWithdrawModal .DepositWithdrawModal__icon {
  width: 72px;
  height: 72px;
  position: absolute;
  top: 16px;
  left: -32px;
  background: no-repeat center / contain;
}
.DepositWithdrawModal .DepositWithdrawModal__coll {
  position: relative;
  margin: 0 28px;
}
.DepositWithdrawModal .DepositWithdrawModal__coll .LoadingStatus__wrap {
  height: 128px;
  width: 100%;
}
.DepositWithdrawModal .DepositWithdrawModal__coll .LoadingStatus__wrap .LoadingStatus {
  margin-left: -60px;
  margin-top: -16px;
}
.DepositWithdrawModal .DepositWithdrawModal__coll.info .DepositWithdrawModal__row {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.DepositWithdrawModal .DepositWithdrawModal__row {
  width: 280px;
  height: 48px;
  margin-bottom: 32px;
}
.DepositWithdrawModal .DepositWithdrawModal__row:last-child {
  margin-bottom: 0;
}
.DepositWithdrawModal .DepositWithdrawModal__row.amount {
  display: flex;
}
.DepositWithdrawModal .DepositWithdrawModal__row.amount .Input {
  flex: 1;
}
.DepositWithdrawModal .DepositWithdrawModal__row.amount .Button {
  width: auto;
  margin-left: 16px;
  padding: 0 24px;
}
.DepositWithdrawModal .Input,
.DepositWithdrawModal .Button {
  width: 100%;
}
.DepositWithdrawModal .DepositWithdrawModal__label {
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: var(--text-black);
  margin-bottom: 8px;
}
.DepositWithdrawModal .DepositWithdrawModal__amount {
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
}
.adaptive .DepositWithdrawModal {
  margin-top: 16px;
}
.adaptive .DepositWithdrawModal__content {
  flex-direction: column;
  margin: 0;
  margin-top: 16px;
}
.adaptive .DepositWithdrawModal__content .LoadingStatus__wrap {
  height: 160px;
}
.adaptive .DepositWithdrawModal__content .LoadingStatus__wrap .LoadingStatus {
  margin-left: auto;
}
.adaptive .DepositWithdrawModal__content .DepositWithdrawModal__row {
  width: 100%;
}
.adaptive .DepositWithdrawModal__content .DepositWithdrawModal__coll {
  margin: 0;
}
.adaptive .DepositWithdrawModal__icon {
  left: 0;
  right: 0;
  top: -32px;
  margin: 0 auto;
}
