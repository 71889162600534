.FiatRefillModal .MethodsList {
  --horisontal-padding: 40px;
}
.FiatRefillModal .MethodsList .MethodsList__item.opened {
  position: relative;
  z-index: 2;
  background: var(--primary-background);
  box-shadow: var(--hover-shadow);
  margin-top: -1px;
}
.FiatRefillModal .MethodsList .MethodsList__item.opened .MethodsList__item__title:after {
  opacity: 0;
}
.FiatRefillModal .MethodsList .MethodsList__item.opened .MethodsList__item__title .isvg {
  transform: rotate(180deg);
}
.FiatRefillModal .MethodsList .MethodsList__item.opened .MethodsList__item__content {
  height: auto;
}
.FiatRefillModal .MethodsList .MethodsList__item .MethodsList__item__title {
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  padding: 16px var(--horisontal-padding);
}
.FiatRefillModal .MethodsList .MethodsList__item .MethodsList__item__title:after {
  position: absolute;
  bottom: 0;
  content: "";
  display: block;
  border-bottom: 1px solid var(--light-gray);
  width: calc(100% - (var(--horisontal-padding) * 2));
}
.FiatRefillModal .MethodsList .MethodsList__item .MethodsList__item__title .isvg {
  color: var(--gray);
  display: block;
  margin-left: auto;
  width: 24px;
  height: 24px;
}
.FiatRefillModal .MethodsList .MethodsList__item .MethodsList__item__content {
  overflow: hidden;
  height: 0;
  box-sizing: border-box;
  padding: 0 var(--horisontal-padding);
}
.FiatRefillModal .MethodsList .MethodsList__item .MethodsList__steps {
  list-style: none;
  padding-left: 0;
}
.FiatRefillModal .MethodsList .MethodsList__item .MethodsList__steps .MethodsList__steps__step {
  display: flex;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  margin-bottom: 16px;
}
.FiatRefillModal .MethodsList .MethodsList__item .MethodsList__steps .MethodsList__steps__step .Clipboard {
  color: var(--dark-blue);
}
.FiatRefillModal .MethodsList .MethodsList__item .MethodsList__steps .MethodsList__steps__step .MethodsList__steps__step__number {
  align-self: baseline;
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  margin-top: -4px;
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  color: var(--dark-blue);
  border-radius: 50%;
  height: 24px;
  width: 24px;
  border: 1px solid;
}
.FiatRefillModal .MethodsList .MethodsList__item:last-child .MethodsList__item__title::after {
  display: none;
}
