.Dropdown {
  background: var(--secondary-background);
  color: var(--black);
  border-radius: var(--radius-panel);
  position: relative;
  user-select: none;
  cursor: pointer;
  border: 1px solid var(--light-gray);
  box-sizing: border-box;
}
.Dropdown.disabled {
  pointer-events: none;
  background: var(--primary-background);
  color: var(--dark-gray);
}
.Dropdown.disabled .Dropdown__option__prefix {
  opacity: 0.3;
}
.Dropdown .Dropdown__header {
  background: inherit;
  border-radius: inherit;
  position: relative;
  z-index: 13;
  padding: 13px 16px;
  padding-right: 12px;
  display: flex;
  align-items: center;
  justify-content: stretch;
}
.Dropdown .Dropdown__header .Dropdown__option {
  width: 100%;
}
.Dropdown .Dropdown__header .Dropdown__option__arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10px;
  height: 6px;
  margin: -4px 0;
  margin-right: 12px;
  color: var(--gray);
}
.Dropdown .Dropdown__header .Dropdown__option__arrow .isvg {
  position: absolute;
  display: block;
  width: inherit;
  height: inherit;
}
.Dropdown .Dropdown__header .Dropdown__option__arrow .isvg svg {
  transition: transform 0.3s ease;
  display: block;
  width: inherit;
  height: inherit;
}
.Dropdown.Dropdown_open .Dropdown__option__arrow svg {
  transform: rotate(180deg);
}
.Dropdown .Dropdown__options {
  padding: 3px 0;
  position: absolute;
  left: -1px;
  background: var(--secondary-background);
  border: 1px solid var(--light-gray);
  border-radius: 0 0 12px 12px;
  width: 100%;
  z-index: 12;
  max-height: 200px;
  overflow-y: auto;
}
.Dropdown .Dropdown__options .Dropdown__option {
  box-sizing: border-box;
  padding: 10px 16px;
  cursor: pointer;
}
.Dropdown .Dropdown__options .Dropdown__option.disabled {
  opacity: 0.5;
  pointer-events: none;
}
.Dropdown .Dropdown__options .Dropdown__option:hover {
  background: var(--cloudy);
}
.Dropdown .Dropdown__option {
  display: flex;
  align-items: center;
  width: 100%;
}
.Dropdown .Dropdown__option .Dropdown__option__prefix {
  margin-right: 8px;
}
.Dropdown .Dropdown__option .Dropdown__option__title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
}
.Dropdown .Dropdown__option .Dropdown__option__note {
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.444444px;
  color: var(--text-black);
  padding-left: 8px;
  margin-left: auto;
}
.Dropdown.small {
  border-radius: 12px;
  box-shadow: var(--small-shadow);
  color: var(--text-black);
}
.Dropdown.small .Dropdown__header {
  padding: 0;
}
.Dropdown.small .Dropdown__header .isvg {
  margin: 0;
  margin-right: 16px;
}
.Dropdown.small.Dropdown_open {
  border-radius: 12px 12px 0 0;
}
.Dropdown.small .Dropdown__option {
  line-height: 16px;
  padding: 4px 16px;
}
.Dropdown.small .Dropdown__option .Dropdown__option__title {
  line-height: inherit;
}
.Dropdown.small .Dropdown__options::before {
  display: none;
}
.Dropdown.small .Dropdown__options .Dropdown__option:hover {
  background: var(--cloudy);
  color: var(--almost-black);
}
.Dropdown_open {
  border-radius: 12px 12px 0 0;
  z-index: 100;
}
.Dropdown__overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
}
