.Referral__Dashboard {
  position: relative;
  padding: 32px 28px;
  background: var(--blue-light-gradient);
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
  border-radius: var(--radius-panel-large);
  margin-bottom: 32px;
  overflow: hidden;
}
.Referral__Dashboard h2 {
  color: #fff;
  margin-bottom: 15px;
}
.Referral__Dashboard .DappUI__Slider {
  margin: 0 -28px;
}
.Referral__Dashboard .DappUI__Slider__container {
  padding: 0 28px 0 38px;
}
.Referral__Dashboard .DappUI__Slider__container > .Row {
  height: 100%;
}
.Referral__Dashboard__arrows {
  display: flex;
  align-items: center;
  position: absolute;
  top: 40px;
  right: 28px;
}
.Referral__Dashboard__arrow {
  cursor: pointer;
  background: none;
  border: none;
  border-radius: 5px;
}
.Referral__Dashboard__arrow:focus,
.Referral__Dashboard__arrow:active {
  outline: none;
}
.Referral__Dashboard__arrow:active svg {
  opacity: 0.9;
  transform: scale(0.94);
}
.Referral__Dashboard__arrow.disabled {
  opacity: 0.4;
  pointer-events: none;
}
.Referral__Dashboard__arrow:first-child {
  transform: rotate(180deg);
  margin-right: 20px;
}
.Referral__Dashboard__cards > *:first-child {
  z-index: 2;
  position: relative;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
}
.adaptive .Referral__Dashboard {
  padding: 25px 15px;
  margin-bottom: 43px;
  border-radius: 20px;
}
.adaptive .Referral__Dashboard h2 {
  margin-bottom: 18px;
}
.adaptive .Referral__Dashboard__cards {
  flex-direction: column;
  margin: 0;
  height: 100%;
}
.adaptive .Referral__Dashboard .Referral__Card {
  margin-bottom: 20px;
}
.adaptive .Referral__Dashboard .Referral__Card__title,
.adaptive .Referral__Dashboard .Referral__Card__count {
  white-space: normal;
}
.adaptive .Referral__Dashboard .DappUI__Slider__container {
  padding: 0 28px;
}
.adaptive .Referral__Dashboard .DappUI__Slider .Referral__Card {
  margin-bottom: 0;
}
.adaptive .Referral__Dashboard .DappUI__Slider .Referral__Card:not(:last-child) {
  margin-right: 6px;
}
.adaptive .Referral__Dashboard .DappUI__Slider .Referral__Card__title,
.adaptive .Referral__Dashboard .DappUI__Slider .Referral__Card__count {
  white-space: nowrap;
}
