.ChangeEmailModal__input_wrapper {
  position: relative;
}
.ChangeEmailModal__input_wrapper .Input {
  box-shadow: var(--main-shadow);
  margin: 40px 0 0 0;
  width: 100%;
  box-sizing: border-box;
  display: block;
  border: 1px solid #f1f1f1;
  -webkit-appearance: none;
}
.ChangeEmailModal__input_wrapper .Input::placeholder {
  color: var(--text-black);
}
.ChangeEmailModal__submit_wrapper {
  width: 100%;
  display: flex;
  padding-top: 40px;
}
.ChangeEmailModal__submit_wrapper .Button {
  margin: auto;
}
