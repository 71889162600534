.ValidatorPreview {
  min-height: calc(100vh - var(--header-height));
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}
.ValidatorPreview__container {
  flex: 1 1 100%;
  padding: 33px 8.6% 33px 50px;
  display: flex;
  align-items: center;
  height: auto;
  position: relative;
  justify-content: space-between;
}
.ValidatorPreview__content {
  position: relative;
  margin-right: 20px;
  z-index: 2;
}
.ValidatorPreview__background {
  flex: 0 1 426px;
  position: relative;
}
.ValidatorPreview__background > .isvg {
  display: flex;
  box-sizing: border-box;
}
.ValidatorPreview__background svg {
  width: 100%;
}
.ValidatorPreview__title {
  font-weight: 600;
  font-size: 48px;
  line-height: 110%;
  letter-spacing: -0.01em;
  color: var(--black-gunmetal);
}
.ValidatorPreview__description {
  margin-top: 22px;
  max-width: 550px;
  font-weight: 500;
  font-size: 14px;
  line-height: 180%;
  color: var(--cool-gray);
}
.ValidatorPreview__buttons {
  margin-top: 49px;
}
.ValidatorPreview__buttons .Button {
  min-width: 250px;
}
.ValidatorPreview__buttons .Button:not(:last-child) {
  margin-right: 13px;
  margin-bottom: 13px;
}
