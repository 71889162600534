.DocPage {
  min-height: 500px;
}
.DocPage .DocPage__title {
  display: flex;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 32px;
}
.DocPage .DocPage__title .Button {
  margin-left: auto;
}
