.SitePageNotFoundScreen .SitePageNotFoundScreen__content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
}
.SitePageNotFoundScreen .SitePageNotFoundScreen__content h2 {
  font-size: 32px;
  margin-bottom: 16px;
}
.SitePageNotFoundScreen .SitePageNotFoundScreen__content p {
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  margin-bottom: 24px;
}
