.ExchangerModal__Settings .settings-toggler {
  width: 100%;
  margin-top: 25px;
}
.ExchangerModal__Settings .settings-toggler.isActive {
  border-radius: var(--radius-button) var(--radius-button) 0 0;
}
.ExchangerModal__Settings .dropdown-icon {
  width: 9px;
  height: 5px;
  margin-left: 7px;
  transition: transform 0.2s;
}
.ExchangerModal__Settings .isActive .dropdown-icon {
  transform: rotate(180deg);
}
.ExchangerModal__Settings .settings-icon {
  width: 13px;
  height: 13px;
  fill: #3071ee;
  margin-right: 5px;
}
.ExchangerModal__Settings__container {
  overflow: hidden;
}
.ExchangerModal__Settings__container > * {
  transition: all 0.3s;
  transition-timing-function: ease-in;
  border-radius: 0;
}
.ExchangerModal__Settings__container:not(.isActive) > * {
  margin-top: -100%;
  height: 0;
}
.ExchangerModal__Settings .SwapSettings {
  background: var(--alice-blue);
  border-radius: 0 0 15px 15px;
  padding: 5px 13px 29px;
}
.ExchangerModal__Settings .SwapSettings .SectionBlock {
  border-radius: 0;
  background: transparent;
  margin-bottom: 20px;
  padding: 0;
}
.adaptive .ExchangerModal__Settings .Button {
  width: auto;
}
.adaptive .ExchangerModal__Settings .settings-toggler {
  width: 100%;
}
