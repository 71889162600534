.PrivatePools {
  display: flex;
  height: 100%;
  width: 100%;
  padding-bottom: 104px;
  box-sizing: border-box;
}
.PrivatePools__wrap {
  height: calc(100vh - 104px);
  width: 100vw;
  margin: 0 auto;
  box-sizing: border-box;
}
.PrivatePools__form {
  display: flex;
  flex-direction: column;
  margin: auto;
  padding: 20px 25px;
  background: #fff;
  border-radius: var(--radius-panel-large);
  box-shadow: 0 0 70px rgba(1, 1, 1, 0.15);
  min-width: 300px;
  max-width: 340px;
  box-sizing: border-box;
}
.PrivatePools h2 {
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 15px;
  position: relative;
}
.PrivatePools__back {
  position: absolute;
  left: 0;
  padding: 4px 8px;
  margin: -4px -8px;
  cursor: pointer;
  border-radius: var(--radius-button);
}
.PrivatePools__back:hover {
  background: var(--alice-blue);
}
.PrivatePools label {
  width: 100%;
}
.PrivatePools label span {
  display: block;
  font-size: 14px;
  font-weight: 500;
  color: var(--cool-gray);
  margin-bottom: 5px;
}
.PrivatePools .Input__wrapper {
  margin: 5px 0;
}
.PrivatePools .CopyTextComponent__content {
  max-width: 100%;
  word-break: break-all;
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 10px;
}
.PrivatePools p {
  margin-bottom: 10px;
}
.PrivatePools .default-text {
  font-size: 14px;
  font-weight: 500;
}
.PrivatePools .row {
  display: flex;
  width: 100%;
}
.PrivatePools .row > .Button {
  width: 100%;
}
.PrivatePools .row.error {
  color: var(--red);
}
.PrivatePools .PrivatePools__table {
  width: 100%;
  padding: 15px;
  box-sizing: border-box;
  background: var(--alice-blue);
  border-radius: var(--radius-panel);
}
.PrivatePools .row {
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  color: var(--cool-gray);
}
.PrivatePools .row:not(:last-child) {
  margin-bottom: 10px;
}
.PrivatePools .row > .Button:not(:last-child) {
  margin-right: 10px;
}
.PrivatePools .col {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.PrivatePools .col > *:not(:last-child) {
  margin-bottom: 5px;
}
.PrivatePools__container {
  position: relative;
}
.PrivatePools__footer {
  position: absolute;
  top: 100%;
  margin-top: 40px;
}
.PrivatePools__footer .row {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
}
.PrivatePools__footer .CopyTextComponent__content {
  font-size: 11px;
  display: block;
  padding: 8px 4px;
  color: var(--dark-blue);
  text-decoration: underline;
  white-space: nowrap;
}
.PrivatePools__footer .CopyTextComponent__content:hover {
  text-decoration: none;
  color: var(--primary-blue);
}
