.BankLogo {
  display: inline-block;
}
.BankLogo .isvg {
  display: block;
  height: 32px;
}
.BankLogo .isvg svg {
  display: inherit;
  height: inherit;
}
.BankLogo .BankLogo__placeholder {
  height: 32px;
  display: flex;
  padding: 0 24px;
  justify-content: center;
  align-items: center;
  border: 1px dashed var(--gray);
  color: var(--text-black);
}
