.Switch {
  display: flex;
  align-items: center;
  font-family: "Montserrat", sans-serif;
  cursor: pointer;
  user-select: none;
  margin-top: 16px;
}
.Switch:first-child {
  margin-top: 0;
}
.Switch.disabled {
  pointer-events: none;
}
.Switch.disabled.on .Switch__control:after {
  background: var(--light-gray);
}
.Switch.light-blue .Switch__control {
  border-radius: 8px;
  box-shadow: none;
  background: rgba(64, 112, 255, 0.1);
}
.Switch.light-blue .Switch__control::after {
  background: var(--blue-light-gradient);
}
.Switch.light-blue .Switch__indicator {
  background: var(--blue-light-gradient);
  border-radius: 7px;
}
.Switch__control {
  width: 40px;
  height: 24px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  transition: box-shadow 0.15s ease-in-out;
  border-radius: 16px;
  position: relative;
  background: var(--primary-background);
  overflow: hidden;
}
.Switch__control::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  border-radius: inherit;
  /* hack for safari */
  background: var(--blue-gradient);
  transition: opacity 0.15s ease-in-out;
}
.Switch.on .Switch__control::after {
  opacity: 1;
}
.Switch.on:not(.disabled) .Switch__control {
  box-shadow: 0px 2px 8px rgba(80, 120, 230, 0.5);
}
.Switch__indicator {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  position: absolute;
  top: 3px;
  left: 3px;
  background: var(--blue-gradient);
  overflow: hidden;
  transition: transform 0.15zs ease-in-out;
  z-index: 1;
}
.Switch__indicator:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  border-radius: inherit;
  /* hack for safari */
  background: var(--primary-background);
  transition: opacity 0.15s ease-in-out;
}
.Switch.on .Switch__indicator {
  transform: translate(16px);
}
.Switch.on .Switch__indicator::after {
  opacity: 1;
}
.Switch.disabled .Switch__indicator {
  background: var(--light-gray);
}
.Switch__label {
  margin-left: 12px;
  font-size: 15px;
  line-height: 20px;
}
