body,
.SiteWrapper {
  --font-size-landing: 16px;
  --font-size-title: 32px;
  --padding-section: 80px;
  --padding-panel: 40px;
  --primary-orange: #ff9326;
  --primary-orange-opacity: rgba(255, 147, 38, 0.1);
  --dark-orange: #f28d00;
  --main-orange: #fa9751;
  --royal-blue: #5078e6;
  --secondary-blue: #3071ee;
  --primary-blue: #1949d7;
  --primary-blue-opacity: rgba(58, 101, 230, 0.1);
  --dark-blue: #1949d7;
  --primary-dark-blue: #365fd9;
  --orange-gradient-color-start: #ffa526;
  --orange-gradient-color-end: #ff860d;
  --orange-gradient: linear-gradient(0deg, var(--orange-gradient-color-end) 0%, var(--orange-gradient-color-start) 100%);
  --light-orange-gradient-color-start: #fa9751;
  --light-orange-gradient-color-end: #fabe4c;
  --light-orange-gradient: linear-gradient(180deg, var(--light-orange-gradient-color-start) 0%, var(--light-orange-gradient-color-end) 100%);
  --blue-gradient-color-start: #4d72df;
  --blue-gradient-color-end: #274cbd;
  --blue-gradient: linear-gradient(0deg, var(--blue-gradient-color-end) 0%, var(--blue-gradient-color-start) 100%);
  --blue-light-gradient-color-start: #78a0ff;
  --blue-light-gradient-color-end: #5078e5;
  --blue-light-gradient: linear-gradient(135deg, var(--blue-light-gradient-color-start) 0%, var(--blue-light-gradient-color-end) 100%),
    #224dcf;
  --primary-blue-color-start: #4070ff;
  --primary-blue-color-end: var(--primary-dark-blue);
  --primary-blue-gradient: linear-gradient(180deg, var(--primary-blue-color-start) 0%, var(--primary-blue-color-end) 100%);
  --gunmetal: #2f323d;
  --black-gunmetal2: #2f323d;
  --black-gunmetal: #2d313f;
  --dark-gunmetal: #222330;
  --dark-black: #0a1539;
  --black: #1c212e;
  --almost-black: #2e323e;
  --text-black: #3a3f4d;
  --cool-gray: #9095a5;
  --cool-dark-gray: #757575;
  --slate-gray: #595e6b;
  --dark-gray: #7b808c;
  --gray: #9ea2ad;
  --light-gray: #c9ccd4;
  --extra-light-gray: #e4e6ed;
  --sidebar-icon: #c9ccd4;
  --cloudy: #edf0f5;
  --alice-blue: #f7f9fc;
  --azureish-white: #dfe3f0;
  --white: #fff;
  --text-secondary: #4e5c85;
  --text-green: #008060;
  --light-green: #5ec47a;
  --green: #00b277;
  --green-opacity: rgba(0, 178, 119, 0.1);
  --light-green: #ebf5f5;
  --text-yellow: #e6aa00;
  --yellow: #ffc832;
  --yellow-opacity: rgba(255, 200, 50, 0.1);
  --light-yellow: #fff5e6;
  --orange-error: #ff5d17;
  --text-red: #e51300;
  --red: #ff4433;
  --red-opacity: rgba(240, 55, 35, 0.1);
  --light-red: #fff5f5;
  --small-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15),
    0px 1px 4px rgba(0, 0, 0, 0.1);
  --main-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08),
    0px 0px 2px rgba(0, 0, 0, 0.15);
  --hover-shadow: 0px 6px 24px rgba(0, 0, 0, 0.08),
    0px 0px 2px rgba(0, 0, 0, 0.1);
  --primary-background: var(--white);
  --secondary-background: var(--alice-blue);
  --radius-panel: 12px;
  --radius-panel-large: 25px;
  --radius-button: 12px;
}
body.theme-dark {
  --primary-orange: #ff9326;
  --primary-orange-opacity: rgba(255, 147, 38, 0.1);
  --dark-orange: #f28d00;
  --primary-blue: #3b6dff;
  --primary-blue-opacity: rgba(58, 101, 230, 0.1);
  --dark-blue: #1949d7;
  --orange-gradient-color-start: #ffa526;
  --orange-gradient-color-end: #ff860d;
  --orange-gradient: linear-gradient(0deg, var(--orange-gradient-color-end) 0%, var(--orange-gradient-color-start) 100%);
  --blue-gradient-color-start: #4d72df;
  --blue-gradient-color-end: #274cbd;
  --blue-gradient: linear-gradient(0deg, var(--blue-gradient-color-end) 0%, var(--blue-gradient-color-start) 100%);
  --black: #d9d9d9;
  --almost-black: #d9d9d9;
  --text-black: #dbdbdb;
  --slate-gray: #595e6b;
  --dark-gray: #7b808c;
  --gray: #9ea2ad;
  --light-gray: #192230;
  --extra-light-gray: #1a2332;
  --sidebar-icon: #c9ccd4;
  --cloudy: #25354a;
  --alice-blue: #f7f9fc;
  --white: #fff;
  --text-secondary: #4e5c85;
  --text-green: #008060;
  --green: #00b277;
  --green-opacity: rgba(0, 178, 119, 0.1);
  --light-green: #ebf5f5;
  --text-yellow: #e6aa00;
  --yellow: #ffc832;
  --yellow-opacity: rgba(255, 200, 50, 0.1);
  --light-yellow: #fff5e6;
  --text-red: #e51300;
  --red: #ff4433;
  --red-opacity: rgba(240, 55, 35, 0.1);
  --light-red: #fff5f5;
  --small-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15),
    0px 1px 4px rgba(0, 0, 0, 0.1);
  --main-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08),
    0px 0px 2px rgba(0, 0, 0, 0.15);
  --hover-shadow: 0px 6px 24px rgba(0, 0, 0, 0.08),
    0px 0px 2px rgba(0, 0, 0, 0.1);
  --primary-background: #233044;
  --secondary-background: #1b2635;
}
body {
  --gap-size: 20px;
}
@media (max-width: 768px) {
  body {
    --gap-size: 16px;
  }
}
@keyframes showElement {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.DappHeader {
  padding: 17px 0 2px;
  z-index: 2;
}
.DappHeader__container {
  display: flex;
  margin: 0 auto;
  padding: 0 18px;
  box-sizing: border-box;
}
.DappHeader__logo {
  height: 38px;
  cursor: pointer;
}
.DappHeader__menu {
  display: flex;
  align-items: center;
  margin-left: auto;
}
.DappHeader__menu .dynamic-shadow {
  display: flex;
  position: relative;
  margin-left: 10px;
  z-index: 1;
}
.DappHeader__menu .dynamic-shadow .Button {
  position: relative;
  height: 39px;
}
.DappHeader__menu .dynamic-shadow .Button .Button__label {
  display: flex;
  align-items: center;
}
.DappHeader__menu .dynamic-shadow .isvg {
  margin-right: 16px;
  height: 24px;
}
.DappHeader__menu .dynamic-shadow.disabled {
  opacity: 0.3;
  pointer-events: none;
}
.DappHeader__menu .DappHeader__icons {
  position: relative;
  display: flex;
  margin-right: 18px;
  align-items: center;
}
.DappHeader__menu .DappHeader__icons .Notifications {
  width: 648px;
  position: absolute;
  top: 48px;
  right: 0;
}
.DappHeader__menu .DappHeader__icons .DappHeader__icon__svg {
  display: block;
}
.DappHeader__menu .DappHeader__connect {
  margin-left: 10px;
}
.DappHeader__menu .DappHeader__account-address {
  color: var(--blue-light-gradient-color-end);
  font-size: 12px;
}
.DappHeader__menu .DappHeader__icon {
  color: var(--text-black);
  position: relative;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 57px;
  cursor: pointer;
}
.DappHeader__menu .DappHeader__icon:hover {
  color: var(--almost-black);
}
.DappHeader__menu .DappHeader__icon .isvg {
  width: 24px;
  height: 24px;
}
.DappHeader__menu .DappHeader__icon .ActionsSheet__list {
  overflow: visible;
  margin-top: 32px;
  margin-right: -8px;
}
.DappHeader__menu .DappHeader__icon .ActionsSheet__list .isvg {
  height: 23px;
  width: 23px;
  margin-right: 5px;
}
.DappHeader__menu .DappHeader__icon::after {
  content: '';
  position: absolute;
  top: 12px;
  left: 50%;
  transform: translateX(-50%);
  width: 90.3%;
  height: 35px;
  z-index: -1;
  background: var(--blue-light-gradient);
  opacity: 0.5;
  filter: blur(45px);
  border-radius: 10px;
}
.DappHeader__language img {
  width: 23px;
  height: 23px;
  border: 1px solid #ebeff4;
  box-sizing: border-box;
  border-radius: 50%;
  object-fit: cover;
  transition: border-color 0.2s;
}
.DappHeader__language.active img,
.DappHeader__language:hover img {
  border-color: var(--blue-light-gradient-color-start);
}
.DappHeader__language:not(:last-child) {
  margin-right: 3px;
}
.DappHeader__settings {
  display: flex;
  align-items: center;
  height: 100%;
  color: #f7f9fc;
  cursor: pointer;
}
.DappHeader__settings .isvg {
  width: 25px;
  height: 25px;
}
.DappHeader__settings .ActionsSheet > div:first-child {
  display: flex;
  align-items: center;
  height: 39px;
}
.DappHeader .ActionsSheet__item__sub svg {
  width: 18px;
}
.DappHeader__wallet-rate {
  display: flex;
  align-items: center;
  height: 39px;
  padding: 0 8px;
  margin-left: 3px;
  box-sizing: border-box;
  border: 1px solid #dfe3f0;
  border-radius: 10px;
  font-weight: 500;
  font-size: 12px;
  line-height: 180%;
  color: var(--dark-black);
}
.DappHeader__wallet-rate > div {
  display: flex;
}
.DappHeader__wallet-rate .full-number {
  color: #d3d5db;
}
.DappHeader__wallet-rate span {
  display: inline-flex;
  align-items: center;
}
.DappHeader__wallet-rate .isvg svg {
  height: 22.5px;
  width: 15px;
}
.DappHeader .wallet-connect__button {
  margin-right: 34px;
}
.DappHeader__wallet {
  height: 100%;
  display: flex;
  align-items: center;
  width: 172px;
  margin-right: 13px;
  margin-left: 3px;
  border: 1px solid #dfe3f0;
  padding: 0 28px 0 12px;
  border-radius: 10px;
  box-sizing: border-box;
  cursor: pointer;
}
.DappHeader__wallet .isvg {
  display: inline-flex;
  align-items: center;
  margin-right: 3px;
}
.DappHeader__wallet .isvg svg,
.DappHeader__wallet .isvg path {
  fill: var(--blue-light-gradient-color-start);
}
.DappHeader__wallet p {
  text-overflow: ellipsis;
  overflow: hidden !important;
  font-weight: 500;
  font-size: 12px;
  line-height: 180%;
  color: var(--black-gunmetal);
}
.DappHeader__burger-menu {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 43px;
  min-width: 43px;
  margin-right: 3.38%;
  background: #fff;
  border-radius: var(--radius-panel);
  box-sizing: border-box;
  cursor: pointer;
}
.DappHeader__burger-menu .isvg {
  display: flex;
  justify-content: center;
  align-items: center;
}
.DappHeader .secondary-text {
  color: var(--cool-gray);
}
.DappHeader__connect {
  margin-left: 10px;
}
.DappHeader__account-address {
  color: var(--blue-light-gradient-color-end);
  font-size: 12px;
}
@media screen and (max-width: 768px) {
  body {
    --header-height: 50px !important;
  }
  .DappHeader {
    position: relative;
    padding: 11px 0 0;
    z-index: 50;
  }
  .DappHeader__container {
    padding: 0 10px;
  }
  .DappHeader__wallet p {
    overflow: hidden;
  }
  .DappHeader__wallet {
    width: 104px;
  }
  .DappHeader .wallet-connect__button {
    width: 47px;
    margin-right: 17px;
  }
  .DappHeader .wallet-connect__button .Button__label .isvg {
    margin-right: 0;
  }
}
@media screen and (max-width: 350px) {
  .DappHeader__wallet {
    width: 63px;
  }
}
html.noScroll,
body.noScroll {
  position: relative;
  height: 100vh;
  overflow: hidden;
}
