.p2p-orders .orders-list__wrapper {
  padding: 40px 50px;
  color: var(--dark-black);
}
.p2p-orders .orders-list__wrapper .orders-list-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 14px;
}
.p2p-orders .orders-list__wrapper .orders-list-pagination__number,
.p2p-orders .orders-list__wrapper .orders-list-pagination__prev,
.p2p-orders .orders-list__wrapper .orders-list-pagination__next {
  width: 18px;
  height: 18px;
  border-radius: 4px;
  transition: background 0.2s;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  text-align: center;
  color: var(--dark-black);
}
.p2p-orders .orders-list__wrapper .orders-list-pagination__number.active,
.p2p-orders .orders-list__wrapper .orders-list-pagination__prev.active,
.p2p-orders .orders-list__wrapper .orders-list-pagination__next.active {
  background: #f7f8fb;
}
.p2p-orders .orders-list__wrapper .orders-list-pagination__number:not(.active):hover,
.p2p-orders .orders-list__wrapper .orders-list-pagination__prev:not(.active):hover,
.p2p-orders .orders-list__wrapper .orders-list-pagination__next:not(.active):hover {
  background: #f7f8fb;
}
.p2p-orders .orders-list__wrapper .orders-list-pagination__skip {
  margin: 0 4px;
  cursor: default;
}
.adaptive .p2p-orders .orders-list__wrapper {
  padding: 30px 18px;
}
