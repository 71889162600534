.Clipboard {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  word-break: break-word;
}
.Clipboard .isvg {
  align-self: start;
  width: 24px;
  height: 24px;
  margin-left: 7px;
}
