.Bot__info .ContentBox__content {
  padding: 40px;
  display: flex;
  justify-content: space-between;
}
.Bot__info .List {
  font-size: 13px;
  line-height: 16px;
}
.Bot__info .Bot__info__uptime {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
}
.Bot__info .Bot__info__uptime .Bot__info__uptime__status {
  color: var(--red);
}
.Bot__info .Bot__info__balance {
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  margin: 24px 0;
}
