.Resend__footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.Resend__footer .Resend {
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.444444px;
  color: var(--text-black);
  margin-bottom: 16px;
}
.Resend__footer .Resend__active {
  text-decoration-line: underline;
  color: var(--black);
  cursor: pointer;
}
