.SectionBlock {
  width: 100%;
  border-radius: var(--radius-panel);
  background: var(--alice-blue);
  padding: 7px 13px 15px;
  box-sizing: border-box;
}
.SectionBlock__title {
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 14px;
  line-height: 180%;
  color: var(--cool-gray);
}
