body,
.SiteWrapper {
  --font-size-landing: 16px;
  --font-size-title: 32px;
  --padding-section: 80px;
  --padding-panel: 40px;
  --primary-orange: #ff9326;
  --primary-orange-opacity: rgba(255, 147, 38, 0.1);
  --dark-orange: #f28d00;
  --main-orange: #fa9751;
  --royal-blue: #5078e6;
  --secondary-blue: #3071ee;
  --primary-blue: #1949d7;
  --primary-blue-opacity: rgba(58, 101, 230, 0.1);
  --dark-blue: #1949d7;
  --primary-dark-blue: #365fd9;
  --orange-gradient-color-start: #ffa526;
  --orange-gradient-color-end: #ff860d;
  --orange-gradient: linear-gradient(0deg, var(--orange-gradient-color-end) 0%, var(--orange-gradient-color-start) 100%);
  --light-orange-gradient-color-start: #fa9751;
  --light-orange-gradient-color-end: #fabe4c;
  --light-orange-gradient: linear-gradient(180deg, var(--light-orange-gradient-color-start) 0%, var(--light-orange-gradient-color-end) 100%);
  --blue-gradient-color-start: #4d72df;
  --blue-gradient-color-end: #274cbd;
  --blue-gradient: linear-gradient(0deg, var(--blue-gradient-color-end) 0%, var(--blue-gradient-color-start) 100%);
  --blue-light-gradient-color-start: #78a0ff;
  --blue-light-gradient-color-end: #5078e5;
  --blue-light-gradient: linear-gradient(135deg, var(--blue-light-gradient-color-start) 0%, var(--blue-light-gradient-color-end) 100%),
    #224dcf;
  --primary-blue-color-start: #4070ff;
  --primary-blue-color-end: var(--primary-dark-blue);
  --primary-blue-gradient: linear-gradient(180deg, var(--primary-blue-color-start) 0%, var(--primary-blue-color-end) 100%);
  --gunmetal: #2f323d;
  --black-gunmetal2: #2f323d;
  --black-gunmetal: #2d313f;
  --dark-gunmetal: #222330;
  --dark-black: #0a1539;
  --black: #1c212e;
  --almost-black: #2e323e;
  --text-black: #3a3f4d;
  --cool-gray: #9095a5;
  --cool-dark-gray: #757575;
  --slate-gray: #595e6b;
  --dark-gray: #7b808c;
  --gray: #9ea2ad;
  --light-gray: #c9ccd4;
  --extra-light-gray: #e4e6ed;
  --sidebar-icon: #c9ccd4;
  --cloudy: #edf0f5;
  --alice-blue: #f7f9fc;
  --azureish-white: #dfe3f0;
  --white: #fff;
  --text-secondary: #4e5c85;
  --text-green: #008060;
  --light-green: #5ec47a;
  --green: #00b277;
  --green-opacity: rgba(0, 178, 119, 0.1);
  --light-green: #ebf5f5;
  --text-yellow: #e6aa00;
  --yellow: #ffc832;
  --yellow-opacity: rgba(255, 200, 50, 0.1);
  --light-yellow: #fff5e6;
  --orange-error: #ff5d17;
  --text-red: #e51300;
  --red: #ff4433;
  --red-opacity: rgba(240, 55, 35, 0.1);
  --light-red: #fff5f5;
  --small-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15),
    0px 1px 4px rgba(0, 0, 0, 0.1);
  --main-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08),
    0px 0px 2px rgba(0, 0, 0, 0.15);
  --hover-shadow: 0px 6px 24px rgba(0, 0, 0, 0.08),
    0px 0px 2px rgba(0, 0, 0, 0.1);
  --primary-background: var(--white);
  --secondary-background: var(--alice-blue);
  --radius-panel: 12px;
  --radius-panel-large: 25px;
  --radius-button: 12px;
}
body.theme-dark {
  --primary-orange: #ff9326;
  --primary-orange-opacity: rgba(255, 147, 38, 0.1);
  --dark-orange: #f28d00;
  --primary-blue: #3b6dff;
  --primary-blue-opacity: rgba(58, 101, 230, 0.1);
  --dark-blue: #1949d7;
  --orange-gradient-color-start: #ffa526;
  --orange-gradient-color-end: #ff860d;
  --orange-gradient: linear-gradient(0deg, var(--orange-gradient-color-end) 0%, var(--orange-gradient-color-start) 100%);
  --blue-gradient-color-start: #4d72df;
  --blue-gradient-color-end: #274cbd;
  --blue-gradient: linear-gradient(0deg, var(--blue-gradient-color-end) 0%, var(--blue-gradient-color-start) 100%);
  --black: #d9d9d9;
  --almost-black: #d9d9d9;
  --text-black: #dbdbdb;
  --slate-gray: #595e6b;
  --dark-gray: #7b808c;
  --gray: #9ea2ad;
  --light-gray: #192230;
  --extra-light-gray: #1a2332;
  --sidebar-icon: #c9ccd4;
  --cloudy: #25354a;
  --alice-blue: #f7f9fc;
  --white: #fff;
  --text-secondary: #4e5c85;
  --text-green: #008060;
  --green: #00b277;
  --green-opacity: rgba(0, 178, 119, 0.1);
  --light-green: #ebf5f5;
  --text-yellow: #e6aa00;
  --yellow: #ffc832;
  --yellow-opacity: rgba(255, 200, 50, 0.1);
  --light-yellow: #fff5e6;
  --text-red: #e51300;
  --red: #ff4433;
  --red-opacity: rgba(240, 55, 35, 0.1);
  --light-red: #fff5f5;
  --small-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15),
    0px 1px 4px rgba(0, 0, 0, 0.1);
  --main-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08),
    0px 0px 2px rgba(0, 0, 0, 0.15);
  --hover-shadow: 0px 6px 24px rgba(0, 0, 0, 0.08),
    0px 0px 2px rgba(0, 0, 0, 0.1);
  --primary-background: #233044;
  --secondary-background: #1b2635;
}
body {
  --gap-size: 20px;
}
@media (max-width: 768px) {
  body {
    --gap-size: 16px;
  }
}
@keyframes showElement {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.WalletsExists {
  display: flex;
  height: 100%;
}
.WalletsExists .WalletsHeader {
  margin-bottom: 39px;
}
.WalletsExists__container {
  padding: 31px 27px 18px 36px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}
.WalletsExists__content {
  height: 100%;
  min-height: 440px;
}
.WalletsExists__content .CabinetBlock {
  background: var(--alice-blue) !important;
  padding: 17px 0px 25px;
  width: 100%;
  position: relative;
}
.WalletsExists__row {
  width: 100%;
  display: flex;
  justify-content: start;
  box-sizing: border-box;
}
.WalletsExists__row:not(:last-child) {
  margin-bottom: 16px;
}
.WalletsExists__row > div {
  width: 100%;
}
.WalletsExists__row > div:first-child {
  margin-right: 10px;
}
.WalletsExists__row > div:last-child {
  max-width: 474px;
}
.WalletsExists__row > div:last-child .WalletsExists__items_header {
  padding: 0 38px 0 31px;
}
.WalletsExists__items_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 38px 0 24px;
}
.WalletsExists__items_header > span:first-child {
  font-weight: 600;
  font-size: 14px;
  line-height: 180%;
  color: var(--cool-gray);
}
.WalletsExists .WalletsList {
  padding: 0 38px 0 24px;
  box-sizing: border-box;
}
.WalletsExists .WalletsList .WalletsListItem__icon {
  width: 39px;
  height: 39px;
}
.WalletsExists .WalletsList .WalletsListItem__icon .isvg {
  width: 100%;
  height: 100%;
}
.WalletsExists .WalletsList .WalletsListItem__icon img {
  width: 100%;
  height: 100%;
}
.WalletsExists .WalletsList .WalletsListItem:not(:last-child) {
  margin-bottom: 30px;
}
.WalletsExists .WalletsList .WalletsListItem__column {
  justify-content: center;
}
.WalletsExists .WalletsList .WalletsListItem .RateIndicator {
  margin-left: 17px;
}
.WalletsExists .WalletsListItem__text-medium {
  font-weight: 500;
  font-size: 12px;
  line-height: 110%;
  letter-spacing: -0.01em;
  margin-bottom: 0;
}
.WalletsExists .WalletsListItem__text-large {
  font-weight: 600;
  font-size: 16px;
  line-height: 110%;
  letter-spacing: -0.01em;
  margin-bottom: 5px;
  color: var(--dark-black);
}
.WalletsExists .ScrollbarBox-container {
  margin-top: 23px;
  width: 100%;
  height: calc(100% - 46px);
}
.WalletsExists .WalletsNFT__cards {
  padding: 0 calc(38px - 8px) 0 calc(31px - 8px);
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
}
.WalletsExists .WalletsNFT__cards .WalletsNFTCard {
  margin: 0 8px;
  margin-bottom: 13px;
  width: calc((100% / 3) - 8px * 2);
}
.WalletsExists .SwitchTabs.light-blue {
  font-size: 11px;
  line-height: 16px;
}
.WalletsExists .SwitchTabs.light-blue .SwitchTabs__item:not(.active) {
  color: var(--primary-dark-blue);
}
.WalletsExists .Button {
  transition: opacity 0.3s, background 0.3s, box-shadow 0.3s !important;
  animation: buttonShow 0.8s;
}
@keyframes buttonShow {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.WalletsExists__content .DappUI__Overlay__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.WalletsExists__content .DappUI__Overlay__bg {
  background: linear-gradient(179.85deg, rgba(255, 255, 255, 0.54) 0.13%, rgba(255, 255, 255, 0.426) 68.91%);
  backdrop-filter: blur(5px);
}
.WalletsExists__content .DappUI__Overlay h3 {
  margin-top: 25px;
}
.WalletsExists .nfts .DappUI__Overlay .isvg {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 63px;
  height: 63px;
  background: rgba(80, 120, 230, 0.1);
  border-radius: 21px;
}
.WalletsExists .nfts .DappUI__Overlay svg {
  width: 29.25px;
  height: 29.25px;
}
.WalletsExists .nfts .DappUI__Overlay svg path {
  fill: #5078e6;
}
.CabinetWallets__tokens-controls div:last-child {
  margin-left: 23px;
}
.CabinetWallets__tokens-controls,
.CabinetWallets__tokens-content {
  display: flex;
  align-items: center;
}
@media screen and (min-width: calc(768px + 1px)) and (max-width: 1300px) {
  .WalletsExists .WalletsNFT__cards .WalletsNFTCard {
    width: calc((100% / 2) - 8px * 2);
  }
}
@media screen and (min-width: 1600px) {
  .WalletsExists__row > div:last-child {
    max-width: 600px;
  }
  .WalletsExists .WalletsNFT__cards .WalletsNFTCard {
    width: calc((100% / 4) - 8px * 2);
  }
}
@media screen and (max-width: 1200px) and (min-width: 600px) {
  .WalletsExists .WalletsNFT__cards .WalletsNFTCard {
    width: calc((100% / 4) - 8px * 2);
  }
}
@media screen and (max-width: 1200px) {
  .WalletsExists__container {
    padding: 32px 11px 10px;
  }
  .WalletsExists .WalletsHeader {
    flex-direction: column;
  }
  .WalletsExists .WalletsHeader__col:not(:first-child) {
    flex-wrap: nowrap;
    margin: 23px auto 0;
  }
  .WalletsExists__content .WalletsNFT__cards {
    padding: 0 calc(21px - 8px) 0 calc(16px - 8px);
  }
  .WalletsExists__content .WalletsList {
    padding: 0 13px 0 18px;
  }
  .WalletsExists__content .CabinetBlock {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 100% !important;
    margin: 0 !important;
  }
  .WalletsExists__content .CabinetBlock.nfts {
    padding: 18px 0 13px;
  }
  .WalletsExists__content .CabinetBlock.wallets-list {
    padding: 31px 0 13px;
  }
  .WalletsExists__content .CabinetBlock.wallets-list .WalletsListItem {
    margin-bottom: 35px;
  }
  .WalletsExists__content .CabinetBlock .ScrollbarBox-container {
    margin: 0;
    height: 100%;
  }
  .WalletsExists__items_footer {
    position: relative;
    width: 100%;
    margin-top: 10px;
    display: flex;
    justify-content: center;
  }
  .WalletsExists__switch {
    padding: 0 30px;
    width: 100%;
    box-sizing: border-box;
  }
  .WalletsExists__switch .SwitchTabs__item::before {
    display: none;
  }
  .WalletsExists .Button {
    padding-top: 0;
    padding-bottom: 0;
  }
  .WalletsExists .SwitchTabs {
    height: auto;
    background: none;
  }
  .WalletsExists .SwitchTabs__item {
    font-weight: 600;
    font-size: 14px;
    line-height: 180%;
    color: var(--cool-gray);
    padding-bottom: 8px;
  }
  .WalletsExists .SwitchTabs__item.active {
    color: var(--royal-blue);
  }
  .WalletsExists .SwitchTabs__indicator span {
    height: 3px;
    width: 70%;
    top: auto;
    right: auto;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    background: var(--royal-blue);
    border-radius: 10px 10px 0px 0px;
  }
}
@media screen and (max-width: 480px) {
  .WalletsExists .WalletsNFT__cards .WalletsNFTCard {
    margin: 0 8px;
    margin-bottom: 13px;
    width: calc((100% / 2) - 8px * 2);
  }
  .WalletsExists .WalletsListItem__text-large {
    max-width: 90%;
  }
}
