.MainLanding-our-partner:not(:last-child) {
  margin-bottom: 120px;
}
.MainLanding-our-partner__list {
  margin-top: 64px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 25px 68px;
}
.adaptive .MainLanding-our-partner:not(:last-child) {
  margin-bottom: 112px;
}
.adaptive .MainLanding-our-partner__list {
  gap: 25px 5px;
}
.adaptive .MainLanding-our-partner .isvg {
  margin: 0 auto;
}
.adaptive .MainLanding-our-partner svg {
  width: 148px;
  height: 40px;
}
