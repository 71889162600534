.ProfileUser {
  text-align: center;
}
.ProfileUser__avatar__wrap {
  width: 112px;
  height: 112px;
  position: relative;
  margin: 18px auto;
  border-radius: 50%;
  cursor: pointer;
}
.ProfileUser__avatar__wrap:hover {
  background-color: white;
}
.ProfileUser__avatar__over {
  opacity: 0;
  transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  background-color: rgba(0, 0, 0, 0.47);
  display: flex;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  border-radius: 50%;
}
.ProfileUser__avatar__over span {
  margin: auto;
  color: var(--white);
}
.ProfileUser__avatar__over:hover {
  opacity: 1;
}
.ProfileUser__avatar {
  position: absolute;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
  background: var(--light-gray);
}
.ProfileUser__description {
  text-align: center;
}
.ProfileUser__title {
  display: flex;
  justify-content: center;
  align-items: center;
  word-break: break-word;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  margin-bottom: 8px;
}
.ProfileUser__verified {
  margin-left: 4px;
  width: 16px;
  height: 16px;
}
.ProfileUser__txt {
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.444444px;
  color: var(--gray);
  margin-bottom: 4px;
}
.ProfileUser__verifyButton {
  margin-top: 8px;
}
.ProfileUser__verify.verified {
  background-color: var(--green) !important;
}
.ProfileUser__verify {
  margin-top: 8px;
  background: var(--red);
  border-radius: 14px;
  padding: 6px 16px;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.444444px;
  color: var(--primary-background);
  border: none;
  outline: none;
}
.adaptive .ProfileUser {
  margin: -8px 0 24px 24px;
  display: flex;
  align-items: center;
}
.adaptive .ProfileUser__avatar__wrap {
  margin: 0;
  flex: 0 0 auto;
}
.adaptive .ProfileUser__description {
  margin-left: 16px;
  margin-right: 16px;
  text-align: left;
}
.adaptive .ProfileUser__txt {
  margin-bottom: 8px;
}
.adaptive .ProfileUser__txt:last-child {
  margin-bottom: 0;
}
