.CheckBox {
  display: flex;
  align-items: center;
  font-family: "Montserrat", sans-serif;
  cursor: pointer;
  user-select: none;
}
.CheckBox.disabled {
  pointer-events: none;
}
.CheckBox__indicator,
.CheckBox__indicator__active {
  border-radius: 6px;
}
.CheckBox__indicator {
  border: 2px solid var(--primary-blue);
  width: 24px;
  height: 24px;
  box-shadow: 0 7px 64px rgba(0, 0, 0, 0.07);
  box-sizing: border-box;
  position: relative;
}
.CheckBox.disabled .CheckBox__indicator {
  background: var(--cloudy);
  border: 0;
}
.CheckBox__indicator__active {
  background: var(--blue-gradient);
  position: absolute;
  top: -2px;
  left: -2px;
  bottom: -2px;
  right: -2px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--primary-background);
  opacity: 0;
  transition: opacity 150ms ease-in-out;
}
.CheckBox__indicator__active .isvg {
  height: 16px;
}
.CheckBox.checked .CheckBox__indicator__active {
  opacity: 1;
}
.CheckBox.disabled .CheckBox__indicator__active {
  background: none;
  color: var(--text-black);
}
.CheckBox__label {
  margin-left: 12px;
  font-size: 15px;
  line-height: 20px;
}
.simple.CheckBox .CheckBox__label {
  color: #9095a5;
  margin-left: 5px;
}
.simple.CheckBox .CheckBox__indicator {
  width: 15.9px;
  height: 15.9px;
  border: 1px solid #9095a5;
  box-shadow: none;
}
.simple.CheckBox .CheckBox__indicator__active {
  background: var(--blue-light-gradient);
}
.simple.CheckBox .CheckBox__indicator,
.simple.CheckBox .CheckBox__indicator__active {
  border-radius: 5px;
}
