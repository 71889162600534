.Exchange__wrapper {
  padding-right: 0 !important;
  display: flex;
  justify-content: center;
  align-items: start;
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  z-index: 0;
}
.Exchange__wrapper .DexSwap {
  padding-top: 40px;
}
.Exchange__wrapper h1 {
  font-weight: 600;
  font-size: 35px;
  line-height: 110%;
  letter-spacing: -0.01em;
  color: var(--black-gunmetal);
}
.Exchange__wrapper .Exchange__header {
  margin-bottom: 40px;
  text-align: center;
}
.Exchange__wrapper .Exchange__container {
  width: 100%;
  max-width: 482px;
  position: relative;
}
.Exchange__wrapper .Exchange__bg {
  position: absolute;
  right: -18px;
  top: 0;
}
.Exchange__wrapper .Exchange__bg-center {
  overflow: hidden;
  position: absolute;
  top: 219px;
  left: 0;
  transform: translateX(-24.7%);
  z-index: -1;
}
.Exchange__wrapper .Exchange__bg-center .isvg {
  height: 100%;
  position: relative;
  bottom: -13.64%;
}
.Exchange__wrapper .Exchange__bg-center .isvg svg {
  height: 100%;
  width: auto;
}
.Exchange__wrapper.isModal .modal-bg {
  width: 100%;
  height: 100%;
  position: absolute;
  background: #f7f9fc;
  opacity: 0.8;
}
