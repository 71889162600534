.Input__wrapper {
  --input-height: 48px;
  width: 100%;
  position: relative;
  border-radius: var(--radius-panel);
}
.Input__wrapper .Input__description {
  font-size: 11px;
  line-height: 16px;
  margin: 8px 16px;
  color: var(--text-black);
}
.Input {
  height: var(--input-height);
  color: var(--almost-black);
  background: var(--secondary-background);
  border-radius: var(--radius-panel);
  font-size: 15px;
  line-height: 20px;
  box-sizing: border-box;
  border: 1px solid var(--light-gray);
  outline: none;
  padding: 14px 16px;
  transition: all 150ms ease-in-out;
  width: 100%;
  -webkit-appearance: none;
}
.Input::-webkit-outer-spin-button,
.Input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.Input::placeholder {
  color: var(--gray);
}
.Input.password {
  padding-right: 35px !important;
}
.Input:focus {
  border-color: var(--primary-blue) !important;
  box-shadow: none;
}
.Input.multiLine {
  height: 100px;
  resize: none;
  padding: 12px 16px;
}
.Input[disabled] {
  background: var(--primary-background);
  color: var(--dark-gray);
}
.Input__wrapper.small {
  --input-height: 24px;
}
.Input__wrapper.small .Input {
  border-radius: 6px;
  font-weight: 500;
  font-size: 11px;
  line-height: 14px;
  padding: 4px 8px;
  border-width: 1px;
}
.Input__wrapper.small .Input__description {
  margin: 4px 8px;
}
.Input__wrapper.small .Input__indicator {
  padding-right: 12px;
}
.Input.error {
  border-color: #ff4545 !important;
  box-shadow: 0 0 8px 0px rgba(132, 1, 1, 0.45) !important;
  transition: all 1s ease;
}
.Input.error::placeholder {
  color: var(--text-red);
}
.Input__indicator {
  height: var(--input-height);
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 16px;
  font-size: 14px;
  line-height: 20px;
  color: var(--text-black);
}
.Input__indicator .isvg {
  line-height: 0;
}
.Input__display_password_button {
  position: absolute;
  right: 8px;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 24px;
  height: 24px;
  cursor: pointer;
  color: #d9d4cf;
  user-select: none;
}
.Input__reliability {
  color: var(--primary-orange);
  margin: 6px 16px;
  display: flex;
  align-items: center;
}
.Input__reliability .Input__reliability__label {
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  margin-right: 8px;
}
.Input__reliability .Input__reliability__indicator {
  flex: 1;
  height: 2px;
  border-radius: 1px;
  background: var(--extra-light-gray);
}
.Input__reliability .Input__reliability__indicator .Input__reliability__indicator__fill {
  width: 40%;
  background: currentColor;
  height: inherit;
}
