.WalletsNFTCard {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  min-height: 165px;
}
.WalletsNFTCard:hover .WalletsNFTCard__title {
  opacity: 0;
}
.WalletsNFTCard__title {
  position: absolute;
  left: 7px;
  bottom: 7px;
  padding: 0 6px;
  background: #fff;
  border-radius: 4px;
  font-weight: 600;
  font-size: 12px;
  line-height: 180%;
  color: var(--dark-black);
  transition: opacity 0.3s;
}
.WalletsNFTCard img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.WalletsNFTCard.empty {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(80, 120, 230, 0.16);
  opacity: 0.35;
  border-radius: 10px;
}
.WalletsNFTCard.empty .isvg {
  opacity: 0.16;
}
