.TransactionHistory__TransactionLink {
  display: flex;
  justify-content: start;
}
.TransactionHistory__TransactionLink a {
  display: flex;
  align-items: center;
  color: inherit;
}
.TransactionHistory__TransactionLink a.disabled {
  pointer-events: none;
  color: var(--cool-gray);
}
.TransactionHistory__TransactionLink a.disabled .isvg {
  filter: grayscale(60%);
}
