.AnswerPopup {
  display: inline-flex;
  align-items: center;
  position: relative;
  white-space: initial;
}
.AnswerPopup__container {
  display: none;
  padding: 12px 13px 15px;
  width: 205px;
  background: #fff;
  border-radius: var(--radius-panel);
  position: absolute;
  z-index: 111;
  left: 50%;
  bottom: -12px;
  transform: translate(-50%, 100%);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  animation: showElement 0.2s;
}
.AnswerPopup__icon .isvg {
  display: flex;
  align-items: center;
}
.AnswerPopup__icon .isvg path {
  fill: #fff;
  transition: fill 0.1s;
}
.AnswerPopup__icon .isvg circle {
  fill: var(--black-gunmetal);
  stroke: var(--black-gunmetal);
  opacity: 0.21;
  transition: fill 0.1s, stroke 0.1s, opacity 0.1s;
}
.AnswerPopup__icon .isvg:hover path {
  fill: var(--cool-gray);
}
.AnswerPopup__icon .isvg:hover circle {
  fill: transparent;
  stroke: var(--cool-gray);
  opacity: 1;
}
.AnswerPopup__icon {
  cursor: pointer;
}
.AnswerPopup__triangle {
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
}
.AnswerPopup__content {
  z-index: 10;
  position: relative;
  font-weight: 500;
  font-size: 12px;
  line-height: 160%;
  color: var(--black-gunmetal);
}
.AnswerPopup:hover .AnswerPopup__container {
  display: block;
}
