.ReferPercent {
  margin: var(--gap-size) 0;
  padding: 40px;
}
.ReferPercent h3 {
  font-size: 16px;
  font-weight: 500;
}
.ReferPercent p {
  font-size: 13px;
  line-height: 24px;
  margin: 24px 0;
  max-width: 380px;
}
.ReferPercent__buttons {
  display: flex;
  justify-content: center;
}
.ReferPercent__buttons .Button {
  margin: 0 4px;
}
.ReferPercent__partner {
  font-size: 13px;
  line-height: 24px;
  margin: 24px 0 0;
  text-align: center;
}
