.SuccessModal {
  width: 304px;
}
.SuccessModal .SuccessModal__content {
  height: 260px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.SuccessModal .SuccessModal__content .SuccessModal__content__title {
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.444444px;
  color: #40bf9d;
}
.SuccessModal .SuccessModal__content .SuccessModal__content__msg {
  box-sizing: border-box;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.444444px;
  color: var(--text-black);
  padding: 10px 20px;
  margin: 0 40px;
}
.SuccessModal .SuccessModal__content .SuccessModal__tick {
  width: 28px;
  height: 29px;
  margin: 40px auto;
}
.SuccessModal .Button {
  width: 160px;
  margin: 0;
}
.SuccessModal .Resend__footer {
  height: 78px;
}
.SuccessModal .Resend__footer .Captcha {
  margin: auto;
}
.adaptive .SuccessModal {
  width: 100%;
}
.adaptive .SuccessModal .SuccessModal__content__msg {
  max-width: 100%;
}
