.light-btn {
  height: 70px;
  min-height: 70px;
  background: var(--blue-light-gradient);
}
.white-btn {
  background: #fff;
  color: #648cf2 !important;
  font-size: 18px;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: -0.01em;
  padding: 38.5px 46.19px;
}
.white-btn:hover {
  box-shadow: none !important;
}
