.MainLanding-SocialLinks {
  gap: 13px;
}
.MainLanding-SocialLinks.icons {
  gap: 13px 30px;
}
.adaptive .MainLanding-SocialLinks {
  gap: 10px;
}
.adaptive .MainLanding-SocialLinks.icons {
  gap: 13px 25px;
}
