.Range {
  position: relative;
  height: 4px;
  border-radius: 2px;
  background: var(--light-gray);
  margin: 40px 0;
}
.Range .Range__filler {
  height: 100%;
  background: var(--primary-blue);
}
.Range .Range__thumb {
  display: flex;
  justify-content: center;
  cursor: grab;
  position: absolute;
  margin-top: -6px;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  margin-left: -8px;
  background: white;
  border: 4px solid var(--primary-blue);
}
.Range .Range__thumb:active {
  cursor: grabbing;
}
.Range.light-blue {
  background: #E3E9F0;
  border-radius: 10px;
}
.Range.light-blue .Range__thumb {
  border: none;
  width: 13px;
  height: 13px;
  margin-top: -5px;
  background: var(--blue-light-gradient);
}
.Range.light-blue .Range__filler {
  background: var(--blue-light-gradient);
  border-radius: 10px;
}
.Range .Range__label {
  white-space: nowrap;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  margin-top: -25px;
}
.Range.disabled {
  opacity: 0.8;
  pointer-events: none;
}
.Range.disabled .Range__filler {
  background: var(--gray);
}
.Range.disabled .Range__thumb {
  border-color: var(--gray);
}
body.draggable {
  user-select: none;
}
