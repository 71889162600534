@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
.TransferModal > .Modal__box > .CabinetModal__container {
  padding: 32px 40px;
  background: #fff;
  border-radius: var(--radius-panel);
}
.TransferModal h3 {
  text-align: center;
}
.TransferModal__address {
  position: relative;
}
.TransferModal__address input {
  padding-right: 36px !important;
}
.TransferModal__address-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  fill: var(--light-gray);
  cursor: pointer;
}
.TransferModal__address-icon path {
  fill: var(--light-gray);
}
.TransferModal-loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 40px 40px;
}
.TransferModal-loading .isvg svg {
  margin-bottom: 16px;
  animation: spin 1s linear infinite;
}
.TransferModal-loading .isvg svg path {
  fill: #fabe4c;
}
.TransferModal-form {
  margin-top: 40px;
}
.TransferModal-form h3 {
  font-size: 13px;
  display: flex;
  justify-content: space-between;
}
.TransferModal-form h3 span:not(:first-child) {
  margin-left: 16px;
}
.TransferModal-form h3 span.active {
  color: var(--primary-blue);
  text-decoration: underline;
  cursor: pointer;
  outline: none;
}
.TransferModal-form h3 span.active:hover {
  text-decoration: none;
}
.TransferModal-form .Input__wrapper {
  margin-bottom: 20px;
}
.TransferModal-form .Input__wrapper .Input {
  min-width: 640px;
}
.adaptive .TransferModal-form .Input__wrapper .Input {
  min-width: initial;
  width: 100%;
}
.TransferModal-form .Input__wrapper .Input__description {
  margin: 16px 0 0;
}
.TransferModal-form .Button {
  margin: 8px auto 20px;
}
.adaptive .TransferModal > .Modal__box {
  padding: 20px !important;
}
.adaptive .TransferModal > .Modal__box > .CabinetModal__container {
  padding: 32px 25px;
  background: #fff;
  border-radius: var(--radius-panel);
}
.adaptive .TransferModal + .BottomSheetModal-container {
  z-index: 999;
}
.adaptive .TransferModal + .BottomSheetModal-container .BottomSheetModal__bg {
  background: none;
}
