body,
.SiteWrapper {
  --font-size-landing: 16px;
  --font-size-title: 32px;
  --padding-section: 80px;
  --padding-panel: 40px;
  --primary-orange: #ff9326;
  --primary-orange-opacity: rgba(255, 147, 38, 0.1);
  --dark-orange: #f28d00;
  --main-orange: #fa9751;
  --royal-blue: #5078e6;
  --secondary-blue: #3071ee;
  --primary-blue: #1949d7;
  --primary-blue-opacity: rgba(58, 101, 230, 0.1);
  --dark-blue: #1949d7;
  --primary-dark-blue: #365fd9;
  --orange-gradient-color-start: #ffa526;
  --orange-gradient-color-end: #ff860d;
  --orange-gradient: linear-gradient(0deg, var(--orange-gradient-color-end) 0%, var(--orange-gradient-color-start) 100%);
  --light-orange-gradient-color-start: #fa9751;
  --light-orange-gradient-color-end: #fabe4c;
  --light-orange-gradient: linear-gradient(180deg, var(--light-orange-gradient-color-start) 0%, var(--light-orange-gradient-color-end) 100%);
  --blue-gradient-color-start: #4d72df;
  --blue-gradient-color-end: #274cbd;
  --blue-gradient: linear-gradient(0deg, var(--blue-gradient-color-end) 0%, var(--blue-gradient-color-start) 100%);
  --blue-light-gradient-color-start: #78a0ff;
  --blue-light-gradient-color-end: #5078e5;
  --blue-light-gradient: linear-gradient(135deg, var(--blue-light-gradient-color-start) 0%, var(--blue-light-gradient-color-end) 100%),
    #224dcf;
  --primary-blue-color-start: #4070ff;
  --primary-blue-color-end: var(--primary-dark-blue);
  --primary-blue-gradient: linear-gradient(180deg, var(--primary-blue-color-start) 0%, var(--primary-blue-color-end) 100%);
  --gunmetal: #2f323d;
  --black-gunmetal2: #2f323d;
  --black-gunmetal: #2d313f;
  --dark-gunmetal: #222330;
  --dark-black: #0a1539;
  --black: #1c212e;
  --almost-black: #2e323e;
  --text-black: #3a3f4d;
  --cool-gray: #9095a5;
  --cool-dark-gray: #757575;
  --slate-gray: #595e6b;
  --dark-gray: #7b808c;
  --gray: #9ea2ad;
  --light-gray: #c9ccd4;
  --extra-light-gray: #e4e6ed;
  --sidebar-icon: #c9ccd4;
  --cloudy: #edf0f5;
  --alice-blue: #f7f9fc;
  --azureish-white: #dfe3f0;
  --white: #fff;
  --text-secondary: #4e5c85;
  --text-green: #008060;
  --light-green: #5ec47a;
  --green: #00b277;
  --green-opacity: rgba(0, 178, 119, 0.1);
  --light-green: #ebf5f5;
  --text-yellow: #e6aa00;
  --yellow: #ffc832;
  --yellow-opacity: rgba(255, 200, 50, 0.1);
  --light-yellow: #fff5e6;
  --orange-error: #ff5d17;
  --text-red: #e51300;
  --red: #ff4433;
  --red-opacity: rgba(240, 55, 35, 0.1);
  --light-red: #fff5f5;
  --small-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15),
    0px 1px 4px rgba(0, 0, 0, 0.1);
  --main-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08),
    0px 0px 2px rgba(0, 0, 0, 0.15);
  --hover-shadow: 0px 6px 24px rgba(0, 0, 0, 0.08),
    0px 0px 2px rgba(0, 0, 0, 0.1);
  --primary-background: var(--white);
  --secondary-background: var(--alice-blue);
  --radius-panel: 12px;
  --radius-panel-large: 25px;
  --radius-button: 12px;
}
body.theme-dark {
  --primary-orange: #ff9326;
  --primary-orange-opacity: rgba(255, 147, 38, 0.1);
  --dark-orange: #f28d00;
  --primary-blue: #3b6dff;
  --primary-blue-opacity: rgba(58, 101, 230, 0.1);
  --dark-blue: #1949d7;
  --orange-gradient-color-start: #ffa526;
  --orange-gradient-color-end: #ff860d;
  --orange-gradient: linear-gradient(0deg, var(--orange-gradient-color-end) 0%, var(--orange-gradient-color-start) 100%);
  --blue-gradient-color-start: #4d72df;
  --blue-gradient-color-end: #274cbd;
  --blue-gradient: linear-gradient(0deg, var(--blue-gradient-color-end) 0%, var(--blue-gradient-color-start) 100%);
  --black: #d9d9d9;
  --almost-black: #d9d9d9;
  --text-black: #dbdbdb;
  --slate-gray: #595e6b;
  --dark-gray: #7b808c;
  --gray: #9ea2ad;
  --light-gray: #192230;
  --extra-light-gray: #1a2332;
  --sidebar-icon: #c9ccd4;
  --cloudy: #25354a;
  --alice-blue: #f7f9fc;
  --white: #fff;
  --text-secondary: #4e5c85;
  --text-green: #008060;
  --green: #00b277;
  --green-opacity: rgba(0, 178, 119, 0.1);
  --light-green: #ebf5f5;
  --text-yellow: #e6aa00;
  --yellow: #ffc832;
  --yellow-opacity: rgba(255, 200, 50, 0.1);
  --light-yellow: #fff5e6;
  --text-red: #e51300;
  --red: #ff4433;
  --red-opacity: rgba(240, 55, 35, 0.1);
  --light-red: #fff5f5;
  --small-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15),
    0px 1px 4px rgba(0, 0, 0, 0.1);
  --main-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08),
    0px 0px 2px rgba(0, 0, 0, 0.15);
  --hover-shadow: 0px 6px 24px rgba(0, 0, 0, 0.08),
    0px 0px 2px rgba(0, 0, 0, 0.1);
  --primary-background: #233044;
  --secondary-background: #1b2635;
}
body {
  --gap-size: 20px;
}
@media (max-width: 768px) {
  body {
    --gap-size: 16px;
  }
}
@keyframes showElement {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.FarmingPopup__header {
  margin-bottom: 12px;
}
.FarmingPopup__body {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  width: 100%;
}
.FarmingPopup__body p {
  margin-bottom: 8px;
}
.FarmingPopup__footer {
  margin: 0 auto;
  text-align: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  color: var(--cool-gray);
}
.FarmingPopup__footer a {
  text-decoration: underline;
  color: var(--dark-black);
}
.FarmingPopup__center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.FarmingPopup:not(.FarmingPopup__fullscreen) {
  max-width: 353px;
  width: 100%;
}
.FarmingPopup__fullscreen .Modal__box {
  padding: 32px 32.5px;
  max-width: 445px;
}
.FarmingPopup__fullscreen .FarmingPopup__header {
  margin-bottom: 24px;
}
.FarmingPopup__fullscreen .close {
  position: absolute;
  top: 37.7px;
  right: 32.5px;
  cursor: pointer;
}
.FarmingPopup__fullscreen .close:hover {
  opacity: 0.8;
}
.FarmingPopup__fullscreen p {
  max-width: 100%;
}
.FarmingPopup.FarmingPopupStake .Modal__box {
  max-width: 388px;
  width: 100%;
  border-radius: var(--radius-panel);
}
.FarmingPopup.FarmingPopupStake .Button {
  margin: 0 0 16px;
}
.FarmingPopup .title {
  margin-bottom: 8px;
  padding-right: 30px;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: var(--dark-gunmetal);
}
.FarmingPopup .success-title {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  padding-right: 30px;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #5ec47a;
}
.FarmingPopup p,
.FarmingPopup .default-text {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: var(--cool-gray);
}
.FarmingPopup .popup-link {
  display: inline-flex;
  align-items: center;
  text-align: center;
  color: #000;
  cursor: pointer;
}
.FarmingPopup .popup-link:hover {
  opacity: 0.9;
}
.FarmingPopup .basic {
  color: var(--dark-black);
}
.FarmingPopup .basic,
.FarmingPopup .popup-link,
.FarmingPopup .Input {
  font-weight: 600;
  font-size: 14px;
  line-height: 100%;
}
.FarmingPopup label {
  margin: 0 0 24px;
  width: 100%;
}
.FarmingPopup__label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.FarmingPopup .Input {
  width: 100%;
  padding: 16px 150px 13px 16px !important;
  border: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
}
.FarmingPopup .Input__wrapper {
  margin: 0;
}
.FarmingPopup .input-container {
  display: flex;
  align-items: center;
  position: relative;
  margin-top: 8px;
}
.FarmingPopup .input-controls {
  display: flex;
  align-items: center;
  position: absolute;
  right: 8px;
}
.FarmingPopup .input-controls__button {
  padding: 8px;
  background: #cadfff;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
}
.FarmingPopup .input-controls span {
  background: linear-gradient(135deg, #78a0ff 0%, #5078e6 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
.FarmingPopup .input-controls .default-text {
  font-weight: 600;
  color: var(--dark-gunmetal);
  margin: 0 10px 0 0;
}
.FarmingPopup .Button {
  height: 46px;
  width: 100%;
  margin: 12px 0 20px;
}
.FarmingPopup .isvg {
  display: inline-flex;
  align-items: center;
  justify-content: start;
}
.FarmingPopup .FarmingPopup__header .isvg {
  margin-right: 4px;
}
.FarmingPopup .popup-link .isvg {
  margin-left: 4px;
}
.FarmingPopup__table {
  margin-top: 12px;
  border: none;
  border-radius: 0;
  box-shadow: none;
}
.FarmingPopup__table td:last-child .Table__td__cont {
  padding-right: 10px;
  text-align: right;
}
.FarmingPopup__table .Table__td__cont {
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  color: var(--dark-black);
}
.FarmingPopup__table .Table__sub {
  font-weight: 500;
  font-size: 12px;
  line-height: 100%;
  color: var(--cool-gray);
}
.FarmingPopup__table thead .Table__td__cont {
  min-height: 14px;
  padding-bottom: 12px;
}
.FarmingPopup__table thead td:first-child .Table__td__cont {
  padding-left: 0;
}
.FarmingPopup__table tbody tr:last-child td:first-child {
  border-radius: 0 0 0 var(--radius-panel);
}
.FarmingPopup__table tbody tr:last-child td:last-child {
  border-radius: 0 0 var(--radius-panel) 0;
}
.FarmingPopup__table tbody tr:first-child td:first-child {
  border-radius: var(--radius-panel) 0 0 0;
}
.FarmingPopup__table tbody tr:first-child td:last-child {
  border-radius: 0 var(--radius-panel) 0 0;
}
@media screen and (max-width: 768px) {
  .FarmingPopup:not(.FarmingPopup__fullscreen) {
    max-width: 100%;
    width: 100%;
  }
}
