.ChooseMarketModal {
  overflow: hidden;
  width: 720px;
  position: relative;
  z-index: 1;
}
.ChooseMarketModal .TableMain {
  box-shadow: none;
}
.ChooseMarketModal .Modal__header {
  margin: 16px 0;
}
.ChooseMarketModal__value.positive {
  color: var(--green);
}
.ChooseMarketModal__value.negative {
  color: var(--red);
}
.ChooseMarketModal__filters {
  position: relative;
  padding: 24px 16px;
  box-shadow: var(--main-shadow);
  z-index: 3;
}
.ChooseMarketModal__filters__buttons {
  display: flex;
  width: 400px;
}
.ChooseMarketModal__filters__form {
  display: flex;
}
.ChooseMarketModal__filters__form .Input,
.ChooseMarketModal__filters__form .Button {
  height: 40px;
}
.ChooseMarketModal__filters__form .Input__indicator {
  height: 40px;
}
.ChooseMarketModal__filters__form .Input__indicator .isvg {
  line-height: 0;
  margin-right: -4px;
}
.ChooseMarketModal__filters__form .Button {
  margin-left: 16px;
}
.ChooseMarketModal__icons {
  display: flex;
}
.ChooseMarketModal__icon {
  z-index: 2;
}
.ChooseMarketModal__icon:last-child {
  margin-left: -18px;
  z-index: 1;
}
.ChooseMarketModal__market {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}
.ChooseMarketModal__market_secondary {
  color: var(--text-black);
  font-size: 13px;
  line-height: 16px;
}
.ChooseMarketModal__market_list .Table td:first-child {
  width: 108px;
}
.ChooseMarketModal__market_list tr.Hover {
  height: 88px;
  position: relative;
}
.ChooseMarketModal__market_list tr.Hover::after {
  position: absolute;
  content: "";
  display: block;
  height: 1px;
  left: 0;
  right: 0;
  box-sizing: border-box;
  margin: -1px auto 0;
  width: calc(100% - 48px);
  background: rgba(0, 0, 0, 0.1);
}
.ChooseMarketModal__market_list tr.Hover:hover + .Hover::after {
  opacity: 0;
}
.ChooseMarketModal__chart .Chart {
  width: 136px;
}
.ChooseMarketModal__wrapper .Modal__box__close {
  z-index: 4;
}
.adaptive .ChooseMarketModal {
  width: auto;
}
.adaptive .ChooseMarketModal__filters {
  box-shadow: none;
  z-index: auto;
}
.adaptive .ChooseMarketModal__filters__form {
  flex-direction: column;
}
.adaptive .ChooseMarketModal__filters__buttons {
  width: 100%;
  margin-top: 16px;
}
.adaptive .ChooseMarketModal__filters__buttons .Button {
  height: 24px;
}
.adaptive .ChooseMarketModal__filters__buttons .Button:first-child {
  margin-left: 0;
}
.adaptive .ChooseMarketModal .Modal__header {
  margin: 0;
}
