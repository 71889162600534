.DepositModal__WithdrawalDetails > .Modal__box {
  max-width: 436px;
  width: 100%;
}
.DepositModal__WithdrawalDetails .DepositModal__container {
  padding: 32px;
}
.DepositModal__WithdrawalDetails__title {
  margin-bottom: 32px;
  font-weight: 500;
  font-size: 20px;
  line-height: 110%;
  letter-spacing: -0.01em;
  color: #0a1539;
}
.DepositModal__WithdrawalDetails__label {
  display: block;
}
.DepositModal__WithdrawalDetails__label:not(:last-child) {
  margin-bottom: 32px;
}
.DepositModal__WithdrawalDetails__label > span {
  display: block;
  margin-bottom: 12px;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: var(--cool-gray);
}
.DepositModal__WithdrawalDetails__label .Input__wrapper:not(:last-child) {
  margin-bottom: 10px;
}
.DepositModal__WithdrawalDetails .Input {
  background: var(--alice-blue);
  border-radius: 15px;
  border: none;
}
.DepositModal__WithdrawalDetails .Input,
.DepositModal__WithdrawalDetails .Input::placeholder {
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  display: flex;
  align-items: flex-end;
}
.DepositModal__WithdrawalDetails .Input::placeholder {
  color: var(--azureish-white);
}
.DepositModal__WithdrawalDetails__buttons {
  width: auto;
  margin: 0 -7.5px;
}
.DepositModal__WithdrawalDetails__buttons .Button {
  flex: 1 1 50%;
  margin: 0 7.5px;
}
.DepositModal__WithdrawalDetails__buttons .Button__label {
  font-weight: 600;
  font-size: 16px;
  line-height: 100%;
}
.adaptive .DepositModal__WithdrawalDetails .DepositModal__container {
  padding: 20px 20.52px;
}
.adaptive .DepositModal__WithdrawalDetails__title {
  font-weight: 600;
  font-size: 16px;
}
.adaptive .DepositModal__WithdrawalDetails__label {
  margin-bottom: 32px;
}
.adaptive .DepositModal__WithdrawalDetails__label:first-of-type {
  margin-bottom: 24px;
}
.adaptive .DepositModal__WithdrawalDetails__label > span {
  font-size: 14px;
}
.adaptive .DepositModal__WithdrawalDetails__buttons .Button {
  flex: 1 1 100%;
}
.adaptive .DepositModal__WithdrawalDetails__buttons .Button__label {
  font-size: 14px;
}
.adaptive .DepositModal__WithdrawalDetails__buttons .Button:not(:first-child) {
  margin-top: 10px;
}
