.DepositModal__Balance .DepositModal__container {
  padding: 32px 35.5px;
  width: 100%;
  max-width: 436px;
  box-sizing: border-box;
}
.DepositModal__Balance h3 {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: var(--dark-gunmetal);
  margin-bottom: 29px;
}
.DepositModal__Balance .CabinetSelect {
  margin-bottom: 24px;
}
.DepositModal__Balance .CabinetSelect__control {
  height: 60px;
  border-radius: 15px !important;
  border: none !important;
}
.DepositModal__Balance .CabinetSelect__menu {
  border-radius: 15px;
  overflow: hidden;
  box-shadow: none;
  background: var(--alice-blue);
}
.DepositModal__Balance .CabinetSelect__single-value {
  width: 100%;
}
.DepositModal__Balance .CabinetSelect__value-container {
  height: 31px;
}
.DepositModal__Balance .CabinetSelect__option {
  cursor: pointer;
  padding: 12px 18px 12px 12px;
}
.DepositModal__Balance .CabinetSelect__option:last-child {
  padding-bottom: 21px;
}
.DepositModal__Balance .CabinetSelect__option-icon {
  width: 31px;
  height: 31px;
}
.DepositModal__Balance .CabinetSelect__option-icon img {
  width: 100%;
  height: 100%;
}
.DepositModal__Balance .CabinetSelect__option-title,
.DepositModal__Balance .CabinetSelect__option-value {
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: var(--dark-black);
}
.DepositModal__Balance .CabinetSelect__indicators .isvg svg path {
  fill: #cdcfd7;
}
.DepositModal__Balance .DappInput {
  color: var(--dark-black);
  padding-left: 19px;
  background: var(--alice-blue);
  border-radius: 15px;
  border: none;
  border-color: transparent !important;
}
.DepositModal__Balance .DappInput__footer span {
  color: var(--royal-blue);
  cursor: pointer;
}
.DepositModal__Balance .DappInput__footer span:hover {
  opacity: 0.8;
}
.DepositModal__Balance .DappInput.error {
  border: 1px solid var(--orange-error) !important;
}
.DepositModal__Balance .DappInput.error,
.DepositModal__Balance .DappInput.error::placeholder {
  color: var(--orange-error);
}
.DepositModal__Balance .DappInput__wrapper {
  --input-height: 60px;
  margin-bottom: 24px;
}
.DepositModal__Balance .DappInput,
.DepositModal__Balance .DappInput::placeholder,
.DepositModal__Balance .DappInput__indicator {
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
}
.DepositModal__Balance .DappInput::placeholder,
.DepositModal__Balance .DappInput__indicator {
  color: var(--cool-gray);
}
.DepositModal__Balance .DappInput__prompt {
  position: absolute;
  right: 18px;
}
.DepositModal__Balance-buttons {
  margin-top: 24px;
}
.DepositModal__Balance-buttons .Button {
  height: 50px;
  flex: 1 1 40%;
}
.DepositModal__Balance-buttons .Button__label {
  font-weight: 600;
  font-size: 16px;
  line-height: 100%;
}
.DepositModal__Balance-buttons .Button:first-child {
  margin-right: 15px;
}
.DepositModal__Balance .TokenSelect__wrap .CabinetBlock {
  padding: 0;
}
.DepositModal__Balance__dropdown {
  display: flex;
  align-items: center;
  border-radius: 15px;
  padding: 9px 8px;
  margin-bottom: 24px;
  position: relative;
  background: var(--secondary-background);
  cursor: pointer;
}
.DepositModal__Balance__dropdown-rate {
  position: absolute;
  top: 100%;
  margin-top: 6px;
  left: 12px;
  font-size: 11px;
  color: var(--gray);
  font-weight: 500;
}
.DepositModal__Balance__track {
  position: absolute;
  top: 100%;
  right: 0;
  margin-top: -4px;
  text-decoration: none;
  font-size: 12px;
  color: var(--primary-blue);
}
.DepositModal__Balance__track:hover {
  text-decoration: underline;
  cursor: pointer;
}
.DepositModal__Balance__icon {
  cursor: pointer;
  border-radius: 50%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
  width: 41px;
  height: 41px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.DepositModal__Balance__icon .isvg,
.DepositModal__Balance__icon svg,
.DepositModal__Balance__icon img {
  width: 100%;
}
.DepositModal__Balance__select {
  margin-left: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 110%;
  cursor: pointer;
}
.DepositModal__Balance__select > span {
  color: #cdcfd7;
  margin-bottom: 4px;
}
.DepositModal__Balance__currency {
  display: flex;
  font-weight: 600;
  font-size: 18px;
  line-height: 110%;
  letter-spacing: -0.01em;
}
.DepositModal__Balance__currency .isvg {
  position: absolute;
  right: 12px;
  top: 50%;
  margin-top: -5px;
}
.adaptive .DepositModal__Balance .DepositModal__container {
  max-width: 100%;
  width: 100%;
  background: #fff;
  box-shadow: 0px 15px 75px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  padding: 20px 18.5px;
}
.adaptive .DepositModal__Balance h3 {
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 23px;
}
.adaptive .DepositModal__Balance .CabinetSelect__option-icon {
  margin-right: 5px;
  width: 25px;
  height: 25px;
}
.adaptive .DepositModal__Balance .CabinetSelect__option-title,
.adaptive .DepositModal__Balance .CabinetSelect__option-value {
  font-size: 14px;
}
.adaptive .DepositModal__Balance .DappInput,
.adaptive .DepositModal__Balance .DappInput::placeholder,
.adaptive .DepositModal__Balance .DappInput__indicator {
  font-size: 14px;
}
.adaptive .DepositModal__Balance .DappInput__indicator {
  padding-right: 11.5px;
}
.adaptive .DepositModal__Balance-buttons {
  margin: 24px -7.5px 0;
  width: auto;
}
.adaptive .DepositModal__Balance-buttons .Button {
  min-width: 210px;
  margin: 0 7.5px;
}
.adaptive .DepositModal__Balance-buttons .Button:not(:last-child) {
  margin-bottom: 10px;
}
.adaptive .DepositModal__Balance-buttons .Button__label {
  font-size: 14px;
  line-height: 100%;
}
