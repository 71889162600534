.DepositModal__ChooseBank {
  padding: 24px 32px;
  flex: 1;
  box-sizing: border-box;
}
.DepositModal__ChooseBank__title {
  margin-bottom: 24px;
}
.DepositModal__ChooseBank__loading {
  min-height: 608px;
  position: relative;
  width: 100%;
  height: 100%;
}
.DepositModal__ChooseBank__empty {
  margin-top: 136px;
  margin-bottom: 148px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.DepositModal__ChooseBank__empty h3 {
  margin-bottom: 29px;
  font-size: 20px;
}
.DepositModal__ChooseBank__empty svg {
  width: 120px;
  height: 120px;
}
.DepositModal__ChooseBank .ScrollbarBox-container {
  height: 100%;
}
.DepositModal__ChooseBank-items {
  padding-top: 24px;
  margin-bottom: 30px;
  border-top: 1px solid #c4c4c4;
}
.DepositModal__ChooseBank-item {
  width: 100%;
  padding: 12.5px 10px;
  background: var(--alice-blue);
  border-radius: var(--radius-panel);
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  cursor: pointer;
}
.DepositModal__ChooseBank-item > * {
  width: 50%;
  height: 25px;
  flex: 1;
}
.DepositModal__ChooseBank-item span {
  text-align: right;
}
.DepositModal__ChooseBank-item .Row .isvg {
  margin-left: 10px;
}
.DepositModal__ChooseBank-item .Row .isvg svg {
  height: 11px;
  width: 11px;
}
.DepositModal__ChooseBank-item:not(:last-child) {
  margin-bottom: 10px;
}
.DepositModal__ChooseBank .bankIcon {
  height: 25px;
}
.DepositModal__ChooseBank .buttons {
  margin-top: auto;
}
.DepositModal__ChooseBank .buttons .Button {
  min-width: 155px;
}
.DepositModal__ChooseBank.withdrawal .DepositModal__ChooseBank-items {
  padding-top: 0;
  margin-bottom: 0;
  border: none;
}
.DepositModal__ChooseBank.withdrawal {
  padding: 30px 32px 34px;
}
.DepositModal__ChooseBank.withdrawal .DepositModal__ChooseBank__title {
  margin-bottom: 24px;
}
.DepositModal__ChooseBank.withdrawal .DepositModal__ChooseBank__title h3 {
  font-weight: 500;
  font-size: 20px;
  line-height: 110%;
  letter-spacing: -0.01em;
}
.DepositModal__ChooseBank__close {
  top: 0;
  right: 0;
}
.adaptive .DepositModal__ChooseBank {
  padding: 24px 32px;
}
.adaptive .DepositModal__ChooseBank__empty {
  margin: 150px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.adaptive .DepositModal__ChooseBank__empty h3 {
  margin: 0 0 10px;
  color: var(--cool-gray);
}
.adaptive .DepositModal__ChooseBank-items .ScrollbarsCustom {
  height: auto !important;
}
.adaptive .DepositModal__ChooseBank__loading {
  min-height: 0px;
  margin: 150px 0;
}
.adaptive .DepositModal__ChooseBank.withdrawal {
  padding: 20px;
}
.adaptive .DepositModal__ChooseBank.withdrawal .DepositModal__ChooseBank__title h3 {
  font-weight: 600;
  font-size: 14px;
}
.adaptive .DepositModal__ChooseBank.withdrawal .DepositModal__ChooseBank-items {
  padding: 0;
}
