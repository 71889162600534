.SiteTokenScreen__RoadMap {
  text-align: center;
  width: 100%;
  max-width: 800px;
  margin: var(--block-margin) auto;
  opacity: 0;
  transition: opacity 1s ease;
}
.SiteTokenScreen__RoadMap.isVisible {
  opacity: 1;
  transform: translate(0);
}
.SiteTokenScreen__RoadMap h2 {
  font-size: var(--title1-font-size);
  line-height: var(--title1-line-height);
  margin-bottom: 24px;
}
.SiteTokenScreen__RoadMap h3 {
  font-size: var(--title3-font-size);
  line-height: var(--title3-line-height);
  opacity: 0.7;
}
.SiteTokenScreen__RoadMap .SiteTokenScreen__RoadMap__list {
  position: relative;
}
.SiteTokenScreen__RoadMap .SiteTokenScreen__RoadMap__list:before {
  position: absolute;
  left: 0;
  top: 30px;
  bottom: 30px;
  content: '';
  display: block;
  width: 2px;
  background: #f0f;
  background: repeating-linear-gradient(transparent, transparent 9px, var(--dark-gray) 9px, var(--dark-gray) 18px);
}
@media (min-width: 800px) {
  .SiteTokenScreen__RoadMap .SiteTokenScreen__RoadMap__list:before {
    left: calc(50% - 1px);
  }
}
.SiteTokenScreen__RoadMap .SiteTokenScreen__RoadMap__list .SiteTokenScreen__RoadMap__list__item {
  width: 50%;
  display: flex;
  text-align: left;
  margin: 80px 0;
}
.SiteTokenScreen__RoadMap .SiteTokenScreen__RoadMap__list .SiteTokenScreen__RoadMap__list__item:nth-child(1) small,
.SiteTokenScreen__RoadMap .SiteTokenScreen__RoadMap__list .SiteTokenScreen__RoadMap__list__item:nth-child(1) strong,
.SiteTokenScreen__RoadMap .SiteTokenScreen__RoadMap__list .SiteTokenScreen__RoadMap__list__item:nth-child(1) .price {
  transition-delay: 1s;
}
.SiteTokenScreen__RoadMap .SiteTokenScreen__RoadMap__list .SiteTokenScreen__RoadMap__list__item:nth-child(2) small,
.SiteTokenScreen__RoadMap .SiteTokenScreen__RoadMap__list .SiteTokenScreen__RoadMap__list__item:nth-child(2) strong,
.SiteTokenScreen__RoadMap .SiteTokenScreen__RoadMap__list .SiteTokenScreen__RoadMap__list__item:nth-child(2) .price {
  transition-delay: 1.7s;
}
.SiteTokenScreen__RoadMap .SiteTokenScreen__RoadMap__list .SiteTokenScreen__RoadMap__list__item:nth-child(3) small,
.SiteTokenScreen__RoadMap .SiteTokenScreen__RoadMap__list .SiteTokenScreen__RoadMap__list__item:nth-child(3) strong,
.SiteTokenScreen__RoadMap .SiteTokenScreen__RoadMap__list .SiteTokenScreen__RoadMap__list__item:nth-child(3) .price {
  transition-delay: 2.4s;
}
.SiteTokenScreen__RoadMap .SiteTokenScreen__RoadMap__list .SiteTokenScreen__RoadMap__list__item:nth-child(4) small,
.SiteTokenScreen__RoadMap .SiteTokenScreen__RoadMap__list .SiteTokenScreen__RoadMap__list__item:nth-child(4) strong,
.SiteTokenScreen__RoadMap .SiteTokenScreen__RoadMap__list .SiteTokenScreen__RoadMap__list__item:nth-child(4) .price {
  transition-delay: 3.1s;
}
.SiteTokenScreen__RoadMap .SiteTokenScreen__RoadMap__list .SiteTokenScreen__RoadMap__list__item:nth-child(5) small,
.SiteTokenScreen__RoadMap .SiteTokenScreen__RoadMap__list .SiteTokenScreen__RoadMap__list__item:nth-child(5) strong,
.SiteTokenScreen__RoadMap .SiteTokenScreen__RoadMap__list .SiteTokenScreen__RoadMap__list__item:nth-child(5) .price {
  transition-delay: 3.8s;
}
@media (min-width: 800px) {
  .SiteTokenScreen__RoadMap .SiteTokenScreen__RoadMap__list .SiteTokenScreen__RoadMap__list__item:nth-child(2n) {
    text-align: right;
    flex-direction: row-reverse;
    margin-left: 41px;
  }
  .SiteTokenScreen__RoadMap .SiteTokenScreen__RoadMap__list .SiteTokenScreen__RoadMap__list__item:nth-child(2n) small,
  .SiteTokenScreen__RoadMap .SiteTokenScreen__RoadMap__list .SiteTokenScreen__RoadMap__list__item:nth-child(2n) strong,
  .SiteTokenScreen__RoadMap .SiteTokenScreen__RoadMap__list .SiteTokenScreen__RoadMap__list__item:nth-child(2n) .price {
    transform: translate(-70px);
  }
  .SiteTokenScreen__RoadMap .SiteTokenScreen__RoadMap__list .SiteTokenScreen__RoadMap__list__item:nth-child(2n-1) {
    margin-left: calc(50% - 41px);
  }
  .SiteTokenScreen__RoadMap .SiteTokenScreen__RoadMap__list .SiteTokenScreen__RoadMap__list__item:nth-child(2n-1) small,
  .SiteTokenScreen__RoadMap .SiteTokenScreen__RoadMap__list .SiteTokenScreen__RoadMap__list__item:nth-child(2n-1) strong,
  .SiteTokenScreen__RoadMap .SiteTokenScreen__RoadMap__list .SiteTokenScreen__RoadMap__list__item:nth-child(2n-1) .price {
    transform: translate(70px);
  }
}
.SiteTokenScreen__RoadMap .SiteTokenScreen__RoadMap__list .SiteTokenScreen__RoadMap__list__item:before {
  margin: auto 32px;
  content: '';
  display: block;
  height: 18px;
  width: 18px;
  background: var(--orange-gradient);
  box-shadow: 0 0 16px rgba(255, 160, 65, 0.25);
  transform: scale(0.7, 1) rotate(45deg);
}
.SiteTokenScreen__RoadMap .SiteTokenScreen__RoadMap__list .SiteTokenScreen__RoadMap__list__item.active:before {
  background: var(--blue-gradient);
  box-shadow: 0 0 16px rgba(100, 140, 245, 0.25);
  transform: scale(-0.7, 1) rotate(45deg);
}
.SiteTokenScreen__RoadMap .SiteTokenScreen__RoadMap__list .SiteTokenScreen__RoadMap__list__item small {
  display: block;
  font-size: inherit;
  line-height: inherit;
  color: var(--light-gray);
  opacity: 0;
  transition: opacity 1s ease, transform 1s ease;
}
.SiteTokenScreen__RoadMap .SiteTokenScreen__RoadMap__list .SiteTokenScreen__RoadMap__list__item strong {
  display: block;
  font-size: var(--title2-font-size);
  line-height: var(--title2-line-height);
  opacity: 0;
  transition: opacity 1s ease, transform 1.3s ease;
}
.SiteTokenScreen__RoadMap .SiteTokenScreen__RoadMap__list .SiteTokenScreen__RoadMap__list__item .price {
  display: block;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  opacity: 0;
  transition: opacity 1s ease, transform 1.6s ease;
}
.SiteTokenScreen__RoadMap.isVisible .SiteTokenScreen__RoadMap__list__item small,
.SiteTokenScreen__RoadMap.isVisible .SiteTokenScreen__RoadMap__list__item strong,
.SiteTokenScreen__RoadMap.isVisible .SiteTokenScreen__RoadMap__list__item .price {
  opacity: 1;
  transform: translate(0) !important;
}
@media (max-width: 800px) {
  .SiteTokenScreen__RoadMap {
    text-align: left;
  }
  .SiteTokenScreen__RoadMap .SiteTokenScreen__RoadMap__list:before {
    left: 8px;
  }
  .SiteTokenScreen__RoadMap .SiteTokenScreen__RoadMap__list .SiteTokenScreen__RoadMap__list__item {
    width: 100%;
    margin: 54px 0;
  }
  .SiteTokenScreen__RoadMap .SiteTokenScreen__RoadMap__list .SiteTokenScreen__RoadMap__list__item:before {
    margin-left: 0;
  }
}
