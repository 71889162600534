.ScrollBox {
  overflow: hidden;
  position: relative;
}
.ScrollBox .ScrollBox__contentWrapper {
  height: inherit;
  overflow-y: scroll;
}
.ScrollBox.init .ScrollBox__contentWrapper {
  overflow-y: hidden;
}
.ScrollBox .ScrollBox__bar {
  top: 0;
  position: absolute;
  width: 7px;
  height: 100%;
  right: 0;
}
.ScrollBox .ScrollBox__bar.hidden {
  display: none;
}
.ScrollBox .ScrollBox__bar__button {
  box-sizing: border-box;
  padding: 1px;
  position: absolute;
  height: 100px;
  width: 100%;
}
.ScrollBox .ScrollBox__bar__button::before {
  content: "";
  display: block;
  background: rgba(0, 0, 0, 0.15);
  height: 100%;
  width: 100%;
  border-radius: 10px;
}
.ScrollBox .ScrollBox__bar__button:hover::before,
.ScrollBox .ScrollBox__bar__button.active::before {
  background: rgba(0, 0, 0, 0.3);
}
