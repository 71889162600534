.WithdrawalModal {
  width: 384px;
  padding: 24px 48px 32px;
  box-sizing: border-box;
}
.WithdrawalModal__success_icon {
  margin: 30px auto 0;
  width: 120px;
  height: 120px;
}
.WithdrawalModal.success {
  text-align: center;
}
.WithdrawalModal.success h4 {
  color: var(--green);
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
}
.WithdrawalModal.success p {
  color: var(--text-black);
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  margin: 16px 0 80px;
}
.WithdrawalModal__icon {
  position: absolute;
  top: 32px;
  left: -16px;
}
.WithdrawalModal__row {
  margin-top: 24px;
}
.WithdrawalModal__row_amount {
  display: flex;
}
.WithdrawalModal__row_amount .Button {
  margin-left: 16px;
  flex: 0 0 auto;
}
.WithdrawalModal__row_amount__input {
  flex: 1;
}
.WithdrawalModal__button_wrap {
  margin-top: 40px;
  text-align: center;
}
.WithdrawalModal__info_row {
  margin-top: 24px;
  text-align: center;
}
.WithdrawalModal__info_row:first-child {
  margin-top: 0;
}
.WithdrawalModal__info_row__title {
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: var(--black);
}
.WithdrawalModal__info_row__caption {
  margin-top: 8px;
  font-weight: 300;
  font-size: 13px;
  line-height: 24px;
  color: var(--black);
}
.WithdrawalModal__without_drop_info {
  margin: 24px 0;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
}
.adaptive .WithdrawalModal {
  padding: 40px 16px 24px;
  width: 100%;
}
.adaptive .WithdrawalModal__wrapper {
  padding-top: 80px;
}
.adaptive .WithdrawalModal .Button {
  width: auto;
}
.adaptive .WithdrawalModal__icon {
  left: 0;
  right: 0;
  top: -16px;
  margin: 0 auto;
}
