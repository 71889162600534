.CabinetProfileScreen__wallets {
  display: grid;
  flex-wrap: wrap;
  grid-template-columns: 33.3% 33.3% 33.3%;
  width: calc(100% + 28px);
  margin-bottom: var(--gap-size);
}
.CabinetProfileScreen__dashboard .CabinetProfileScreen__dashboard__wrapper {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 24.5px;
}
.CabinetProfileScreen__height_padding {
  height: 24px;
  width: 100%;
}
.CabinetStartProfileScreen__wallets {
  display: grid;
  flex-wrap: wrap;
  grid-template-columns: 33.3% 33.3% 33.3%;
  width: calc(100% + 28px);
}
.CabinetStartProfileScreen__actionCards {
  display: grid;
  flex-wrap: wrap;
  grid-template-columns: 33.3% 33.3% 33.3%;
  margin-top: 24px;
  width: calc(100% + 28px);
}
.CabinetStartProfileScreen__right {
  grid-template-columns: 100%;
  margin-top: 0;
}
.CabinetStartProfileScreen__right .DashboardItemAction {
  margin-bottom: 24px;
}
.CabinetStartProfileScreen__right .DashboardItemAction .DashboardItemAction__description {
  font-size: 14px;
}
.CabinetStartProfileScreen__right .DashboardItemAction .DashboardItemAction__button {
  width: 100%;
  display: flex;
  margin-top: 40px;
}
.CabinetStartProfileScreen__right .DashboardItemAction .DashboardItemAction__button .Button {
  width: 170px;
  margin: auto;
}
.CabinetProfileScreen__height24 {
  height: var(--gap-size);
  width: 100%;
}
.ReferralLinkBlock__main {
  width: 100%;
  box-sizing: border-box;
  padding: 0 40px 40px 40px;
  margin-top: 24px !important;
}
.ReferralLinkBlock__main .ReferralLinkBlock__left {
  width: calc(100% - 200px);
  display: flex;
}
.ReferralLinkBlock__main .ReferralLinkBlock__right {
  width: 200px;
  display: flex;
}
.ReferralLinkBlock__main .ReferralLinkBlock__line:first-child {
  padding-top: 24px !important;
}
.ReferralLinkBlock__main .ReferralLinkBlock__line {
  width: 100%;
  display: flex;
}
.ReferralLinkBlock__main .ReferralLinkBlock__line .ReferralLinkBlock__left .ReferralLinkBlock__description,
.ReferralLinkBlock__main .ReferralLinkBlock__line .ReferralLinkBlock__right .ReferralLinkBlock__description {
  width: 100%;
  box-sizing: border-box;
  padding-right: 40px;
  font-size: 13px;
  line-height: 24px;
}
.ReferralLinkBlock__main .ReferralLinkBlock__line .ReferralLinkBlock__left .ReferralLinkBlock__right_social,
.ReferralLinkBlock__main .ReferralLinkBlock__line .ReferralLinkBlock__right .ReferralLinkBlock__right_social {
  width: 64px;
  height: 64px;
  margin-left: -16px;
  cursor: pointer;
}
.ReferralLinkBlock__main .ReferralLinkBlock__line .ReferralLinkBlock__left .ReferralLinkBlock__right_social:first-child,
.ReferralLinkBlock__main .ReferralLinkBlock__line .ReferralLinkBlock__right .ReferralLinkBlock__right_social:first-child {
  margin-left: 0;
}
.ReferralLinkBlock__main .ReferralLinkBlock__line .ReferralLinkBlock__left .ReferralLinkBlock__right_button,
.ReferralLinkBlock__main .ReferralLinkBlock__line .ReferralLinkBlock__right .ReferralLinkBlock__right_button {
  width: 100%;
  margin: 0 auto;
}
.ReferralLinkBlock__main .ReferralLinkBlock__line .ReferralLinkBlock__left .ReferralLinkBlock__right_button .Button,
.ReferralLinkBlock__main .ReferralLinkBlock__line .ReferralLinkBlock__right .ReferralLinkBlock__right_button .Button {
  width: 100%;
}
.ReferralLinkBlock__main .ReferralLinkBlock__line .ReferralLinkBlock__left .h1 {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  display: flex;
}
.ReferralLinkBlock__main .ReferralLinkBlock__line .ReferralLinkBlock__left .h1 span {
  margin: auto 0;
}
.adaptive .CabinetProfileScreen__wallets {
  grid: none;
  width: 100% !important;
}
.adaptive .CabinetProfileScreen__wallets .WalletBox {
  margin-right: 0;
}
.adaptive .CabinetProfileScreen__wallets .WalletBox .WalletBox__icon {
  position: absolute;
  transform: none;
  left: 10px;
}
.adaptive .CabinetProfileScreen__wallets .WalletBox .WalletBox__content {
  box-sizing: border-box;
  box-shadow: none;
}
.adaptive .CabinetProfileScreen__wallets .WalletBox .ContentBox {
  box-shadow: none;
  border-radius: var(--radius-panel);
}
.adaptive .CabinetProfileScreen__wallets .WalletBox .ContentBox .ContentBox__content {
  padding-left: 72px;
  padding-right: 24px;
}
.adaptive .CabinetProfileScreen__wallets .CabinetProfileScreen__walletsContentBox {
  background-color: var(--white);
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
}
.adaptive .WalletBalance {
  padding-block-end: 0;
}
.adaptive .WalletBalance__selected_wallet {
  padding-inline-start: 24px !important;
}
.adaptive .WalletBalance .WalletBalance__convert {
  position: relative;
  right: 0;
  top: 0;
  padding: 0;
}
.adaptive .WalletBalance .WalletBalance__convert span {
  color: var(--almost-black);
}
.adaptive .CabinetProfileScreen__dashboard {
  margin: var(--gap-size) 0;
  width: 100%;
  height: 312px;
  overflow: hidden;
  overflow-x: scroll;
  box-sizing: border-box;
}
.adaptive .CabinetProfileScreen__dashboard .CabinetProfileScreen__dashboard__wrapper {
  width: 100%;
  display: flex;
  margin: 0 calc(var(--gap-size) / 2);
}
.adaptive .CabinetProfileScreen__dashboard .CabinetProfileScreen__dashboard__wrapper > div {
  flex: 0 0 auto;
  margin: 0 calc(var(--gap-size) / 2);
  width: 192px;
}
