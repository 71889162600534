.Search {
  display: flex;
  background: var(--orange-gradient);
  height: 68px;
  border-radius: 34px;
  box-sizing: border-box;
  padding: 2px;
  position: relative;
  overflow: hidden;
}
.Search.lite {
  height: 52px;
  padding: 0;
  border-radius: var(--radius-panel);
  box-shadow: 1px 4px 24px rgba(0, 0, 0, 0.05);
  background: none;
}
.Search.simple {
  height: 38px;
  border: 1px solid var(--extra-light-gray);
  border-radius: 7px;
}
.Search__input {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 15px;
  color: var(--text-black);
  background: var(--primary-background);
  padding: 0 100px 0 38px;
  width: 100%;
  border: 0;
  outline: none;
  border-radius: 34px;
}
.Search.lite .Search__input {
  padding: 0 20px 0 47px;
  height: 100%;
  border-radius: var(--radius-panel);
}
.Search.simple .Search__input {
  background: var(--alice-blue);
  padding: 0 9px 0 31px;
  font-size: 14px;
}
.Search.simple .Search__input::placeholder {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #bdbdbd;
}
.Search.simple.right .Search__input {
  padding: 0 29px 0 12px;
}
.Search.disabled .Search__input {
  pointer-events: none;
}
.Search__input::placeholder {
  color: var(--text-black);
}
.Search__button {
  position: absolute;
  top: 0;
  height: 100%;
  right: 0;
  width: 89px;
  cursor: pointer;
  border-radius: 34px;
  z-index: 2;
  transition: opacity 150ms ease-in-out;
}
.Search__button:after {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 29px;
  height: 29px;
  transform: translate(-50%, -50%);
  background: url('../../asset/search_29.svg') no-repeat center;
  background-size: 29px;
}
.Search__button.active {
  opacity: 0;
  background: var(--orange-gradient);
  z-index: 3;
}
.Search__button.active:after {
  background-image: url('../../asset/search_white_29.svg');
}
.Search__input:valid ~ .Search__button.active {
  opacity: 1;
}
.Search__icon {
  flex: 0 0 1;
  width: 17px;
  height: 17px;
  background: url('../../asset/search_29.svg') no-repeat center / contain;
  margin-right: 10px;
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
}
.Search.simple .Search__icon {
  left: 9px;
}
.Search.simple.right .Search__icon {
  left: auto;
  right: 12px;
  margin-right: 0;
}
