.DappInput__wrapper {
  --input-height: 48px;
  width: 100%;
  position: relative;
  border-radius: var(--radius-panel);
}
.DappInput__wrapper .DappInput__description {
  font-size: 11px;
  line-height: 16px;
  margin: 8px 16px;
  color: var(--text-black);
}
.DappInput__wrapper .DappInput__footer {
  width: 100%;
  margin-top: 7px;
  padding-left: 13px;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  box-sizing: border-box;
}
.DappInput {
  height: var(--input-height);
  color: var(--almost-black);
  background: var(--secondary-background);
  border-radius: var(--radius-panel);
  font-size: 15px;
  line-height: 20px;
  box-sizing: border-box;
  border: 1px solid var(--light-gray);
  outline: none;
  padding: 14px 16px;
  transition: all 150ms ease-in-out;
  width: 100%;
  -webkit-appearance: none;
}
.DappInput::-webkit-outer-spin-button,
.DappInput::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.DappInput::placeholder {
  color: var(--gray);
}
.DappInput.password {
  padding-right: 35px !important;
}
.DappInput:focus {
  border-color: var(--primary-blue) !important;
  box-shadow: none;
}
.DappInput.multiLine {
  height: 100px;
  resize: none;
  padding: 12px 16px;
}
.DappInput[disabled] {
  background: var(--primary-background);
  color: var(--dark-gray);
}
.DappInput__wrapper.small {
  --input-height: 24px;
}
.DappInput__wrapper.small .DappInput {
  border-radius: 6px;
  font-weight: 500;
  font-size: 11px;
  line-height: 14px;
  padding: 4px 8px;
  border-width: 1px;
}
.DappInput__wrapper.small .DappInput__description {
  margin: 4px 8px;
}
.DappInput__wrapper.small .DappInput__indicator {
  padding-right: 12px;
}
.DappInput.error {
  border-color: var(--orange-error) !important;
  color: var(--orange-error) !important;
  transition: all 1s ease;
}
.DappInput.error::placeholder {
  color: var(--orange-error);
}
.DappInput__indicator {
  height: var(--input-height);
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 16px;
  font-size: 14px;
  line-height: 20px;
  color: var(--text-black);
}
.DappInput__indicator .isvg {
  line-height: 0;
}
.DappInput__display_password_button {
  position: absolute;
  right: 8px;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 24px;
  height: 24px;
  cursor: pointer;
  color: #d9d4cf;
  user-select: none;
}
.DappInput__reliability {
  color: var(--primary-orange);
  margin: 6px 16px;
  display: flex;
  align-items: center;
}
.DappInput__reliability .DappInput__reliability__label {
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  margin-right: 8px;
}
.DappInput__reliability .DappInput__reliability__indicator {
  flex: 1;
  height: 2px;
  border-radius: 1px;
  background: var(--extra-light-gray);
}
.DappInput__reliability .DappInput__reliability__indicator .DappInput__reliability__indicator__fill {
  width: 40%;
  background: currentColor;
  height: inherit;
}
