body {
  --landing-text-size: 16px;
  --landing-text-weight: 500;
  --landing-title-size: 52px;
  --landing-title-line-height: 61px;
  --landing-title-size-mobile: 35px;
  --landing-title-line-height: 38.5px;
  --landing-title-weight: 600;
  --landing-color-black: #2d313f;
  --landing-color-gray: #939393;
  --landing-color-blue-gray: #dadfe7;
  --blue-light-gradient-color-start: #78a0ff;
  --blue-light-gradient-color-end: #5078e5;
  --blue-light-gradient: linear-gradient(135deg, var(--blue-light-gradient-color-start) 0%, var(--blue-light-gradient-color-end) 100%),
    #224dcf;
  --landing-bg-white: #f7f9fc;
}
