.Separator {
  --vertical-margin: 8px;
  --horisontal-margin: 12px;
  display: block;
  height: 1px;
  background: var(--black);
  opacity: 0.2;
  margin: var(--vertical-margin) var(--horisontal-margin);
  width: calc(100% - var(--horisontal-margin) * 2);
  border: none;
  transform-origin: center top;
}
@media (min-resolution: 2dppx) {
  .Separator {
    transform: scaleY(0.5);
  }
}
@media (min-resolution: 3dppx) {
  .Separator {
    transform: scaleY(0.32);
  }
}
