.ConnectToWalletModal .TransactionModal__header {
  padding: 0;
}
.ConnectToWalletModal .Modal__box {
  padding: 32px 30px;
}
.ConnectToWalletModal .row {
  display: flex;
  justify-content: space-between;
}
.ConnectToWalletModal__wallet {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  flex: 1 1 200px;
  padding: 20px 0;
  background: #e5ebfc;
  border-radius: var(--radius-panel);
  color: var(--primary-dark-blue);
  margin-bottom: 18px;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  transition: all 0.1s;
  cursor: pointer;
}
.ConnectToWalletModal__wallet:hover {
  opacity: 0.92;
  box-shadow: 0 4px 10px rgba(229, 235, 252, 0.7);
}
.ConnectToWalletModal__wallet:not(:last-child) {
  margin-right: 10px;
}
.ConnectToWalletModal__wallet__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin-bottom: 14px;
  border-radius: 50%;
}
.ConnectToWalletModal__wallet__icon .isvg {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ConnectToWalletModal__wallet.disabled {
  opacity: 0.5;
  pointer-events: none;
}
.ConnectToWalletModal .action-text {
  display: flex;
  align-items: center;
  margin-top: 12.5px;
  color: var(--royal-blue);
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  cursor: pointer;
}
.ConnectToWalletModal .action-text:hover {
  opacity: 0.9;
}
.ConnectToWalletModal .action-text .isvg {
  display: flex;
  align-items: center;
  margin-right: 9.5px;
}
.ConnectToWalletModal .action-text .isvg circle {
  stroke: var(--royal-blue);
}
.ConnectToWalletModal .action-text .isvg path {
  fill: var(--royal-blue);
}
.adaptive .ConnectToWalletModal .TransactionModal-Bottom {
  padding: 32px 22px 25px;
}
.adaptive .ConnectToWalletModal .TransactionModal__header {
  margin-bottom: 19px;
  padding: 0 2.5px;
}
.adaptive .ConnectToWalletModal__wallets,
.adaptive .ConnectToWalletModal .action-text {
  padding: 0 4px;
}
.adaptive .ConnectToWalletModal__wallet {
  padding: 23px 0;
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 20px;
}
.adaptive .ConnectToWalletModal__wallet__icon {
  margin-bottom: 10px;
}
.adaptive .ConnectToWalletModal .action-text {
  margin-top: 13.5px;
}
.walletconnect-qrcode__image {
  max-height: 50vh !important;
}
