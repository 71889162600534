.Method__params h2 {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 16px;
}
.Method__params .Method__params__list {
  margin: 16px -40px -33px;
}
.Method__params .Method__params__list .Method__params__list__line {
  display: flex;
  padding: 16px 40px;
}
.Method__params .Method__params__list .Method__params__list__line:nth-child(2n-1) {
  background: var(--alice-blue);
}
.Method__params .Method__params__list .Method__params__list__line .Method__params__description {
  flex: 1;
}
.Method__params .Method__params__list .Method__params__list__line .Method__params__description p {
  font-size: 13px;
  line-height: 24px;
}
.Method__params .Method__params__list .Method__params__list__line .Method__params__param {
  flex: 0 0 auto;
  width: 140px;
  margin-right: 40px;
}
.Method__params .Method__params__list .Method__params__list__line .Method__params__param strong {
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  display: block;
}
.Method__params .Method__params__list .Method__params__list__line .Method__params__param small {
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
}
