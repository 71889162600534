.TransactionHistory__wrap {
  min-height: 100%;
  padding: 32px;
  box-sizing: border-box;
}
.TransactionHistory__title {
  font-weight: 700;
  font-size: 24px;
  line-height: 110%;
  color: var(--black-gunmetal);
}
.TransactionHistory__description {
  max-width: 436px;
  margin: 8px 0 12px;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: -0.01em;
  color: var(--cool-gray);
}
.TransactionHistory__item {
  text-transform: capitalize;
}
.TransactionHistory .WalletIcon,
.TransactionHistory .DoubleWallets {
  margin-right: 5px;
}
.TransactionHistory__icon-export {
  margin-left: 4px;
}
.TransactionHistory__icon-export .isvg {
  display: flex;
  align-items: center;
  justify-content: center;
}
.TransactionHistory__icon-arrow {
  margin: 0 6px;
  display: flex;
  justify-content: center;
}
.TransactionHistory__icon-arrow .isvg {
  margin: 0 !important;
}
.TransactionHistory__icon-sortArrow {
  margin-left: 4px;
}
.TransactionHistory__icon-sortArrow.up svg {
  transform: rotate(-180deg);
}
.TransactionHistory__icon-sortArrow .isvg svg {
  width: 8px;
  height: 8px;
}
.TransactionHistory__table {
  position: relative;
}
.TransactionHistory__table__container {
  min-height: 400px;
}
.TransactionHistory__table__container.blur {
  filter: blur(2.5px);
  user-select: none;
  margin-top: 28px;
  pointer-events: none;
}
.adaptive .TransactionHistory__wrap {
  padding: 23px 18px;
}
.adaptive .TransactionHistory__description {
  font-size: 12px;
  margin-bottom: 16px;
}
.adaptive .TransactionHistory__table__container.blur {
  margin-top: 0;
}
