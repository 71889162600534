.InviteAgent {
  padding: 40px;
}
.InviteAgent__title {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}
.InviteAgent__form {
  display: flex;
  margin-top: 40px;
}
.InviteAgent__form .Button {
  margin-left: 16px;
  flex: 0 0 auto;
}
