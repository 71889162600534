.Modal-AdaptiveTop .Modal-AdaptiveTop-content {
  padding: 50px 18px 30px;
  background: #fff;
  box-sizing: border-box;
  border-radius: var(--radius-panel);
}
.Modal-AdaptiveTop .Modal-AdaptiveTop-content h3 {
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: var(--dark-gunmetal);
}
.Modal-AdaptiveTop__blocks {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 30px;
}
.Modal-AdaptiveTop .Modal-AdaptiveTop-block__title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}
.Modal-AdaptiveTop .Modal-AdaptiveTop-block__title h4 {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: var(--cool-gray);
}
