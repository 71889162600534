.MainLandingWrapperHeader .burger-menu {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.MainLandingWrapperHeader .burger-menu span {
  width: 27px;
  height: 2px;
  background: #0a1539;
}
.MainLandingWrapperHeader .burger-menu::after {
  content: '';
  margin-top: 4px;
  width: 15px;
  height: 2px;
  background: #0a1539;
  opacity: 0.4;
}
