.Collapse .ContentBox__content {
  padding: 0;
}
.Collapse .Collapse__header {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 16px;
}
.Collapse .Collapse__header .Collapse__title {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}
.Collapse .Collapse__header .Collapse__arrow {
  width: 24px;
  height: 25px;
  margin-right: 4px;
  font-size: 0;
  transition: transform 150ms ease-in-out;
  color: var(--text-black);
}
.Collapse .Collapse__header .Collapse__controls {
  margin-left: auto;
}
.Collapse .Collapse__content {
  padding: 16px;
  padding-top: 0;
}
.Collapse.isOpen .Collapse__arrow {
  transform: rotate(-180deg);
}
.adaptive .Collapse__header {
  position: relative;
}
.adaptive .Collapse__header .Collapse__title {
  margin: 0 auto;
}
.adaptive .Collapse__header .Collapse__arrow {
  right: 16px;
  position: absolute;
}
