@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
.DeleteWalletModal-loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 40px 40px;
}
.DeleteWalletModal-loading .isvg svg {
  margin-bottom: 16px;
  animation: spin 1s linear infinite;
}
.DeleteWalletModal-loading .isvg svg path {
  fill: #fabe4c;
}
.DeleteWalletModal-form {
  margin-top: 40px;
}
.DeleteWalletModal-form h3 {
  font-size: 13px;
}
.DeleteWalletModal-form p {
  max-width: 640px;
  margin: 0 0 40px 0;
  font-size: 12px;
  line-height: 1.5em;
}
.DeleteWalletModal-form .Input__wrapper {
  margin-bottom: 20px;
}
.DeleteWalletModal-form .Input__wrapper .Input {
  min-width: 640px;
}
.adaptive .DeleteWalletModal-form .Input__wrapper .Input {
  min-width: initial;
  width: 100%;
}
.DeleteWalletModal-form .Input__wrapper .Input__description {
  margin: 16px 0 0;
}
.DeleteWalletModal-form .Button {
  margin: 8px auto 20px;
}
