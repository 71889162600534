.OperationModal__footer {
  border-top: 1px solid var(--extra-light-gray);
  padding-top: 24px;
  display: flex;
}
.OperationModal__footer__right {
  margin-left: auto;
  text-align: right;
}
.OperationModal__footer__label {
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: var(--slate-gray);
  margin-bottom: 4px;
}
.OperationModal__footer__value {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var(--text-black);
}
