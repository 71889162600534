.p2p-orders .orders-information {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
  box-sizing: border-box;
  padding: 40px 50px;
}
.p2p-orders .orders-information__title {
  gap: 25px 20px;
}
.p2p-orders .orders-information__cards {
  gap: 30px 65px;
}
.p2p-orders .orders-information-card {
  flex: 1 1 calc(100% / 3 - 65px);
  min-width: 190px;
}
.p2p-orders .orders-information-card img {
  margin-bottom: 18px;
}
.p2p-orders .orders-information-card h5 {
  margin-bottom: 10px;
}
.p2p-orders .orders-information-card p {
  line-height: 18px;
}
.adaptive .p2p-orders .orders-information {
  padding: 30px 18px;
}
.adaptive .p2p-orders .orders-information__title {
  flex-wrap: wrap-reverse !important;
}
.adaptive .p2p-orders .orders-information .SwitchTabs {
  width: 100%;
}
.adaptive .p2p-orders .orders-information-card {
  flex: 1 1 100%;
}
