.UploadAvatar__imgPreview .img {
  width: 112px;
  height: 112px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background-color: #eee;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin: 16px auto 0;
}
.UploadAvatar__percents {
  color: var(--primary-orange);
  width: 100%;
  font-size: 50px;
  padding-top: 24px;
  text-align: center;
}
.UploadAvatar__imgPreviewText {
  display: flex;
}
.UploadAvatar__imgPreviewText span {
  margin: auto;
}
.UploadAvatar__button {
  width: 100%;
  display: flex;
}
.UploadAvatar__button .Button {
  margin: auto;
}
.UploadAvatar__padding {
  height: 24px;
  width: 100%;
}
.UploadAvatar__padding_preview {
  width: 100%;
  display: flex;
}
.UploadAvatar__padding_preview form {
  width: 100%;
}
.UploadAvatar__padding_preview .UploadAvatar__imgPreview {
  margin: auto;
}
