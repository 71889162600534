.Toasts {
  pointer-events: none;
  position: fixed;
  top: 106px;
  right: 32px;
  z-index: 1200;
}
.adaptive .Toasts {
  padding: 16px;
  right: 0;
  top: 42px;
}
.adaptive .Toasts .Toast {
  width: 100%;
  max-width: 320px;
}
