.WalletBox {
  position: relative;
  margin-right: 28px;
  flex: 1;
}
.WalletBox.noAction .WalletBox__content {
  cursor: default;
}
.WalletBox:nth-child(n + 4) {
  margin-top: 16px;
}
.WalletBox .WalletBox__close {
  position: absolute;
  top: 7px;
  right: 7px;
}
.WalletBox .WalletBox__icon {
  position: absolute;
  width: 52px;
  height: 72px;
  top: 0;
  left: 0;
  transform: translateX(-50%);
  background: no-repeat center / 72px;
  cursor: pointer;
  z-index: 61;
}
.WalletBox .CircleIcon {
  position: absolute;
  bottom: 0;
  top: 0;
  left: -16px;
  margin: auto 0;
  z-index: 61;
}
.WalletBox .WalletBox__content {
  margin-top: 0;
  cursor: pointer;
  user-select: none;
  background: var(--primary-background);
}
.WalletBox .WalletBox__content .ContentBox__content {
  min-height: 72px;
  padding: 0;
  padding-left: 40px;
  background: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.WalletBox .WalletBox__content.new {
  border: 1px solid var(--primary-orange);
  border-radius: 8px;
  padding-left: 0;
  color: var(--primary-orange);
  text-align: center;
  cursor: pointer;
  transition: opacity 150ms ease-in-out;
}
.WalletBox .WalletBox__content.new:hover {
  opacity: 0.7;
}
.WalletBox .WalletBox__content h3 {
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: var(--text-black);
}
.WalletBox .WalletBox__content p {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var(--almost-black);
  margin-top: 4px;
}
.WalletBox .WalletBox__loader {
  position: absolute;
  width: 24px;
  height: 24px;
  right: 10px;
  top: 10px;
  color: #ff9e65;
  animation: rotate 2s infinite linear;
}
.WalletBox__inactive {
  opacity: 0.5;
}
.WalletBox__selected .WalletBox__content h3,
.WalletBox__selected .WalletBox__content p {
  color: var(--white) !important;
}
.WalletBox__selected .WalletBox__content .ContentBox__content {
  background: transparent !important;
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.adaptive .CabinetWalletScreen__wallets {
  box-shadow: var(--main-shadow);
  border-radius: 8px;
}
.adaptive .WalletBox {
  position: relative;
  margin-right: 28px;
  flex: 1;
}
.adaptive .WalletBox:nth-child(n + 4) {
  margin-top: 0;
}
.adaptive .WalletBox .CircleIcon {
  left: 16px;
}
.adaptive .WalletBox__content {
  box-shadow: none;
}
.adaptive .WalletBox__content .ContentBox__content {
  padding-left: 0;
}
.adaptive .WalletBox {
  margin-right: 0;
}
.adaptive .WalletBox .WalletBox__icon {
  position: absolute;
  transform: none;
  left: 10px;
}
.adaptive .WalletBox .WalletBox__content {
  padding-left: 72px;
  box-sizing: border-box;
}
.adaptive .WalletBox .Content_box {
  box-shadow: none;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0);
}
