.CheckNewEmailModal__content {
  color: var(--text-black);
  text-align: center;
  font-size: 13px;
  line-height: 16px;
  margin-bottom: 40px;
}
.CheckNewEmailModal__resend_button {
  text-align: center;
  margin-bottom: 15px;
}
.CheckNewEmailModal__resend_button span {
  font-size: 13px;
  line-height: 16px;
  color: var(--text-black);
  text-decoration: underline;
  cursor: pointer;
}
.CheckNewEmailModal__new_email {
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: var(--black);
  margin: 4px 0;
}
.CheckNewEmailModal__icon {
  height: 120px;
  width: 120px;
  margin: 70px auto 16px;
}
.CheckNewEmailModal__button_wrapper {
  text-align: center;
}
.CheckNewEmailModal__button_wrapper .Button {
  margin: 0 auto;
  width: 208px;
}
