.CabinetChangeEmail {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.CabinetChangeEmail__content {
  text-align: center;
  width: 480px;
  margin-top: 24px;
  box-sizing: border-box;
}
.CabinetChangeEmail__content .ContentBox__content {
  padding: 50px;
}
.CabinetChangeEmail__content p {
  font-size: 24px;
  line-height: 32px;
  margin: 24px auto 40px;
}
.CabinetChangeEmail__content__icon {
  margin: 0 auto;
  width: 120px;
  height: 120px;
}
