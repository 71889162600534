.FarmingAdaptiveItemOptions {
  width: 100%;
  margin: 12px 0;
  padding: 10px 12px 12px;
  border: 1px solid #e3e9f0;
  border-radius: 12px;
}
.FarmingAdaptiveItemOptions .row {
  align-items: center;
}
.FarmingAdaptiveItemOptions > .row:not(:last-child) {
  margin-bottom: 20px;
}
.FarmingAdaptiveItemOptions .col:nth-child(2) {
  margin-left: auto;
}
.FarmingAdaptiveItemOptions .statistics {
  width: 100%;
}
.FarmingAdaptiveItemOptions .statistics > .row:not(:last-child) {
  margin-bottom: 12px;
}
.FarmingAdaptiveItemOptions .statistics .isvg {
  margin-left: 8px;
  opacity: 0.5;
  cursor: pointer;
}
.FarmingAdaptiveItemOptions .statistics .isvg path {
  fill: #5078e6;
}
.FarmingAdaptiveItemOptions .statistics .isvg circle {
  stroke: #5078e6;
}
.FarmingAdaptiveItemOptions .Button {
  --button-height: 36px;
}
.FarmingAdaptiveItemOptions .Button.get-lp {
  padding: 0 16px;
}
.FarmingAdaptiveItemOptions .Button.stake,
.FarmingAdaptiveItemOptions .Button.unstake {
  padding: 0 9px;
  margin-left: 5px;
}
.FarmingAdaptiveItemOptions .Button.harvest {
  padding: 0 23px;
}
.FarmingAdaptiveItemOptions .Button .isvg {
  margin-right: 6.9px;
}
.FarmingAdaptiveItemOptions .Number {
  font-weight: 600;
  font-size: 14px;
  line-height: 100%;
  color: var(--black-gunmetal);
}
.FarmingAdaptiveItemOptions .link {
  color: var(--dark-blue);
}
@keyframes toVisible {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
