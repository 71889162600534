.DexSwap {
  width: 100%;
  box-sizing: border-box;
}
.DexSwap__header {
  margin: 0 0 40px;
}
.DexSwap .CabinetBlock {
  box-sizing: border-box;
  width: 100%;
  background: var(--primary-background);
}
.DexSwap .Switch {
  margin: 0 0 0 15px;
}
.DexSwap .CabinetBlock {
  padding: 0;
  border-radius: 25px;
}
.DexSwap__manage {
  cursor: pointer;
}
.DexSwap__manage .isvg,
.DexSwap__manage svg {
  width: 18px;
  height: 18px;
}
.DexSwap__manage svg,
.DexSwap__manage path {
  fill: #5078e6;
}
.DexSwap .DexSwap__form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 40px 33px 31px;
  box-sizing: border-box;
}
.DexSwap .DexSwap__form > .isvg {
  margin: 21px 0;
  cursor: pointer;
}
.DexSwap .DexSwap__form .Button.lightBlue .isvg svg,
.DexSwap .DexSwap__form .Button.lightBlue .isvg path {
  fill: #fff;
}
.DexSwap .DexSwap__form .Button.lightBlue.DexSwap__button-swap .isvg path:nth-child(1),
.DexSwap .DexSwap__form .Button.lightBlue.DexSwap__button-swap .isvg path:nth-child(2) {
  fill: none;
}
.DexSwap .DexSwap__form > .Button {
  width: 100%;
  height: 72px;
  margin-top: 31px;
  border-radius: 15px;
}
.DexSwap .DexSwap__form > .Button .Button__label {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 18px;
  line-height: 100%;
  text-align: center;
  letter-spacing: -0.01em;
}
.DexSwap .DexSwap__form > .Button .isvg {
  width: 29px;
  height: 29px;
  margin-right: 11px;
}
.DexSwap .DexSwap__form > .Button .isvg svg {
  width: 100%;
  height: 100%;
}
.DexSwap .isvg {
  display: flex;
  align-items: center;
}
.DexSwap__Price {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 8px 0 8px 8px;
  margin-top: 12px;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 500;
  position: relative;
}
.DexSwap__Price + .DexSwap__Slippage {
  margin-top: 0;
}
.DexSwap__Price span:first-child {
  opacity: 0.5;
}
.DexSwap__Price span:nth-child(2) {
  font-weight: 600;
  margin-right: 43px;
}
.DexSwap__Price-swap {
  position: absolute;
  right: 16px;
  width: 19px;
  height: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
}
.DexSwap__Price-swap:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: var(--blue-gradient);
  opacity: 0.12;
  border-radius: 50%;
}
.DexSwap__Price-swap:hover {
  box-shadow: 0 4px 8px rgba(64, 112, 255, 0.12);
}
.DexSwap .HoverPopup {
  margin-left: 6px;
  width: fit-content;
}
.DexSwap .HoverPopup__content {
  z-index: 100;
  color: var(--almost-black);
  white-space: normal;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.6em;
}
.DexSwap__hint {
  z-index: 100;
  text-align: left;
  min-width: 300px;
  max-width: 345px;
}
.adaptive .Exchange__wrapper {
  padding-top: 35px;
}
.adaptive .Exchange__wrapper h1 {
  font-size: 24px;
}
.adaptive .Exchange__wrapper .Exchange__bg,
.adaptive .Exchange__wrapper .Exchange__bg-center {
  display: none;
}
.adaptive .DexSwap .CabinetBlock {
  border-radius: 0;
}
.adaptive .DexSwap__form {
  padding: 24px 16px;
}
