.SiteTokenScreen__Usability {
  max-width: 1008px;
  margin: 0 auto;
}
.SiteTokenScreen__Usability h2 {
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 56px;
  margin-bottom: 8px;
}
.SiteTokenScreen__Usability ul {
  padding: 0;
  list-style: none;
  display: flex;
  margin: 40px -42px;
}
.SiteTokenScreen__Usability h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 44px;
  margin-bottom: 8px;
}
.SiteTokenScreen__Usability p {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  margin-bottom: 8px;
}
.SiteTokenScreen__Usability .link {
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  color: var(--primary-orange) !important;
}
.SiteTokenScreen__Usability li {
  flex: 1;
  padding: 0 42px;
  border-right: 1px solid rgba(255, 255, 255, 0.5);
  transition: opacity 1s ease;
}
.SiteTokenScreen__Usability li:nth-child(1) {
  transition-delay: 0s;
}
.SiteTokenScreen__Usability li:nth-child(2) {
  transition-delay: 0.5s;
}
.SiteTokenScreen__Usability li:nth-child(3) {
  transition-delay: 1s;
}
.SiteTokenScreen__Usability li:nth-child(4) {
  transition-delay: 1.5s;
}
.SiteTokenScreen__Usability li:last-child {
  border-right: 0;
}
.SiteTokenScreen__Usability li .isvg {
  width: 120px;
  height: 120px;
  display: block;
  margin-bottom: 8px;
}
.SiteTokenScreen__Usability li .isvg svg {
  display: block;
  width: inherit;
  height: inherit;
}
.SiteTokenScreen__UsabilityWrapper.notVisible li {
  opacity: 0;
}
@media (max-width: 800px) {
  .SiteTokenScreen__Usability ul {
    flex-direction: column;
    margin: 40px 0;
  }
  .SiteTokenScreen__Usability li {
    margin-bottom: 32px;
    padding: 0;
    border: none;
  }
  .SiteTokenScreen__Usability h4 {
    font-size: 20px;
    line-height: 32px;
  }
  .SiteTokenScreen__Usability p,
  .SiteTokenScreen__Usability .link {
    font-size: 15px;
    line-height: 24px;
  }
}
