.DappUI__SharePopup__icon {
  width: 40px;
  height: 40px;
  background: var(--royal-blue);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 14px;
}
.DappUI__SharePopup__icon .isvg {
  display: flex;
  justify-content: center;
  align-items: center;
}
.DappUI__SharePopup__icon svg {
  width: 20px;
  height: 20px;
}
.DappUI__SharePopup__icon svg path {
  fill: #fff;
}
.DappUI__SharePopup__title,
.DappUI__SharePopup__icon,
.DappUI__SharePopup svg {
  transition: transform 0.2s;
}
.DappUI__SharePopup__item {
  box-sizing: border-box;
}
.DappUI__SharePopup .ContentBox__content {
  padding: 0 !important;
}
.DappUI__SharePopup .ActionsSheet__list {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 34px 70px rgba(199, 201, 207, 0.25) !important;
  overflow: hidden;
  padding: 15px 0;
}
.DappUI__SharePopup .ActionsSheet__item {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: var(--dark-black);
  padding: 9px 32px;
  transition: background 0.1s;
}
.DappUI__SharePopup .ActionsSheet__item:hover {
  background: var(--azureish-white);
}
.DappUI__SharePopup .ActionsSheet__item:hover .DappUI__SharePopup__icon,
.DappUI__SharePopup .ActionsSheet__item:hover .DappUI__SharePopup__icon svg,
.DappUI__SharePopup .ActionsSheet__item:hover .DappUI__SharePopup__title {
  transform: scale(1.1);
}
