.p2p-orders .orders-faq {
  padding: 40px 50px;
}
.p2p-orders .orders-faq__title {
  margin-bottom: 30px;
  gap: 10px 20px;
}
.p2p-orders .orders-faq .SwitchTabs {
  min-width: 327px;
}
.adaptive .p2p-orders .orders-faq {
  padding: 30px 18px;
}
.adaptive .p2p-orders .orders-faq__title {
  flex-wrap: wrap-reverse !important;
}
