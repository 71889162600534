.AuthModal .Input__wrapper {
  margin-top: 40px;
  width: 304px;
}
.AuthModal .Captcha {
  margin: 40px 0 0;
}
.AuthModal .AuthModal__ga .AuthModal__footer {
  margin: 30px 0 10px;
}
.AuthModal .AuthModal__ga .AuthModal__footer .Button {
  margin: 4px 8px;
}
.AuthModal .AuthModal__ga .AuthModal__footer .Button .Button__label {
  font-size: 13px;
}
.AuthModal .AuthModal__title {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.444444px;
  color: var(--black);
  margin-bottom: 15px;
  margin-top: 20px;
}
.AuthModal .AuthModal__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.AuthModal .AuthModal__content .AuthModal__content__ga {
  max-width: 304px;
  margin: 0 auto;
  text-align: center;
}
.AuthModal .AuthModal__content .AuthModal__content__ga .AuthModal__title {
  text-align: left;
}
.AuthModal .AuthModal__content .AuthModal__content__ga .AuthModal__content__ga__msg {
  font-weight: 300;
  font-size: 13px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.444444px;
  color: var(--black);
}
.AuthModal .AuthModal__content .AuthModal__content__ga .AuthModal__content__ga__hash {
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.444444px;
}
.AuthModal .AuthModal__content .AuthModal__content__ga img {
  width: 190px;
  height: 190px;
  margin: 15px;
}
.AuthModal .AuthModal__content .AuthModal__err_msg {
  text-align: center;
  font-size: 12px;
  color: var(--text-red);
  letter-spacing: 0.4444px;
}
.AuthModal .AuthModal__content .CheckBox {
  align-self: center;
  margin-top: 30px;
}
.AuthModal .AuthModal__content .CheckBox .CheckBox__label {
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: 0.444444px;
  color: var(--text-black);
}
.AuthModal .AuthModal__content .AuthModal__content__title {
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.444444px;
  color: #40bf9d;
}
.AuthModal .AuthModal__content .AuthModal__input_wrapper {
  position: relative;
}
.AuthModal .AuthModal__content .AuthModal__input_wrapper img {
  position: absolute;
  transform: translate(283px, -39px);
  width: 26px;
  height: 26px;
}
.AuthModal .AuthModal__content .AuthModal__inputs__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 144px;
}
.AuthModal .AuthModal__content .AuthModal__inputs__wrapper .Input,
.AuthModal .AuthModal__content .AuthModal__inputs__wrapper .Dropdown {
  width: 100%;
  box-shadow: 0px 6px 24px rgba(0, 0, 0, 0.08), 0px 0px 4px rgba(0, 0, 0, 0.05);
}
.AuthModal .AuthModal__content .AuthModal__inputs__wrapper .Input .Dropdown__header,
.AuthModal .AuthModal__content .AuthModal__inputs__wrapper .Dropdown .Dropdown__header {
  width: 112px;
}
.AuthModal .AuthModal__content .AuthModal__content__msg {
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.444444px;
  color: var(--text-black);
  padding: 10px 20px;
  max-width: 200px;
  margin: 0 40px;
}
.AuthModal .AuthModal__content .AuthModal__tick {
  margin: 40px;
}
.AuthModal .AuthModal__help_link {
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.444444px;
  color: var(--gray);
  margin-top: 16px;
  cursor: pointer;
}
.AuthModal .AuthModal__help_link:hover {
  text-decoration: underline;
}
.AuthModal .AuthModal__footer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 70px;
}
.AuthModal .AuthModal__footer .AuthModal__footer__link {
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.444444px;
  color: var(--primary-blue);
  width: 50%;
  cursor: pointer;
}
.AuthModal .AuthModal__footer .Button {
  width: 50%;
  margin: 0;
}
.RegisterModal .AuthModal__content .AuthModal__content__terms {
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.RegisterModal .AuthModal__content .AuthModal__content__terms .CheckBox {
  margin: 0 16px 0;
}
.RegisterModal .AuthModal__content .AuthModal__content__terms .CheckBox .CheckBox__label {
  display: none;
}
.RegisterModal .AuthModal__content .AuthModal__content__terms .AuthModal__content__terms__link {
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: 0.444444px;
  color: var(--text-black);
  cursor: pointer;
}
.RegisterModal .AuthModal__content .AuthModal__content__terms .AuthModal__content__terms__link:hover {
  text-decoration: underline;
}
.RegisterModal .AuthModal__footer {
  margin-top: 25px;
}
.AuthModal__Phone__dropdown {
  display: flex;
  align-items: center;
}
.AuthModal__Phone__dropdown img {
  max-width: 16px;
  margin-right: 8px;
}
@media (min-width: 420px) {
  .AuthModal__Phone {
    width: 360px;
  }
  .AuthModal__Phone .AuthModal__content {
    padding: 0 20px;
  }
  .AuthModal__Phone .AuthModal__content .Input {
    margin: 0;
  }
}
.AuthModal.adaptive .Input__wrapper {
  width: 100%;
}
