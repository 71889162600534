.CabinetSelect-BottomSheet-menu {
  display: flex;
  position: relative;
}
.CabinetSelect-BottomSheet-menu__triangle {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  width: 13.62px;
  height: 13.62px;
  background: #dce5fd;
  z-index: 1;
}
.CabinetSelect-BottomSheet-menu__container {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  overflow: hidden;
  border-radius: inherit;
  z-index: 2;
}
.CabinetSelect-BottomSheet-option {
  display: flex;
  align-items: center;
  height: 100%;
}
.CabinetSelect-BottomSheet-option__icon {
  display: flex;
  height: 100%;
}
.CabinetSelect-BottomSheet-option__icon img {
  width: 23px;
  height: 23px;
  border-radius: 50%;
  object-fit: cover;
}
.CabinetSelect-BottomSheet-option__icon:not(:last-child) {
  margin-right: 6px;
}
.CabinetSelect-BottomSheet .CabinetSelect-BottomSheet-option__title {
  color: var(--dark-black);
}
.CabinetSelect-BottomSheet.bold .CabinetSelect-BottomSheet-option__title {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
}
.CabinetSelect-BottomSheet.average .CabinetSelect-BottomSheet-option__title {
  font-weight: 500;
  font-size: 12px;
  line-height: 100%;
  letter-spacing: -0.01em;
}
.CabinetSelect-BottomSheet .ScrollbarBox .ScrollbarTrackY {
  background: #f7f9fc !important;
  border: 1px solid #fff;
  width: 2px !important;
  right: 8px !important;
  transition: width 0.1s, right 0.1s;
}
.CabinetSelect-BottomSheet .ScrollbarBox .ScrollbarTrackY:hover,
.CabinetSelect-BottomSheet .ScrollbarBox .ScrollbarTrackY:focus,
.CabinetSelect-BottomSheet .ScrollbarBox .ScrollbarTrackY:active {
  width: 4px !important;
  right: 7px !important;
}
.CabinetSelect-BottomSheet .DappInput {
  border-radius: 0;
  background: none;
  padding-right: 26px !important;
}
.CabinetSelect-BottomSheet .DappInput,
.CabinetSelect-BottomSheet .DappInput:focus {
  border: none !important;
  border-bottom: 1px solid var(--azureish-white) !important;
}
.CabinetSelect-BottomSheet .DappInput__wrapper {
  border: none;
}
.CabinetSelect-BottomSheet .DappInput__wrapper,
.CabinetSelect-BottomSheet .DappInput {
  font-weight: inherit;
  font-size: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
}
.CabinetSelect-BottomSheet .DappInput__wrapper.small .DappInput {
  border-radius: 0;
  height: 38px;
}
.CabinetSelect-BottomSheet.average .CabinetSelect-BottomSheet__indicator .isvg path {
  fill: #dfe3f0;
}
.adaptive .CabinetSelect-BottomSheet-menu__triangle {
  display: none;
}
.adaptive .CabinetSelect-BottomSheet-option__icon img {
  width: 21px;
  height: 21px;
}
.adaptive .CabinetSelect-BottomSheet .BottomSheetModal {
  padding: 0;
  background: transparent;
}
