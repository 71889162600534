.CircleIcon {
  position: relative;
  background: var(--cloudy);
  border-radius: 50%;
  display: flex;
  color: var(--text-black);
  overflow: hidden;
  justify-content: center;
  align-items: center;
}
.CircleIcon.type {
  background: none;
}
.CircleIcon.type:not(.currency):after {
  position: absolute;
  top: 0;
  left: 0;
  background: currentColor;
  content: '';
  display: block;
  width: inherit;
  height: inherit;
  opacity: 0.12;
}
.CircleIcon.type.fail,
.CircleIcon.type.failed,
.CircleIcon.type.error,
.CircleIcon.type.canceled {
  color: var(--red);
}
.CircleIcon.type.success {
  color: var(--green);
}
.CircleIcon.type.primary {
  color: var(--primary-blue);
}
.CircleIcon.type.secondary,
.CircleIcon.type.pending {
  color: var(--gray);
}
.CircleIcon.type.default {
  color: var(--text-black);
}
.CircleIcon.type.default:after {
  color: var(--gray);
}
.CircleIcon.skeleton {
  animation: skeleton 1s linear infinite;
}
.CircleIcon.medium {
  height: 40px;
  width: 40px;
}
.CircleIcon.small {
  height: 32px;
  width: 32px;
}
.CircleIcon.extra_small {
  height: 24px;
  width: 24px;
}
.CircleIcon.ultra_small {
  height: 20px;
  width: 20px;
}
.CircleIcon .isvg {
  margin: auto;
  height: 24px;
}
.CircleIcon .CircleIcon__icon {
  height: inherit;
  width: inherit;
  background-position: center;
  background-size: contain;
}
