.Button {
  --button-height: 48px;
  outline: none;
  border: none;
  font-family: "Montserrat", sans-serif;
  padding: 0 32px;
  background: var(--blue-gradient);
  color: var(--primary-blue);
  border-radius: var(--radius-button);
  display: inline-flex;
  height: var(--button-height);
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: box-shadow 150ms ease-in-out;
  position: relative;
  overflow: hidden;
  user-select: none;
  box-sizing: border-box;
  text-decoration: none !important;
  z-index: 0;
}
.Button.buy,
.Button.success {
  background: var(--green);
  color: var(--white);
}
.Button.sell,
.Button.danger {
  background: var(--red);
  color: var(--white);
}
.Button:not(.lite):not(.secondary) {
  color: var(--white);
}
.Button.loading {
  user-select: none;
  pointer-events: none;
}
.Button.smallPadding {
  padding: 0 16px;
}
.Button.forCabinet {
  background: linear-gradient(47.71deg, #ffc75d 21.44%, #ffa363 76.19%);
}
.Button:after {
  content: '';
  background: inherit;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  transform: rotate(180deg);
  transition: opacity 150ms ease-in-out;
}
.Button.default:hover {
  opacity: 0.92;
  box-shadow: 0 4px 8px rgba(64, 112, 255, 0.32);
}
.Button:active {
  opacity: 1;
  box-shadow: none;
}
.Button:active:after {
  opacity: 1;
}
.Button.extra_small {
  --button-height: 29px;
  padding: 0 9px;
  border-radius: 6px;
}
.Button.small {
  --button-height: 32px;
  padding: 0 16px;
}
.Button.ultra_small {
  --button-height: 24px;
  padding: 0 8px;
  border-radius: 6px;
}
.Button.medium {
  --button-height: 44px;
  padding: 0 14px;
  border-radius: 11px;
}
.Button.middle {
  --button-height: 40px;
  padding: 0 24px;
}
.Button.large {
  text-align: center;
}
.Button.large .Button__cont {
  justify-content: center;
}
.Button.extra_large {
  --button-height: 53px;
}
.Button.extra_large .Button__label {
  font-size: 14px;
}
.Button.big {
  --button-height: 65px;
  border-radius: 15px;
}
.Button.big .Button__label {
  font-size: 14px;
}
.Button.secondary {
  background: transparent;
  color: var(--dark-blue);
}
.Button.secondary:hover {
  box-shadow: 0 4px 8px rgba(64, 112, 255, 0.12);
}
.Button.secondary:after {
  background: var(--primary-blue);
  opacity: 0.12;
}
.Button.secondary:hover:after {
  opacity: 0.16;
}
.Button.secondary:active:after {
  opacity: 0.24;
}
.Button.lite {
  background: transparent;
  box-shadow: none;
}
.Button.lite:after {
  background: var(--primary-blue);
  opacity: 0;
}
.Button.lite:hover:after {
  opacity: 0.12;
}
.Button.lite:active:after {
  opacity: 0.24;
}
.Button.negative {
  background: var(--red);
}
.Button.negative:hover {
  opacity: 0.95;
  box-shadow: 0 4px 8px rgba(255, 68, 51, 0.32);
}
.Button.negative:active {
  background: var(--text-red);
}
.Button.green {
  background: var(--green);
}
.Button.green:hover {
  opacity: 0.95;
  box-shadow: 0 4px 8px rgba(0, 178, 119, 0.32);
}
.Button.green:active {
  background: var(--text-green);
}
.Button.green-light {
  background: #5ec47a;
  border: none;
  transition: all 0.2s;
}
.Button.green-light.disabled {
  opacity: 1;
  background: #e3e9f0;
}
.Button.green-light.disabled .Button__label {
  color: var(--cool-gray);
}
.Button.green-light:hover {
  box-shadow: 0 4px 8px rgba(94, 196, 122, 0.32);
  opacity: 0.9;
}
.Button.green-light:active {
  opacity: 0.8;
}
.Button.green-light .Button__label {
  color: #fff;
}
.Button.lightBlue {
  background: var(--blue-light-gradient);
  transition: all 0.2s;
}
.Button.lightBlue:hover {
  opacity: 0.92;
  box-shadow: 0 4px 8px rgba(64, 112, 255, 0.32);
}
.Button.primary-blue {
  background: var(--primary-blue-gradient);
}
.Button.primary-blue:hover {
  opacity: 0.92;
  box-shadow: 0 4px 8px rgba(64, 112, 255, 0.32);
}
.Button.gray {
  background: rgba(223, 227, 240, 0.25);
  transition: all 0.2s;
}
.Button.gray:hover {
  background: rgba(223, 227, 240, 0.4);
  box-shadow: 0 4px 8px rgba(223, 227, 240, 0.35);
}
.Button.gray .Button__label {
  color: var(--cool-gray);
}
.Button.gray .Button__icon svg,
.Button.gray .Button__icon svg path {
  fill: var(--cool-gray);
}
.Button.secondary-blue {
  background: #e5ebfc;
}
.Button.secondary-blue:hover {
  opacity: 0.92;
  box-shadow: 0 4px 8px rgba(229, 235, 252, 0.5);
}
.Button.secondary-blue .Button__label {
  color: var(--secondary-blue);
}
.Button.secondary-lightBlue {
  background: none;
  transition: all 0.1s;
  border: 2px solid rgba(80, 120, 230, 0.25);
}
.Button.secondary-lightBlue:hover {
  background: rgba(229, 235, 252, 0.5);
  opacity: 0.92;
  box-shadow: 0 4px 8px rgba(229, 235, 252, 0.5);
}
.Button.secondary-lightBlue:active {
  opacity: 0.85;
}
.Button.secondary-lightBlue .Button__label {
  color: var(--royal-blue);
}
.Button.secondary-alice {
  background: var(--alice-blue);
  transition: all 0.1s;
}
.Button.secondary-alice:hover {
  background: #e5ebfc;
  opacity: 0.92;
  box-shadow: 0 4px 8px rgba(229, 235, 252, 0.5);
}
.Button.secondary-alice:active {
  background: #cfdaf9;
}
.Button.secondary-alice .Button__label {
  color: var(--secondary-blue);
}
.Button.secondary-light {
  background: #f7f8fb;
  transition: all 0.2s;
}
.Button.secondary-light:hover {
  background: rgba(223, 227, 240, 0.4);
  box-shadow: 0 4px 10px rgba(223, 227, 240, 0.35);
}
.Button.secondary-light .Button__label {
  color: var(--dark-black);
}
.Button.light {
  background: transparent;
  border: 1px solid var(--dark-black);
  transition: all 0.2s;
}
.Button.light:hover {
  background: transparent;
  box-shadow: none;
}
.Button.light .Button__label {
  color: var(--dark-black);
}
.Button.dark {
  background: var(--dark-black);
  border: none;
  transition: all 0.2s;
}
.Button.dark:hover {
  opacity: 0.9;
  box-shadow: 0 4px 10px rgba(45, 49, 63, 0.35);
}
.Button.dark:active {
  opacity: 0.8;
  box-shadow: 0 4px 5px rgba(45, 49, 63, 0.35);
}
.Button.dark .Button__label {
  color: #fff;
}
.Button.orange {
  background: var(--light-orange-gradient);
  transition: all 0.2s;
}
.Button.orange:hover {
  opacity: 0.92;
  box-shadow: 0 4px 8px rgba(250, 200, 106, 0.42);
}
.Button.white {
  background: #fff;
  transition: all 0.2s;
}
.Button.white:hover {
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0 4px 10px rgba(255, 255, 255, 0.35);
}
.Button.white .Button__label {
  color: var(--secondary-blue);
}
.Button.orange .Button__label,
.Button.white .Button__label,
.Button.dark .Button__label,
.Button.light .Button__label,
.Button.secondary-light .Button__label,
.Button.secondary-blue .Button__label,
.Button.secondary-alice .Button__label,
.Button.gray .Button__label,
.Button.lightBlue .Button__label,
.Button.secondary-lightBlue .Button__label,
.Button.green-light .Button__label {
  display: flex;
  align-items: center;
}
.Button.orange .isvg,
.Button.white .isvg,
.Button.dark .isvg,
.Button.light .isvg,
.Button.secondary-light .isvg,
.Button.secondary-blue .isvg,
.Button.secondary-alice .isvg,
.Button.gray .isvg,
.Button.lightBlue .isvg,
.Button.secondary-lightBlue .isvg,
.Button.green-light .isvg {
  display: inline-flex;
  align-items: center;
}
.Button.shadow {
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
}
.Button.shadow:hover {
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
}
.Button.disabled {
  pointer-events: none;
  opacity: 0.65;
}
.Button.disabled:hover {
  opacity: 0.65;
}
.Button__loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 16px;
  height: 16px;
  z-index: 5;
  animation: Button__loader_anim 1s infinite linear;
}
@keyframes Button__loader_anim {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
.Button__label {
  margin: auto;
  font-size: 13px;
  line-height: 17px;
  font-weight: 600;
}
.Button.small .Button__label {
  font-size: 13px;
  line-height: 16px;
}
.Button.ultra_small .Button__label {
  font-weight: 500;
  font-size: 11px;
  line-height: 11px;
}
.Button.middle .Button__label {
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
}
.Button__cont {
  z-index: 2;
  display: flex;
  align-items: center;
}
.Button.loading .Button__cont {
  opacity: 0;
}
.WatchButton {
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 45px;
  padding: 0 12px;
}
.WatchButton:active {
  transform: translateY(1px);
}
.WatchButton__icon {
  width: 28px;
  height: 28px;
  background: var(--orange-gradient);
  border-radius: 50%;
  position: relative;
}
.WatchButton__icon:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background: url('../../asset/watch_button_icon_28.svg') no-repeat center / contain;
}
.WatchButton__label {
  font-weight: 500;
  font-size: 15px;
  margin-left: 14px;
  color: var(--primary-orange);
}
.buttonFontSizeStyle {
  font-size: 15px !important;
}
.ButtonWrapper {
  display: flex;
  align-items: center;
}
.ButtonWrapper .Button {
  margin: 0 8px;
}
.ButtonWrapper .Button:first-child {
  margin-left: 0;
}
.ButtonWrapper .Button:last-child {
  margin-right: 0;
}
.ButtonWrapper.left {
  justify-content: flex-start;
}
.ButtonWrapper.right {
  justify-content: flex-end;
}
.ButtonWrapper.justify {
  justify-content: space-between;
}
.ButtonWrapper.center {
  justify-content: center;
}
.ButtonWrapper.fill .Button {
  flex: 1;
}
