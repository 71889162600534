.CabinetResetPassword {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 0;
}
.CabinetResetPassword__content {
  padding: 50px;
  width: 424px;
  box-sizing: border-box;
  text-align: center;
}
.CabinetResetPassword__content__title {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 24px;
}
.CabinetResetPassword__content__submit_wrapper {
  text-align: center;
  margin-top: 80px;
}
.CabinetResetPassword__content__icon {
  margin: 0 auto;
  width: 120px;
  height: 120px;
}
.CabinetResetPassword p {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  margin: 24px auto 40px;
  text-align: center;
}
.CabinetResetPassword .Tooltip__wrapper,
.CabinetResetPassword .Input__wrapper {
  margin-bottom: 40px;
}
.CabinetResetPassword .Tooltip__wrapper .Input__wrapper,
.CabinetResetPassword .Input__wrapper .Input__wrapper {
  margin-bottom: 0;
}
.adaptive .CabinetResetPassword {
  padding: 16px 0;
}
.adaptive .CabinetResetPassword__content {
  width: 100%;
  padding: 24px 16px;
}
.adaptive .CabinetResetPassword__content .Button {
  width: 100%;
}
