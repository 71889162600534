.CabinetModal {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  top: 0;
  left: 0;
  z-index: 999;
  position: fixed;
}
.CabinetModal .Modal__box {
  padding: 0;
  background: transparent;
}
.CabinetModal__container {
  width: 100%;
  box-sizing: border-box;
  position: relative;
}
.CabinetModal__container > *:not(.DappModal__Close) {
  position: relative;
  transform: none;
  left: auto;
  right: auto;
  top: auto;
  bottom: auto;
  max-height: 100% !important;
  box-shadow: none;
}
.adaptive .CabinetModal {
  padding: 0;
  height: auto;
  min-height: 100%;
  background: transparent;
  overflow: hidden;
}
.adaptive .CabinetModal .Modal__box {
  box-shadow: none;
  padding: 20px;
  top: 50%;
  transform: translateY(-50%);
  overflow-y: scroll;
  max-height: 100%;
}
.adaptive .CabinetModal__container {
  max-width: 500px;
  margin: 0 auto;
}
.adaptive .CabinetModal__container > *:not(.DappModal__Close) {
  width: 100% !important;
  margin: 0 auto;
  border: none;
}
.adaptive .CabinetModal .Modal__back {
  background: rgba(1, 1, 1, 0.25);
}
