.DepositModal__InfoWrapper {
  background: var(--alice-blue);
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
  border-radius: var(--radius-panel);
  padding: 16.06px 13.5px;
  text-align: center;
  flex: 1;
}
.DepositModal__InfoWrapper.large p:not(:first-child),
.DepositModal__InfoWrapper.large .blue:not(.extra-small) {
  margin-top: 0;
}
.DepositModal__InfoWrapper.secondary {
  background-color: #e5ebfc;
}
.DepositModal__InfoWrapper p:not(:first-child) {
  margin-top: 7px;
}
.DepositModal__InfoWrapper .blue:not(.extra-small) {
  margin-top: 5px;
}
.DepositModal__InfoWrapper .CopyTextComponent__content {
  display: flex;
  align-items: center;
}
.DepositModal__InfoWrapper .CopyTextComponent__content .isvg {
  position: absolute;
  right: -5px;
  transform: translateX(100%);
}
.adaptive .DepositModal__InfoWrapper:not(.secondary) {
  background: var(--white);
}
