.DappWrapper {
  display: flex;
  flex-direction: column;
  background: var(--secondary-background);
  min-height: 100vh;
  width: 100%;
}
.floatingButtonPadding {
  width: 100%;
}
.adaptive .DappWrapper .EmptyContentBlock {
  min-height: 250px;
}
.adaptive .DappWrapper .Empty_box {
  box-sizing: border-box;
  padding-bottom: 24px;
}
.adaptive .DappWrapper .Empty_box h3 {
  min-width: 80%;
  font-size: 14px;
}
.adaptive .DappWrapper .Empty_box svg {
  width: 90px;
  height: 90px;
}
.adaptive .DappWrapper .AdaptiveHeader__logo {
  margin: auto 0 auto 16px;
  cursor: pointer;
}
.adaptive .DappWrapper .AdaptiveHeader__logo svg {
  width: 78px;
  margin: 0 !important;
}
.adaptive .floatingButtonPadding {
  width: 100%;
  height: 64px;
}
.adaptive .DappWrapper {
  min-width: auto;
}
.adaptive .DappWrapper__content {
  overflow: hidden;
}
