.NrfxButton {
  position: relative;
  background: transparent;
  color: var(--white);
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  padding: 16px;
  border-radius: 16px;
  border: 1px solid var(--white);
  outline: none;
  cursor: pointer;
  font-family: inherit;
  transition: opacity 0.2s ease;
}
.NrfxButton.loading {
  opacity: 1 !important;
  color: transparent !important;
}
.NrfxButton.loading:before {
  position: absolute;
  margin: auto;
  right: 0;
  left: 0;
  content: '';
  display: block;
  height: 16px;
  width: 16px;
  background: url("../../../../../../ui/asset/spinner.svg") no-repeat;
  animation: Button__loader_anim 1s infinite linear;
}
.NrfxButton[disabled] {
  opacity: 0.6;
  pointer-events: none;
}
.NrfxButton.active {
  background: var(--white);
  color: var(--text-black);
}
.NrfxButton.small {
  padding: 8px 16px;
  border-radius: var(--radius-panel);
}
