.SwapFormAdaptive {
  margin-bottom: var(--gap-size);
}
.SwapFormAdaptive .ContentBox__content {
  padding: 24px 16px;
}
.SwapFormAdaptive .Input__indicator {
  padding-right: 8px;
}
.SwapFormAdaptive__amountLabel {
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  margin: 16px;
  margin-top: 0px;
}
.SwapFormAdaptive__maxAmountButton {
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  margin: 8px;
  padding: 16px;
  color: var(--dark-blue);
  text-align: center;
  cursor: pointer;
}
.SwapFormAdaptive__maxAmountButton:hover {
  opacity: 0.75;
}
.SwapFormAdaptive__submitButton {
  width: 100%;
}
.SwapFormAdaptive__controlPanel {
  position: relative;
  display: flex;
  margin: 0 -16px 16px;
  padding-left: 8px;
}
.SwapFormAdaptive__controlPanel:before {
  top: -1px;
  left: 0;
  position: absolute;
  content: '';
  display: block;
  height: 1px;
  width: calc(100% - 32px);
  margin: 0 16px;
  background: var(--extra-light-gray);
  transform-origin: center top;
}
@media (min-resolution: 2dppx) {
  .SwapFormAdaptive__controlPanel:before {
    transform: scaleY(0.5);
  }
}
@media (min-resolution: 3dppx) {
  .SwapFormAdaptive__controlPanel:before {
    transform: scaleY(0.32);
  }
}
.SwapFormAdaptive__controlPanel__select {
  position: relative;
  flex: 1;
}
.SwapFormAdaptive__controlPanel__select.disabled {
  opacity: 0.7;
  pointer-events: none;
}
.SwapFormAdaptive__controlPanel__select select {
  font-family: inherit;
  width: 100%;
  border: 0;
  outline: none;
  padding: 34px 14px 14px 40px;
  appearance: none;
  -webkit-appearance: none;
  background: transparent;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  border-radius: 8px;
}
.SwapFormAdaptive__controlPanel__select select:hover,
.SwapFormAdaptive__controlPanel__select select:active {
  background: var(--cloudy);
}
.SwapFormAdaptive__controlPanel__select .CircleIcon {
  pointer-events: none;
  position: absolute;
  left: 8px;
  top: 0;
  bottom: 0;
  margin: auto;
}
.SwapFormAdaptive__controlPanel__select__label {
  pointer-events: none;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  position: absolute;
  top: 14px;
  left: 40px;
}
.SwapFormAdaptive__controlPanel__swapButton {
  width: 56px;
  display: flex;
  opacity: 0.3;
  cursor: default;
  pointer-events: none;
}
.SwapFormAdaptive__controlPanel__swapButton__circle {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  padding: 0;
  color: var(--primary-blue) !important;
  margin: auto;
}
.SwapFormAdaptive__controlPanel__swapButton__circle .isvg {
  display: block;
}
.SwapFormAdaptive__controlPanel__swapButton__circle .isvg svg {
  display: inherit;
}
.SwapFormAdaptive__commission {
  position: absolute;
  top: 28px;
  right: 28px;
  font-size: 10px;
  font-weight: 500;
  opacity: 0.5;
}
