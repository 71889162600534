.p2p-orders .orders-blog {
  padding: 40px 50px;
}
.p2p-orders .orders-blog h3 {
  margin-bottom: 38px;
}
.p2p-orders .orders-blog__cards {
  display: flex;
  flex-wrap: wrap;
  gap: 20px 40px;
}
.p2p-orders .orders-blog__cards > * {
  flex: 1 1 calc(33% - 40px);
}
.adaptive .p2p-orders .orders-blog {
  padding: 30px 18px;
}
.adaptive .p2p-orders .orders-blog h3 {
  margin-bottom: 20px;
}
