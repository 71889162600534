.EditorTooltip {
  position: relative;
  background: var(--text-black);
  backdrop-filter: blur(8px);
  padding: 4px;
  padding-bottom: 8px;
  border-radius: 8px;
  color: var(--white);
  width: fit-content;
  max-width: 340px;
  box-sizing: border-box;
  text-align: center;
  transition: opacity 0.2s ease;
}
.EditorTooltip:not(.visible) {
  transition: none;
  opacity: 0;
  pointer-events: none;
}
.EditorTooltip:after {
  display: block;
  content: '';
  position: absolute;
  border: 8px solid transparent;
  border-top-color: var(--text-black);
  bottom: -16px;
  width: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.EditorTooltip .EditorTooltip__item {
  cursor: pointer;
  font-size: 15px;
  line-height: 24px;
  display: inline-block;
  margin: 4px 2px;
  padding: 2px 6px;
  margin-bottom: 0;
  border-radius: 4px;
}
.EditorTooltip .EditorTooltip__item:hover {
  background: rgba(255, 255, 255, 0.2);
}
.EditorTooltip .EditorTooltip__item.bold {
  font-weight: bold;
}
.EditorTooltip .EditorTooltip__item.italic {
  font-style: italic;
}
.EditorTooltip .EditorTooltip__item.underline {
  text-decoration: underline;
  font-style: italic;
}
.EditorTooltip .EditorTooltip__item.monospace,
.EditorTooltip .EditorTooltip__item.code,
.EditorTooltip .EditorTooltip__item.block {
  font-family: monospace;
}
