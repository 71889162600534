.TransactionHistoryOverlay {
  box-shadow: 0 0 5px 15px rgba(255, 255, 255, 0.5);
  height: 320px;
  border-radius: 15px;
}
.TransactionHistoryOverlay-empty {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  padding: 5px;
  text-align: center;
}
.TransactionHistoryOverlay .Button {
  margin-top: 25px;
}
.TransactionHistoryOverlay .Button__label {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
}
.TransactionHistoryOverlay .Button .isvg {
  margin-right: 10px;
}
