.ReferralLink {
  display: flex;
  padding: 40px;
  margin-bottom: var(--gap-size);
}
.adaptive .ReferralLink {
  flex-direction: column;
  padding: 24px 16px;
}
.ReferralLink__left {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.ReferralLink__title {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var(--black);
}
.ReferralLink__caption {
  font-size: 13px;
  line-height: 24px;
  margin-top: 24px;
}
.adaptive .ReferralLink__caption {
  margin-top: 8px;
}
.ReferralLink__right {
  flex: 0 0 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 24px;
}
.adaptive .ReferralLink__right {
  margin-left: 0;
  margin-top: 32px;
  align-items: center;
}
.ReferralLink__right__social_buttons {
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
}
.adaptive .ReferralLink__right__social_buttons {
  justify-content: center;
}
.ReferralLink__right__social_button {
  width: 32px;
  height: 32px;
  margin-left: 16px;
  cursor: pointer;
  position: relative;
  display: block;
}
.ReferralLink__right__social_button svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
}
.ReferralLink__right__social_button:first-child {
  margin-left: 0;
}
.ReferralLink__link {
  color: var(--text-black);
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  margin-bottom: 8px;
  text-align: center;
  overflow: hidden;
  word-wrap: break-word;
  max-width: 220px;
  width: 100%;
}
.adaptive .ReferralLink__link__wrapper {
  text-align: center;
}
