.CabinetSelect-Buttons .CabinetSelect-Buttons__header {
  margin-bottom: 8px;
}
.CabinetSelect-Buttons .CabinetSelect-Buttons__header h4 {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: var(--cool-gray);
}
.CabinetSelect-Buttons .CabinetSelect-Buttons__header .CustomButton {
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: var(--dark-black);
}
.CabinetSelect-Buttons .dark-dropdown-icon {
  width: 7px;
  height: 4px;
  margin-left: 3px;
}
.CabinetSelect-Buttons .dark-dropdown-icon path {
  fill: var(--dark-black);
}
.CabinetSelect-Buttons .CabinetSelect-Buttons__buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 5px 4px;
}
.CabinetSelect-Buttons .CabinetSelect-Buttons__buttons .Button {
  width: calc(33% - 2px);
  padding: 0px;
}
.CabinetSelect-Buttons .CabinetSelect-Buttons__buttons .Button__label {
  font-weight: 500;
  font-size: 10px;
  line-height: 10px;
}
.CabinetSelect-Buttons .CabinetSelect-Buttons__buttons .Button img {
  margin-right: 4px;
  width: 16px;
  height: 16px;
  object-fit: cover;
}
.CabinetSelect-Buttons .CabinetSelect-Buttons__buttons .Button:not(.isSelected) .Button__label {
  color: var(--cool-gray);
}
.CabinetSelect-Buttons .CabinetSelect-Buttons__buttons .Button:not(.isSelected).highlighted .Button__label {
  color: var(--dark-black);
}
