.WalletsTotalBalance {
  display: flex;
  justify-content: start;
}
.WalletsTotalBalance__icon {
  width: 63px;
  height: 63px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: 10px;
  margin-right: 27px;
}
.WalletsTotalBalance__icon-bg {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: var(--royal-blue);
  border-radius: 21px;
  opacity: 0.1;
}
.WalletsTotalBalance__icon .isvg {
  margin-top: -3px;
  margin-left: 2px;
}
.WalletsTotalBalance__icon .isvg,
.WalletsTotalBalance__icon .isvg svg {
  position: relative;
  width: 32.8px;
  height: 31.8px;
}
.WalletsTotalBalance__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  margin-top: 10px;
}
.WalletsTotalBalance__content .RateIndicator {
  height: 20px;
  padding: 3px 11px 3px 4px;
  margin-left: 16px;
  margin-right: 10px;
  border-radius: 5px;
}
.WalletsTotalBalance__content .RateIndicator span {
  font-weight: 600;
  font-size: 15px;
  line-height: 110%;
}
.WalletsTotalBalance__content .RateIndicator .isvg,
.WalletsTotalBalance__content .RateIndicator .isvg svg {
  width: 10px;
  height: 8px;
}
.WalletsTotalBalance__row {
  display: flex;
  align-items: center;
}
.WalletsTotalBalance__text-medium {
  font-weight: 600;
  font-size: 14px;
  line-height: 180%;
  letter-spacing: 0.12em;
  color: var(--cool-gray);
  margin-bottom: 2px;
}
.WalletsTotalBalance__text-large {
  font-weight: 600;
  font-size: 35px;
  line-height: 110%;
  letter-spacing: -0.01em;
  color: var(--dark-black);
}
