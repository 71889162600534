.CustomButton {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: none;
  padding: 0;
  font-family: inherit;
  cursor: pointer;
}
.CustomButton.disabled {
  pointer-events: none;
  filter: grayscale(1);
  opacity: 0.9;
}
.CustomButton:focus {
  outline: none;
}
.CustomButton .isvg {
  display: inline-flex;
  align-items: center;
}
.CustomButton.button:active {
  transform: scale(0.93);
}
.CustomButton.link:active {
  opacity: 0.8;
}
.CustomButton.link:hover {
  text-decoration: underline;
}
