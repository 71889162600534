.ConfirmModal {
  max-width: 316px;
}
.ConfirmModal .ConfirmModal__buttons {
  display: flex;
  flex-wrap: wrap;
  margin: 24px -8px -16px;
}
.ConfirmModal .Button {
  flex: 1;
  min-width: max-content;
  margin: 0 8px 16px;
}
.ConfirmModal .ConfirmModal__content {
  opacity: 0.8;
  font-size: 13px;
  line-height: 24px;
  margin: 16px 0 32px;
  text-align: center;
}
.adaptive .ConfirmModal {
  max-width: 100%;
}
