.ChangeSecretKayModal {
  margin-top: 46px;
}
.ChangeSecretKayModal__submit_wrapper {
  margin-top: 56px;
  text-align: center;
}
.ChangeSecretKayModal__submit_wrapper .Button {
  width: 200px;
}
