.Message {
  border-radius: var(--radius-panel);
  background: var(--primary-blue-opacity);
  padding: 16px;
  font-family: "Montserrat", sans-serif;
  color: var(--black);
  font-size: 15px;
  line-height: 20px;
  margin: 16px 0;
}
.Message .Message__content {
  display: flex;
  align-items: center;
}
.Message.error {
  background: var(--red-opacity);
}
.Message.warning {
  background: var(--yellow-opacity);
}
.Message.success {
  background: var(--green-opacity);
}
.Message.alert {
  padding: 18px 25px;
  color: var(--uikit-message-default-border-color);
}
.Message.alert.error {
  color: var(--uikit-message-error-border-color);
}
.Message.alert.warning {
  color: var(--uikit-message-warning-border-color);
}
.Message.alert.success {
  color: var(--uikit-message-success-border-color);
}
.Message__title {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 8px;
}
.Message__label {
  flex: 1;
  font-weight: normal;
  font-size: 13px;
  line-height: 21px;
}
.Message.alert .Message__label {
  font-weight: 600;
}
.Message__icon {
  flex: 0 0 auto;
  width: 47px;
  height: 47px;
  margin-right: 12px;
  background: red;
}
.Message__close {
  width: 24px;
  height: 24px;
  background: red;
  flex: 0 0 auto;
  cursor: pointer;
  transition: opacity 150ms ease-in-out;
}
.Message__close:hover {
  opacity: 0.7;
}
