.BottomSheetModal {
  display: flex;
  justify-content: center;
  background: #fff;
  padding: 32px;
  box-sizing: border-box;
  border-radius: 25px 25px 0 0;
  position: relative;
  box-shadow: 0px 15px 75px rgba(0, 0, 0, 0.1);
  overflow-y: scroll;
  animation: showFromBottom 0.5s;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
}
.BottomSheetModal__line {
  position: absolute;
  display: block;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  height: 3px;
  width: 30%;
  border-top: 1.5px solid rgba(1, 1, 1, 0.3);
}
.BottomSheetModal__children {
  width: 100%;
  height: 100%;
}
.BottomSheetModal__bg {
  position: absolute;
  bottom: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.75);
  height: calc(100% - 67px);
  width: 100%;
}
@keyframes showFromBottom {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}
.BottomSheetModal-container {
  height: 100vh;
  width: 100vw;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 101;
}
.noScroll {
  overflow: hidden;
}
