.MainLanding-BenefitCards {
  gap: 26px 0;
}
.MainLanding-BenefitCards__row {
  display: flex;
  gap: 26px 21px;
}
.MainLanding-BenefitCards .currencies-background,
.MainLanding-BenefitCards .community-background,
.MainLanding-BenefitCards .transactions-background,
.MainLanding-BenefitCards .users-background {
  left: 50%;
  transform: translateX(-50%);
}
.MainLanding-BenefitCards .trustless-background {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.MainLanding-BenefitCards .trustless-background img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.MainLanding-BenefitCards .users-background {
  top: 31px;
}
.MainLanding-BenefitCards .ecosystem-background {
  left: 163px;
  top: -244px;
}
.MainLanding-BenefitCards .transactions-background {
  top: 43px;
  font-weight: 600;
  font-size: 148px;
  line-height: 114%;
  text-align: center;
  letter-spacing: -0.07em;
  color: #d0d3dc;
}
.MainLanding-BenefitCards .transactions-background span {
  opacity: 0.4;
}
.MainLanding-BenefitCards .transactions-background span:nth-child(2) {
  padding: 0 13px;
  opacity: 1;
  background: linear-gradient(179.57deg, #fa9751 -2.68%, #ffbc3c 86.61%), #2f323d;
  -webkit-background-clip: text;
  -webkit-text-fill-color: rgba(0, 0, 0, 0);
  background-clip: text;
}
.MainLanding-BenefitCards .currencies-background img {
  filter: drop-shadow(0px 4px 50px rgba(0, 0, 0, 0.25));
}
.MainLanding-BenefitCards .community-background img {
  filter: drop-shadow(0px 4px 50px rgba(57, 57, 57, 0.16));
}
.MainLanding-BenefitCards .community-background,
.MainLanding-BenefitCards .currencies-background {
  top: 70px;
}
.isDesktop.MainLanding-BenefitCards .transactions-background {
  font-size: 78px;
}
.isDesktop.MainLanding-BenefitCards .users-background {
  top: -30px;
}
.isDesktop.MainLanding-BenefitCards .ecosystem-background {
  left: auto;
  right: -113px;
}
.isDesktop.MainLanding-BenefitCards .transactions-background,
.isDesktop.MainLanding-BenefitCards .community-background,
.isDesktop.MainLanding-BenefitCards .currencies-background {
  top: 35px;
}
@media screen and (max-width: 1200px) {
  .MainLanding-BenefitCards__row {
    flex-wrap: wrap;
  }
}
.adaptive .MainLanding-BenefitCards .ecosystem-background {
  left: auto;
  right: -113px;
  top: -101px;
}
.adaptive .MainLanding-BenefitCards .ecosystem-background img {
  width: 311.3px;
  height: 381.6px;
}
