.ActionsSheet {
  position: relative;
  width: fit-content;
}
.ActionsSheet.disabled {
  opacity: 0.5;
  pointer-events: none;
}
.ActionsSheet__drop-icon {
  position: absolute;
  top: 0;
  transform: translateY(-7px);
}
.ActionsSheet:not(.left) .ActionsSheet__drop-icon {
  left: 3px;
}
.ActionsSheet.left .ActionsSheet__drop-icon {
  right: 3px;
}
.ActionsSheet .ActionsSheet__list__icon svg {
  display: block;
  width: 24px;
  height: 24px;
}
.ActionsSheet:not(.visible) .ActionsSheet__list {
  display: none;
}
.ActionsSheet .ActionsSheet__list {
  min-width: 204px;
  position: absolute !important;
  z-index: 10;
  margin: 0;
  top: calc(100% + 17px) !important;
  left: 0;
  background: #fff;
  border-radius: var(--radius-panel);
  border: none;
  box-shadow: 0px 4px 100px rgba(0, 0, 0, 0.1) !important;
}
.ActionsSheet .ActionsSheet__list .ContentBox__content {
  padding: 8.5px 0;
}
.ActionsSheet.left .ActionsSheet__list {
  right: 0;
  left: auto;
}
.ActionsSheet .ActionsSheet__item {
  display: flex;
  align-items: center;
  white-space: nowrap;
  cursor: pointer;
  padding: 3.5px 14px;
  font-weight: 500;
  font-size: 12px;
  line-height: 180%;
  color: var(--black-gunmetal);
}
.ActionsSheet .ActionsSheet__item.destructive .ActionsSheet__item__title {
  color: var(--text-red);
}
.ActionsSheet .ActionsSheet__item.primary .ActionsSheet__item__title {
  color: var(--dark-blue);
}
.ActionsSheet .ActionsSheet__item:not(.destructive):hover .ActionsSheet__item__title {
  color: var(--black);
}
.ActionsSheet .ActionsSheet__item .ActionsSheet__item__sub {
  margin-left: auto;
  padding-left: 24px;
  display: inline-flex;
  align-items: center;
}
