html,
body,
#root,
#root > div {
  min-height: 100%;
}
.MerchantScreen {
  height: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.MerchantStatus {
  width: 240px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.MerchantStatus .MerchantStatus__icon {
  width: 120px;
  height: 120px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.MerchantStatus .MerchantStatus__text {
  text-align: center;
  width: 180px;
  margin: 24px 0 40px;
}
