.TableMain {
  width: 100%;
  position: relative;
  overflow: hidden;
}
.TableMain .ContentBox__content,
.TableMain .Collapse__content {
  padding: 0 !important;
}
.TableMain .Table__header {
  width: 100%;
  height: 40px;
  display: flex;
}
.TableMain .Table__header span {
  margin: auto auto 0 auto;
  font-weight: 500;
}
.TableMain .Table__header span.icon {
  position: absolute;
  right: 16px;
  height: 56px;
  display: flex;
  color: var(--link-color);
}
.TableMain .Table__header span.icon svg {
  margin: auto;
}
.Table {
  border-collapse: collapse;
  width: 100%;
  overflow: hidden;
  position: relative;
}
.Table .Table__row {
  background: var(--primary-background);
}
.Table .Table__dark__row {
  background: var(--alice-blue);
}
.Table tr.dark {
  background: var(--alice-blue);
}
.Table tr.accent {
  background: var(--primary-blue-opacity);
}
.Table tr.success {
  background: var(--green-opacity);
}
.Table tr.warning {
  background: var(--primary-orange-opacity);
}
.Table tr.danger,
.Table tr.destroyed {
  background: var(--red-opacity);
}
.Table tr.Hover:hover {
  background: var(--primary-background);
}
.Table tr.Hover:hover .Table__td__cont {
  background: var(--primary-background);
}
.Table.compact tr.dark {
  background: transparent;
}
.Table.compact tr.dark .Table__td__cont {
  background: var(--alice-blue);
}
.Table.compact tbody td:first-child .Table__td__cont {
  border-radius: 12px 0 0 12px;
}
.Table.compact tbody td:last-child .Table__td__cont {
  border-radius: 0 12px 12px 0;
}
.Table thead td {
  color: var(--text-black);
  font-weight: 500;
}
.Table .Table__td__cont {
  display: flex;
  justify-content: center;
  flex: 1;
  flex-direction: column;
  padding: 0 8px;
  line-height: 24px;
  min-height: 56px;
  box-sizing: border-box;
}
.Table th,
.Table td {
  font-size: 13px;
  border: none;
  vertical-align: middle;
  padding: 0;
  margin: 0;
}
.Table th:first-child .Table__td__cont,
.Table td:first-child .Table__td__cont {
  padding-left: 24px;
}
.Table th:last-child .Table__td__cont,
.Table td:last-child .Table__td__cont {
  padding-right: 24px;
}
.Table.compact th,
.Table.compact td {
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
}
.Table.compact th .Table__td__cont,
.Table.compact td .Table__td__cont {
  min-height: 0;
}
.Table.compact th:first-child .Table__td__cont,
.Table.compact td:first-child .Table__td__cont {
  padding-left: 16px;
}
.Table.compact th:last-child .Table__td__cont,
.Table.compact td:last-child .Table__td__cont {
  padding-right: 16px;
}
.Table.compact thead td {
  height: 16px;
}
.Table.compact tbody td {
  padding-top: 4px;
}
.Table.compact tbody tr:first-child td {
  padding-top: 0;
}
.Table.compact .Table__td__cont {
  height: 24px;
}
.Table.compact.inline tbody td {
  padding-top: 0;
}
.Table.compact.inline .Table__td__cont {
  height: 16px;
  line-height: 16px;
}
.Table td.right {
  text-align: right;
}
.Table td.right .Table__td__cont {
  align-items: right;
}
.Table td.center {
  text-align: center;
}
.Table td.center .Table__td__cont {
  align-items: center;
}
.Table td.highlighted {
  color: var(--primary-orange);
}
.Table .Table__sub {
  color: var(--gray);
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
}
.Table__item {
  display: flex;
  align-items: center;
  margin: 0 8px;
  height: 20px;
}
.Table__item .isvg {
  margin: 0 5px;
}
.Table__item .Table__dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}
.Table__item_right {
  justify-content: flex-end;
}
.Table__item_center {
  justify-content: center;
}
.Table__item_highlighted {
  color: var(--primary-orange);
}
.Table__item__type_sent {
  color: #658bf5;
}
.Table__item__type_received {
  color: #24b383;
}
.adaptive .Table__header span {
  margin: auto;
}
.adaptive .Table.compact {
  border-collapse: separate;
  border-spacing: 0 1px;
}
.adaptive .Table.compact tr {
  border-bottom: 1px solid transparent;
}
.adaptive .Table.compact .Table__td__cont {
  height: auto;
  padding: 4px 0;
}
