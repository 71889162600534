.Filter {
  display: inline-flex;
  border: 1px solid #f5f1ee;
  box-sizing: border-box;
  border-radius: 12px;
  padding: 4px 8px;
  align-items: center;
  margin: 4px;
}
.Filter .Filter__name {
  color: var(--text-black);
  margin-right: 0.5em;
}
.Filter .Filter__cancel {
  cursor: pointer;
  color: var(--text-black);
  margin: -8px;
  margin-left: 2px;
  margin-right: -4px;
}
.Filter .Filter__cancel .isvg {
  display: block;
  line-height: 0;
}
