.UserBlockModal {
  margin: 42px 0;
  max-width: 320px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.UserBlockModal .isvg {
  display: block;
  width: 120px;
  height: 120px;
}
.UserBlockModal p {
  text-align: center;
  margin-top: 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 40px;
}
.UserBlockModal p a {
  font-weight: 600;
  color: inherit;
  text-decoration: inherit;
}
