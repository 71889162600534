.VerificationModal .VerificationModal__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  min-height: 400px;
  min-width: 400px;
}
.VerificationModal .LoadingStatus__wrap {
  margin: 24px 0;
}
.VerificationModal.loading .VerificationModal__content #sumsub,
.VerificationModal.failed .VerificationModal__content #sumsub {
  display: none;
}
.adaptive .VerificationModal__content {
  min-height: 100px;
  min-width: auto;
}
