.MainLanding-JoinUsCard {
  height: 485px;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  padding: 28px 35px;
  border: 1px solid #dae0ef;
  border-radius: 30px;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
}
.MainLanding-JoinUsCard__title {
  margin: auto 0 19px;
  font-weight: 600;
  font-size: 25px;
  line-height: 125%;
  color: var(--black-gunmetal);
}
.MainLanding-JoinUsCard a {
  display: inline-flex;
  align-items: center;
  font-weight: 600;
  font-size: 18px;
  line-height: 100%;
  letter-spacing: -0.01em;
  color: var(--royal-blue);
}
.MainLanding-JoinUsCard a .isvg {
  margin-left: 9px;
}
.MainLanding-JoinUsCard__background {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -60%);
}
.adaptive .MainLanding-JoinUsCard {
  align-items: center;
  height: 300px;
  width: 100%;
  min-width: 0;
}
.adaptive .MainLanding-JoinUsCard.education-card img {
  width: 168px;
}
.adaptive .MainLanding-JoinUsCard.governance-card img {
  width: 282px;
  height: 290px;
}
.adaptive .MainLanding-JoinUsCard .MainLanding-JoinUsCard__title {
  font-size: 18px;
  margin-bottom: 4px;
}
.adaptive .MainLanding-JoinUsCard a {
  font-size: 16px;
}
