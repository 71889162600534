.ModalState {
  min-height: 16px;
}
.ModalState.loading .LoadingStatus__wrap {
  height: initial;
}
.ModalState .LoadingStatus__wrap {
  position: relative !important;
  width: 300px;
  margin: 48px 0;
}
