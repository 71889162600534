.DocumentationMenu {
  width: 200px;
  position: relative;
  color: var(--text-black);
}
.DocumentationMenu .DocumentationMenu__controls {
  margin-bottom: 16px;
}
.DocumentationMenu .DocumentationMenu__editMode {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  margin: 8px 0;
}
.DocumentationMenu .DocumentationMenu__editMode .Switch {
  margin: 0;
  margin-left: auto;
}
.DocumentationMenu .DocumentationMenu__list .DocumentationMenu__list__item {
  display: block;
  cursor: pointer;
}
.DocumentationMenu .DocumentationMenu__list .DocumentationMenu__list__item.active > .DocumentationMenu__list__item__title {
  font-weight: bold;
  color: var(--almost-black);
}
.DocumentationMenu .DocumentationMenu__list .DocumentationMenu__list__item.active > .DocumentationMenu__list__item__title.active {
  color: var(--dark-blue);
  border-left: 2px solid;
  margin-left: -2px;
}
.DocumentationMenu .DocumentationMenu__list .DocumentationMenu__list__item .DocumentationMenu__list__item__title {
  display: block;
  padding: 12px 16px;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
}
.DocumentationMenu .DocumentationMenu__list .DocumentationMenu__list {
  margin-left: 14px;
  border-left: 2px solid var(--light-gray);
}
