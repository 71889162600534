.ProfileSidebar {
  width: 180px;
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
}
.ProfileSidebar .ProfileUser {
  padding: 30px 0 15px;
}
@supports (position: sticky) {
  .ProfileSidebar {
    position: sticky;
    overflow-y: auto;
    height: calc(100vh - 56px);
    top: 56px;
    align-self: flex-start;
  }
}
.ProfileSidebar .ProfileSidebar__top {
  margin-bottom: 24px;
}
.ProfileSidebar .ProfileSidebar__menu .ProfileSidebar__menu__item {
  display: flex;
  align-items: center;
  padding: 16px 24px;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  cursor: pointer;
  transition: color 100ms ease-in-out;
  text-decoration: none !important;
  color: var(--text-black);
  user-select: none;
}
.ProfileSidebar .ProfileSidebar__menu .ProfileSidebar__menu__item svg {
  flex: 0 0 auto;
  margin-right: 8px;
  width: 24px;
  height: 24px;
}
.ProfileSidebar .ProfileSidebar__menu .ProfileSidebar__menu__item:hover {
  color: var(--almost-black);
}
.ProfileSidebar .ProfileSidebar__menu .ProfileSidebar__menu__item.active {
  color: var(--black);
}
.ProfileSidebar .ProfileSidebar__menu .ProfileSidebar__menu__item .active {
  background-color: #000;
}
.ProfileSidebar .ProfileSidebar__menu .ProfileSidebar__menu__item_passive {
  color: var(--text-black);
}
.ProfileSidebar .ProfileSidebar__footer {
  margin-top: auto;
}
