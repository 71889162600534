.PrivatePools__Information {
  margin-top: 10px;
}
.PrivatePools__Information .deposit {
  margin-top: 10px;
}
.PrivatePools__Information .deposit > label > span {
  display: flex;
  justify-content: space-between;
}
.PrivatePools__Information .LoadingStatus {
  position: relative;
}
