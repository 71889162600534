.FarmingIndicator {
  padding: 6px 12px;
  border-radius: 8px;
  text-align: center;
  font-weight: 600;
  font-size: 10px;
  line-height: 100%;
  letter-spacing: -0.01em;
  color: #fff;
}
.FarmingIndicator.green {
  background: linear-gradient(90deg, #5ec47a 0%, #12cb45 100%);
  color: #ecf8f0;
}
.FarmingIndicator.red {
  background: linear-gradient(89.33deg, #f07f4f -0.62%, #ff5d17 99.42%);
}
