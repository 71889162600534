.Radio {
  display: flex;
  align-items: center;
  font-family: "Montserrat", sans-serif;
  cursor: pointer;
  user-select: none;
  margin-top: 16px;
}
.Radio:first-child {
  margin-top: 0;
}
.Radio.disabled {
  pointer-events: none;
}
.Radio__indicator {
  border: 2px solid var(--primary-blue);
  width: 24px;
  height: 24px;
  box-shadow: 0 7px 64px rgba(0, 0, 0, 0.07);
  box-sizing: border-box;
  position: relative;
  border-radius: 50%;
}
.Radio__indicator:after {
  content: '';
  display: block;
  position: absolute;
  transform: translate(-50%, -50%);
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: var(--blue-gradient);
  top: 50%;
  left: 50%;
  opacity: 0;
  transition: opacity 150ms ease-in-out;
}
.Radio.selected .Radio__indicator:after {
  opacity: 1;
}
.Radio.disabled .Radio__indicator {
  background: var(--cloudy);
  border: 0;
}
.Radio.disabled .Radio__indicator {
  background: none;
  border: 2px solid var(--cloudy);
}
.Radio.disabled .Radio__indicator:after {
  background: var(--cloudy);
}
.Radio__label {
  margin-left: 12px;
  font-size: 15px;
  line-height: 20px;
}
.Radio.small {
  margin-top: 5px;
}
.Radio.small .Radio__label {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
}
.Radio.small .Radio__indicator {
  width: 16px;
  height: 16px;
  border-width: 1px;
}
.Radio.small .Radio__indicator::after {
  width: 12px;
  height: 12px;
}
.Radio.light-blue .Radio__label {
  color: var(--cool-gray);
}
.Radio.light-blue .Radio__indicator {
  border-color: var(--cool-gray);
}
.Radio.light-blue.selected .Radio__indicator {
  border-color: var(--royal-blue);
}
.Radio.light-blue.selected .Radio__indicator::after {
  background: var(--royal-blue);
}
