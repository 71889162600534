.MainLanding-ProductCard {
  height: var(--card-height, 660px);
  width: 521px;
  padding: 61px 47px;
  border-radius: 45px;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  border: 2px solid #e5e9f4;
  background: #f7f9fc;
}
.MainLanding-ProductCard h4 {
  margin-bottom: 17px;
  font-weight: 600;
  font-size: 35px;
  line-height: 114%;
  color: var(--gunmetal);
}
.MainLanding-ProductCard p {
  margin-bottom: 60px;
  max-width: 360px;
  font-weight: 500;
  font-size: 18px;
  line-height: 160%;
  color: var(--gunmetal);
  opacity: 0.6;
}
.MainLanding-ProductCard-link__wrapper {
  display: flex;
  justify-content: flex-start;
}
.MainLanding-ProductCard a {
  display: flex;
  align-items: center;
  color: var(--royal-blue);
  font-weight: 600;
  font-size: 18px;
  line-height: 100%;
  letter-spacing: -0.01em;
}
.MainLanding-ProductCard a.disabled {
  user-select: none;
  pointer-events: none;
}
.MainLanding-ProductCard a .isvg {
  margin-left: 9px;
}
.MainLanding-ProductCard__content {
  position: relative;
  z-index: 2;
}
.MainLanding-ProductCard__background {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  pointer-events: none;
  user-select: none;
}
.MainLanding-ProductCard-statistics {
  display: flex;
  align-items: center;
  position: absolute;
  right: 46px;
  bottom: 26px;
  z-index: 3;
  color: var(--gunmetal);
}
.MainLanding-ProductCard-statistics__icon {
  margin-right: 14px;
  width: 45px;
  height: 45px;
  border-radius: 11px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.MainLanding-ProductCard-statistics__icon .isvg {
  display: flex;
}
.MainLanding-ProductCard-statistics__title {
  font-weight: 600;
  font-size: 21px;
  line-height: 114%;
}
.MainLanding-ProductCard-statistics__subtitle {
  margin-top: -5px;
  font-weight: 500;
  font-size: 14px;
  line-height: 160%;
  color: #7c7f88;
}
.MainLanding-ProductCard .MainLanding-ProductCard-statistics__icon {
  background: #f4f7fe;
}
.MainLanding-ProductCard.dark {
  background: #e6e9f4;
}
.MainLanding-ProductCard.dark .MainLanding-ProductCard-statistics__icon {
  background: #fff;
}
@keyframes productCardIsHidden {
  0%,
  100% {
    transform: translateX(200vw);
    opacity: 0;
  }
}
@keyframes showProductCard {
  0% {
    transform: translateX(200vw);
    opacity: 0;
  }
  100% {
    transform: none;
  }
}
.adaptive .MainLanding-ProductCard {
  height: var(--card-height, 524px);
  width: 315px;
  padding: 45px 32px 45px 35px;
  border-radius: var(--radius-panel-large);
}
.adaptive .MainLanding-ProductCard h4 {
  font-size: 23px;
  margin-bottom: 14px;
}
.adaptive .MainLanding-ProductCard p {
  font-size: 16px;
  margin-bottom: 30px;
}
.adaptive .MainLanding-ProductCard a {
  font-size: 16px;
}
.adaptive .MainLanding-ProductCard__background {
  width: 100%;
  height: 100%;
}
.adaptive .MainLanding-ProductCard__background img {
  height: inherit;
  width: inherit;
  object-fit: cover;
}
