.ExchangerModal__Currency {
  color: var(--dark-gunmetal);
}
.ExchangerModal__Currency .Number {
  margin: 0 10px;
  font-weight: 500;
  font-size: 26px;
  line-height: 110%;
}
.ExchangerModal__Currency-name {
  margin-bottom: 1px;
  font-weight: 500;
  font-size: 10px;
  line-height: 110%;
  color: #cdcfd7;
}
.ExchangerModal__Currency-currency {
  font-weight: 600;
  font-size: 15px;
  line-height: 110%;
}
.adaptive .ExchangerModal__Currency .Number {
  font-size: 20px;
  margin-left: 6px;
  margin-right: 8px;
}
.adaptive .ExchangerModal__Currency-currency {
  font-size: 14px;
}
