.CalcDepositModal {
  width: 568px;
  margin-top: 12px;
}
.CalcDepositModal__icon {
  position: absolute;
  top: 32px;
  left: -16px;
}
.CalcDepositModal__row {
  position: relative;
  display: flex;
  margin: 24px -12px;
}
.CalcDepositModal__row:last-child {
  margin-bottom: 0;
}
.CalcDepositModal__row .CalcDepositModal__column {
  display: flex;
  flex: 1;
  margin: 0 12px;
}
.CalcDepositModal__row .CalcDepositModal__column .Dropdown {
  flex: 1;
}
.CalcDepositModal__row .CalcDepositModal__column .Input {
  flex: 1;
}
.CalcDepositModal__row .CalcDepositModal__column .Button.outline {
  margin-left: 12px;
  padding: 0 16px;
}
.CalcDepositModal__description {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.CalcDepositModal__description .CalcDepositModal__description__label {
  font-size: 13px;
  line-height: 16px;
  margin-bottom: 8px;
  color: var(--text-black);
}
.CalcDepositModal__description .CalcDepositModal__description__value {
  font-size: 13px;
  line-height: 16px;
}
.CalcDepositModal__delimiter {
  height: 1px;
  background: black;
  opacity: 0.15;
  margin: 40px 0;
}
.CalcDepositModal__drop_button {
  position: absolute;
  width: 48px;
  right: 0;
}
.CalcDepositModal__drop_button .Button {
  width: 100%;
  padding: 0;
}
.CalcDepositModal__drop_button .Button .Button__label {
  line-height: 0;
}
.CalcDepositModal__drop_button .Button:hover {
  color: var(--red);
}
.CalcDepositModal__day {
  margin-bottom: 56px;
}
.CalcDepositModal__day:last-child {
  margin-bottom: 0;
}
.CalcDepositModal__result .Button {
  flex: 1;
}
.adaptive .CalcDepositModal {
  width: 100%;
}
.adaptive .CalcDepositModal__row {
  flex-direction: column;
  margin: 0;
}
.adaptive .CalcDepositModal__column {
  margin: 12px;
}
.adaptive .CalcDepositModal__drop_button {
  width: 64px;
}
.adaptive .CalcDepositModal__day .CalcDepositModal__row:first-child .Input__wrapper {
  width: calc(100% - 76px);
}
.adaptive .CalcDepositModal__column.amount .Button {
  width: 64px;
  flex: 0 0 auto;
}
.adaptive .CalcDepositModal__column:empty {
  display: none;
}
.adaptive .CalcDepositModal__delimiter {
  margin: 27px -16px;
}
.adaptive .CalcDepositModal__result {
  flex-direction: column-reverse;
}
.adaptive .CalcDepositModal__icon {
  left: 0;
  right: 0;
  top: -16px;
  margin: 0 auto;
}
.adaptive .CalcDepositModal__wrapper {
  padding-top: 80px;
}
