.CabinetSettingsScreen__padding_box {
  width: 100%;
  padding: 24px;
  box-sizing: border-box;
}
.CabinetSettingsScreen__header.withPadding {
  padding: 16px 16px 0;
}
.CabinetSettingsScreen__header {
  width: 100%;
  font-size: 16px;
  color: var(--almost-black);
  margin-bottom: 24px;
}
.CabinetSettingsScreen__main {
  width: 100%;
  box-sizing: border-box;
  margin-bottom: var(--gap-size);
}
.CabinetSettingsScreen__main .ContentBox__content {
  padding: 40px;
  font-size: 13px;
  line-height: 24px;
}
.CabinetSettingsScreen__main .CabinetSettingsScreen__w100wrapper {
  width: 100%;
  display: flex;
}
.CabinetSettingsScreen__main .CabinetSettingsScreen__relative {
  position: relative;
}
.CabinetSettingsScreen__main .CabinetSettingsScreen__relative p {
  font-size: 13px;
  line-height: 24px;
}
.CabinetSettingsScreen__main .CabinetSettingsScreen__form.left {
  width: 344px;
  margin: auto;
}
.CabinetSettingsScreen__main .CabinetSettingsScreen__form.left .CabinetSettingsScreen__input_field {
  width: 100%;
  margin-bottom: 40px;
}
.CabinetSettingsScreen__main .CabinetSettingsScreen__form.left .CabinetSettingsScreen__input_field:last-child {
  margin-bottom: 0;
}
.CabinetSettingsScreen__main .CabinetSettingsScreen__form.left .CabinetSettingsScreen__switch_field {
  width: 100%;
  display: flex;
  margin-bottom: 40px;
  font-size: 13px;
}
.CabinetSettingsScreen__main .CabinetSettingsScreen__form.left .CabinetSettingsScreen__switch_field span {
  height: 50px;
  line-height: 50px;
  display: flex;
}
.CabinetSettingsScreen__main .CabinetSettingsScreen__form.left .CabinetSettingsScreen__switch_field span .Switch {
  margin: auto auto auto 16px;
}
.CabinetSettingsScreen__main .CabinetSettingsScreen__form.right {
  width: calc(100% - 344px);
  margin: auto;
}
.CabinetSettingsScreen__main .CabinetSettingsScreen__form.right .Button {
  position: absolute;
  bottom: 0;
  right: 0;
  width: calc(100% - 360px);
}
.CabinetSettingsScreen__main .CabinetSettingsScreen__space {
  width: 100%;
  height: 40px;
}
.CabinetSettingsScreen__switch_item {
  width: 100%;
  padding: 8px 0px 8px 16px;
  box-sizing: border-box;
  border: 1px solid transparent;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  display: flex;
}
.CabinetSettingsScreen__switch_item .text_span {
  width: calc(100% - 60px);
  height: 28px;
  line-height: 28px;
  font-size: 13px;
}
.CabinetSettingsScreen__switch_item .switch_span {
  width: 60px;
}
.CabinetSettingsScreen__switch_item:hover {
  background-color: #fbf8f6;
  border-color: #f5f1ee;
}
.CabinetWalletScreen__transactions_padding {
  margin-top: var(--gap-size);
}
.adaptive .CabinetSettingsScreen__main .ContentBox__content {
  padding: 24px 16px;
}
.adaptive .CabinetSettingsScreen__space {
  height: 0;
}
.adaptive .CabinetSettingsScreen__header {
  margin-bottom: 16px;
  line-height: 24px;
}
.adaptive .CabinetSettingsScreen__input_field {
  margin-bottom: 25px !important;
}
.adaptive .CabinetSettingsScreen__relative {
  margin-bottom: 48px;
  display: block;
}
.adaptive .CabinetSettingsScreen__form.left,
.adaptive .CabinetSettingsScreen__form.right {
  width: 100%;
}
.adaptive .CabinetSettingsScreen__form.left .Button,
.adaptive .CabinetSettingsScreen__form.right .Button {
  position: relative;
  bottom: initial;
  width: 100%;
}
