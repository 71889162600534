.CreateReferralLink {
  max-width: 482px;
  padding: 32px;
  box-shadow: 0px 34px 70px rgba(199, 201, 207, 0.25);
  border-radius: var(--radius-panel-large);
  box-sizing: border-box;
}
.CreateReferralLink__header {
  padding-bottom: 17px;
  border-bottom: 2px solid #dfe3f0;
}
.CreateReferralLink__header .isvg {
  cursor: pointer;
}
.CreateReferralLink__header .isvg rect {
  fill: var(--royal-blue);
}
.CreateReferralLink__header .isvg:hover {
  opacity: 0.8;
}
.CreateReferralLink h2 {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: var(--dark-gunmetal);
}
.CreateReferralLink__description {
  margin-top: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: var(--cool-dark-gray);
}
.CreateReferralLink .isvg {
  display: flex;
  align-items: center;
}
.CreateReferralLink .Number {
  color: var(--secondary-blue);
}
.CreateReferralLink__main {
  margin: 51.9px 0 39px;
  box-sizing: border-box;
}
.CreateReferralLink__main > div:not(:last-child) {
  margin-right: 62px;
}
.CreateReferralLink__main .title {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: var(--dark-gunmetal);
}
.CreateReferralLink__main .title .HoverPopup {
  margin-left: 5px;
}
.CreateReferralLink__main .title .question-icon circle {
  stroke: var(--royal-blue);
}
.CreateReferralLink__main .title .question-icon path {
  fill: var(--royal-blue);
}
.CreateReferralLink__main .Number {
  font-weight: 500;
  font-size: 32px;
  line-height: 39px;
}
.CreateReferralLink__table {
  margin-bottom: 32px;
  padding: 18.25px 10px 19.75px;
  background: var(--alice-blue);
  border-radius: var(--radius-panel);
  box-sizing: border-box;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: var(--cool-gray);
}
.CreateReferralLink__table > div:not(:last-child) {
  margin-right: 32px;
}
.CreateReferralLink__buttons {
  margin-bottom: 32px;
}
.CreateReferralLink .Button {
  min-width: 90.5px;
  box-sizing: border-box;
}
.CreateReferralLink .Button.secondary .Button__label {
  color: var(--secondary-blue);
}
.CreateReferralLink .submit-button {
  width: 100%;
}
.CreateReferralLink .submit-button span:not(:last-child) {
  margin-right: 8px;
}
.CreateReferralLink-Bottom {
  padding: 0;
}
.CreateReferralLink-Bottom .CreateReferralLink {
  max-width: 100%;
  border-radius: 0;
  padding: 30px 20px;
}
