.DropdownElement__content {
  width: 100%;
}
.DropdownElement__dropElement {
  width: 100%;
}
.DropdownElement .dropdown-icon {
  transition: 0.2s;
}
.DropdownElement.active .dropdown-icon {
  transform: rotate(180deg);
}
