.MethodList .MethodList__title {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 8px;
}
.MethodList .MethodList__path {
  color: var(--dark-gray);
  margin-bottom: 32px;
}
.MethodList .MethodList__path .link {
  color: inherit;
}
.MethodList .MethodList__list {
  cursor: pointer;
  margin: 0 -40px;
}
.MethodList .MethodList__list .MethodList__list__line {
  display: flex;
  padding: 8px 40px;
}
.MethodList .MethodList__list .MethodList__list__line:nth-child(2n-1) {
  background: var(--alice-blue);
}
.MethodList .MethodList__list .MethodList__list__line .MethodList__list__method {
  font-size: 13px;
  line-height: 24px;
  width: 52px;
  flex: 0 0 auto;
  margin-right: 16px;
}
.MethodList .MethodList__list .MethodList__list__line .MethodList__list__method .Label {
  margin: 0;
  text-align: center;
  width: 100%;
}
.MethodList .MethodList__list .MethodList__list__line .MethodList__list__methodName {
  font-size: 13px;
  line-height: 24px;
  width: 160px;
  flex: 0 0 auto;
  margin-right: 16px;
}
.MethodList .MethodList__list .MethodList__list__line .MethodList__list__description {
  font-size: 13px;
  line-height: 24px;
}
