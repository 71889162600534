.HamburgerButton {
  position: relative;
  cursor: pointer;
  height: 24px;
  width: 24px;
}
.HamburgerButton .HamburgerButton__button {
  position: absolute;
  display: block;
  height: inherit;
  width: inherit;
  transition: opacity 0.3s ease;
  top: 0;
  left: 0;
}
.HamburgerButton .HamburgerButton__button svg {
  display: block;
}
.HamburgerButton .HamburgerButton__button:last-child {
  opacity: 0;
}
.HamburgerButton.active .HamburgerButton__button:first-child {
  opacity: 0;
}
.HamburgerButton.active .HamburgerButton__button:last-child {
  opacity: 1;
}
