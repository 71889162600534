.p2p h1,
.p2p h2,
.p2p h3,
.p2p h4,
.p2p h5 {
  color: var(--dark-black);
}
.p2p h3 {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
}
.p2p h5 {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}
.p2p p {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: var(--cool-gray);
}
.p2p .SwitchTabs {
  height: 46px;
  min-width: 240px;
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  border-radius: 10px;
  background: var(--alice-blue);
}
.p2p .SwitchTabs__item:not(.active) span {
  background: var(--blue-light-gradient);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: var(--blue-light-gradient-color-end);
}
.p2p .SwitchTabs__indicator span {
  border-radius: 10px;
}
.p2p .SwitchTabs.small {
  min-width: 174px;
}
.p2p .SocialLinks {
  padding-top: 38px;
}
.adaptive .p2p {
  padding-bottom: 20px;
}
.adaptive .p2p .SocialLinks {
  padding-top: 30px;
}
