.Select {
  position: relative;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: normal;
}
.Select .Select__option {
  display: flex;
  align-items: center;
}
.Select .Select__option .Select__option__icon {
  margin-right: 8px;
}
.Select .Select__option .Select__option__label {
  flex: 1;
}
.Select .Select__remove {
  color: var(--gray);
  margin-left: -8px;
}
.Select .Select__remove svg {
  display: block;
}
.Select .Select__arrow {
  color: var(--gray);
  transition: transform 0.3s ease;
}
.Select .Select__arrow.open {
  transform: rotate(180deg);
}
.Select .Select__arrow svg {
  display: block;
}
