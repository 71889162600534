.RateDetailsModal {
  width: 700px;
}
.RateDetailsModal__icon {
  width: 72px;
  height: 72px;
  background: no-repeat center / contain;
  position: absolute;
  left: -32px;
  top: 16px;
}
.RateDetailsModal__button_wrapper {
  text-align: center;
  margin: 24px 0 16px;
}
.RateDetailsModal__button_wrapper .Button {
  width: 200px;
}
.RateDetailsModal h3 {
  margin-bottom: 16px;
  text-transform: uppercase;
}
.RateDetailsModal__lists {
  display: flex;
  margin: 0 -20px;
  margin-top: 48px;
}
.RateDetailsModal__lists__list {
  flex: 1;
  list-style: none;
  padding: 0;
  margin: 0 20px;
}
.RateDetailsModal__lists__item {
  margin-bottom: 16px;
  font-size: 13px;
  line-height: 24px;
  display: flex;
}
.RateDetailsModal__lists__item ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.RateDetailsModal__lists__item ul li {
  padding: 0;
}
.RateDetailsModal__lists__item .isvg {
  display: block;
  width: 24px;
  height: 24px;
  margin-right: 8px;
  flex: 0 0 auto;
}
.adaptive .RateDetailsModal {
  width: 100%;
}
.adaptive .RateDetailsModal__wrapper {
  padding-top: 80px;
}
.adaptive .RateDetailsModal__lists {
  margin: 0;
  margin-top: 16px;
  flex-direction: column;
}
.adaptive .RateDetailsModal__lists__list {
  margin-bottom: 16px;
}
.adaptive .RateDetailsModal__icon {
  left: 0;
  right: 0;
  top: -32px;
  margin: 0 auto;
}
.adaptive .RateDetailsModal__button_wrapper {
  margin-bottom: 0;
}
