.SwiftGenerated__finally {
  padding: 0.5px 0;
}
.SwiftGenerated__finally .isvg {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 18px;
}
.SwiftGenerated__finally h3 {
  margin-bottom: 18px;
}
.SwiftGenerated__finally p.SwiftGenerated__finally__subtitle {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: var(--dark-black);
}
