.DappVideoModal {
  color: var(--black-gunmetal2);
}
.DappVideoModal .CabinetModal__container {
  padding: 30px 50px 40px;
  background: #fff;
  border-radius: var(--radius-panel);
}
.DappVideoModal iframe {
  width: 50vw;
  height: calc(50vw * (9 / 16));
}
.DappVideoModal h3 {
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
  text-align: center;
  margin-bottom: 15px;
}
.adaptive .DappVideoModal .CabinetModal__container {
  padding: 30px 20px 20px;
}
.adaptive .DappVideoModal iframe {
  width: 50vw;
  height: calc(50vw);
}
.adaptive .DappVideoModal h3 {
  margin-bottom: 15px !important;
}
