.BackgroundBit {
  position: absolute;
  display: flex;
}
.BackgroundBit .isvg {
  display: flex;
}
.BackgroundBit .isvg,
.BackgroundBit svg {
  width: inherit;
  height: inherit;
}
