body,
.SiteWrapper {
  --font-size-landing: 16px;
  --font-size-title: 32px;
  --padding-section: 80px;
  --padding-panel: 40px;
  --primary-orange: #ff9326;
  --primary-orange-opacity: rgba(255, 147, 38, 0.1);
  --dark-orange: #f28d00;
  --main-orange: #fa9751;
  --royal-blue: #5078e6;
  --secondary-blue: #3071ee;
  --primary-blue: #1949d7;
  --primary-blue-opacity: rgba(58, 101, 230, 0.1);
  --dark-blue: #1949d7;
  --primary-dark-blue: #365fd9;
  --orange-gradient-color-start: #ffa526;
  --orange-gradient-color-end: #ff860d;
  --orange-gradient: linear-gradient(0deg, var(--orange-gradient-color-end) 0%, var(--orange-gradient-color-start) 100%);
  --light-orange-gradient-color-start: #fa9751;
  --light-orange-gradient-color-end: #fabe4c;
  --light-orange-gradient: linear-gradient(180deg, var(--light-orange-gradient-color-start) 0%, var(--light-orange-gradient-color-end) 100%);
  --blue-gradient-color-start: #4d72df;
  --blue-gradient-color-end: #274cbd;
  --blue-gradient: linear-gradient(0deg, var(--blue-gradient-color-end) 0%, var(--blue-gradient-color-start) 100%);
  --blue-light-gradient-color-start: #78a0ff;
  --blue-light-gradient-color-end: #5078e5;
  --blue-light-gradient: linear-gradient(135deg, var(--blue-light-gradient-color-start) 0%, var(--blue-light-gradient-color-end) 100%),
    #224dcf;
  --primary-blue-color-start: #4070ff;
  --primary-blue-color-end: var(--primary-dark-blue);
  --primary-blue-gradient: linear-gradient(180deg, var(--primary-blue-color-start) 0%, var(--primary-blue-color-end) 100%);
  --gunmetal: #2f323d;
  --black-gunmetal2: #2f323d;
  --black-gunmetal: #2d313f;
  --dark-gunmetal: #222330;
  --dark-black: #0a1539;
  --black: #1c212e;
  --almost-black: #2e323e;
  --text-black: #3a3f4d;
  --cool-gray: #9095a5;
  --cool-dark-gray: #757575;
  --slate-gray: #595e6b;
  --dark-gray: #7b808c;
  --gray: #9ea2ad;
  --light-gray: #c9ccd4;
  --extra-light-gray: #e4e6ed;
  --sidebar-icon: #c9ccd4;
  --cloudy: #edf0f5;
  --alice-blue: #f7f9fc;
  --azureish-white: #dfe3f0;
  --white: #fff;
  --text-secondary: #4e5c85;
  --text-green: #008060;
  --light-green: #5ec47a;
  --green: #00b277;
  --green-opacity: rgba(0, 178, 119, 0.1);
  --light-green: #ebf5f5;
  --text-yellow: #e6aa00;
  --yellow: #ffc832;
  --yellow-opacity: rgba(255, 200, 50, 0.1);
  --light-yellow: #fff5e6;
  --orange-error: #ff5d17;
  --text-red: #e51300;
  --red: #ff4433;
  --red-opacity: rgba(240, 55, 35, 0.1);
  --light-red: #fff5f5;
  --small-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15),
    0px 1px 4px rgba(0, 0, 0, 0.1);
  --main-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08),
    0px 0px 2px rgba(0, 0, 0, 0.15);
  --hover-shadow: 0px 6px 24px rgba(0, 0, 0, 0.08),
    0px 0px 2px rgba(0, 0, 0, 0.1);
  --primary-background: var(--white);
  --secondary-background: var(--alice-blue);
  --radius-panel: 12px;
  --radius-panel-large: 25px;
  --radius-button: 12px;
}
body.theme-dark {
  --primary-orange: #ff9326;
  --primary-orange-opacity: rgba(255, 147, 38, 0.1);
  --dark-orange: #f28d00;
  --primary-blue: #3b6dff;
  --primary-blue-opacity: rgba(58, 101, 230, 0.1);
  --dark-blue: #1949d7;
  --orange-gradient-color-start: #ffa526;
  --orange-gradient-color-end: #ff860d;
  --orange-gradient: linear-gradient(0deg, var(--orange-gradient-color-end) 0%, var(--orange-gradient-color-start) 100%);
  --blue-gradient-color-start: #4d72df;
  --blue-gradient-color-end: #274cbd;
  --blue-gradient: linear-gradient(0deg, var(--blue-gradient-color-end) 0%, var(--blue-gradient-color-start) 100%);
  --black: #d9d9d9;
  --almost-black: #d9d9d9;
  --text-black: #dbdbdb;
  --slate-gray: #595e6b;
  --dark-gray: #7b808c;
  --gray: #9ea2ad;
  --light-gray: #192230;
  --extra-light-gray: #1a2332;
  --sidebar-icon: #c9ccd4;
  --cloudy: #25354a;
  --alice-blue: #f7f9fc;
  --white: #fff;
  --text-secondary: #4e5c85;
  --text-green: #008060;
  --green: #00b277;
  --green-opacity: rgba(0, 178, 119, 0.1);
  --light-green: #ebf5f5;
  --text-yellow: #e6aa00;
  --yellow: #ffc832;
  --yellow-opacity: rgba(255, 200, 50, 0.1);
  --light-yellow: #fff5e6;
  --text-red: #e51300;
  --red: #ff4433;
  --red-opacity: rgba(240, 55, 35, 0.1);
  --light-red: #fff5f5;
  --small-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15),
    0px 1px 4px rgba(0, 0, 0, 0.1);
  --main-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08),
    0px 0px 2px rgba(0, 0, 0, 0.15);
  --hover-shadow: 0px 6px 24px rgba(0, 0, 0, 0.08),
    0px 0px 2px rgba(0, 0, 0, 0.1);
  --primary-background: #233044;
  --secondary-background: #1b2635;
}
body {
  --gap-size: 20px;
}
@media (max-width: 768px) {
  body {
    --gap-size: 16px;
  }
}
@keyframes showElement {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.SwapForm {
  margin-bottom: var(--gap-size);
  border-radius: var(--radius-panel-large);
  border: none;
}
.SwapForm .ContentBox__content {
  width: 100%;
  padding: 33px 43px 32px;
  box-sizing: border-box;
}
.SwapForm__formWrapper {
  display: flex;
  margin-bottom: 8px;
}
.SwapForm__form {
  flex: 1;
}
.SwapForm__form__control:not(:last-child) {
  margin-bottom: 65px;
}
.SwapForm__form__label {
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  margin-bottom: 16px;
}
.SwapForm__form__label:empty {
  display: none;
}
.SwapForm__form__control {
  margin-bottom: 26px;
}
.SwapForm__form__control .SwapForm__input {
  text-align: right;
}
.SwapForm__form__control .Dropdown {
  border-radius: 20px;
  border: 1px solid var(--azureish-white);
}
.SwapForm__form__control .Dropdown__header {
  padding: 16px 8px;
}
.SwapForm__form__control .Dropdown__options {
  border-radius: inherit;
  border: 1px solid var(--azureish-white);
}
.SwapForm__form__control .Dropdown .Dropdown__option__title {
  font-weight: 600;
  font-size: 18px;
  line-height: 16px;
  color: #1c212e;
}
.SwapForm__form__control .Input {
  --input-height: 72px;
  border-radius: 20px;
  border: 1px solid var(--azureish-white);
}
.SwapForm__form__control__meta {
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  color: var(--gray);
  margin: 7px 14px 0;
}
.SwapForm__form__control__meta.right {
  text-align: right;
}
.SwapForm__form__control__meta.active {
  cursor: pointer;
}
.SwapForm__form__control__meta.active:hover {
  color: var(--primary-blue);
}
.SwapForm__separator {
  position: relative;
  width: 104px;
  margin: 32px 0 40px;
  display: flex;
}
.SwapForm__separator:before {
  position: absolute;
  content: '';
  display: block;
  width: 1px;
  height: 100%;
  left: 0;
  right: 0;
  margin: auto;
  background: var(--black);
  transform-origin: left center;
  opacity: 0.2;
  z-index: -1;
}
@media (min-resolution: 2dppx) {
  .SwapForm__separator:before {
    transform: scaleX(0.5);
  }
}
@media (min-resolution: 3dppx) {
  .SwapForm__separator:before {
    transform: scaleX(0.32);
  }
}
.SwapForm__switchButton {
  position: relative;
  margin: auto;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: var(--primary-blue-gradient);
  color: var(--white);
  display: flex;
  border: 8px solid var(--primary-background);
  cursor: pointer;
  background: var(--light-gray);
  cursor: default;
  pointer-events: none;
}
.SwapForm__switchButton .isvg {
  transition: 0.3s ease;
  margin: auto;
  display: block;
}
.SwapForm__switchButton .isvg svg {
  display: block;
}
.SwapForm__switchButton:hover {
  background: var(--dark-blue);
}
.SwapForm__switchButton::after {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  content: '';
  height: 24px;
  width: 24px;
  display: block;
  filter: invert(1);
  background: url('../../../../asset/24px/loading.svg');
  animation: Button__loader_anim 1s infinite linear;
  z-index: 1;
  opacity: 0;
  transform: scale(0.3);
  transition: 0.3s ease;
}
.SwapForm__switchButton.loading {
  pointer-events: none;
}
.SwapForm__switchButton.loading .isvg {
  opacity: 0;
  transform: scale(0.3);
}
.SwapForm__switchButton.loading::after {
  opacity: 1;
  transform: scale(1);
}
.SwapForm__submitWrapper {
  display: flex;
}
.SwapForm__submitWrapper .Button {
  margin: 0 auto;
  padding: 0 24px;
}
@media screen and (max-width: 768px) {
  .SwapForm {
    box-shadow: 0px 34px 70px rgba(199, 201, 207, 0.25);
  }
  .SwapForm .ContentBox__content {
    width: 100%;
    padding: 43px 18px 32px;
    box-sizing: border-box;
  }
  .SwapForm__formWrapper {
    flex-direction: column;
    align-items: center;
    margin-bottom: 33px;
  }
  .SwapForm__form {
    width: 100%;
  }
  .SwapForm__form__control {
    margin-bottom: 0;
  }
  .SwapForm__form__control:not(:last-child) {
    margin-bottom: 0;
  }
  .SwapForm__form__control__meta {
    margin-top: 8px;
    font-size: 12px;
  }
  .SwapForm__separator {
    margin: 23px 0;
  }
  .SwapForm__switchButton {
    background: #e5ebfc;
    width: 40px;
    height: 40px;
  }
  .SwapForm__switchButton svg {
    width: 24px;
    height: 24px;
    transform: rotate(90deg);
  }
  .SwapForm__submitWrapper .Button {
    width: 100%;
    height: 65px;
  }
}
