.DexRoute {
  width: 100%;
  margin: 24px 0;
}
.DexRoute h3 {
  padding: 0 8px;
  display: inline-flex;
  align-items: center;
}
.DexRoute h3 .AnswerPopup {
  margin-left: 9px;
}
.DexRoute h3 span {
  position: relative;
  font-size: 16px;
  font-weight: 600;
}
.DexRoute h3 .HoverPopup {
  font-size: 12px;
  font-weight: 500;
  display: inline-block;
}
.DexRoute h3 .HoverPopup__children {
  background: rgba(45, 49, 63, 0.21);
  width: 14px;
  height: 14px;
  color: white;
  font-size: 10px;
  font-weight: 600;
  line-height: 2em;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.DexRoute-container {
  display: flex;
  padding: 21px 19px;
  border: 1px solid var(--light-gray);
  border-radius: 20px;
  margin-top: 10px;
  width: 100%;
  box-sizing: border-box;
  overflow-x: scroll;
}
.DexRoute-container::-webkit-scrollbar {
  display: none;
}
.DexRoute-symbol {
  display: flex;
  align-items: center;
}
.DexRoute-symbol span {
  font-size: 16px;
  font-weight: 600;
}
.DexRoute-arrow {
  margin: 0 12px;
}
.DexRoute-logo {
  width: 30px;
  height: 30px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
  margin-right: 8px;
}
.adaptive .DexRoute {
  margin: 24px 0;
}
.adaptive .DexRoute h3 {
  text-align: center;
  padding: 0;
}
.adaptive .DexRoute-container {
  margin: 10px auto 0;
}
