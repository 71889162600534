.RoadmapModal {
  position: absolute;
  width: 207px;
  background: #fff;
  border-radius: 18px;
  padding: 31px 24px 28px;
  box-sizing: border-box;
  z-index: 10;
}
.RoadmapModal svg {
  margin-right: 15px;
}
.RoadmapModal span {
  display: block;
}
.RoadmapModal__tasks {
  margin-top: 13px;
}
.RoadmapModal__task {
  display: flex;
  font-size: var(--landing-text-size);
  font-weight: var(--landing-text-weight);
  font-weight: 500;
  line-height: 210%;
  color: var(--landing-color-gray);
  overflow-wrap: anywhere;
  padding: 0 2px;
}
.RoadmapModal__task:not(:last-child) {
  margin-bottom: 3px;
}
