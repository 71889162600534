.TabBar {
  display: flex;
  position: fixed;
  bottom: 0;
  width: 100vw;
  background: var(--primary-background);
  border-radius: 8px 8px 0 0;
  box-shadow: var(--main-shadow);
  height: 48px;
  z-index: 100;
}
.TabBar__item {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--dark-gray);
  flex: 1;
}
.TabBar__item:hover {
  text-decoration: none;
}
.TabBar__item.active {
  color: var(--primary-blue);
}
.TabBar__item.active .TabBar__item__text {
  font-weight: 600;
}
.TabBar__item.disabled {
  opacity: 0.3;
  pointer-events: none;
}
.TabBar__item .isvg {
  display: block;
  line-height: 0;
}
.TabBar__item .isvg svg {
  display: block;
}
.TabBar__item__text {
  font-size: 7px;
  line-height: 12px;
}
.TabBar__item__primaryButton {
  position: relative;
  top: -8px;
  display: flex;
  background: var(--blue-gradient);
  border-radius: 40px;
  height: 40px;
  width: 40px;
  color: var(--white);
}
.TabBar__item__primaryButton .isvg {
  margin: auto;
}
