.DepositModal__Bank .Modal__box {
  width: 100%;
}
.DepositModal__Bank.small .Modal__box {
  max-width: 441px;
}
.DepositModal__Bank.medium .Modal__box {
  max-width: 468px;
}
.DepositModal__Bank .DepositModal__container {
  padding: 0;
  background: #fff;
  box-sizing: border-box;
}
.DepositModal__Bank__container {
  flex: 1 1;
  box-sizing: border-box;
}
.DepositModal__Bank .BankLogo {
  margin-right: 25px;
}
.DepositModal__Bank .bankIcon {
  height: 30px;
  margin-right: 25px;
  max-width: 80%;
  object-fit: contain;
}
.DepositModal__Bank .Button {
  height: 50px;
}
.DepositModal__Bank .Button__label {
  font-weight: 600;
  font-size: 14px;
  line-height: 100%;
}
.adaptive .DepositModal__Bank.small .Modal__box,
.adaptive .DepositModal__Bank.medium .Modal__box {
  max-width: 100%;
  width: 100%;
  box-sizing: border-box;
}
.adaptive .DepositModal__Bank__container {
  align-items: flex-start !important;
  min-height: 0;
}
.adaptive .DepositModal__Bank .DepositModal__container {
  width: 100%;
  padding: 0;
}
.adaptive .DepositModal__Bank__sidebar {
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
}
