.SiteTokenScreen__Benefits {
  width: 100%;
  max-width: 800px;
  margin: 200px auto;
  opacity: 1;
  transform: translate(0);
  transition: opacity 1s ease, transform 1s ease;
}
.SiteTokenScreen__Benefits .SiteTokenScreen__Benefits__content {
  max-width: 590px;
}
.SiteTokenScreen__Benefits .SiteTokenScreen__Benefits__content h2 {
  font-size: var(--title1-font-size);
  line-height: var(--title1-line-height);
  margin-bottom: 40px;
}
.SiteTokenScreen__Benefits .SiteTokenScreen__Benefits__content p {
  margin-bottom: 24px;
}
.SiteTokenScreen__Benefits .SiteTokenScreen__Benefits__list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
  margin: 40px 0;
}
.SiteTokenScreen__Benefits .SiteTokenScreen__Benefits__list .SiteTokenScreen__Benefits__list__item {
  align-items: center;
  display: flex;
  opacity: 1;
  transform: scale(1);
  transition: opacity 1s ease, transform 1s ease;
}
.SiteTokenScreen__Benefits .SiteTokenScreen__Benefits__list .SiteTokenScreen__Benefits__list__item:nth-child(1) {
  transition-delay: 0s;
}
.SiteTokenScreen__Benefits .SiteTokenScreen__Benefits__list .SiteTokenScreen__Benefits__list__item:nth-child(2) {
  transition-delay: 0.5s;
}
.SiteTokenScreen__Benefits .SiteTokenScreen__Benefits__list .SiteTokenScreen__Benefits__list__item:nth-child(3) {
  transition-delay: 1s;
}
.SiteTokenScreen__Benefits .SiteTokenScreen__Benefits__list .SiteTokenScreen__Benefits__list__item:nth-child(4) {
  transition-delay: 1.5s;
}
.SiteTokenScreen__Benefits .SiteTokenScreen__Benefits__list .SiteTokenScreen__Benefits__list__item .isvg {
  margin-left: -24px;
}
.SiteTokenScreen__Benefits .SiteTokenScreen__Benefits__list .SiteTokenScreen__Benefits__list__item svg {
  width: 120px;
  height: 120px;
  display: block;
}
.SiteTokenScreen__Benefits.notVisible {
  opacity: 0;
  transform: translate(0, 100px);
}
.SiteTokenScreen__Benefits .SiteTokenScreen__Benefits__list.notVisible .SiteTokenScreen__Benefits__list__item {
  opacity: 0;
  transform: scale(0.9);
}
@media (max-width: 800px) {
  .SiteTokenScreen__Benefits .SiteTokenScreen__Benefits__list {
    grid-template-columns: 1fr;
    grid-gap: 0;
  }
  .SiteTokenScreen__Benefits .SiteTokenScreen__Benefits__list .SiteTokenScreen__Benefits__list__item .isvg {
    margin-left: 0;
  }
  .SiteTokenScreen__Benefits .SiteTokenScreen__Benefits__list .SiteTokenScreen__Benefits__list__item svg {
    display: block;
    width: 80px;
    height: 80px;
  }
}
