.Validator {
  --header-height: 79px;
  padding: 19px 18px 0;
  width: 100%;
  box-sizing: border-box;
}
.Validator .SocialLinks {
  padding-bottom: 30px;
  margin-left: 50px;
}
.Validator .SocialLinks > * {
  margin-top: 38px;
}
