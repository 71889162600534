.DappUI__Overlay {
  display: flex;
  justify-content: center;
  align-items: center;
}
.DappUI__Overlay,
.DappUI__Overlay__bg {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: inherit;
  box-sizing: border-box;
}
.DappUI__Overlay__bg {
  background: linear-gradient(179.85deg, #ffffff 0.13%, rgba(255, 255, 255, 0.71) 107.49%);
}
.DappUI__Overlay__container {
  padding: 28px;
  position: relative;
}
.DappUI__Overlay h3 {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
}
.adaptive .DappUI__Overlay {
  align-items: stretch;
}
.adaptive .DappUI__Overlay__bg {
  background: linear-gradient(179.85deg, rgba(255, 255, 255, 0.9) 0.13%, rgba(255, 255, 255, 0.18) 95.94%);
}
.adaptive .DappUI__Overlay__container {
  padding-top: 44px;
}
