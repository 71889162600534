@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
.Web3Wallets {
  margin-bottom: var(--gap-size);
}
.Web3Wallets .ContentBox__content {
  padding: var(--padding-panel);
}
.adaptive .Web3Wallets .ContentBox__content {
  padding: 24px 16px 24px;
}
.Web3Wallets h2 {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 40px;
  display: flex;
  align-items: center;
}
.Web3Wallets-addresses-item:not(:first-child) {
  margin-top: 40px;
}
.Web3Wallets-addresses-item-body {
  display: flex;
  justify-content: space-between;
}
.Web3Wallets-addresses-item-right {
  display: flex;
  align-items: flex-start;
  font-weight: 800;
  font-size: 24px;
  text-transform: uppercase;
}
.Web3Wallets-addresses-item-right small {
  background: var(--primary-blue-opacity);
  color: var(--text-black);
  display: block;
  padding: 0 8px;
  border-radius: var(--radius-button);
}
.adaptive .Web3Wallets-addresses-item-right {
  display: none;
}
.Web3Wallets-addresses-item-address {
  font-size: 12px;
  font-weight: 500;
}
.Web3Wallets-balances {
  display: flex;
  flex-wrap: wrap;
  margin: 4px -11px;
}
.Web3Wallets-balances-loading {
  display: flex;
  align-items: center;
  margin: 4px 8px;
}
.Web3Wallets-balances-loading .isvg {
  transform: scale(0.5) translateY(3px);
}
.Web3Wallets-balances-loading .isvg svg {
  animation: spin 1s linear infinite;
}
.Web3Wallets-balances-loading .isvg svg path {
  fill: #fabe4c;
}
.Web3Wallets-balances-loading-text {
  font-size: 13px;
  font-weight: 600;
  margin-left: 6px;
}
.Web3Wallets-balances-unknown {
  font-size: 13px;
  font-weight: 600;
  display: flex;
  align-items: center;
  margin: 4px 12px;
}
.Web3Wallets-balances-unknown:before {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: var(--gray);
  margin-right: 6px;
}
.Web3Wallets-balance {
  display: flex;
  align-items: center;
  margin: 4px 12px;
}
.Web3Wallets-balance-circle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}
.Web3Wallets-balance-amount {
  font-size: 13px;
  font-weight: 600;
  margin-left: 6px;
}
.Web3Wallets-balance-currency {
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
  margin-left: 6px;
}
.Web3Wallets-controls {
  margin-top: 40px;
  display: flex;
  justify-content: center;
}
.Web3Wallets-controls.delete {
  justify-content: flex-end;
}
.Web3Wallets-controls .Button:not(:first-child) {
  margin-left: 16px;
}
