.WalletTransactionModal__icon {
  position: absolute;
  left: -16px;
  top: 32px;
}
.WalletTransactionModal__status {
  display: flex;
  margin-top: 24px;
}
.WalletTransactionModal__status__row {
  flex: 1;
}
.WalletTransactionModal__status__row.right {
  text-align: right;
}
.WalletTransactionModal__status__row__label {
  color: var(--text-black);
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
}
.WalletTransactionModal__status__row__value {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-top: 4px;
}
.WalletTransactionModal__status__row__value.pending {
  color: var(--primary-orange);
}
.WalletTransactionModal__status__row__value.done {
  color: var(--green);
}
.WalletTransactionModal__status__row__value.canceled {
  color: var(--red);
}
.adaptive .WalletTransactionModal__icon {
  left: 0;
  right: 0;
  top: -16px;
  margin: 0 auto;
}
.adaptive .WalletTransactionModal__wrapper {
  padding-top: 80px;
}
